import React from 'react';
import Badge from '@atlaskit/badge';
import { Emoji } from '@atlaskit/emoji';
import { Box, xcss, Inline } from '@atlaskit/primitives';
import {
	useFieldEmoji,
	useFieldLabel,
	useFieldTypeIcon,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';

type Props = {
	fieldKey: string;
	issuesCount: number;
};

export const Header = ({ fieldKey, issuesCount }: Props) => {
	const icon = useFieldTypeIcon(fieldKey, undefined);
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);
	const label = useFieldLabel(fieldKey);

	return (
		<Inline space="space.150" alignBlock="center">
			{emoji ? (
				<Emoji emoji={emoji} fitToHeight={16} showTooltip />
			) : (
				<Box xcss={iconContainerStyles}>{icon}</Box>
			)}
			{label}
			{issuesCount > 0 && <Badge>{issuesCount.toString()}</Badge>}
		</Inline>
	);
};

const iconContainerStyles = xcss({
	width: '16px',
	height: '16px',
});
