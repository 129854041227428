import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { jpdQuickStartViewVideoExperimentEntryPoint } from '../entrypoint.tsx';
import { PACKAGE_NAME, ENTRY_POINT_ID } from './constants.tsx';
import type { QuickStartViewVideoEntryPointProps } from './types.tsx';

const entryPointParams = {};
export const AsyncPolarisQuickStartViewVideo = (props: QuickStartViewVideoEntryPointProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		jpdQuickStartViewVideoExperimentEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id={ENTRY_POINT_ID}
			packageName={PACKAGE_NAME}
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
		/>
	);
};
