import React from 'react';
import JpdTimelineBlankIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/jpd-timeline-blank/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages as commonMessages } from '../../../common/messages.tsx';
import { Container, Wrapper } from '../../../common/ui/container/styled.tsx';
import { ViewEmptyState } from '../../../common/ui/view-empty-state/index.tsx';
import type { ViewKindEmptyStateProps } from '../../../types.tsx';
import { messages } from './messages.tsx';
import { ColumnPlaceholder, TimelineViewPlaceholder } from './styled.tsx';

export const TimelineViewEmptyState = ({
	onButtonClick,
	isHintHidden = false,
	isCollectionView,
}: ViewKindEmptyStateProps) => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			{!isHintHidden && (
				<Wrapper>
					<ViewEmptyState
						renderImage={JpdTimelineBlankIllustrationWrapper}
						header={formatMessage(messages.header)}
						description={
							<>
								{formatMessage(messages.description1)}
								{!isCollectionView && (
									<>
										<br />
										{formatMessage(messages.description2)}
									</>
								)}
							</>
						}
						buttonText={formatMessage(commonMessages.buttonTextFields, {
							fieldsCount: 2,
						})}
						onButtonClick={onButtonClick}
					/>
				</Wrapper>
			)}
			<TimelineViewPlaceholder>
				{[...Array.from({ length: 5 })].map((_, i) => (
					<ColumnPlaceholder key={i} />
				))}
			</TimelineViewPlaceholder>
		</Container>
	);
};

const JpdTimelineBlankIllustrationWrapper = () => (
	<JpdTimelineBlankIllustration alt="" width={306} />
);
