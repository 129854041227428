import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import InlineEdit, { type InlineEditProps } from '@atlaskit/inline-edit';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { ErrorContainer } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/styled.tsx';
import { CustomTooltip } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/tooltip/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MAX_NUMBER } from '../../../../common/constants.tsx';
import { getAppliedNumberDecoration } from '../../../../controllers/field/decoration/index.tsx';
import { DecoratedNumber } from '../../../decoration/number/index.tsx';
import { messages } from './messages.tsx';

type NumericNumberFieldReadViewInternalProps = {
	value: number | undefined;
	decorations: ValueDecoration[] | undefined;
	placeholder?: string | undefined;
};

type NumericNumberFieldReadViewProps = {
	isEditable?: boolean;
} & NumericNumberFieldReadViewInternalProps;

type NumericNumberFieldProps = NumericNumberFieldReadViewProps & {
	isIdeaView?: boolean;
	onUpdate: ((inputValue: number | undefined) => void) | undefined;
	onModeChanged?: (arg1: { isEditing: boolean }) => void;
	testId?: string;
};

export const NumericNumberFieldReadViewInternal = ({
	decorations,
	value,
	placeholder,
}: NumericNumberFieldReadViewInternalProps) => {
	const decoration = getAppliedNumberDecoration(decorations, value);

	return <DecoratedNumber decoration={decoration} value={value} placeholder={placeholder} />;
};

export const NumericNumberFieldReadView = ({
	decorations,
	value,
	placeholder,
	isEditable,
}: NumericNumberFieldReadViewProps) => (
	<ReadViewContainer isEditable={isEditable}>
		<NumericNumberFieldReadViewInternal
			decorations={decorations}
			value={value}
			placeholder={placeholder}
		/>
	</ReadViewContainer>
);

export const NumericNumberField = ({
	isIdeaView,
	value,
	decorations,
	onUpdate,
	isEditable,
	placeholder,
	onModeChanged,
	testId,
}: NumericNumberFieldProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const initialValue = value !== undefined && value !== null ? Number(value) : undefined;

	const validateNumberInput = (newValue: string) => {
		if (newValue && Number(newValue) > MAX_NUMBER) {
			return formatMessage(messages.largeNumberTypeError);
		}
		return undefined;
	};

	const handleConfirm = useCallback(
		(newValue: string) => {
			const valueForUpdate =
				newValue === '' || newValue === null || newValue === undefined
					? undefined
					: Number(newValue);

			if (valueForUpdate !== initialValue) {
				onUpdate?.(valueForUpdate);
			}

			onModeChanged && onModeChanged({ isEditing: false });
		},
		[onModeChanged, onUpdate, initialValue],
	);

	const handleEdit = useCallback(() => {
		onModeChanged && onModeChanged({ isEditing: true });
	}, [onModeChanged]);

	const readView = useCallback(
		() => (
			<NumericNumberFieldReadViewInternal
				decorations={decorations}
				value={value}
				placeholder={placeholder}
			/>
		),
		[decorations, value, placeholder],
	);

	const onTextFieldFocus = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'focused', actionSubject: 'textField' }),
			'issueField',
		);
	}, [createAnalyticsEvent]);

	const editView: InlineEditProps<string>['editView'] = useCallback(
		({ errorMessage, ...fieldProps }) => (
			<EditViewContainer>
				{isIdeaView ? (
					<>
						<TextField
							{...fieldProps}
							type="Number"
							autoFocus
							isCompact
							onFocus={onTextFieldFocus}
							isInvalid={!!errorMessage}
						/>
						{!!errorMessage && (
							<ErrorContainer data-testid={testId && `${testId}--error`}>
								{errorMessage}
							</ErrorContainer>
						)}
					</>
				) : (
					<TextField
						{...fieldProps}
						type="Number"
						autoFocus
						isCompact
						onFocus={onTextFieldFocus}
						isInvalid={!!errorMessage}
						elemAfterInput={
							!!errorMessage && (
								<Tooltip component={CustomTooltip} content={errorMessage} position="bottom-end">
									<ErrorIconContainer>
										{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
										<ErrorIcon primaryColor={colors.R400} label="error" />
									</ErrorIconContainer>
								</Tooltip>
							)
						}
					/>
				)}
			</EditViewContainer>
		),
		[isIdeaView, onTextFieldFocus, testId],
	);

	return isEditable === true ? (
		<WriteViewContainer data-testid={testId}>
			<InlineEdit
				defaultValue={initialValue}
				readViewFitContainerWidth
				onConfirm={handleConfirm}
				validate={validateNumberInput}
				onEdit={handleEdit}
				readView={readView}
				editView={editView}
				hideActionButtons
			/>
		</WriteViewContainer>
	) : (
		// Inline Edit component wraps a border around the read view component, we do the same here
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div data-testid={testId} style={{ border: '2px solid transparent' }}>
			{readView()}
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WriteViewContainer = styled.div({
	marginTop: token('space.negative.100', '-8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div<{ isEditable?: boolean }>({
	minHeight: '16px',
	lineHeight: '16px',
	padding: '3px 0',
	overflow: 'hidden',
	wordBreak: 'break-word',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: ({ isEditable }) => `${isEditable !== false ? 2 : 0}px solid transparent`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	border: '2px solid transparent',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorIconContainer = styled.div({
	cursor: 'pointer',
	marginBottom: token('space.negative.025', '-2px'),
	marginRight: token('space.025', '2px'),
});
