import { setCollectionsTourSpotlightStep } from './collections/tour-spotlights/index.tsx';
import {
	loadCollectionsTourDismissed,
	setCollectionsTourDismissed,
} from './collections/tour/index.tsx';
import {
	loadCrossProjectListViewFieldsDismissed,
	setCrossProjectListViewFieldsDismissed,
} from './cross-project-view/fields-section/index.tsx';
import { setCrossProjectViewTourSpotlightStep } from './cross-project-view/tour-spotlights/index.tsx';
import {
	loadCrossProjectViewTourDismissed,
	setCrossProjectViewTourDismissed,
} from './cross-project-view/tour/index.tsx';
import {
	loadGoToProjectSpotlightDismissed,
	setGoToProjectSpotlightDismissed,
} from './global-fields/go-to-project-spotlight/index.tsx';
import { setGlobalFieldsTourSpotlightStep } from './global-fields/tour-spotlights/index.tsx';
import {
	loadGlobalFieldsTourDismissed,
	setGlobalFieldsTourDismissed,
} from './global-fields/tour/index.tsx';
import {
	loadExploreSectionMessageDismissed,
	setExploreSectionMessageDismissed,
} from './project-fields/explore-section-message/index.tsx';
import {
	loadProjectFieldsPageViewed,
	setProjectFieldsPageViewed,
} from './project-fields/page-viewed/index.tsx';
import {
	loadSeenPulsatingFieldsButton,
	setSeenPulsatingFieldsButton,
} from './project-fields/pulsating-fields-button/index.tsx';
import { setProjectFieldsTourSpotlightStep } from './project-fields/tour-spotlights/index.tsx';
import {
	loadProjectFieldsTourDismissed,
	setProjectFieldsTourDismissed,
} from './project-fields/tour/index.tsx';
import {
	loadTimelineSectionMessageDismissed,
	setTimelineSectionMessageDismissed,
} from './timeline/spotlight/index.tsx';

const actions = {
	loadGoToProjectSpotlightDismissed,
	setGoToProjectSpotlightDismissed,
	setGlobalFieldsTourSpotlightStep,
	loadGlobalFieldsTourDismissed,
	setGlobalFieldsTourDismissed,
	loadExploreSectionMessageDismissed,
	setExploreSectionMessageDismissed,
	loadProjectFieldsPageViewed,
	setProjectFieldsPageViewed,
	loadSeenPulsatingFieldsButton,
	setSeenPulsatingFieldsButton,
	loadProjectFieldsTourDismissed,
	setProjectFieldsTourDismissed,
	setProjectFieldsTourSpotlightStep,
	loadCollectionsTourDismissed,
	setCollectionsTourDismissed,
	setCollectionsTourSpotlightStep,
	loadCrossProjectViewTourDismissed,
	setCrossProjectViewTourDismissed,
	setCrossProjectViewTourSpotlightStep,
	loadTimelineSectionMessageDismissed,
	setTimelineSectionMessageDismissed,
	loadCrossProjectListViewFieldsDismissed,
	setCrossProjectListViewFieldsDismissed,
};

export default actions;
