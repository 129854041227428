import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type {
	DistributeArray,
	IntervalFieldFilterValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';

export const INTERVAL_ENDED_VISUALIZATION = 'INTERVAL_ENDED';
export const INTERVAL_STARTS_VISUALIZATION = 'INTERVAL_STARTS';
export const INTERVAL_ENDS_VISUALIZATION = 'INTERVAL_ENDS';
export const INTERVAL_STARTED_VISUALIZATION = 'INTERVAL_STARTED';

export type UiFilter = {
	id: string;
	isChecked: (values: DistributeArray<IntervalFieldFilterValue[]>) => boolean;
	getValues: (
		parameter: number | undefined,
	) => DistributeArray<IntervalFieldFilterValue[]> | undefined;
	hasParameter?: boolean;
	label: MessageDescriptor;
	activeLabel?: MessageDescriptor;
};

export type SelectOption<T> = {
	label: string;
	value: T;
};

export function ensureNonNullable<T>(value: T, label?: string): NonNullable<T> {
	if (value === undefined || value === null) {
		throw new Error(label || 'Expected value to be NonNullable');
	}

	return value;
}

export function assertUnreachable(value: never, label?: string): never {
	throw new Error(label || `assertUnreachable should never be called. Called with value: ${value}`);
}
