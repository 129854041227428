import React, { type ReactNode, useMemo } from 'react';
import { styled } from '@compiled/react';
import Banner from '@atlaskit/banner';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages.tsx';

const annualPlanLink = 'https://confluence.atlassian.com/x/Epi9Ug';

const FireAnalytics = ({
	attributes,
}: {
	attributes: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
}) => (
	<FireUiAnalytics
		action="viewed"
		actionSubject="banner"
		actionSubjectId="betaBanner"
		attributes={attributes}
	/>
);

const getStartOfUTCDay = (timestamp: number) => new Date(timestamp).setUTCHours(0, 0, 0, 0);
const getTrialDaysLeft = (currentDate: number, trialEndTime?: number): number | undefined =>
	trialEndTime && Math.ceil((getStartOfUTCDay(trialEndTime) - currentDate) / (24 * 3600 * 1000));

export const StandardTrialBanner = () => {
	const { formatMessage } = useIntl();
	const trialEndDate = useShowStandardTrialBannerDate();
	if (!ff('polaris.banner-for-beta-standard-trial')) {
		return null;
	}

	if (!trialEndDate) {
		return null;
	}

	return (
		<BannerWrapper>
			<FireAnalytics attributes={{ standardTrialBanner: true }} />
			<Banner appearance="announcement" icon={<WarningIcon label="" secondaryColor="inherit" />}>
				{formatMessage(messages.annualReadyStandard, {
					date: trialEndDate,
				})}{' '}
				<a href={annualPlanLink} target="_blank">
					{formatMessage(messages.betaWarningExtendedLink)}
				</a>
			</Banner>
		</BannerWrapper>
	);
};

export const StandardTrialBannerContainer = ({ children }: { children: ReactNode }) => {
	const trialEndDate = useShowStandardTrialBannerDate();

	if (!trialEndDate) {
		return <>{children}</>;
	}

	return (
		<WarningWrapper>
			<StandardTrialBanner />
			{children}
		</WarningWrapper>
	);
};

const useShowStandardTrialBannerDate = () => {
	const { formatDate } = useIntl();
	const { productEntitlementDetails } = useTenantContext();
	const entitlementDetails = productEntitlementDetails?.['jira-product-discovery'];
	const billingSourceSystem = entitlementDetails?.billingSourceSystem || 'HAMS'; // If billingSourceSystem is not set, it is HAMS
	const appEditions = useAppEditions();
	const isStandard = appEditions?.productDiscovery === 'STANDARD_EDITION';
	const isSharedView = useIsSharedView();

	const currentDate = useMemo(() => Date.now(), []);
	const trialDaysLeft = getTrialDaysLeft(currentDate, entitlementDetails?.trialEndTime);
	const trialEndTime = entitlementDetails?.trialEndTime;
	const notInTrialOrGracePeriod =
		billingSourceSystem === 'HAMS' && (!trialDaysLeft || trialDaysLeft < -14);

	if (notInTrialOrGracePeriod || !trialEndTime || !isStandard || isSharedView) {
		return;
	}

	const formattedDate = formatDate(new Date(trialEndTime), {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});

	return formattedDate;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerWrapper = styled.div({
	flexShrink: 'inherit',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
});
