import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './controllers/types.tsx';
import type { ProjectAccessScreen } from './ui/main.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPolarisProjectAccessScreen = lazyForPaint<typeof ProjectAccessScreen>(
	() => import(/* webpackChunkName: "async-polaris-project-access-screen" */ './ui'),
	{ ssr: false },
);

const SafeLazyPolarisProjectAccessScreen = (props: Props) => (
	<JSErrorBoundary
		fallback="flag"
		id="async.jira.polaris.project-settings"
		packageName="atlassian/jira-polaris-lib-project-access"
	>
		<Placeholder name="polaris-project-access-screen" fallback={<></>}>
			<LazyPolarisProjectAccessScreen {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

SafeLazyPolarisProjectAccessScreen.defaultProps = {
	breadcrumbs: undefined,
};

export default SafeLazyPolarisProjectAccessScreen;
