import { ff } from '@atlassian/jira-feature-flagging';
import { toAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	convertLogicToRules,
	generateLocalDecorationId,
} from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import { POLARIS_SUB_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/constants.tsx';
import { JIRA_API_FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { PolarisPlay } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { FieldPresentation } from '@atlassian/jira-polaris-domain-field/src/presentation/types.tsx';
import type { JiraField } from '../../../../services/types.tsx';
import { NON_EDITABLE_FIELDS } from '../../constants.tsx';

export const isEditable = ({ type }: JiraField): boolean => !NON_EDITABLE_FIELDS.includes(type);

export const getPresentationType = ({
	configuration,
}: Pick<JiraField, 'configuration'>): FieldPresentation | undefined =>
	configuration?.polarisPresentation ? { type: configuration.polarisPresentation } : undefined;

export const getOption = (
	{ id, value }: { id: number; value: string },
	weight: number,
): FieldOption => ({
	id: `${id}`,
	jiraOptionId: `${id}`,
	value,
	weight,
});

export const getOptions = ({ options, configuration }: JiraField) => {
	// Archived needs to return a different options object
	if (configuration?.polarisSubType === POLARIS_SUB_TYPES.ARCHIVED) return undefined;

	const weights = configuration?.optionWeights ?? [];
	return (
		options?.map((option) =>
			getOption(option, weights.find(({ optionId }) => optionId === option.id)?.weight ?? 1),
		) ?? undefined
	);
};

export const getArchivedOption = ({ options, configuration }: JiraField) => {
	// Archived needs to return a different options object
	const archivedOption = options?.[0];
	if (configuration?.polarisSubType !== POLARIS_SUB_TYPES.ARCHIVED || archivedOption === undefined)
		return undefined;

	const { id, value } = archivedOption;

	return {
		id: `${id}`,
		jiraOptionId: `${id}`,
		value,
		weight: 1,
	};
};

export const getPlay = ({ type, name, configuration }: JiraField): PolarisPlay | undefined => {
	const { polarisFormula, maxSpend } = configuration ?? {};
	if (type !== JIRA_API_FIELD_TYPES.VOTES || polarisFormula?.template !== 'play_agg')
		return undefined;

	const ari = toAri(polarisFormula?.parameters?.play ?? '');
	if (ari === undefined) {
		throw new Error(`polaris-field.bad-play-ari: ${ari}`);
	}

	const parameters = {
		maxSpend,
	};

	return {
		id: ari,
		kind: 'PolarisBudgetAllocationPlay',
		label: name,
		parameters,
	};
};

// we are not returning whole configuration but instead only returning the relevant ones.
// everything else (i.e. valueDecorations) are transformed and mapped to other parts of the field (i.e. field.valueDecorations)
export const getFieldConfiguration = ({ type, configuration }: JiraField) => {
	if (!configuration) {
		return undefined;
	}

	if (configuration?.polarisSubType === POLARIS_SUB_TYPES.ARCHIVED) return undefined;

	// return the whole configuration for atlas, interval, people, connection, and system fields
	if (
		configuration.provider ||
		configuration.source ||
		configuration.displayMode ||
		configuration.hidden !== undefined ||
		configuration.issueFilters !== undefined
	) {
		return configuration;
	}

	if (
		type === JIRA_API_FIELD_TYPES.SINGLE_SELECT ||
		type === JIRA_API_FIELD_TYPES.MULTI_SELECT ||
		type === JIRA_API_FIELD_TYPES.JSW_MULTI_SELECT
	) {
		return {
			optionWeights: configuration.optionWeights,
			optionWeightType: configuration.optionWeightType,
			optionAutoFormatDisabled: configuration.optionAutoFormatDisabled,
		};
	}

	return undefined;
};

// This is a straight copy from ../../polaris-api/transform/utils
const nullToUndefined = (value?: string | null): string | undefined => {
	if (value !== null) {
		return value;
	}

	return undefined;
};

export const transformDecorations = ({
	type,
	configuration,
}: Pick<JiraField, 'type' | 'configuration'>): ValueDecoration[] => {
	const { valueDecorations } = configuration ?? {};

	let result: ValueDecoration[] = [];
	if (valueDecorations != null) {
		result = valueDecorations.map((decor) => ({
			localDecorationId: decor.localDecorationId || generateLocalDecorationId(),
			emoji: nullToUndefined(decor.emoji),
			backgroundColor: nullToUndefined(decor.backgroundColor),
			highlightContainer: decor.highlightContainer,
			...(ff('polaris.fields-migration.decorations')
				? { logic: decor.logic }
				: { rules: convertLogicToRules(decor.logic) }),
		}));
	}

	// https://pi-dev-sandbox.atlassian.net/browse/POL-10039
	// Some rating fields had wrong orders in the backend, so should sort them here
	// The sort below is not ideal, and it probably only works for RATING fields.
	if (type === JIRA_API_FIELD_TYPES.RATING) {
		try {
			result.sort((a: ValueDecoration, b: ValueDecoration) => {
				// example rule based decoration {rules: [{operator: "EQ", value: "1"}]}}
				if ('rules' in a && 'rules' in b) {
					const aValue = parseInt(a.rules[0].value, 10);
					const bValue = parseInt(b.rules[0].value, 10);

					return aValue - bValue;
				}
				// example logic based decoration {logic: {<=: [1, {var: "value"}, 1]}}
				if ('logic' in a && 'logic' in b) {
					const aValue = Object.values(a.logic)[0];
					const bValue = Object.values(b.logic)[0];

					return aValue - bValue;
				}
				return 0;
			});
		} catch {
			// do nothing if we cannot sort it since the sorting is not critical
		}
	}
	return result;
};
