import React from 'react';
import { Inline } from '@atlaskit/primitives';
import { useLinkedIssueDataByLinkedIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/linked-issues/hooks.tsx';

type IssueTypeCellRendererProps = {
	issueId: number;
};

export const IssueTypeCellRenderer = ({ issueId }: IssueTypeCellRendererProps) => {
	const linkedIssue = useLinkedIssueDataByLinkedIssueId(issueId);

	if (!linkedIssue) {
		return null;
	}

	return (
		<Inline alignInline="center">
			{/* TODO: to be replace with actual issue type field renderer */}
			<img
				width={16}
				height={16}
				src={linkedIssue.issueType.iconUrl}
				alt={linkedIssue.issueType.name}
			/>
		</Inline>
	);
};
