import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { ViewIdMappingSynchroniser } from './legacy-utils/index.tsx';
import { type ExternalProps, InnerViewContainer } from './main.tsx';

export const ViewContainer = ({ children, ...rest }: ExternalProps) => {
	return (
		<InnerViewContainer {...rest}>
			{fg('jpd_visitor_handling_refactor') && <ViewIdMappingSynchroniser />}
			{children}
		</InnerViewContainer>
	);
};
