import { createSelector } from 'reselect';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import type { EntityLimitType } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { parseAdfString } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { mapOptionWeightType } from '@atlassian/jira-polaris-lib-field-option-configuration/src/utils/map-option-weight-type/index.tsx';
import type { FieldData } from '../../common/types.tsx';
import { DEFAULT_OPTION_WEIGHT, type State } from '../types.tsx';

export const getField = (state: State): FieldData['field'] | undefined => state.fieldData?.field;

export const getFieldKey = (state: State) => getField(state)?.key;

export const getFieldDescription = createSelector(getField, (field) => {
	const fixedDescriptionFields = [
		'summary',
		'assignee',
		'created',
		'creator',
		'key',
		'reporter',
		'status',
		'updated',
		'labels',
	];

	const value = field?.description ?? '';

	return {
		value,
		adf: parseAdfString(value),
		fixed: field !== undefined ? fixedDescriptionFields.includes(field?.key) : false,
	};
});

export const getNewFieldType = (state: State) => getField(state)?.type;
export const getFieldLabel = (state: State) => getField(state)?.label;

export const getHasFieldFormula = (state: State) => getField(state)?.formula !== undefined;
export const getFieldOptionWeightTypeIsSet = (state: State) =>
	getField(state)?.configuration?.optionWeightType !== undefined;

export const getFieldIsLocked = (state: State) => getField(state)?.isLocked ?? false;
export const getFieldEmoji = (state: State) => getField(state)?.emoji;
export const getFieldFormula = (state: State) => getField(state)?.formula;
export const getIsGlobalCustomField = (state: State) => getField(state)?.global;
export const getFieldOptions = (state: State) => getField(state)?.options ?? [];

export const getFieldTypeIcon = createSelector(
	getNewFieldType,
	getFieldOptionWeightTypeIsSet,
	(fieldType, fieldOptionWeightTypeIsSet) =>
		iconForPolarisFieldType(fieldType, undefined, fieldOptionWeightTypeIsSet),
);

export const getFieldValueDecorations = (state: State) => state.fieldData?.valueDecorations ?? [];

export const getFieldOption = (optionId: string) =>
	createSelector(getFieldOptions, (options) => options.find((option) => option.id === optionId));

export const getFieldOptionWeight = (optionId: string) => {
	const getFieldOptionSelector = getFieldOption(optionId);
	return createSelector(getFieldOptionSelector, (option) => option?.weight);
};

export const getFieldOptionWeightType = (state: State) =>
	mapOptionWeightType(getField(state)?.configuration);

export const getFieldOptionAutoFormatting = (state: State) =>
	!getField(state)?.configuration?.optionAutoFormatDisabled;

export const getFieldDefaultOpenOptionId = (state: State) => state.defaultOpenSidebarOptionId;

export const hasDefaultFieldOptionWeights = (state: State) => {
	const options = getFieldOptions(state);
	return !options.some((option) => option.weight !== DEFAULT_OPTION_WEIGHT);
};

export const getFieldOptionCount = createSelector(getFieldOptions, (options) =>
	options === undefined ? 0 : options.length,
);

export const getFieldPeopleDisplayMode = (state: State) =>
	getField(state)?.configuration?.displayMode;

export const hasReachedFieldOptionsLimit = (state: State): [EntityLimitType | null, number] => {
	const optionsCount = getFieldOptionCount(state);
	const optionsLimit = state.optionsLimitCount;
	if (optionsLimit && optionsCount >= optionsLimit) {
		return [ENTITY_LIMIT_TYPE.OPTIONS_PER_FIELD, optionsLimit];
	}

	return [null, 0];
};

export const getAreGlobalLabelsLoading = (state: State) => !getField(state)?.options;
export const getIsGlobalLabelsLoadingFailed = (state: State) =>
	getField(state)?.meta?.globalLabels?.error;
