import React, { useCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { useIntl } from '@atlassian/jira-intl';
import {
	useOpenRightSidebarOnField,
	useOpenRightSidebarOnFieldList,
	useOpenRightSidebarTimelineFieldConfig,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	RightSidebarShowingCreateField,
	RightSidebarShowingTimelineConfig,
	SharingSettingsOpeningSource,
	showingFieldList,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useSortedSelectedFields } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewFields,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { CreateNewField } from '@atlassian/jira-polaris-common/src/ui/config/fields/create-field/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ignoreEscapePress } from '@atlassian/jira-polaris-lib-escape-keypress-utils/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { Header } from '../header/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	onClose: () => void;
};

export const FieldCreationComponent = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const openFieldList = useOpenRightSidebarOnFieldList();
	const selectedFields = useCurrentViewFields();
	const openFieldConfig = useOpenRightSidebarOnField();
	const { setViewColumns } = useViewActions();
	const sortedSelectedFields = useSortedSelectedFields();
	const canManageCurrentView = useCanManageCurrentView();
	const [sidebarShowing] = useRightSidebarShowing();
	const openTimelineFieldConfig = useOpenRightSidebarTimelineFieldConfig();

	useCloseOnEscapePress({
		onClose: (event) => {
			if (ignoreEscapePress(event)) {
				return;
			}
			onClose();
		},
	});

	const onSaveColumns = useCallback(
		(columnFields: Field[]) => {
			setViewColumns(columnFields);
		},
		[setViewColumns],
	);

	const previousSidebarShowing =
		(sidebarShowing.mode === RightSidebarShowingCreateField && sidebarShowing?.backTo) || undefined;

	const isOpenedFromSidebar =
		typeof previousSidebarShowing === 'object' && !!previousSidebarShowing.mode;
	const isPreviousSidebarTimelineConfig =
		isOpenedFromSidebar && previousSidebarShowing?.mode === RightSidebarShowingTimelineConfig;
	const isSidebarOpenedFromSharingSettings =
		previousSidebarShowing === SharingSettingsOpeningSource;

	const addField = useCallback(
		(field: Field) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'field added', 'config-fields');

			if (
				sidebarShowing.mode === RightSidebarShowingCreateField &&
				(isPreviousSidebarTimelineConfig || isSidebarOpenedFromSharingSettings)
			) {
				sidebarShowing.onCreate?.(field.key);
				return;
			}
			const index = sortedSelectedFields.findIndex((f) => f.key === field.key);
			if (index >= 0) {
				const updatedFields = [...selectedFields];
				updatedFields.splice(index, 0, field);
				onSaveColumns(updatedFields);
			} else {
				const updatedFields = [...selectedFields, field];
				onSaveColumns(updatedFields);
			}
		},
		[
			createAnalyticsEvent,
			onSaveColumns,
			selectedFields,
			sortedSelectedFields,
			isPreviousSidebarTimelineConfig,
			isSidebarOpenedFromSharingSettings,
			sidebarShowing,
		],
	);

	const onCancel = useMemo(
		() => (isPreviousSidebarTimelineConfig ? openTimelineFieldConfig : openFieldList),
		[openFieldList, openTimelineFieldConfig, isPreviousSidebarTimelineConfig],
	);

	const onCancelHandler = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'backToIssueFieldsList',
		);
		onCancel();
	}, [onCancel, createAnalyticsEvent]);

	const sidebarOrigin =
		sidebarShowing.mode === RightSidebarShowingCreateField ? sidebarShowing.origin : undefined;

	const isOpenedFromProjectFieldsPage = sidebarOrigin === 'projectFieldsPage';

	const onOpenFieldConfig = useCallback(
		(fieldKey: FieldKey) => {
			openFieldConfig(
				fieldKey,
				isOpenedFromSidebar ? previousSidebarShowing : showingFieldList,
				sidebarOrigin,
			);
		},
		[openFieldConfig, previousSidebarShowing, isOpenedFromSidebar, sidebarOrigin],
	);

	const preselectedField = useMemo<
		{ type: typeof FIELD_TYPES.INTERVAL | typeof FIELD_TYPES.SHORT_TEXT } | undefined
	>(() => {
		if (isPreviousSidebarTimelineConfig) {
			return { type: FIELD_TYPES.INTERVAL };
		}
		if (isSidebarOpenedFromSharingSettings) {
			return { type: FIELD_TYPES.SHORT_TEXT };
		}
		return undefined;
	}, [isPreviousSidebarTimelineConfig, isSidebarOpenedFromSharingSettings]);

	const defaultFieldName =
		sidebarShowing.mode === RightSidebarShowingCreateField
			? sidebarShowing.defaultFieldName
			: undefined;

	return (
		<>
			{!isOpenedFromProjectFieldsPage && (
				<Header
					icon={<ArrowLeftIcon label="back" />}
					title={formatMessage(messages.createField)}
					onClick={onCancelHandler}
					onClose={onClose}
				/>
			)}
			<CreateNewField
				onFieldCreate={canManageCurrentView ? addField : noop}
				onOpenFieldConfig={onOpenFieldConfig}
				onCancel={isOpenedFromProjectFieldsPage ? onClose : onCancel}
				onClose={onClose}
				preselectedField={preselectedField}
				defaultFieldName={defaultFieldName}
				highlightCreateField={sidebarOrigin === 'projectFieldsPage'}
			/>
		</>
	);
};
