import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { DRAGGABLE_ITEM_TYPE } from '../../../../common/constants.tsx';
import type { RawItem } from '../../../../common/types/timeline/index.tsx';
import { getMiddleOfTheMonthDate } from '../../../../common/utils.tsx';
import {
	type ItemId,
	type GroupToItems,
	type GroupedItemArrangement,
	NO_VALUE_GROUP_ID,
} from '../../../../types.tsx';
import type { Item } from '../../../types.tsx';
import { calculateItemArrangement } from '../../../utils/arrangement.tsx';

export const createItems = (rawItems: RawItem[]): Record<ItemId, Item> =>
	keyBy(
		rawItems.map((rawItem) => ({
			...rawItem,
			type: DRAGGABLE_ITEM_TYPE,
		})),
		({ id }) => id,
	);

export const initGroupedItemRows = (
	rawItems: RawItem[],
	groupItems: GroupToItems | undefined,
	groupedItemRows: GroupedItemArrangement | undefined,
): GroupedItemArrangement => {
	if (groupItems === undefined) {
		return {
			[NO_VALUE_GROUP_ID]: calculateItemArrangement(
				rawItems,
				groupedItemRows?.[NO_VALUE_GROUP_ID] || {},
			),
		};
	}
	return mapValues(groupItems, (itemIds, groupId) =>
		calculateItemArrangement(
			rawItems.filter((rawItem) => itemIds.includes(rawItem.id)),
			groupedItemRows?.[groupId] || {},
		),
	);
};

export const shouldShowMiddleOfTheMonth = (dateString: string, isStartDate: boolean): boolean => {
	const dateObj = new Date(dateString);
	const dayOfTheMonth = dateObj.getUTCDate();
	const [middleForStart, middleForEnd] = getMiddleOfTheMonthDate(dateObj);

	return isStartDate ? dayOfTheMonth >= middleForStart : dayOfTheMonth <= middleForEnd;
};
