import React, { useState } from 'react';
import { ConnectionFieldEditView } from './edit/index.tsx';
import type { ConnectionFieldProps } from './types.tsx';
import { ConnectionFieldView } from './view/index.tsx';

export const ConnectionField = (props: ConnectionFieldProps) => {
	const [isEditing, setIsEditing] = useState(false);

	return isEditing ? (
		<ConnectionFieldEditView {...props} onClose={() => setIsEditing(false)} />
	) : (
		<ConnectionFieldView {...props} onClick={() => setIsEditing(true)} />
	);
};
