import React, { type SyntheticEvent, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorDateIcon from '@atlaskit/icon/glyph/editor/date';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { renderDateString } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import messages from './messages.tsx';
import type { FuzzyDateProps } from './types.tsx';

export const FuzzyDate = (props: FuzzyDateProps) => {
	const { onClick, onClearDate, triggerProps = {}, placeholder, value: date } = props;
	const [isIconHovered, setHovered] = useState(false);
	const { formatMessage } = useIntl();

	const iconToShow = date ? (
		<div
			onClick={(event: SyntheticEvent) => {
				event.stopPropagation();
				onClearDate();
			}}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			onKeyDown={(event: React.KeyboardEvent) => {
				if (event.key === 'Enter' || event.key === 'Space') {
					event.stopPropagation();
					onClearDate();
				}
				null;
			}}
			role="button"
			tabIndex={0}
		>
			{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
			<SelectClearIcon
				primaryColor={isIconHovered ? colors.N400 : colors.N70}
				label={formatMessage(messages.clearDate)}
				size="small"
			/>
		</div>
	) : (
		<EditorDateIcon label="" />
	);

	return (
		<Wrapper {...triggerProps}>
			<Button shouldFitContainer onClick={onClick} iconAfter={iconToShow}>
				{date ? renderDateString(date, placeholder) : formatMessage(messages.noDateSet)}
			</Button>
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		paddingRight: '0 !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		lineHeight: '32px !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		height: 'auto !important',
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography, @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		fontSize: '14px !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		textAlign: 'left !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'> span:nth-child(2)': {
			padding: `0 ${token('space.100', '8px')}`,
			margin: 0,
			textOverflow: 'unset',
		},
	},
});
