import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useLinkedIssueDataByLinkedIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/linked-issues/hooks.tsx';
import { LinkedIssueCard } from '@atlassian/jira-polaris-common/src/ui/linked-issue-card/index.tsx';
import { NoValueContainer } from '../../../../common/board-header-column/styled.tsx';
import type {
	ColumnHeaderComponentProps,
	ColumnHeaderConfiguration,
	EmptyColumnHeaderComponentProps,
} from '../types.tsx';
import messages from './messages.tsx';

const Component = ({ groupIdentity, fieldKey }: ColumnHeaderComponentProps) => {
	const linkedIssue = useLinkedIssueDataByLinkedIssueId(parseInt(groupIdentity, 10));

	if (linkedIssue === undefined) {
		return null;
	}

	return (
		<Box padding="space.025">
			<LinkedIssueCard
				issueKey={linkedIssue.issueKey}
				summary={linkedIssue.summary}
				issueType={linkedIssue.issueType}
				fieldKey={fieldKey}
			/>
		</Box>
	);
};

const EmptyComponent = ({ isRow }: EmptyColumnHeaderComponentProps) => {
	const { formatMessage } = useIntl();

	return (
		<NoValueContainer isRow={isRow}>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>
	);
};

export const connectionColumnHeaderConfig: ColumnHeaderConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
	EmptyComponent,
};
