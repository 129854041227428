import React, { useEffect, useState } from 'react';
import { usePreventRightSidebarClosing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { GlobalFieldDetails } from './global-field-details/index.tsx';
import { GlobalFieldsList } from './global-field-list/index.tsx';
import type { DisplayMode, FieldType, GlobalFieldsPanelProps } from './types.tsx';

export const GlobalFieldsPanel = ({ onClose }: GlobalFieldsPanelProps) => {
	const [displayMode, setDisplayMode] = useState<DisplayMode>('list');
	const [selectedFields, setSelectedFields] = useState<FieldKey[]>([]);
	const [fieldKeyForDetailView, setFieldKeyForDetailView] = useState<string | null>(null);
	const [fieldTypeFilter, setFieldTypeFilter] = useState<FieldType>('jpd');
	const preventRightSidebarClosing = usePreventRightSidebarClosing();

	const openFieldDetails = (fieldKey: string) => {
		setDisplayMode('details');
		setFieldKeyForDetailView(fieldKey);
	};

	const openFieldsList = () => {
		setDisplayMode('list');
		setFieldKeyForDetailView(null);
	};

	useEffect(() => {
		if (displayMode === 'list') {
			preventRightSidebarClosing(false);
		} else {
			preventRightSidebarClosing('onEsc');
		}
	}, [displayMode, preventRightSidebarClosing]);

	if (displayMode === 'details' && !!fieldKeyForDetailView) {
		return (
			<GlobalFieldDetails
				fieldKey={fieldKeyForDetailView}
				onBack={openFieldsList}
				onClose={onClose}
			/>
		);
	}
	return (
		<GlobalFieldsList
			selectedFields={selectedFields}
			setSelectedFields={setSelectedFields}
			onOpenFieldDetails={openFieldDetails}
			fieldTypeFilter={fieldTypeFilter}
			setFieldTypeFilter={setFieldTypeFilter}
			onClose={onClose}
		/>
	);
};
