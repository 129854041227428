import React, { useState, useCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import Checkbox from '@atlaskit/checkbox';
import { Emoji } from '@atlaskit/emoji';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, Grid, Text, Inline, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	useGlobalFieldData,
	useGlobalFieldFormula,
} from '@atlassian/jira-polaris-common/src/controllers/global-fields/selectors/global-fields-hooks.tsx';
import { FormulaContent } from '@atlassian/jira-polaris-common/src/ui/config/fields/field-item/formula-content/index.tsx';
import { FieldConfiguration } from '@atlassian/jira-polaris-component-field-configuration/src/main.tsx';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { GlobalField } from '@atlassian/jira-polaris-domain-field/src/global-field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { GlobalFieldItemProps, LabelProps } from './types.tsx';

const Label = ({ emoji, icon, name }: LabelProps) => {
	if (fg('polaris_global_field_name_truncation')) {
		return (
			<Grid as="span" templateColumns="1fr auto" xcss={fieldStyles}>
				<Inline as="span" xcss={fieldIconStyles}>
					{emoji ? <Emoji emoji={emoji} fitToHeight={16} /> : icon}
				</Inline>
				<Text maxLines={1}>{name}</Text>
			</Grid>
		);
	}

	return (
		<Inline as="span" xcss={fieldStyles}>
			<Inline as="span" xcss={fieldIconStyles}>
				{emoji ? <Emoji emoji={emoji} fitToHeight={16} /> : icon}
			</Inline>
			{name}
		</Inline>
	);
};

export const GlobalFieldItem = ({
	field,
	isChecked,
	onFieldSelectionChange,
	onOpenFieldDetails,
}: GlobalFieldItemProps) => {
	const fieldEmoji = useEmoji(field.configuration?.emoji);
	const { formatMessage } = useIntl();
	const [isHovered, setHovered] = useState(false);

	const fieldData = useGlobalFieldData(field.key);
	const formula = useGlobalFieldFormula(field.key);

	const icon = iconForPolarisFieldType(field.type);

	const openFieldDetails = useCallback(
		(globalField: GlobalField) => {
			onOpenFieldDetails(globalField.key);
		},
		[onOpenFieldDetails],
	);

	return (
		<Popup
			isOpen={isHovered}
			content={() =>
				fieldData && (
					<Box
						testId="polaris-ideas.ui.right-sidebar.global-fields.global-field-list.global-fields-selector.global-field-item.preview"
						padding="space.200"
						xcss={popupStyles}
					>
						<FieldConfiguration
							fieldData={fieldData}
							isPreview
							canEditField
							readonly
							formulaContent={
								Boolean(formula) && (
									<FormulaContent fieldKey={field.key} formula={formula} readonly isPreview />
								)
							}
						/>
					</Box>
				)
			}
			placement="left-start"
			trigger={(triggerProps: TriggerProps) => (
				<div {...triggerProps}>
					<Box
						key={field.key}
						testId="polaris-ideas.ui.right-sidebar.global-fields.global-field-list.global-fields-selector.global-field-item.global-field-item"
						xcss={[fieldCheckboxWrapperStyles, isChecked && selectedFieldCheckboxWrapperStyles]}
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
					>
						<Checkbox
							testId="polaris-ideas.ui.right-sidebar.global-fields.global-field-list.global-fields-selector.global-field-item.checkbox"
							onChange={(_event, analyticsEvent) => {
								fireUIAnalytics(analyticsEvent, 'field', {
									checked: _event.target.checked,
								});
								onFieldSelectionChange(field.key);
							}}
							value={field.key}
							isChecked={isChecked}
							label={<Label emoji={fieldEmoji} icon={icon} name={field.label} />}
						/>
						<IconButton
							testId="polaris-ideas.ui.right-sidebar.global-fields.global-field-list.global-fields-selector.global-field-item.details"
							icon={ChevronRightLargeIcon}
							appearance="subtle"
							spacing="compact"
							label={formatMessage(messages.openFieldDetailsLabel)}
							onClick={() => openFieldDetails(field)}
						/>
					</Box>
				</div>
			)}
		/>
	);
};

const popupStyles = xcss({
	width: '300px',
});

const fieldIconStyles = xcss({
	marginRight: 'space.100',
});

const fieldStyles = xcss({
	alignItems: 'center',
});

const fieldCheckboxWrapperStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.150',
	paddingRight: 'space.100',
	borderRadius: 'border.radius.100',
	':hover': {
		backgroundColor: 'color.background.neutral',
	},
});

const selectedFieldCheckboxWrapperStyles = xcss({
	backgroundColor: 'color.background.selected',
	borderLeft: '4px solid',
	borderLeftColor: 'color.border.selected',
	paddingLeft: 'space.100',
});
