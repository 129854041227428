/** @jsx jsx */
import React, { type ReactNode, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { type EmojiDescription, Emoji } from '@atlaskit/emoji';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { formatWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/index.tsx';
import type {
	DistributeArray,
	IntervalFieldFilterValue,
	IntervalFieldFilterLegacyValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	isIntervalFieldFilterAbsoluteDatesValue,
	isIntervalFieldFilterEmptyValue,
	isIntervalFieldFilterLegacyValue,
	isIntervalFieldFilterNotEmptyValue,
	isIntervalFieldFilterRollingDatesCurrentValue,
	isIntervalFieldFilterRollingDatesPastNextValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/utils.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { EmojiWrapper } from '../../../../field-config/item/emoji-wrapper/index.tsx';
import { filters } from '../utils/index.tsx';
import { intervalFilterValueMigration } from '../utils/interval-filter-value-migration.tsx';
import { assertUnreachable } from '../utils/types.tsx';
import { DATE_FORMAT } from './constants.tsx';
import { messages } from './messages.tsx';
import {
	formatMessageIntervalFilterDateType,
	formatMessageIntervalFilterPeriod,
	formatMessageIntervalFilterTime,
} from './utils.tsx';

const SelectionLabelLegacy = ({ values }: { values: IntervalFieldFilterLegacyValue[] }) => {
	const { formatMessage } = useIntl();
	const activeFilter = useMemo(() => filters.find((filter) => filter.isChecked(values)), [values]);

	if (activeFilter?.activeLabel === undefined) {
		return null;
	}

	return (
		<>
			{formatMessage(activeFilter.activeLabel, {
				value: values[0].numericValue !== undefined ? Math.abs(values[0].numericValue) : undefined,
			})}
		</>
	);
};

const SelectionLabelNext = ({
	values,
}: {
	values: DistributeArray<IntervalFieldFilterValue[]>;
}) => {
	const { formatMessage } = useIntl();
	const { locale } = useTenantContext();

	const valueBeforeMigration = values[0];
	if (!valueBeforeMigration) {
		return null;
	}

	const value = isIntervalFieldFilterLegacyValue(valueBeforeMigration)
		? intervalFilterValueMigration(valueBeforeMigration)
		: valueBeforeMigration;

	if (isIntervalFieldFilterAbsoluteDatesValue(value)) {
		if (value.start && value.end) {
			const startDate = formatWithLocale(new Date(value.start), DATE_FORMAT, locale);
			const endDate = formatWithLocale(new Date(value.end), DATE_FORMAT, locale);
			return formatMessage(messages.absoluteDatesStartEnd, { startDate, endDate });
		}

		if (value.start) {
			const startDate = formatWithLocale(new Date(value.start), DATE_FORMAT, locale);
			return formatMessage(messages.absoluteDatesStart, { startDate });
		}

		if (value.end) {
			const endDate = formatWithLocale(new Date(value.end), DATE_FORMAT, locale);
			return formatMessage(messages.absoluteDatesEnd, { endDate });
		}

		return null;
	}

	if (isIntervalFieldFilterRollingDatesCurrentValue(value)) {
		return formatMessage(messages.rollingDatesCurrent, {
			intervalDateType: formatMessageIntervalFilterDateType(formatMessage, value.intervalDateType),
			period: formatMessageIntervalFilterPeriod(formatMessage, value.period, 1),
		});
	}

	if (isIntervalFieldFilterRollingDatesPastNextValue(value)) {
		return formatMessage(messages.rollingDatesPastNext, {
			intervalDateType: formatMessageIntervalFilterDateType(formatMessage, value.intervalDateType),
			time: formatMessageIntervalFilterTime(formatMessage, value.time),
			count: value.numericValue,
			period: formatMessageIntervalFilterPeriod(formatMessage, value.period, value.numericValue),
		});
	}

	if (isIntervalFieldFilterEmptyValue(value)) {
		return formatMessage(messages.isEmpty);
	}

	if (isIntervalFieldFilterNotEmptyValue(value)) {
		return formatMessage(messages.isNotEmpty);
	}

	assertUnreachable(value);
};

const SelectionLabel = ({ values }: { values: DistributeArray<IntervalFieldFilterValue[]> }) => {
	if (fg('polaris_better_date_filters')) {
		return <SelectionLabelNext values={values} />;
	}

	const value = values[0];
	if (isIntervalFieldFilterLegacyValue(value)) {
		return <SelectionLabelLegacy values={[value]} />;
	}

	// FF is OFF and `values` contains new filters
	// that cannot be supported by using the `legacy` component.
	// New filters are not backwards compatible with the old one
	// therefore we render nothing here
	return null;
};

export const FilterButtonLabel = ({
	label,
	values,
	fieldTypeIcon,
	emoji,
}: {
	label: string;
	values: DistributeArray<IntervalFieldFilterValue[]>;
	fieldTypeIcon: ReactNode;
	emoji?: EmojiDescription;
}) => (
	<Flex alignItems="center">
		{emoji ? (
			<EmojiWrapper>
				<Emoji emoji={emoji} fitToHeight={16} showTooltip />
			</EmojiWrapper>
		) : (
			<Flex xcss={iconContainerStyles}>{fieldTypeIcon}</Flex>
		)}
		<div css={labelStyles}>{label}</div>
		{values.length > 0 && (
			<Box xcss={filterLabelValueStyles}>
				<SelectionLabel values={values} />
			</Box>
		)}
	</Flex>
);

const iconContainerStyles = xcss({
	paddingInlineEnd: 'space.100',
	alignItems: 'center',
});

const filterLabelValueStyles = xcss({
	marginInlineStart: 'space.050',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '260px',
});

const labelStyles = css({
	fontWeight: token('font.weight.bold'),
});
