import React, { memo, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
	monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { B400, N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { isSafari as isBrowserSafari } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { IdeaCard } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/main.tsx';
import type { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { preserveOffsetFromPointer } from '../../../../../common/ui/dnd-v2/utils.tsx';
import { BOARD_CARD_HORIZONTAL_MARGIN } from '../../constants.tsx';
import {
	type DragState,
	type DraggableCardData,
	isDraggableCardData,
} from '../../utils/draggable.types.tsx';

const isSafari = isBrowserSafari();
const CARD_CLONE_WIDTH = 326;

export type DraggableCardProps = {
	ideaId: string;
	draggableId: string;
	droppableId: string;
	index: number;
	canDrag: boolean;
	viewLayoutType: ViewLayoutType;
	initialDragStatus?: DragState;
	onDragStart?: (ideaId: string) => void;
};

export const DraggableCard = memo((props: DraggableCardProps) => {
	const {
		ideaId,
		draggableId,
		droppableId,
		index,
		canDrag,
		viewLayoutType,
		initialDragStatus = 'idle',
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onDragStart = () => {},
	} = props;
	const cardRef = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const [dragStatus, setDragStatus] = useState<DragState>(initialDragStatus);
	const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(null);

	useEffect(() => {
		if (!cardRef.current || !canDrag) {
			return undefined;
		}

		const data: DraggableCardData = {
			ideaId,
			draggableId,
			droppableId,
			index,
			type: 'CARD',
		};

		const cleanupDragAndDrop = combine(
			draggable({
				element: cardRef.current,
				onGenerateDragPreview: ({ nativeSetDragImage, source, location }) => {
					setDragStatus('preview');
					setCustomNativeDragPreview({
						getOffset: preserveOffsetFromPointer({
							sourceElement: source.element,
							input: location.current.input,
						}),
						render: ({ container }) => {
							setPreviewContainer(container);
							return () => {
								setPreviewContainer(null);
							};
						},
						nativeSetDragImage,
					});
				},
				getInitialData() {
					return data;
				},
				onDragStart() {
					setDragStatus('dragging');
					onDragStart(ideaId);
				},
				onDrop() {
					// the setTimeout prevents a flickering before the card is moved to another location
					setTimeout(() => setDragStatus('idle'));
				},
			}),
			dropTargetForElements({
				element: cardRef.current,
				canDrop({ source }) {
					return isDraggableCardData(source.data);
				},
				getData({ input, element }) {
					return attachClosestEdge(data, {
						input,
						element,
						allowedEdges: ['top', 'bottom'],
					});
				},
				onDrag({ source, self }) {
					if (!isDraggableCardData(source.data)) {
						return;
					}

					if (source.data.draggableId !== draggableId) {
						const dropEdge = extractClosestEdge(self.data);
						if (closestEdge !== dropEdge) {
							setClosestEdge(dropEdge);
						}
					}
				},
				onDragLeave() {
					setClosestEdge(null);
				},
				onDrop() {
					setClosestEdge(null);
				},
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [ideaId, draggableId, closestEdge, canDrag, droppableId, index, onDragStart]);

	// since cards are virtualized, the drag source card may be unmounted during a drag.
	// because of this, onDrop will not be triggered in the "draggable" api above.
	// therefore, we need to monitor all card drop events globally
	useEffect(() => {
		const cleanupDragAndDrop = monitorForElements({
			canMonitor: ({ source }) => source.data.type === 'CARD',
			onDrop: ({ source }) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const sourceIdeaId = source.data.ideaId as string;

				if (sourceIdeaId !== ideaId) {
					return;
				}

				// the setTimeout prevents a flickering before the card is moved to another location
				setTimeout(() => setDragStatus('idle'));
			},
		});

		return cleanupDragAndDrop;
	}, [ideaId]);

	return (
		<>
			<CardWrapper
				ref={cardRef}
				data-testid="polaris-ideas.ui.view-content.idea-board.card-list.draggable-card.card"
				data-ideaid={ideaId}
				closestEdge={closestEdge}
				dragStatus={dragStatus}
			>
				<IdeaCard
					id={ideaId}
					hasHoverState={dragStatus !== 'dragging'}
					viewLayoutType={viewLayoutType}
					hideEmptyFields
					hasMultilineStrings
					isGrayedOut={dragStatus === 'dragging'}
					hoverBackgroundColor={token('elevation.surface.hovered', N20)}
				/>
				<CardSeparator />
			</CardWrapper>
			{previewContainer
				? ReactDOM.createPortal(
						<CardCloneWrapper>
							<IdeaCard
								id={ideaId}
								hasHoverState
								viewLayoutType={viewLayoutType}
								hideEmptyFields
								hasMultilineStrings
								isDisabled
								disableLazyRendering
								cappedFieldsDisplay={isSafari}
							/>
						</CardCloneWrapper>,
						previewContainer,
					)
				: null}
		</>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWrapper = styled.div<{
	closestEdge: Edge | null;
	dragStatus: string;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ dragStatus }) => dragStatus === 'dragging' && 0.3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ dragStatus, closestEdge }) => (!closestEdge || dragStatus !== 'idle') && 'none',
		content: '',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `calc(100% - ${BOARD_CARD_HORIZONTAL_MARGIN * 2}px)`,
		height: '2px',
		position: 'absolute',
		zIndex: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		left: `${BOARD_CARD_HORIZONTAL_MARGIN}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		top: ({ closestEdge }) => (closestEdge === 'top' ? '-5px' : 'auto'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		bottom: ({ closestEdge }) => (closestEdge === 'bottom' ? '3px' : 'auto'),
		background: token('color.border.brand', B400),
	},
	// necessary for the cards box shadow to show correctly
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginLeft: `${BOARD_CARD_HORIZONTAL_MARGIN}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginRight: `${BOARD_CARD_HORIZONTAL_MARGIN}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardCloneWrapper = styled.div({
	width: `${CARD_CLONE_WIDTH}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardSeparator = styled.div({
	width: '100%',
	height: '8px',
});
