import React, { useCallback } from 'react';
import { AutoResizer, BaseTableV2, type ColumnShape } from 'react-base-table';
import { useConnectionFieldSelectedDisplayFieldKey } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useSelectedIssuesConnectionFieldIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ListPortalProvider } from '../../../view-content/idea-list/portal/index.tsx';
import { ROW_HEIGHT } from '../constants.tsx';
import { IssueTypeCellRenderer } from './cells/issue-type/index.tsx';
import { SelectedFieldCellRenderer } from './cells/selected-field/index.tsx';
import { IssueSummaryCellRenderer } from './cells/summary/index.tsx';
import { getRowRenderer, type RowRendererProps } from './rows/index.tsx';
import type { RowData } from './types.tsx';

type Props = {
	fieldKey: FieldKey;
};

const getColumns = (selectedFieldKey: FieldKey | undefined): ColumnShape<RowData>[] => {
	const columns: ColumnShape<RowData>[] = [
		{
			key: 'issue-type',
			title: 'Issue type',
			dataKey: 'issue-type',
			width: 24,
			flexShrink: 0,
			cellRenderer: ({ rowData }) => <IssueTypeCellRenderer issueId={parseInt(rowData.id, 10)} />,
		},
		{
			key: 'summary',
			title: 'Summary',
			dataKey: 'summary',
			width: 0,
			flexGrow: 2,
			cellRenderer: ({ rowData }) => (
				<IssueSummaryCellRenderer issueId={parseInt(rowData.id, 10)} />
			),
		},
	];

	if (selectedFieldKey !== undefined) {
		columns.push({
			key: selectedFieldKey,
			title: 'Selected field',
			dataKey: selectedFieldKey,
			width: 0,
			flexGrow: 1,
			cellRenderer: ({ rowData }) => (
				<SelectedFieldCellRenderer fieldKey={selectedFieldKey} issueId={parseInt(rowData.id, 10)} />
			),
		});
	}

	return columns;
};

export const Content = ({ fieldKey }: Props) => {
	const connectionFieldIssueIds = useSelectedIssuesConnectionFieldIssueIds(fieldKey);
	const rowsCount = connectionFieldIssueIds.length;
	const selectedDisplayFieldKey = useConnectionFieldSelectedDisplayFieldKey(fieldKey);
	const columns = getColumns(selectedDisplayFieldKey);

	const rowRenderer = useCallback(
		(props: RowRendererProps) => getRowRenderer(props, rowsCount),
		[rowsCount],
	);

	if (rowsCount === 0) {
		return 'EMPTY STATE';
	}

	return (
		<ListPortalProvider>
			<AutoResizer>
				{({ width, height }) => (
					<BaseTableV2<RowData>
						width={width}
						height={height}
						fixed={false}
						columns={columns}
						data={connectionFieldIssueIds}
						headerHeight={0}
						rowHeight={ROW_HEIGHT}
						rowRenderer={rowRenderer}
					/>
				)}
			</AutoResizer>
		</ListPortalProvider>
	);
};
