/** @jsx jsx */
import React, { useMemo } from 'react';
import { jsx } from '@compiled/react';
import { Stack } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ACCESS_ROLE_EDITOR,
	VIEW_ACCESS_LEVELS,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRole,
	AccessRoleError,
	Account,
	Group,
	ViewAccessLevel,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useProcessedAdditionalAccessEntries } from './access-list-item/hooks.tsx';
import { AccessListItem, type AccessListItemProps } from './access-list-item/index.tsx';
import { AnyoneElseAccessListItem } from './anyone-else-access-list-item/index.tsx';

export type AccessUserListProps = {
	accessLevel: ViewAccessLevel;
	additionalAccess: {
		profiles: Account[];
		groups: Group[];
	};
	currentUserAccountId?: AccountId;
	skippedAccountFailures?: {
		name: string;
		accountId: string;
		errorCode: AccessRoleError;
		avatarUrl?: string;
		requestedRole: AccessRole;
	}[];
	isDisabled: AccessListItemProps['isDisabled'];
	onPrincipalRoleChange: AccessListItemProps['onPrincipalRoleChange'];
	onRemovePrincipals: AccessListItemProps['onRemovePrincipals'];
};

export const AccessUserList = ({
	accessLevel,
	additionalAccess,
	isDisabled,
	currentUserAccountId,
	skippedAccountFailures,
	onPrincipalRoleChange,
	onRemovePrincipals,
}: AccessUserListProps) => {
	const additionalAccessEntries = useProcessedAdditionalAccessEntries(
		additionalAccess,
		currentUserAccountId,
		skippedAccountFailures,
	);

	// if it's false it prevents removal of last editor in case view isn't open; a view should have at least one explicit editor
	const canDowngradeEditor = useMemo(
		() =>
			accessLevel === VIEW_ACCESS_LEVELS.OPEN ||
			additionalAccessEntries.filter((item) => item.role === ACCESS_ROLE_EDITOR).length > 1,
		[accessLevel, additionalAccessEntries],
	);

	return (
		<Stack
			space="space.150"
			testId="polaris-component-view-access.ui.access-screen.access-user-list"
		>
			{fg('polaris-view-permissions-confluence-align') && (
				<AnyoneElseAccessListItem accessLevel={accessLevel} />
			)}
			{additionalAccessEntries.map((entry) => (
				<AccessListItem
					key={entry.id}
					isDisabled={isDisabled}
					onPrincipalRoleChange={onPrincipalRoleChange}
					onRemovePrincipals={onRemovePrincipals}
					canDowngradeRole={canDowngradeEditor || entry.role !== ACCESS_ROLE_EDITOR}
					{...entry}
				/>
			))}
		</Stack>
	);
};
