import React, { useCallback, useEffect } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { PublishFeatureDiscovery } from '@atlassian/jira-jpd-publish-feature-discovery/src/index.tsx';
import debouncePromise from '@atlassian/jira-platform-debounce-promise/src/ui/debounced-promise/index.tsx';
import { publishFeatureDisabled } from '@atlassian/jira-polaris-common/src/common/utils/publish/index.tsx';
import {
	useIsSharedView,
	useIsCollectionView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import type { StakeholdersToAdd } from '@atlassian/jira-polaris-common/src/controllers/sharing/actions/stakeholders/index.tsx';
import { EMPTY_STAKEHOLDERS } from '@atlassian/jira-polaris-common/src/controllers/sharing/constants.tsx';
import { useSharingActions } from '@atlassian/jira-polaris-common/src/controllers/sharing/main.tsx';
import {
	useCurrentViewSharingSettings,
	useCurrentViewSharingStakeholders,
	useHasCurrentViewSharingError,
	useIsSharingSiteConfigLoading,
	useIsSharingOnSiteEnabled,
	useProjectAccessLevel,
	useHasCurrentViewSharingSettingsError,
	useIsPublishDialogContentLoading,
	usePublicSharingControls,
} from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import {
	useCurrentViewAri,
	useCurrentViewKind,
	useCurrentViewUUID,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { PublishDialog } from '@atlassian/jira-polaris-lib-control-sharing/src/ui/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { JIRA_PRODUCT_DISCOVERY } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition, FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { DescriptionFieldSelect } from './description-field-select/index.tsx';
import { useUpdateSharingSettings } from './hooks/index.tsx';
import { useReadOnlyMessage, useUnsupportedFieldsList } from './utils.tsx';

const Publish = () => {
	const { viewUnsupportedFields } = useUnsupportedFieldsList();
	const viewUUID = useCurrentViewUUID();
	const {
		updateSharingSettings,
		addSharingStakeholders,
		removeSharingStakeholders,
		loadSharingSettings,
		loadSharingStakeholders,
	} = useSharingActions();
	const settings = useCurrentViewSharingSettings();
	const publicSharingControls = usePublicSharingControls();
	const stakeholders = useCurrentViewSharingStakeholders();
	const hasError = useHasCurrentViewSharingError();
	const hasSettingsError = useHasCurrentViewSharingSettingsError();
	const readOnlyMessage = useReadOnlyMessage();
	const isSharingSiteConfigLoading = useIsSharingSiteConfigLoading();
	const isSharingEnabledOnSite = useIsSharingOnSiteEnabled();
	const projectAccessLevel = useProjectAccessLevel();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const viewAri = useCurrentViewAri();
	const viewKind = useCurrentViewKind();
	const { misconfiguredViewUnsupportedFields } = useUnsupportedFieldsList();

	const isPublishDialogContentLoading = useIsPublishDialogContentLoading();

	const handleOpen = useCallback(
		(viewId: string) => {
			if (hasError) {
				return;
			}

			// Load sharing settings and stakeholders when the dialog is opened and sharing on site is enabled
			if (isSharingEnabledOnSite) {
				loadSharingStakeholders(viewId, { viewAri, viewKind });
				loadSharingSettings(viewId);
			}
		},
		[
			loadSharingSettings,
			loadSharingStakeholders,
			isSharingEnabledOnSite,
			viewAri,
			viewKind,
			hasError,
		],
	);

	const handleAddStakeholders = useCallback(
		(viewId: string, stakeholdersToAdd: StakeholdersToAdd) =>
			addSharingStakeholders(viewId, stakeholdersToAdd, { viewAri, viewKind }),
		[addSharingStakeholders, viewAri, viewKind],
	);

	const handleUpdate = debouncePromise(
		// FF usage is an exemption from rules of hooks
		ff('polaris.publish.replace-view-icon-when-publishing')
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useUpdateSharingSettings()
			: updateSharingSettings,
		500,
	);

	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'feature exposed', 'jpdViewPublishing');
	}, [createAnalyticsEvent]);

	return (
		<PublishDialog
			viewUUID={viewUUID}
			components={{
				FieldSelect: DescriptionFieldSelect,
			}}
			unsupportedFields={viewUnsupportedFields}
			isLoading={isPublishDialogContentLoading}
			stakeholders={stakeholders || EMPTY_STAKEHOLDERS}
			settings={settings}
			publicSharingControls={publicSharingControls}
			readOnlyMessage={readOnlyMessage}
			onUpdateSettings={handleUpdate}
			onAddStakeholders={handleAddStakeholders}
			onRemoveStakeholders={removeSharingStakeholders}
			hasError={hasError}
			hasSettingsError={hasSettingsError}
			isDisabled={!isSharingEnabledOnSite && !isSharingSiteConfigLoading}
			projectAccessLevel={projectAccessLevel}
			onOpen={handleOpen}
			misconfiguredViewUnsupportedFields={misconfiguredViewUnsupportedFields}
		/>
	);
};

export const PublishButton = () => {
	const appEditions = useAppEditions();
	const isSharedView = useIsSharedView();
	const isCollectionView = useIsCollectionView();

	if (isSharedView) {
		// A user that is viewing a shared view doesn't need to publish
		return null;
	}

	if (isCollectionView) {
		// publish is not yet available on collection views
		return null;
	}

	if (!ff('polaris.sharing-enabled')) {
		// The capability to share is turned off
		return null;
	}

	const edition = getEdition(JIRA_PRODUCT_DISCOVERY, appEditions);

	if (publishFeatureDisabled(edition)) {
		// It requires standard edition and has been marked as disabled
		if (edition !== FREE_EDITION) {
			// An unlicensed user shouldn't see a button at all
			return null;
		}

		return <PublishFeatureDiscovery />;
	}

	return <Publish />;
};
