import { useCallback, useEffect } from 'react';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { SEVEN_DAYS } from './constants.tsx';

const storage = createSessionStorageProvider('polaris');
const APPLICATION_FIRST_LOAD_KEY = 'application.first.load';

// This code is executed when the module is loaded, so that it is run only once
// even if a project switch happens
storage.set(APPLICATION_FIRST_LOAD_KEY, Date.now());

export const useAppRefresher = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleTabActive = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.hidden) {
			return;
		}

		const applicationFirstLoad = storage.get(APPLICATION_FIRST_LOAD_KEY);
		if (typeof applicationFirstLoad !== 'number') {
			return;
		}

		const timeElapsedSinceApplicationFirstLoad = Date.now() - applicationFirstLoad;
		if (timeElapsedSinceApplicationFirstLoad < SEVEN_DAYS) {
			return;
		}

		storage.set(APPLICATION_FIRST_LOAD_KEY, undefined);
		fireTrackAnalytics(createAnalyticsEvent({}), 'client refresh', {
			featureFlagValue: fg('polaris_outdated-client-refresh_dvnyi'),
			timeElapsedSinceApplicationFirstLoad,
		});
		if (fg('polaris_outdated-client-refresh_dvnyi')) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.reload();
		}
	}, [createAnalyticsEvent]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('visibilitychange', handleTabActive);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('visibilitychange', handleTabActive);
		};
	}, [handleTabActive]);
};
