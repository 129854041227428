import React, { type MouseEvent, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { N20, N30, N50, B200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { shouldOpenInNewTab } from '../../../common/utils/events/index.tsx';
import { getIssueLink } from '../../../common/utils/issue-link/index.tsx';
import { useIsSharedView } from '../../../controllers/environment/index.tsx';
import { useSafeIssueKey } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { usePolarisRouter } from '../../../controllers/route/index.tsx';
import { messages } from './messages.tsx';

const expandIconSvg = (
	<svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
		<path
			d="M16.587 6.003H15A1 1 0 0115 4h3.9l.047.001a.975.975 0 01.736.285l.032.032c.2.2.296.47.284.736l.001.048v3.896a1 1 0 11-2 0V7.411l-3.309 3.308a.977.977 0 01-1.374-.005l-.032-.032a.976.976 0 01-.005-1.374l3.307-3.305zM7.413 17.997H9A1 1 0 019 20H5.1l-.047-.001a.975.975 0 01-.736-.285l-.032-.032A.977.977 0 014 18.946a1.12 1.12 0 010-.048v-3.896a1 1 0 112 0v1.587l3.309-3.308a.977.977 0 011.374.005l.032.032a.976.976 0 01.005 1.374l-3.307 3.305z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

type Props = {
	localIssueId: LocalIssueId;
};

export const Menu = ({ localIssueId }: Props) => {
	const { formatMessage } = useIntl();
	const issueKey = useSafeIssueKey(localIssueId);
	const projectKey = useProjectKeyUnsafe();
	const { openIssueView } = usePolarisRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSharedView = useIsSharedView();

	const href = useMemo(
		() => getIssueLink(projectKey, issueKey, isSharedView),
		[issueKey, projectKey, isSharedView],
	);

	const handleOpenIdeaView = useCallback(
		(event: MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();

			if (!issueKey) return;

			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'ideaCard');

			if (shouldOpenInNewTab(event) && href) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(href, '_blank');
			} else {
				openIssueView(issueKey);
			}
		},
		[href, openIssueView, issueKey, createAnalyticsEvent],
	);

	// we avoid to use an atlaskit button, tooltip and icon to improve performance
	return (
		<Button
			data-testid="polaris-common.ui.idea-card-v2.menu.idea-preview-button"
			title={formatMessage(messages.openIdea)}
			onClick={handleOpenIdeaView}
		>
			{expandIconSvg}
		</Button>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Button = styled.button({
	position: 'absolute',
	top: '0px',
	right: '0px',
	width: '24px',
	height: '24px',
	cursor: 'pointer',
	border: 0,
	padding: 0,
	background: token('color.background.neutral.subtle', N20),
	borderRadius: '4px',
	opacity: 0,
	transition: 'opacity 50ms',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:hover:not(:active)': {
		background: token('color.background.neutral.subtle.hovered', N30),
	},
	'&:active': {
		background: token('color.background.neutral.subtle.pressed', N50),
	},
	'&:focus': {
		outline: `2px solid ${token('color.border.focused', B200)}`,
		outlineOffset: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="container-J2q9"]:hover &': {
		opacity: 1,
	},
});
