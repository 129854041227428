import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { getUnsupportedContentLevelsTracking } from '@atlassian/jira-common-util-unsupported-content/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import type { MessagesContent } from '@atlassian/jira-polaris-domain-insight/src/snippet/messages/types.tsx';
import { FormattedDate } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import {
	SEVERITY_DEGRADED_THRESHOLD,
	SEVERITY_NORMAL_THRESHOLD,
} from '../../../common/constants.tsx';
import { RemoteAttachment, Image } from '../../../common/ui/attachment/index.tsx';
import { Attachment } from '../../common/attachments/index.tsx';

type RenderProps = {
	mediaClientConfig: MediaClientConfig;
	dataProviders: ProviderFactory;
	contentItem: MessagesContent[0];
	prevItem: MessagesContent[0] | null;
};

const Render = ({ contentItem, prevItem, dataProviders, mediaClientConfig }: RenderProps) => {
	const message =
		'message' in contentItem && typeof contentItem.message === 'string' ? (
			<Message>{contentItem.message}</Message>
		) : (
			<AkRenderer
				dataProviders={dataProviders}
				// @ts-expect-error - TS2339 - Property 'message' does not exist on type 'JiraAttachment | RemoteAttachment | Image | MessagesItem'.
				document={contentItem.message}
				analyticsEventSeverityTracking={{
					enabled: true,
					severityDegradedThreshold: SEVERITY_DEGRADED_THRESHOLD,
					severityNormalThreshold: SEVERITY_NORMAL_THRESHOLD,
				}}
				unsupportedContentLevelsTracking={getUnsupportedContentLevelsTracking()}
				useSpecBasedValidator
				disableHeadingIDs
				eventHandlers={{
					smartCard: { onClick: onLinkClick },
					link: { onClick: onLinkClick },
				}}
				featureFlags={{
					codeBidiWarnings: true,
					'code-bidi-warnings': true,
					'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
				}}
				smartLinks={smartLinksDefault}
				UNSTABLE_allowTableResizing={expVal(
					'platform_editor_support_table_in_comment_jira',
					'isEnabled',
					false,
				)}
				UNSTABLE_allowTableAlignment={expVal(
					'platform_editor_support_table_in_comment_jira',
					'isEnabled',
					false,
				)}
			/>
		);
	if (contentItem.type === 'messagesItem') {
		return (
			<>
				{prevItem !== null && contentItem.type === 'messagesItem' ? (
					<Spacer key="separator" />
				) : null}
				<MessageHeader>
					<Sender>{contentItem.sender.name}</Sender>
					<MessageHeaderSeparator>·</MessageHeaderSeparator>
					<Created>
						<FormattedDate date={contentItem.time} />
					</Created>
				</MessageHeader>
				{message}
			</>
		);
	}
	if (contentItem.type === 'image') {
		return <Image src={contentItem.src} alt="" />;
	}
	if (contentItem.type === 'attachment') {
		return <Attachment id={contentItem.attachmentId} mediaClientConfig={mediaClientConfig} />;
	}
	if (contentItem.type === 'remoteAttachment') {
		return (
			<>
				{prevItem !== null && prevItem.type === 'messagesItem' ? (
					<Spacer size={2} key="separator" />
				) : null}
				<RemoteAttachment icon={contentItem.icon} title={contentItem.title} url={contentItem.url} />
			</>
		);
	}
	return null;
};

type MessagesProps = {
	content: MessagesContent;
	mediaClientConfig: MediaClientConfig;
	dataProviders: ProviderFactory;
};

export const Messages = ({ content, mediaClientConfig, dataProviders }: MessagesProps) => (
	<div>
		{content.map<ReactNode>((contentItem, i) => (
			<Render
				key={i}
				contentItem={contentItem}
				prevItem={i > 0 ? content[i - 1] : null}
				mediaClientConfig={mediaClientConfig}
				dataProviders={dataProviders}
			/>
		))}
	</div>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageHeader = styled.div({
	display: 'flex',
	marginTop: token('space.150', '12px'),
	marginBottom: token('space.050', '4px'),
	alignItems: 'baseline',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Sender = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	fontWeight: 400,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Created = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageHeaderSeparator = styled.div({
	padding: `0 ${token('space.050', '4px')}`,
	lineHeight: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Message = styled.div({
	wordBreak: 'break-word',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N700),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Spacer = styled.div<{ size?: any }>({
	padding: `${token('space.075', '6px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingTop: ({ size }) => `${size}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ size }) => `${size}px`,
});
