import { formulaUsesFields } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/main.tsx';
import { GLOBAL_FIELD_TYPES } from '../field-types/index.tsx';
import type { FieldKey } from '../field/types.tsx';
import type { GlobalField } from './types.tsx';

/**
 * Recursively gets the dependencies of a formula field.
 * Does not consider cyclic dependencies, as we already prevent adding one.
 */
export const getFormulaDependencies = (fieldKey: FieldKey, fields: GlobalField[]) => {
	const field = fields.find((f) => f.key === fieldKey);
	if (field?.type !== GLOBAL_FIELD_TYPES.FORMULA || !field.formula) {
		return [];
	}

	const dependencies: FieldKey[] = formulaUsesFields(field.formula);

	dependencies.forEach((dependencyKey) => {
		dependencies.push(...getFormulaDependencies(dependencyKey, fields));
	});

	const uniqueDependencies = [...new Set(dependencies)];
	const fieldKeys = new Set(fields.map(({ key }) => key));
	return uniqueDependencies.filter(
		(dependencyKey) => fieldKeys.has(dependencyKey), // filter out removed dependencies
	);
};
