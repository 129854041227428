import React, { useCallback, useEffect } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { BLUR } from '@atlassian/jira-polaris-lib-create-issue-input/src/common/constants.tsx';
import type {
	CreateMetadata,
	CreatePayload,
} from '@atlassian/jira-polaris-lib-create-issue-input/src/common/types.tsx';
import { CreateIssueInput } from '@atlassian/jira-polaris-lib-create-issue-input/src/ui/index.tsx';
import { ROW_PINNED_BOTTOM_DATA_COMPONENT_SELECTOR } from '../../constants.tsx';
import messages from './messages.tsx';
import type { AddIdeaInputProps } from './types.tsx';

const TEST_ID = 'polaris-ideas.ui.view-content.idea-list.rows.add-idea-input.add-idea-list-input';

export const AddIdeaInput = ({ onCreate, onCancel, ideaIssueTypes }: AddIdeaInputProps) => {
	const { formatMessage } = useIntl();

	const handleCreate = useCallback(
		({ summary }: CreatePayload, meta: CreateMetadata) => {
			onCreate(ideaIssueTypes[0], summary, meta.createdBy === BLUR);
		},
		[ideaIssueTypes, onCreate],
	);

	const shouldPreventCreationOnBlur = useCallback((element: Element | null) => {
		if (
			element &&
			element.getAttribute('data-component-selector') === ROW_PINNED_BOTTOM_DATA_COMPONENT_SELECTOR
		) {
			return true;
		}

		return false;
	}, []);

	useEffect(() => {
		experience.listView.createIdeaInlineClickResponse.success();
	}, []);

	return (
		<div data-testid={TEST_ID}>
			<CreateIssueInput
				placeholder={formatMessage(messages.placeholder)}
				onCancel={onCancel}
				onCreate={handleCreate}
				validationMessageType="icon"
				preventAutoFocusScroll
				shouldPreventCreationOnBlur={shouldPreventCreationOnBlur}
			/>
		</div>
	);
};
