import React, { type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import MediaServicesActualSizeIcon from '@atlaskit/icon/glyph/media-services/actual-size';
import MediaServicesFitToPageIcon from '@atlaskit/icon/glyph/media-services/fit-to-page';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

type Props = {
	isFullscreen: boolean;
	onToggle: () => void;
};

export const FullscreenToggle = ({ isFullscreen, onToggle }: Props) => {
	const { formatMessage } = useIntl();

	const onClick = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'fullscreen', {
			isActive: !isFullscreen,
		});
		onToggle();
	};

	return (
		<IconButton
			id="polaris.ideas.src.ui.view-header.fullscreen.button"
			testId="polaris-ideas.ui.view-header.fullscreen.button"
			label={formatMessage(
				isFullscreen ? messages.collapseButtonLabel : messages.expandButtonLabel,
			)}
			appearance={isVisualRefreshEnabled() ? 'default' : 'subtle'}
			onClick={onClick}
			// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
			icon={isFullscreen ? MediaServicesFitToPageIcon : MediaServicesActualSizeIcon}
			isTooltipDisabled={false}
		/>
	);
};
