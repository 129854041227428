import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { FuzzyDateField } from '../../../../view-content/idea-list/cell/cells/fuzzy-date-field/index.tsx';
import { Date } from '../date/index.tsx';
import { DateTime } from '../datetime/index.tsx';
import { DeliveryProgress } from '../delivery-progress/index.tsx';
import { ExternalReferenceProperty } from '../external-reference-property/index.tsx';
import { ExternalReference } from '../external-reference/index.tsx';
import { FieldPin } from '../field-pin/index.tsx';
import { Labels } from '../label/index.tsx';
import { LinkedIssueStatus } from '../linked-status/index.tsx';
import { FieldDropdownMenu } from '../menu/index.tsx';
import { Number } from '../number/index.tsx';
import { Play } from '../play/index.tsx';
import { ProjectField } from '../project/index.tsx';
import { Reactions } from '../reactions/index.tsx';
import { MultiSelect, SingleSelect } from '../select/index.tsx';
import { Status } from '../status/index.tsx';
import { String } from '../string/index.tsx';
import { Textarea } from '../textarea/index.tsx';
import { User } from '../user/index.tsx';
import { Users } from '../users/index.tsx';

type FieldComponentProps = {
	fieldKey: FieldKey;
	portalElement?: HTMLElement;
	onManageField?: (arg1: FieldKey, optionId?: string) => void;
};
export const FieldComponent = ({ fieldKey, onManageField, portalElement }: FieldComponentProps) => {
	const fieldType = useFieldType(fieldKey);

	const onConfigRequested = useCallback(
		(optionId?: string) => {
			if (fieldKey !== undefined && onManageField) {
				onManageField(fieldKey, optionId ?? undefined);
			}
		},
		[fieldKey, onManageField],
	);

	switch (fieldType) {
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.LINKED_ISSUES:
		case FIELD_TYPES.INSIGHTS:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.CHECKBOX:
			return (
				<NumberContentWrapper>
					<Number fieldKey={fieldKey} isIdeaView />
				</NumberContentWrapper>
			);
		case FIELD_TYPES.DATE:
			return <Date fieldKey={fieldKey} />;
		case FIELD_TYPES.INTERVAL:
			return (
				<FuzzyDateContentWrapper>
					<FuzzyDateField fieldKey={fieldKey} jpdExperience={experience.ideaView.makeFieldUpdate} />
				</FuzzyDateContentWrapper>
			);
		case FIELD_TYPES.UPDATED:
		case FIELD_TYPES.CREATED:
			return (
				<Box xcss={dateTimeContentWrapperStyles}>
					<DateTime fieldKey={fieldKey} />
				</Box>
			);
		case FIELD_TYPES.STATUS:
			return (
				<StatusContentWrapper>
					<Status fieldKey={fieldKey} />
				</StatusContentWrapper>
			);
		case FIELD_TYPES.HYPERLINK:
			return (
				<StringContentWrapper>
					<String fieldKey={fieldKey} isIdeaView />
				</StringContentWrapper>
			);
		case FIELD_TYPES.SHORT_TEXT:
			return (
				<TextareaContentWrapper>
					<Textarea fieldKey={fieldKey} />
				</TextareaContentWrapper>
			);
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.REPORTER:
			return (
				<AssigneeContentWrapper>
					<User fieldKey={fieldKey} portalElement={portalElement} />
				</AssigneeContentWrapper>
			);
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return (
				<Box xcss={usersContentWrapperStyles}>
					<Users fieldKey={fieldKey} portalElement={portalElement} />
				</Box>
			);
		case FIELD_TYPES.SINGLE_SELECT:
			return (
				<SingleSelectContentWrapper>
					<SingleSelect fieldKey={fieldKey} onConfigRequested={onConfigRequested} />
				</SingleSelectContentWrapper>
			);
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return (
				<Box xcss={multiSelectContentWrapperStyles}>
					<MultiSelect fieldKey={fieldKey} onConfigRequested={onConfigRequested} />
				</Box>
			);
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return (
				<LabelsContentWrapper>
					<Labels fieldKey={fieldKey} onConfigRequested={onConfigRequested} />
				</LabelsContentWrapper>
			);
		case FIELD_TYPES.DELIVERY_PROGRESS:
			return (
				<DeliveryProgressContentWrapper>
					<DeliveryProgress fieldKey={fieldKey} />
				</DeliveryProgressContentWrapper>
			);
		case FIELD_TYPES.DELIVERY_STATUS:
			return (
				<LinkedIssueStatusContentWrapper>
					<LinkedIssueStatus />
				</LinkedIssueStatusContentWrapper>
			);
		case FIELD_TYPES.VOTES:
			return (
				<PlayContentWrapper>
					<Play fieldKey={fieldKey} />
				</PlayContentWrapper>
			);
		case FIELD_TYPES.ATLAS_GOAL:
		case FIELD_TYPES.ATLAS_PROJECT:
			return (
				<Box xcss={externalReferrenceContentWrapperStyles}>
					<ExternalReference fieldKey={fieldKey} />
				</Box>
			);
		case FIELD_TYPES.ATLAS_PROJECT_STATUS:
			return <ExternalReferenceProperty fieldKey={fieldKey} />;
		case FIELD_TYPES.REACTIONS:
			return <Reactions fieldKey={fieldKey} />;
		case FIELD_TYPES.PROJECT:
			return <ProjectField />;
		default:
			return null;
	}
};

type FieldEntryProps = {
	isPinned?: boolean;
	fieldKey: FieldKey;
	portalElement?: HTMLElement;
	onManageField: (arg1: FieldKey) => void;
	onPinClick?: (arg1: FieldKey) => void;
};

export const FieldEntry = memo(
	({ fieldKey, onManageField, portalElement, isPinned = false, onPinClick }: FieldEntryProps) => {
		const fieldType = useFieldType(fieldKey);

		const handlePinClick = useCallback(() => {
			onPinClick?.(fieldKey);
		}, [onPinClick, fieldKey]);

		// skip specific field types
		if (fieldType === FIELD_TYPES.ISSUE_COMMENTS) {
			return null;
		}

		return (
			<>
				<FieldNameWrapper>
					<FieldDropdownMenu
						fieldKey={fieldKey}
						isPinned={isPinned}
						onManageField={onManageField}
						onPinField={handlePinClick}
					/>
					{onPinClick && (
						<FieldPinWrapper data-component-selector="field-pin-wrapper-57Ak">
							<FieldPin isPinned={isPinned} onClick={handlePinClick} />
						</FieldPinWrapper>
					)}
				</FieldNameWrapper>
				<FieldContentWrapper>
					<FieldComponent
						fieldKey={fieldKey}
						onManageField={onManageField}
						portalElement={portalElement}
					/>
				</FieldContentWrapper>
			</>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContentWrapper = styled.div({
	minWidth: '0px',
	paddingLeft: '0px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& div[class$='-menu']": {
		zIndex: 104,
		maxWidth: '240px',
		right: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldPinWrapper = styled.div({
	display: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldNameWrapper = styled.div({
	maxWidth: '250px',
	display: 'flex',
	alignItems: 'center',
	paddingRight: token('space.250', '20px'),
	'&:hover': {
		paddingRight: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="field-pin-wrapper-57Ak"]': {
			display: 'block',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StatusContentWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-7px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AssigneeContentWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-10px',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		marginLeft: token('space.025', '2px'),
	},
});

const dateTimeContentWrapperStyles = xcss({
	marginLeft: 'space.negative.075',
});

const externalReferrenceContentWrapperStyles = xcss({
	marginLeft: 'space.negative.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NumberContentWrapper = styled.div({
	marginLeft: token('space.negative.075', '-6px'),

	// plain numeric value
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-component-selector="decorated-tag-content-c3R"]': {
		marginLeft: token('space.025', '2px'),
	},

	// slider's value
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-component-selector="slider-read-view-45Tj"]': {
		marginLeft: token('space.negative.050', '-4px'),
	},

	// stop resizing the cell on hover
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-component-selector="slider-read-view-45Tj"] > div': {
		margin: `${token('space.negative.025', '-2px')} 0`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelsContentWrapper = styled.div({
	marginLeft: token('space.negative.100', '-8px'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-component-selector="decorated-tag-content-c3R"]': {
		marginLeft: token('space.050', '4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextareaContentWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-10px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FuzzyDateContentWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-9px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlayContentWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-component-selector="table-cell-wrapper-hide-95Gj"]': {
		marginLeft: token('space.negative.050', '-4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StringContentWrapper = styled.div({
	marginLeft: token('space.negative.075', '-6px'),

	// stop resizing the cell on hover
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-component-selector="edit-button-aAZ"] button': {
		height: 'initial',
	},
});

const multiSelectContentWrapperStyles = xcss({
	marginLeft: 'space.negative.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SingleSelectContentWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-1px',
});

const usersContentWrapperStyles = xcss({
	marginLeft: 'space.negative.075',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkedIssueStatusContentWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-9px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryProgressContentWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-7px',
});
