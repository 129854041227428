import React, { type ReactNode, type SyntheticEvent, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import { LinkButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import { Box, Text, Inline, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import {
	FireScreenAnalytics,
	fireUIAnalytics,
	ContextualAnalyticsData,
	MODAL,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { SpotlightTypes } from '../../common/types.tsx';
import { useActions } from '../../controllers/index.tsx';
import { useIsLoaded } from '../../controllers/selectors/remote-state-hooks.tsx';
import { useIsJoinCommunityFlagShown } from '../../controllers/selectors/spotlights-hooks.tsx';
import communityDark from './assets/community--dark.svg';
import communityLight from './assets/community--light.svg';
import messages from './messages.tsx';

const FLAG_APPEARANCE_DELAY = 10000; // 10 seconds
const FLAG_ID = 'join-community-flag-id';

type JoinCommunityFlagInternalProps = {
	onDismiss: () => void;
};

const JoinCommunityFlagInternal = ({ onDismiss }: JoinCommunityFlagInternalProps) => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleAcceptClick = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'joinTheCommunity');
		onDismiss();
	};

	const handleDismissClick = (e: SyntheticEvent) => {
		e.preventDefault(); // we need to ignore the href, which is empty in this case, but necessary for UX (mouse-cursor)
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'dismiss');
		onDismiss();
	};

	return (
		<>
			<FireScreenAnalytics />
			<Container>
				<Image
					src={isDarkMode ? communityDark : communityLight}
					alt={formatMessage(messages.imageAlt)}
				/>
				<Box xcss={mainStyles}>
					<Heading size="xsmall" as="h1">
						{formatMessage(messages.title)}
					</Heading>

					<Box paddingBlockStart="space.150">
						<Text size="UNSAFE_small">
							{formatMessage(messages.description, {
								link1: (message: ReactNode) => (
									<Link
										href="https://community.atlassian.com/t5/Jira-Product-Discovery-articles/Product-demo-Automation-for-Delivery/ba-p/2057152"
										target="_blank"
										rel="noopener noreferrer"
									>
										{message}
									</Link>
								),
								link2: (message: ReactNode) => (
									<Link
										href="https://community.atlassian.com/t5/Jira-Product-Discovery-articles/Frequently-asked-questions-about-the-product-how-do-I/ba-p/2002378"
										target="_blank"
										rel="noopener noreferrer"
									>
										{message}
									</Link>
								),
							})}
						</Text>
					</Box>

					<Box paddingBlockStart="space.150">
						<Inline space="space.150">
							<LinkButton
								appearance="discovery"
								href="https://community.atlassian.com/t5/Jira-Product-Discovery/ct-p/jpd"
								onClick={handleAcceptClick}
							>
								{formatMessage(messages.acceptButton)}
							</LinkButton>
							<LinkButton
								href="" // necessary for rendering the mouse cursor as pointer
								appearance="subtle"
								onClick={handleDismissClick}
							>
								{formatMessage(messages.dismissButton)}
							</LinkButton>
						</Inline>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export const JoinCommunityFlag = () => {
	const { showCustomFlag, dismissFlag } = useNotifications();
	const shouldShow = useIsJoinCommunityFlagShown();
	const isLoaded = useIsLoaded();
	const { closeSpotlight } = useActions();

	const handleClose = useCallback(() => {
		dismissFlag(FLAG_ID);
		closeSpotlight(SpotlightTypes.JOIN_COMMUNITY);
	}, [closeSpotlight, dismissFlag]);

	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout>;
		if (isLoaded && shouldShow) {
			timeoutId = setTimeout(() => {
				showCustomFlag({
					id: FLAG_ID,
					render: (props) => (
						<ContextualAnalyticsData sourceName="joinCommunityFlag" sourceType={MODAL} {...props}>
							<JoinCommunityFlagInternal onDismiss={handleClose} />
						</ContextualAnalyticsData>
					),
				});
			}, FLAG_APPEARANCE_DELAY);
		}

		return () => {
			clearTimeout(timeoutId);
			dismissFlag(FLAG_ID);
		};
	}, [shouldShow, isLoaded, showCustomFlag, dismissFlag, handleClose]);

	return null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	width: '520px',
	boxSizing: 'border-box',
	padding: token('space.300', '24px'),
	borderRadius: '8px',
	background: token('color.background.discovery', '#F3F0FF'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	boxShadow: token(
		'elevation.shadow.overlay',
		'0px 0px 1px rgba(9, 30, 66, 0.31), 0px 8px 12px rgba(9, 30, 66, 0.15)',
	),
	display: 'flex',
});

const mainStyles = xcss({
	marginLeft: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	width: '84px',
	height: '117px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Link = styled.a({
	fontWeight: 500,
});
