import React from 'react';
import Checkbox from '@atlaskit/checkbox';
import { Box, Flex, xcss, Text } from '@atlaskit/primitives';
import type { OptionProps } from '@atlaskit/react-select/src';
import { token } from '@atlaskit/tokens';
import { LinkedIssueCard } from '../../../../linked-issue-card/index.tsx';
import type { ConnectionFieldOption } from '../../types.tsx';
import { useConnectionFieldSelectContext } from '../context/index.tsx';

export const ConnectionFieldIssueOption = ({
	isFocused,
	data,
	isSelected,
	innerProps,
	innerRef,
}: OptionProps<ConnectionFieldOption, true>) => {
	const { fieldKey } = useConnectionFieldSelectContext();
	const { issueKey, label, issueType } = data;

	return (
		<div ref={innerRef} {...innerProps}>
			<Box xcss={[containerStyles, isFocused && focusedContainerStyles]}>
				<Box paddingInlineStart="space.075" paddingInlineEnd="space.050">
					<Checkbox isChecked={isSelected} />
				</Box>
				<Flex
					alignItems="center"
					justifyContent="space-between"
					gap="space.100"
					xcss={contentStyles}
				>
					<LinkedIssueCard
						isLink={false}
						fieldKey={fieldKey}
						issueKey={issueKey}
						summary={label}
						issueType={issueType}
					/>

					<Text size="small" color="color.text.subtlest">
						{issueKey}
					</Text>
				</Flex>
			</Box>
		</div>
	);
};

const contentStyles = xcss({
	whiteSpace: 'nowrap',
	width: '100%',
});

const containerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	height: '34px',
	width: '100%',
	cursor: 'default',
	background: 'transparent',
	marginBottom: 'space.025',
	paddingInlineEnd: 'space.150',
});

const focusedContainerStyles = xcss({
	background: token('color.background.neutral.subtle.hovered'),
});
