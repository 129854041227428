import React from 'react';
import { Box } from '@atlaskit/primitives';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useLinkedIssueDataByLinkedIssueId } from '../../../../../controllers/issue/selectors/properties/linked-issues/hooks.tsx';
import { LinkedIssueCard } from '../../../../linked-issue-card/index.tsx';

type Props = {
	id: string;
	fieldKey: FieldKey;
};

export const ConnectionFieldViewCard = ({ id, fieldKey }: Props) => {
	const linkedIssue = useLinkedIssueDataByLinkedIssueId(parseInt(id, 10));

	if (!linkedIssue) {
		return null;
	}

	return (
		<Box padding="space.050">
			<LinkedIssueCard
				fieldKey={fieldKey}
				isLink={false}
				issueKey={linkedIssue.issueKey}
				summary={linkedIssue.summary}
				issueType={linkedIssue.issueType}
			/>
		</Box>
	);
};
