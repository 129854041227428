import React from 'react';
import OfficeBuildingIcon from '@atlaskit/icon/core/migration/office-building--office-building-filled';
import type { NewGlyphColorProps } from '@atlaskit/icon/src/types';
import { Flex, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';

import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type GlobalFieldMarkerProps = {
	color?: NewGlyphColorProps['color'];
	noMargins?: boolean;
	hideTooltip?: boolean;
	inline?: boolean;
};

export const GlobalFieldMarker = ({
	color,
	noMargins,
	hideTooltip = false,
	inline = false,
}: GlobalFieldMarkerProps) => {
	const { formatMessage } = useIntl();

	return (
		<Flex
			alignItems="center"
			testId="polaris-lib-global-field-marker.ui"
			xcss={[baseStyles, !noMargins && withMarginsStyles, inline && inlineStyles]}
		>
			<Tooltip content={!hideTooltip && formatMessage(messages.globalField)}>
				<OfficeBuildingIcon
					color={color}
					label={formatMessage(messages.globalField)}
					spacing="none"
					LEGACY_primaryColor={color}
					LEGACY_size="small"
				/>
			</Tooltip>
		</Flex>
	);
};

const baseStyles = xcss({
	width: '16px',
});

const withMarginsStyles = xcss({
	marginLeft: 'space.075',
});

const inlineStyles = xcss({
	display: 'inline-flex',
});
