import React, { useState } from 'react';
import { xcss, Box } from '@atlaskit/primitives';
import { useLocalIssueIdForJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { RenderCell } from '../../../../../view-content/idea-list/cell/cells/main.tsx';

type SelectedFieldCellRendererProps = {
	issueId: number;
	fieldKey: string;
};

export const SelectedFieldCellRenderer = ({
	fieldKey,
	issueId,
}: SelectedFieldCellRendererProps) => {
	const localIssueId = useLocalIssueIdForJiraIssueId(issueId.toString());
	const [isHovered, setIsHovered] = useState(false);

	if (localIssueId === undefined) {
		return null;
	}

	return (
		<Box
			xcss={containerStyles}
			onMouseOver={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<RenderCell fieldKey={fieldKey} isHovered={isHovered} localIssueId={localIssueId} />
		</Box>
	);
};

const containerStyles = xcss({
	paddingInline: 'space.050',
});
