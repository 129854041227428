import gqlTagPolaris from 'graphql-tag';
import { ff } from '@atlassian/jira-feature-flagging';
import { toAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { ViewFragment } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/get/index.tsx';
import type { CreateViewRequest } from '../../../common/types.tsx';
import { getSortMode } from '../../../common/utils.tsx';
import type {
	jira_polaris_CreateView as CreateView,
	jira_polaris_CreateViewVariables as CreateViewVariables,
} from './__generated_apollo__/jira_polaris_CreateView';
import type { CreateViewReturn } from './types.tsx';

const CREATE_VIEW_MUTATION = gqlTagPolaris`
mutation jira_polaris_CreateView(
    $projectAri: ID!,
    $type: PolarisVisualizationType!,
    $update: UpdatePolarisViewInput,
    $isShareViewDialogEnabled: Boolean!,
    $skipDescription: Boolean!,
) {
  createPolarisView(input: {
    container: $projectAri
    visualizationType: $type
    update: $update
  }) {
    success
    errors {
        message
    }
    node {
      ...EdgeView
    }
  }
}
${ViewFragment}
`;

// normalizes the query; we basically own the query itself but NOT (essentially) the fragment
// so we transform the fragment-ish request object into the shape of the query variables,
// and unpack the response up to but not including the fragment (which is the View in this
// context)
export const createView = async (
	apolloClient: PolarisApolloClient,
	props: CreateViewRequest,
): Promise<CreateViewReturn> => {
	const result = await apolloClient.mutate<CreateView, CreateViewVariables>({
		mutation: CREATE_VIEW_MUTATION,
		variables: {
			projectAri: props.projectAri,
			// @ts-expect-error - Type '"TABLE" | "BOARD" | "MATRIX" | "TIMELINE" | "TWOXTWO"' is not assignable to type 'PolarisVisualizationType'.
			type: props.kind,
			update: {
				name: props.name,
				description: props.description,
				fields: props.fields,
				hidden: props.hidden,
				groupBy: props.groupBy,
				verticalGroupBy: props.verticalGroupBy,
				groupValues: props.groupValues,
				verticalGroupValues: props.verticalGroupValues,
				hideEmptyGroups: props.hideEmptyGroups,
				hideEmptyColumns: props.hideEmptyColumns,
				// @ts-expect-error - TS2322 - Type 'UpdateFilter[] | null' is not assignable to type 'readonly PolarisViewFilterInput[] | null | undefined'.
				filter: props.filter,
				// @ts-expect-error - TS2322 - Type 'readonly SortedField[] | null' is not assignable to type 'readonly PolarisSortFieldInput[] | null | undefined'.
				sort: props.sort,
				// @ts-expect-error - TS2322 - Type '"PROJECT_RANK" | "FIELDS_SORT" | "VIEW_RANK" | undefined' is not assignable to type 'PolarisViewSortMode | null | undefined'.
				sortMode: getSortMode(props.sortMode),
				matrixConfig: props.matrixConfig,
				timelineConfig: props.timelineConfig,
				// @ts-expect-error - loosen type
				fieldRollups: props.fieldRollups,
				tableColumnSizes: props.tableColumnSizes,
				enabledAutoSave: props.enabledAutoSave,
				// @ts-expect-error - TS2322 - Type 'ViewLayoutType.COMPACT' is not assignable to type 'PolarisViewLayoutType | null | undefined'
				layoutType: props.layoutType,
			},
			isShareViewDialogEnabled: ff('polaris.sharing-enabled'),
			skipDescription: false,
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`project-config.create-view-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (result.data == null) {
		throw new Error('project-config.create-view-error: expected data, got null');
	}

	// Awkward unpacking dance to make flow type checking happy with all the nullable
	// types.

	const { createPolarisView } = result.data;

	if (createPolarisView == null) {
		throw new Error('project-config.create-view-error: createPolarisView in response, got null');
	}

	const { node } = createPolarisView;

	if (node == null) {
		throw new Error('project-config.create-view-error: expected data node, got null');
	}

	const viewId = toAri(node.id);
	if (viewId === undefined) {
		throw new Error(`project-config.create-view-error: expected ari for view, got: ${node.id}`);
	}

	return {
		viewId,
		uuid: node.uuid,
		node: { ...node },
	};
};
