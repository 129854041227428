import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	restrictionsLabel: {
		id: 'polaris-component-view-access.restrictions-label',
		defaultMessage: 'Restrictions',
		description: 'Text displayed in the modal header for changing view permissions',
	},
	modalHeader: {
		id: 'polaris-component-view-access.modal-header',
		defaultMessage: 'Configure view access',
		description: 'Text displayed in the modal header',
	},
	modalCloseButtonLabel: {
		id: 'polaris-component-view-access.modal-close-button-label',
		defaultMessage: 'Close modal',
		description: 'Label of the close modal icon button',
	},
	cancelAddingPrincipalsLabel: {
		id: 'polaris-component-view-access.cancel-adding-principals-label',
		defaultMessage: 'Cancel adding principals',
		description: 'Cancel adding principals (users or groups) button',
	},
});
