import React, { useMemo } from 'react';
import { Emoji } from '@atlaskit/emoji';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { ISSUEID_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import messages from './messages.tsx';

export type FieldSelectOptionComponentProps = {
	fieldKey: FieldKey;
	label?: string;
	type?: FieldType;
	emojiId?: string;
	hasWeightType?: boolean;
	global?: boolean;
	custom?: boolean;
};

export const FieldSelectOptionComponent = ({
	fieldKey,
	emojiId,
	type,
	label,
	hasWeightType,
	global,
	custom,
}: FieldSelectOptionComponentProps) => {
	const { formatMessage } = useIntl();

	const icon = useMemo(
		() => iconForPolarisFieldType(type, undefined, hasWeightType),
		[hasWeightType, type],
	);
	const emoji = useEmoji(emojiId);

	return (
		<Flex alignItems="center">
			{emoji ? (
				<Flex alignItems="center" xcss={emojiWrapperStyles}>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</Flex>
			) : (
				<Box xcss={iconContainerStyles}>{icon}</Box>
			)}
			<Box xcss={optionContentStyles}>
				{fieldKey === ISSUEID_FIELDKEY ? formatMessage(messages.excludeIdeas) : label}
			</Box>
			{global && custom && <GlobalFieldMarker />}
		</Flex>
	);
};

const iconContainerStyles = xcss({
	marginRight: 'space.100',
});

const optionContentStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
	minWidth: '0',
});

const emojiWrapperStyles = xcss({
	marginInlineEnd: 'space.100',
});
