import React from 'react';
import { styled } from '@compiled/react';
import { useIntl } from '@atlassian/jira-intl';
import { TimelineConfiguration } from '../../view-controls/config-timeline/timeline-configuration/index.tsx';
import { Header } from '../header/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	onClose: () => void;
};

export const TimelinePanel = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<FieldsContainer>
			<Header title={formatMessage(messages.timelineLabel)} onClose={onClose} />
			<TimelineConfiguration />
		</FieldsContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div({
	minHeight: '100%',
	display: 'flex',
	flexDirection: 'column',
});
