import React, { useEffect } from 'react';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import Link from '@atlaskit/link';
import { Box, Stack, xcss, type Space } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { BANNER, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge/src/utils/analytics-context-wrapper';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics-component';
import { useOnboardingFlowsActions } from '../../../../controllers/index.tsx';
import { useCrossProjectListViewFieldsDismissed } from '../../../../controllers/selectors/cross-project-view-hooks.tsx';
import { messages } from './messages.tsx';

type Props = {
	paddingBlock?: Space;
	paddingInline?: Space;
	innerPaddingInline?: Space;
};

export const CrossProjectListViewsFieldsOnboarding = ({
	paddingBlock,
	paddingInline,
	innerPaddingInline,
}: Props) => {
	const isCrossProjectListViewFieldsDismissed = useCrossProjectListViewFieldsDismissed();
	const { loadCrossProjectListViewFieldsDismissed, setCrossProjectListViewFieldsDismissed } =
		useOnboardingFlowsActions();
	const { formatMessage } = useIntl();

	useEffect(() => {
		loadCrossProjectListViewFieldsDismissed();
	}, [loadCrossProjectListViewFieldsDismissed]);

	if (isCrossProjectListViewFieldsDismissed !== false) {
		return null;
	}

	return (
		<ContextualAnalyticsData sourceType={BANNER} sourceName="roadmapViewFields">
			<FireScreenAnalytics />
			<Box paddingBlock={paddingBlock} paddingInline={paddingInline}>
				<Box padding="space.200" paddingInline={innerPaddingInline} xcss={sectionMessageStyles}>
					<Stack space="space.100">
						<Heading size="xsmall">{formatMessage(messages.title)}</Heading>
						<Box>{formatMessage(messages.body)}</Box>
						<Link
							href="https://support.atlassian.com/jira-product-discovery/docs/create-and-manage-global-fields/"
							target="_blank"
						>
							{formatMessage(messages.link)}
						</Link>
						<Box>
							<ButtonGroup>
								<Button
									onClick={(_, analyticsEvent) => {
										setCrossProjectListViewFieldsDismissed();
										fireUIAnalytics(analyticsEvent, 'dismiss');
									}}
								>
									{formatMessage(messages.dismissButton)}
								</Button>
							</ButtonGroup>
						</Box>
					</Stack>
				</Box>
			</Box>
		</ContextualAnalyticsData>
	);
};

const sectionMessageStyles = xcss({
	backgroundColor: 'color.background.discovery',
	borderRadius: 'border.radius.100',
});
