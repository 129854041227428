import React, { type ReactNode } from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import { ROW_HEIGHT } from '../../constants.tsx';

export type RowRendererProps = {
	rowIndex: number;
	cells: ReactNode[];
};

export const getRowRenderer = ({ cells, rowIndex }: RowRendererProps, rowsCount: number) => {
	return (
		<Inline alignBlock="center" xcss={[rowStyles, rowIndex === rowsCount - 1 && lastRowStyles]}>
			{cells}
		</Inline>
	);
};

const rowStyles = xcss({
	borderBottom: '1px solid',
	borderColor: 'color.border',
	paddingInline: 'space.100',
	gap: 'space.100',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	height: `${ROW_HEIGHT}px`,
});

const lastRowStyles = xcss({
	borderBottom: 'none',
});
