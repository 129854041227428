import type { RemoteState } from './types.tsx';

export const QUICK_START_VIEW_VIDEO_ANALYTICS_SOURCE = 'quickStartViewVideo';
export const QUICK_START_VIEW_VIDEO_USER_PROPERTY = 'polaris.quick-start-view-video';
export const ENTRY_POINT_ID = 'jira.polaris.quick-start-view-video';
export const PACKAGE_NAME = 'jiraPolarisQuickStartViewVideo';

export const initialRemoteState: RemoteState = {
	isDismissed: false,
	isMinimised: false,
};
