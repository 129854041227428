import React, { useEffect, useCallback, useState, useMemo, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import lowerCase from 'lodash/lowerCase';
import toString from 'lodash/toString';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { isGroupBySupportedFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/utils/is-group-by-supported.tsx';
import { useOpenRightSidebarOnField } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import {
	showingGroupByOptions,
	showingVerticalGroupByOptions,
	type Showing,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useFieldsForViewControls } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import {
	useCurrentViewGroupBy,
	useCurrentViewHideEmptyBoardColumns,
	useCurrentViewHideEmptyGroups,
	useCurrentViewVerticalGroupBy,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { SearchableDropdown } from '@atlassian/jira-polaris-common/src/ui/common/decoration/searchable-dropdown/index.tsx';
import { FieldSelect } from '@atlassian/jira-polaris-common/src/ui/common/field-select/index.tsx';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	VIEW_KIND_TABLE,
	VIEW_KIND_BOARD,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { GroupOptions, VerticalGroupOptions } from './group-options/index.tsx';
import { messages } from './messages.tsx';

type BaseFieldConfigProps = {
	isClearable: boolean;
	fields: {
		[key: string]: Field;
	};
	selectedField?: Field;
	helpText: ReactNode;
	children?: ReactNode;
	backTo?: Showing;
	testId?: string;
	onChange: (fieldKey?: FieldKey) => void;
	canHideEmptyGroups?: boolean;
};

const BaseFieldConfig = ({
	selectedField,
	fields,
	onChange,
	children,
	helpText,
	isClearable,
	backTo,
	testId = 'polaris-ideas.ui.view-control.config-columns.group-by-dropdown',
	canHideEmptyGroups = false,
}: BaseFieldConfigProps) => {
	const { formatMessage } = useIntl();
	const openRightSidebarOnField = useOpenRightSidebarOnField();
	const canEditFields = useCanEditFields();
	const hideEmptyGroups = useCurrentViewHideEmptyGroups();
	const hideEmptyBoardColumns = useCurrentViewHideEmptyBoardColumns();
	const { setHideEmptyGroups, setHideEmptyColumns } = useViewActions();

	const { hideEmpty, setHideEmpty, toggleMessage } = useMemo(() => {
		if (backTo === showingGroupByOptions) {
			return {
				hideEmpty: hideEmptyBoardColumns,
				setHideEmpty: setHideEmptyColumns,
				toggleMessage: messages.hideEmptyColumnsToggle,
			};
		}
		return {
			hideEmpty: hideEmptyGroups,
			setHideEmpty: setHideEmptyGroups,
			toggleMessage: messages.hideEmptyGroupsToggle,
		};
	}, [backTo, hideEmptyBoardColumns, hideEmptyGroups, setHideEmptyColumns, setHideEmptyGroups]);

	const [isToggleOn, setToggle] = useState(hideEmpty);

	useEffect(() => setToggle(hideEmpty), [hideEmpty]);

	const fieldList = useMemo(() => {
		const list = Object.keys(fields)
			.map((key) => fields[key])
			.filter((field) => field.key !== selectedField?.key)
			.filter((field) => isGroupBySupportedFieldType(field.type))
			.sort((a, b) => a.label.localeCompare(b.label))
			.map(({ key, label }) => ({ key, label }));
		return list;
	}, [fields, selectedField]);

	const onChangeToggle = () => {
		if (isToggleOn) {
			setHideEmpty(false);
		} else {
			setHideEmpty(true);
		}
		setToggle(!isToggleOn);
	};

	return (
		<Container
			data-testid={testId}
			id="polaris-ideas.ui.view-control.config-columns.group-by-dropdown"
		>
			<HelpText>{helpText}</HelpText>
			<FieldGroup>
				<FieldSelect
					isClearable={isClearable}
					selectedField={selectedField}
					onChange={onChange}
					fieldOptions={fieldList}
					SearchableComponent={SearchableDropdown}
				/>
			</FieldGroup>
			{children}
			{selectedField ? (
				<Box xcss={editFieldContainerStyles}>
					<Button onClick={() => openRightSidebarOnField(selectedField.key, backTo)}>
						{!canEditFields
							? formatMessage(messages.fieldSettings)
							: formatMessage(messages.editField)}
					</Button>
				</Box>
			) : null}
			{selectedField && canHideEmptyGroups && (
				<GroupsToggleContainer>
					<ToggleWrapper>
						<Toggle isChecked={isToggleOn} onChange={onChangeToggle} />
						<HideText>{formatMessage(toggleMessage)}</HideText>
					</ToggleWrapper>
				</GroupsToggleContainer>
			)}
		</Container>
	);
};

BaseFieldConfig.defaultProps = {
	isClearable: false,
};

export const ConfigGroupBy = () => {
	const { formatMessage } = useIntl();
	const viewKind = useCurrentViewKind();
	const groupBy = useCurrentViewGroupBy();
	const { setGroupBy } = useViewActions();
	const fields = useFieldsForViewControls();

	const handleChange = useCallback(
		(fieldKey: string | undefined) => {
			if (fieldKey === undefined) {
				return;
			}
			setGroupBy(fieldKey);
			fireCompoundAnalyticsEvent.RightSidebarGroupByColumnsFieldChanged();
		},
		[setGroupBy],
	);

	const testId = useMemo(
		() => `polaris-ideas.ui.view-control.config-columns.group-by.${lowerCase(toString(viewKind))}`,
		[viewKind],
	);

	return (
		<BaseFieldConfig
			helpText={formatMessage(messages.columnsHelpText)}
			fields={fields}
			selectedField={groupBy}
			backTo={showingGroupByOptions}
			onChange={handleChange}
			testId={testId}
			canHideEmptyGroups
		>
			{groupBy && <GroupOptions field={groupBy} />}
		</BaseFieldConfig>
	);
};

const getMessageForViewKind = (viewKind?: ViewKind) => {
	switch (viewKind) {
		case VIEW_KIND_TABLE:
			return messages.groupByHelpTextForListView;
		case VIEW_KIND_BOARD:
			return messages.groupByHelpText;
		default:
			return messages.groupByHelpText;
	}
};

export const ConfigVerticalGroupBy = () => {
	const { formatMessage } = useIntl();
	const viewKind = useCurrentViewKind();
	const verticalGroupBy = useCurrentViewVerticalGroupBy();
	const { setVerticalGroupBy } = useViewActions();
	const fields = useFieldsForViewControls();

	const handleChange = useCallback(
		(fieldKey?: FieldKey): void => {
			experience.headerView.viewGroupBy.start();
			setVerticalGroupBy(
				fieldKey,
				() => {
					experience.headerView.viewGroupBy.success();
				},
				(error?: Error) => {
					experience.headerView.viewGroupBy.failure(error);
				},
			);
			fireCompoundAnalyticsEvent.RightSidebarGroupByRowsFieldChanged();
		},
		[setVerticalGroupBy],
	);

	return (
		<BaseFieldConfig
			isClearable
			helpText={formatMessage(getMessageForViewKind(viewKind))}
			fields={fields}
			selectedField={verticalGroupBy}
			onChange={handleChange}
			backTo={showingVerticalGroupByOptions}
			testId={`polaris-ideas.ui.view-control.config-columns.vertical-group-by.${lowerCase(
				toString(viewKind),
			)}`}
			canHideEmptyGroups
		>
			{verticalGroupBy ? <VerticalGroupOptions field={verticalGroupBy} /> : null}
		</BaseFieldConfig>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	padding: `${token('space.200', '16px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldGroup = styled.div({
	margin: `${token('space.100', '8px')} 0`,
	padding: `0 ${token('space.200', '16px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		width: '100%',
		textAlign: 'left',
		'&:hover': {
			width: '100%',
			textAlign: 'left',
		},
	},
});

const editFieldContainerStyles = xcss({
	paddingLeft: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelpText = styled.p({
	padding: `0px ${token('space.200', '16px')} 10px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupsToggleContainer = styled.div({
	marginTop: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '18px',
	boxShadow: '0px -2px 1px rgba(9, 30, 66, 0.08)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ToggleWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HideText = styled.span({
	marginLeft: token('space.100', '8px'),
});
