import React, { useCallback, type ElementRef } from 'react';
import { styled } from '@compiled/react';
import Popup, { type PopupProps, type ContentProps } from '@atlaskit/popup';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';

type PolarisPopupProps = PopupProps & {
	noPadding?: boolean;
	// onClose is called if a click occurred outside of the dialog - the function argument can be a ref of the target
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClose: (arg1: ElementRef<any>) => void;
};

export const PolarisPopup = (props: PolarisPopupProps) => {
	const { trigger, onClose, noPadding, content, ...rest } = props;

	const handleClickOutside = useCallback(
		(event: Event) => {
			onClose(event.target);
		},
		[onClose],
	);

	const wrappedContent = (contentProps: ContentProps) => (
		<OutsideClickAlerter onClickOutside={handleClickOutside}>
			{(outsideClickAlerterProps) => (
				<div {...outsideClickAlerterProps}>{content(contentProps)}</div>
			)}
		</OutsideClickAlerter>
	);

	return (
		<PolarisPopupContainer noPadding={noPadding !== undefined ? noPadding : false}>
			<Popup {...rest} content={wrappedContent} trigger={trigger} />
		</PolarisPopupContainer>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PolarisPopupContainer = styled.div<{ noPadding: any }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		padding: ({ noPadding }) => (noPadding ? '0px !important' : undefined),
	},
});
