import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import { JIRA_API_FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { createField } from '../../../../services/jira/create-field/index.tsx';
import { createPlay } from '../../../../services/polaris-api/create-play/index.tsx';
import type { CreatePlayFieldRequest } from '../../types.tsx';
import { transformResponse as transformJiraResponse } from '../transform/index.tsx';

/**
 * Create a play field directly in Jira
 * @param apolloClient required for polaris-api part of the process
 * @param request the request to create the field
 */
export const createVotesFieldWithPlay = async (
	apolloClient: PolarisApolloClient,
	request: CreatePlayFieldRequest,
) => {
	// since play data (contributions) are still stored in polaris-api, we need to do this in two steps:
	// step 1: create a free-floating (not attached to any field) "play" entity in polaris-api; this will be
	//         used to store the contributions, and will be dead until associated with a field.
	// step 2: create a field in Jira, and associate the play entity created in step 1 with it.

	const playId = await createPlay(apolloClient, {
		project: request.project,
		label: request.label,
		// @ts-expect-error - TS2322: Type '"PolarisBudgetAllocationPlay"' is not assignable to type 'PolarisPlayKind'.+
		kind: request.kind,
		parameters: request.parameters,
	}).then(({ node }) => node.id);

	// call jira api to create the field and attach the play id to it
	const projectIdFromARI = getAriConfig(request.project).resourceId;
	const issueTypeIdFromARI = getAriConfig(request.issueTypeId).resourceId;
	return createField(
		projectIdFromARI,
		issueTypeIdFromARI,
		request.label,
		JIRA_API_FIELD_TYPES.VOTES,
		{
			polarisFormula: {
				template: 'play_agg',
				parameters: {
					play: playId,
					aspect: 'amount',
				},
			},
			maxSpend: request.parameters?.maxSpend,
		},
	).then((jiraField) =>
		transformJiraResponse([jiraField], { [jiraField.key]: [issueTypeIdFromARI] }),
	);
};
