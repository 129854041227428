import React from 'react';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { SkeletonItem } from '@atlassian/jira-spa-apps-common/src/skeletons/main.tsx';
import { Spacer } from './styled.tsx';

const skeletonWidth = 500;

export const Skeleton = () => (
	<>
		<SkeletonItem width={skeletonWidth} height={gridSize * 2} />
		<Spacer margin={gridSize / 2} />
		<SkeletonItem width={skeletonWidth} height={gridSize * 2} />

		<Spacer margin={gridSize * 3} />
		<SkeletonItem width={skeletonWidth} height={gridSize * 5} />

		<Spacer margin={gridSize * 3} />
		<SkeletonItem width={skeletonWidth / 2} height={gridSize * 3} />
		<Spacer margin={gridSize} />
		<SkeletonItem width={skeletonWidth / 2} height={gridSize * 3} />
		<Spacer margin={gridSize} />
		<SkeletonItem width={skeletonWidth / 2} height={gridSize * 3} />
	</>
);

export default Skeleton;
