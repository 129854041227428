import React, { useCallback, useState, memo, Fragment } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Avatar from '@atlaskit/avatar';
import AvatarGroup from '@atlaskit/avatar-group';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIsEmbedded } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useCurrentViewUUID } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { Visitors as VisitorsNew } from '@atlassian/jira-polaris-component-view-visitors/src/ui/index.tsx';
import { FormattedDate } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { VIEW_PROFILE_ACTION } from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import { ProfileCard } from '@atlassian/jira-profilecard-next/src/main.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { messages } from './messages.tsx';
import { useVisitors } from './utils.tsx';

export const VisitorsInternal = (props: { testId?: string }) => {
	const { formatMessage } = useIntl();
	const [isOpen, setOpen] = useState(false);
	const [visitors, avatars] = useVisitors();

	const visitorPopupTrigger = useCallback(
		(triggerProps: TriggerProps) =>
			isVisualRefreshEnabled() ? (
				<Button
					label=""
					onClick={() => setOpen(true)}
					{...triggerProps}
					testId={props.testId && `${props.testId}.button`}
					appearance="subtle"
					iconAfter={
						<AvatarGroup
							size="small"
							maxCount={5}
							appearance="stack"
							data={avatars.map((avatar) => ({ ...avatar, name: '' }))}
							isTooltipDisabled
							onMoreClick={noop}
							testId="polaris-ideas.ui.view-header.visitors.avatar-group"
						/>
					}
				/>
			) : (
				<ButtonWrapper>
					<Button
						onClick={() => setOpen(true)}
						{...triggerProps}
						testId={props.testId && `${props.testId}.button`}
						iconAfter={
							<AvatarGroupWrapper>
								<AvatarGroup
									size="small"
									maxCount={5}
									appearance="stack"
									// @ts-expect-error - TS2322 - Type 'Avatar[]' is not assignable to type 'AvatarProps[]'.
									data={avatars}
									isTooltipDisabled
									onMoreClick={noop}
									testId="polaris-ideas.ui.view-header.visitors.avatar-group"
								/>
							</AvatarGroupWrapper>
						}
					/>
				</ButtonWrapper>
			),
		[avatars, props.testId],
	);

	const visitorPopupContent = useCallback(
		() =>
			isVisualRefreshEnabled() ? (
				<Box padding="space.250" xcss={fixedSizeCss}>
					<Stack space="space.250">
						<Heading size="xsmall" as="h4">
							{formatMessage(messages.heading)} ({visitors.length})
						</Heading>

						<Stack space="space.100">
							{visitors.map((visitor) => {
								const content = (
									<Inline alignBlock="center" spread="space-between">
										<Inline alignBlock="center" space="space.100">
											<Avatar src={visitor.avatar} size="small" presence={visitor.presence} />
											<Text>{visitor.name ?? formatMessage(messages.anonymous)}</Text>
										</Inline>

										<Text>
											<FormattedDate date={visitor.timestamp?.getTime() ?? Date.now()} />
										</Text>
									</Inline>
								);

								return visitor.accountId == null || visitor.name == null ? (
									<Fragment key={visitor.accountId}>{content}</Fragment>
								) : (
									<ProfileCard
										key={visitor.accountId}
										actions={[VIEW_PROFILE_ACTION]}
										accountId={visitor.accountId}
										TenantContextSubscriber={TenantContextSubscriber}
									>
										{content}
									</ProfileCard>
								);
							})}
						</Stack>
					</Stack>
				</Box>
			) : (
				<VisitorPopupContainer>
					{/* eslint-disable-next-line @atlaskit/design-system/use-heading */}
					<h4>
						{formatMessage(messages.heading)} ({visitors.length})
					</h4>

					{visitors.map((visitor) => {
						const content = (
							<VisitorPopupItemWrapper>
								<Visitor>
									<Avatar src={visitor.avatar} size="small" presence={visitor.presence} />
									<span>{visitor.name ?? formatMessage(messages.anonymous)}</span>
								</Visitor>
								<VisitDate>
									<FormattedDate date={visitor.timestamp?.getTime() ?? Date.now()} />
								</VisitDate>
							</VisitorPopupItemWrapper>
						);

						return visitor.accountId == null || visitor.name == null ? (
							content
						) : (
							<ProfileCard
								key={visitor.accountId}
								actions={[VIEW_PROFILE_ACTION]}
								accountId={visitor.accountId}
								TenantContextSubscriber={TenantContextSubscriber}
							>
								{content}
							</ProfileCard>
						);
					})}
				</VisitorPopupContainer>
			),
		[formatMessage, visitors],
	);

	if (visitors.length === 0) {
		return <></>;
	}

	return (
		<Popup
			shouldUseCaptureOnOutsideClick
			placement="bottom-start"
			isOpen={isOpen}
			onClose={() => setOpen(false)}
			content={visitorPopupContent}
			trigger={visitorPopupTrigger}
		/>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const VisitorsOld = memo<Record<any, any>>(VisitorsInternal);

export const Visitors = ({ testId }: { testId?: string }) => {
	const viewUUID = useCurrentViewUUID();
	const embedded = useIsEmbedded();

	if (fg('jpd_visitor_handling_refactor')) {
		if (!embedded) {
			return <VisitorsNew viewUUID={viewUUID} testId={testId} />;
		}
		return null;
	}
	return <VisitorsOld testId={testId} />;
};

const fixedSizeCss = xcss({
	maxWidth: '352px',
	maxHeight: '340px',
	overflowY: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VisitorPopupContainer = styled.div({
	display: 'flex',
	padding: `${token('space.250', '20px')} 0`,
	flexDirection: 'column',
	width: '352px',
	maxHeight: '340px',
	overflowY: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > h4': {
		textTransform: 'uppercase',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtlest', colors.N200),
		font: token('font.body.UNSAFE_small'),
		padding: `0 ${token('space.300', '24px')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VisitorPopupItemWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginTop: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	padding: `0 ${token('space.300', '24px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-child': {
		marginTop: token('space.150', '12px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Visitor = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		marginLeft: '10px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VisitDate = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	zIndex: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > button': {
		background: token('elevation.surface', 'white'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarGroupWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a:hover': {
		'&:active': {
			transform: 'none',
		},
		'&::after': {
			backgroundColor: 'transparent',
		},
	},
});
