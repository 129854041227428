import { tryAddCurrentUserAsSiteCreator } from './beta/add-as-site-creator.tsx';
import { toggleEnforcePermissionChecks } from './beta/toggle-enforce-permission-checks.tsx';
import { toggleSelfServeAddAsSiteCreator } from './beta/toggle-self-serve.tsx';
import { load } from './load/index.tsx';

export const actions = {
	load,
	// beta actions
	tryAddCurrentUserAsSiteCreator,
	toggleEnforcePermissionChecks,
	toggleSelfServeAddAsSiteCreator,
};
