import React, { forwardRef } from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { P200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import { IssueLinkingDisabledNotification } from '../../issue-linking/index.tsx';

const IssueLinkingDisabledTooltip = forwardRef((props, ref) => (
	<InlineDialog {...props} ref={ref}>
		<IssueLinkingDisabledNotification />
	</InlineDialog>
));

export const IssueLinkingDisabledMarker = () => (
	// "infoIcon" is used as a tooltip's content only because this prop can't be empty,
	// otherwise the tooltip doesn't render the provided component
	<Tooltip
		component={IssueLinkingDisabledTooltip}
		content="infoIcon"
		testId="polaris-common.ui.field-info-marker.issue-linking-disabled.tooltip"
	>
		{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
		<InfoIcon size="small" label="warn" primaryColor={P200} />
	</Tooltip>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineDialog = styled<any>(TooltipPrimitive)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface.overlay', colors.N0),
	borderRadius: '3px',
	boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	padding: token('space.150', '12px'),
	maxWidth: '390px',
});
