import { createStateHook } from '@atlassian/react-sweet-state';
import { Store } from '../index.tsx';

export const useEditorAiEnabled = createStateHook(Store, {
	selector: (state) => state.features.isEditorAiEnabled,
});

export const useIssueLinkingEnabled = createStateHook(Store, {
	selector: (state) => state.meta.errors.issueLinkTypes?.statusCode !== 404,
});

export const useDeliveryIssueLinkType = createStateHook(Store, {
	selector: (state) => state.jira.jpdIssueLinkTypes.delivery,
});

export const useMergeIssueLinkType = createStateHook(Store, {
	selector: (state) => state.jira.jpdIssueLinkTypes.merge,
});

export const useDataPointIssueLinkType = createStateHook(Store, {
	selector: (state) => state.jira.jpdIssueLinkTypes.dataPoint,
});

export const useEpicNameFieldKey = createStateHook(Store, {
	selector: (state) => state.jira.epicNameFieldKey,
});

export const useHiddenPolarisIssueLinkTypes = createStateHook(Store, {
	selector: (state) =>
		[state.jira.jpdIssueLinkTypes.delivery, state.jira.jpdIssueLinkTypes.dataPoint].filter(Boolean),
});

export const useIsBeta = createStateHook(Store, {
	selector: (state) => state.features.isBeta,
});

export const useIsBetaSelfServeEnabledOnSite = createStateHook(Store, {
	selector: (state) => state.features.isSelfServeEnabledOnSite,
});

export const useIsBetaEnforcePermissionChecksEnabledOnSite = createStateHook(Store, {
	selector: (state) => state.features.isEnforcePermissionChecksEnabledOnSite,
});

export const useIssueLinkTypes = createStateHook(Store, {
	selector: (state) => state.jira.issueLinkTypes,
});

export const useArjConfiguration = createStateHook(Store, {
	selector: (state) => state.jira.arj,
});

export const useStatusCategories = createStateHook(Store, {
	selector: (state) => state.jira.statusCategories,
});
