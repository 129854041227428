import { createContext, useContext } from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

type ConnectionFieldSelectContextValue = {
	fieldKey: FieldKey;
	onEditFieldRequested?: () => void;
};

export const ConnectionFieldSelectContext = createContext<ConnectionFieldSelectContextValue>({
	fieldKey: '',
});
export const useConnectionFieldSelectContext = () => useContext(ConnectionFieldSelectContext);
