import {
	END_AFTER_NOW,
	END_BEFORE_NOW,
	INTERVAL_FILTER_DATE_TYPE_END,
	INTERVAL_FILTER_DATE_TYPE_START,
	INTERVAL_FILTER_PERIOD_MONTH,
	INTERVAL_FILTER_TIME_NEXT,
	INTERVAL_FILTER_TIME_PAST,
	START_AFTER_NOW,
	START_BEFORE_NOW,
	type IntervalFieldFilterLegacyValue,
	type IntervalFieldFilterRollingDatesCurrentValue,
	type IntervalFieldFilterRollingDatesPastNextValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { assertUnreachable } from './types.tsx';

export const intervalFilterValueMigration = (
	value: IntervalFieldFilterLegacyValue,
): IntervalFieldFilterRollingDatesCurrentValue | IntervalFieldFilterRollingDatesPastNextValue => {
	const { operator, numericValue } = value;

	switch (operator) {
		case START_BEFORE_NOW:
			return {
				type: 'ROLLING_DATES',
				time: INTERVAL_FILTER_TIME_PAST,
				period: INTERVAL_FILTER_PERIOD_MONTH,
				numericValue,
				intervalDateType: INTERVAL_FILTER_DATE_TYPE_START,
			};
		case END_BEFORE_NOW:
			return {
				type: 'ROLLING_DATES',
				time: INTERVAL_FILTER_TIME_PAST,
				period: INTERVAL_FILTER_PERIOD_MONTH,
				numericValue,
				intervalDateType: INTERVAL_FILTER_DATE_TYPE_END,
			};
		case START_AFTER_NOW:
			return {
				type: 'ROLLING_DATES',
				time: INTERVAL_FILTER_TIME_NEXT,
				period: INTERVAL_FILTER_PERIOD_MONTH,
				numericValue,
				intervalDateType: INTERVAL_FILTER_DATE_TYPE_START,
			};
		case END_AFTER_NOW:
			return {
				type: 'ROLLING_DATES',
				time: INTERVAL_FILTER_TIME_NEXT,
				period: INTERVAL_FILTER_PERIOD_MONTH,
				numericValue,
				intervalDateType: INTERVAL_FILTER_DATE_TYPE_END,
			};
		default:
			assertUnreachable(operator);
	}
};
