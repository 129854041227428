import React, { type SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Image from '@atlaskit/image';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import HelpPanelButton from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useUserPropertiesActions,
	useUserProperty,
} from '@atlassian/jira-polaris-common/src/controllers/user-properties/index.tsx';
import { useCanCreateInsights } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { FireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useHasReachedInsightsLimit } from '../../../../controllers/insights/selectors/insights-hooks.tsx';
import { useIsInsightsLoading } from '../../../../controllers/insights/selectors/meta-hooks.tsx';
import { Restore } from '../../../idea-view/restore/index.tsx';
import {
	CreateInsightHint,
	CREATE_INSIGHT_HELP_ARTICLE_ID,
	CREATE_INSIGHT_HELP_FALLBACK_URL,
	CREATE_INSIGHT_HINT_CLOSED_KEY,
} from '../add-insight/create-insight-hint/index.tsx';
import { CreateInsight } from '../add-insight/index.tsx';
import InsightsImgDark from './assets/insights-dark.svg';
import InsightsImg from './assets/insights.svg';
import messages from './messages.tsx';

export const EmptyInsights = ({
	isCompact,
	...props
}: {
	isCompact?: boolean;
	onEnterEditMode: () => void;
}) => {
	const isLoadingInsights = useIsInsightsLoading();

	const [isEditMode, setEditMode] = useState(false);
	const isIdeaArchived = useIsSelectedIssueArchived();

	const [hasReachedInsightsLimit] = useHasReachedInsightsLimit();

	const { formatMessage } = useIntl();
	const canCreateInsights = useCanCreateInsights();

	const onEnterEditMode = useCallback(() => {
		setEditMode(true);
	}, []);

	const hintMessage = () => {
		if (isIdeaArchived) {
			return formatMessage(messages.descriptionArchived);
		}
		if (canCreateInsights) {
			return formatMessage(messages.description);
		}
		return formatMessage(messages.descriptionReadOnly);
	};

	const [, { loadProperty, saveProperty }] = useUserPropertiesActions();

	useEffect(() => {
		loadProperty(CREATE_INSIGHT_HINT_CLOSED_KEY);
	}, [loadProperty]);

	const [
		{
			value: createInsightHintClosed,
			isLoading: isLoadingCreateInsightHintClosed,
			error: createInsightHintClosedError,
		},
	] = useUserProperty(CREATE_INSIGHT_HINT_CLOSED_KEY);

	const handleCloseCreateInsightHint = useCallback(() => {
		saveProperty(CREATE_INSIGHT_HINT_CLOSED_KEY, true);
	}, [saveProperty]);

	if (isLoadingCreateInsightHintClosed || isLoadingInsights) {
		return null;
	}

	const isCreateInsightHintEnabled =
		createInsightHintClosed !== true &&
		createInsightHintClosedError === null &&
		!isEditMode &&
		!isIdeaArchived &&
		!hasReachedInsightsLimit;

	if (isCreateInsightHintEnabled) {
		return (
			<Box xcss={createInsightHintWrapperStyles}>
				<FireTrackAnalytics eventName="hint viewed" actionSubjectId="createInsights" />
				<CreateInsightHint onClose={handleCloseCreateInsightHint} onCreate={onEnterEditMode} />
			</Box>
		);
	}

	return (
		<Container isReadonly={!canCreateInsights} isEditing={isEditMode}>
			{!isEditMode && (
				<ImageContainer>
					<Image src={InsightsImg} srcDark={InsightsImgDark} alt="insights" />
				</ImageContainer>
			)}
			<Content>
				{!isEditMode && (
					<>
						<Description>
							<>
								{hintMessage()}
								{!isIdeaArchived && (
									<HelpPanelButton
										appearance="link"
										spacing="none"
										articleId={CREATE_INSIGHT_HELP_ARTICLE_ID}
										onClick={(e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
											fireCompoundAnalyticsEvent.IdeaView.InsightsTab.createInsightLearnMoreButtonClicked(
												analyticsEvent,
												false,
											);
										}}
										fallbackComponent={
											<Button
												appearance="link"
												spacing="none"
												rel="noopener noreferrer"
												target="_blank"
												href={CREATE_INSIGHT_HELP_FALLBACK_URL}
											>
												{formatMessage(messages.learnMoreLink)}
											</Button>
										}
									>
										{formatMessage(messages.learnMoreLink)}
									</HelpPanelButton>
								)}
							</>
						</Description>
						{isIdeaArchived && <Restore />}
					</>
				)}
				{canCreateInsights && !isIdeaArchived && (
					<Box xcss={addInsightContainerStyles}>
						<CreateInsight isCompact={isCompact} onEnterEditMode={props.onEnterEditMode} />
					</Box>
				)}
			</Content>
		</Container>
	);
};

const createInsightHintWrapperStyles = xcss({
	marginTop: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isReadonly: boolean; isEditing: boolean }>({
	display: 'flex',
	justifyContent: 'flex-start',
	flexWrap: 'wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ isEditing }) =>
		isEditing ? token('space.150', '12px') : token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: ({ isReadonly }) => (isReadonly ? 'center' : 'start'),
	gap: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	flex: 1,
	width: '460px',
});

const addInsightContainerStyles = xcss({
	marginTop: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, button': {
		fontWeight: 400,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	width: '110px',
	height: '88px',
});
