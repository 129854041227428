/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useLinkedIssueDataByLinkedIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/linked-issues/hooks.tsx';
import { IssueLink } from '@atlassian/jira-polaris-common/src/ui/issue-link/index.tsx';

type Props = {
	issueId: number;
};

export const IssueSummaryCellRenderer = ({ issueId }: Props) => {
	const linkedIssue = useLinkedIssueDataByLinkedIssueId(issueId);

	if (!linkedIssue) {
		return null;
	}

	return (
		<Tooltip content={linkedIssue.summary}>
			<div css={summaryStyles}>
				<IssueLink issueKey={linkedIssue.issueKey}>{linkedIssue.summary}</IssueLink>
			</div>
		</Tooltip>
	);
};

const summaryStyles = css({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	a: {
		color: token('color.text'),
	},
});
