import { ff } from '@atlassian/jira-feature-flagging';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { addCurrentUserToSiteCreators } from '../../../services/jira/jpd-permissions/index.tsx';
import { createAddAsSiteCreator } from '../../../services/polaris-api/site-creator/index.tsx';
import type { State } from '../../types.tsx';

type ActionProps = {
	apolloClient: PolarisApolloClient;
	cloudId: CloudId;
};

export const tryAddCurrentUserAsSiteCreator =
	({ apolloClient, cloudId }: ActionProps): Action<State> =>
	async ({ getState, setState }) => {
		const { features } = getState();
		const { canSelfServeBetaSiteCreator } = features;

		if (ff('polaris.beta-migration-handling') && canSelfServeBetaSiteCreator) {
			const addAsSiteCreator = createAddAsSiteCreator(apolloClient, cloudId);

			Promise.all([
				addAsSiteCreator(), // perform action on polaris-api
				addCurrentUserToSiteCreators(), // log on monolith
			])

				.catch(() => {
					setState({
						features: {
							...getState().features,
							canSelfServeBetaSiteCreator: false,
						},
					});
				});
		}
	};
