import gqlTagPolaris from 'graphql-tag';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { jira_polaris_AddBetaUserAsSiteCreator as AddBetaUserAsSiteCreator } from './__generated_apollo__/jira_polaris_AddBetaUserAsSiteCreator.ts';

export const ADD_AS_SITE_CREATOR = gqlTagPolaris`
mutation jira_polaris_AddBetaUserAsSiteCreator ($input: AddBetaUserAsSiteCreatorInput!){
    addBetaUserAsSiteCreator(input: $input) @optIn(to: ["polaris-v0"]) {
        success
        errors {
          message
        }
    }
}
`;

export const createAddAsSiteCreator =
	(apolloClient: PolarisApolloClient, cloudID: string) => (): Promise<void> =>
		apolloClient
			.mutate<AddBetaUserAsSiteCreator>({
				mutation: ADD_AS_SITE_CREATOR,
				variables: {
					input: {
						cloudID,
					},
				},
			})
			.then((result) => {
				if (result.errors !== undefined) {
					throw new Error(
						`polaris-remote-site.add-as-site-creator:${result.errors
							.map((e) => e.message)
							.join(', ')}`,
					);
				}
			});
