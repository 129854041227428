/** @jsx jsx */
import React, { memo, useCallback, useMemo, useState } from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import { IconButton } from '@atlaskit/button/new';
import { Emoji } from '@atlaskit/emoji';
import EyeOpen from '@atlaskit/icon/core/eye-open';
import EyeOpenStrikethrough from '@atlaskit/icon/core/eye-open-strikethrough';
import SettingsIcon from '@atlaskit/icon/core/settings';
import StarFilledIcon from '@atlaskit/icon/core/star-starred';
import StarIcon from '@atlaskit/icon/core/star-unstarred';
import { ButtonItem } from '@atlaskit/menu';
import { Box, xcss, Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { MenuOption } from '@atlassian/jira-polaris-lib-decoration/src/ui/menu-option/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useFieldEmoji,
	useFieldLabel,
	useFieldTypeIcon,
	useIsGlobalCustomField,
} from '../../../controllers/field/selectors/field-hooks.tsx';
import { useFieldsSidebarConfig } from '../../../controllers/idea/selectors/hooks.tsx';
import { useViewActions } from '../../../controllers/views/main.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewLayoutType,
	useCurrentViewSummaryCardFieldKey,
	useCurrentViewKind,
} from '../../../controllers/views/selectors/view-hooks.tsx';
import { FIELD_ITEM_OUTER_SPACING } from '../../config/fields/field-item/constants.tsx';
import { DeliveryDataRestrictedMarker } from '../../field-info-marker/delivery-data-restricted/index.tsx';
import { FieldTooltip } from '../../field-tooltip/index.tsx';
import type { TogglePermissionType } from '../constants.tsx';
import { EmojiWrapper } from './emoji-wrapper/index.tsx';
import { FieldListItem as FieldListItemLegacy } from './legacy.tsx';
import messages from './messages.tsx';
import { FieldToggle } from './toggle/index.tsx';

type Props = {
	isSelected: boolean;
	isHidden: boolean;
	isDragEnabled?: boolean;
	fieldKey: FieldKey;
	hasReachedViewFieldsLimit?: string | null;
	togglePermissionType: TogglePermissionType;
	onEdit: (arg1: FieldKey) => void;
	onToggle: (arg1: FieldKey, arg2: boolean) => void;
	onHide?: (arg1: FieldKey) => void;
};

export const FieldListItem = componentWithFG(
	'polaris_groupby-filter-sort-fields-panels-refresh',
	memo(
		({
			fieldKey,
			isSelected,
			isHidden,
			isDragEnabled = true,
			hasReachedViewFieldsLimit = null,
			togglePermissionType,
			onEdit,
			onHide,
			onToggle,
		}: Props) => {
			const { formatMessage } = useIntl();
			const { createAnalyticsEvent } = useAnalyticsEvents();
			const canEditFields = useCanEditFields();
			const canManageCurrentView = useCanManageCurrentView();
			const label = useFieldLabel(fieldKey);
			const icon = useFieldTypeIcon(fieldKey, undefined);
			const emojiId = useFieldEmoji(fieldKey);
			const emoji = useEmoji(emojiId);
			const isGlobalCustomField = useIsGlobalCustomField(fieldKey);
			const isFieldHideable = fieldKey !== SUMMARY_FIELDKEY;
			const isTimelineView = useCurrentViewKind() === VIEW_KIND_TIMELINE;
			const fieldsSidebarConfig = useFieldsSidebarConfig();
			const isSummaryLayout = useCurrentViewLayoutType() === ViewLayoutType.SUMMARY;
			const isFieldSelectedForSummaryCard = useCurrentViewSummaryCardFieldKey() === fieldKey;
			const [isSummaryCardFieldToggleHovered, setIsSummaryCardFieldToggleHovered] = useState(false);
			const { setSummaryCardField } = useViewActions();

			const toggleFieldVisibilityTooltipContent = useMemo(() => {
				if (!canManageCurrentView) {
					return formatMessage(messages.noManageViewFieldsPermissions);
				}
				if (!isFieldHideable) {
					return formatMessage(messages.notHideable);
				}
				if (isHidden) {
					return formatMessage(messages.showField);
				}
				return formatMessage(messages.hideField);
			}, [canManageCurrentView, formatMessage, isFieldHideable, isHidden]);

			const fieldSettingsLabel = !canEditFields
				? formatMessage(messages.fieldSettings)
				: formatMessage(messages.editField);

			const shouldShowSummaryCardFieldToggle =
				canManageCurrentView &&
				isFieldHideable &&
				isSelected &&
				isTimelineView &&
				fieldsSidebarConfig.mode !== 'LIST' &&
				isSummaryLayout;

			const onLinkItemClick = useCallback(
				({
					target,
					currentTarget,
				}: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
					let isInteractiveEl = false;
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					let node: HTMLElement = target as HTMLElement;
					while (!isInteractiveEl && node && node !== currentTarget) {
						isInteractiveEl = !!node.getAttribute('data-interactive');
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						node = node.parentNode as HTMLElement;
					}
					if (!isInteractiveEl && canEditFields) {
						fireUIAnalytics(
							createAnalyticsEvent({
								action: 'clicked',
								actionSubject: 'listItem',
							}),
							'field',
							{
								issueFieldKey: fieldKey,
							},
						);
						onEdit(fieldKey);
					}
				},
				[canEditFields, createAnalyticsEvent, fieldKey, onEdit],
			);

			const onSettingsButtonClick = useCallback(
				(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
					e.stopPropagation();
					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'clicked',
							actionSubject: 'icon',
						}),
						'editFieldValue',
						{
							issueFieldKey: fieldKey,
						},
					);
					sendPendoTrackEvent({
						actionSubjectAndAction: 'icon clicked',
						actionSubjectId: 'editFieldValue',
						attributes: { issueFieldKey: fieldKey },
					});
					onEdit(fieldKey);
				},
				[createAnalyticsEvent, fieldKey, onEdit],
			);

			const onHideButtonClick = useCallback(
				(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
					e.stopPropagation();

					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'clicked',
							actionSubject: 'icon',
						}),
						isHidden ? 'showField' : 'hideField',
						{
							issueFieldKey: fieldKey,
						},
					);
					onHide?.(fieldKey);
				},
				[createAnalyticsEvent, fieldKey, isHidden, onHide],
			);

			const onSummaryCardToggleClick = useCallback(
				(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
					e.stopPropagation();

					setSummaryCardField(isFieldSelectedForSummaryCard ? undefined : fieldKey);
					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'clicked',
							actionSubject: 'icon',
						}),
						isFieldSelectedForSummaryCard ? 'hideFieldFromSummaryCard' : 'showFieldOnSummaryCard',
						{
							issueFieldKey: fieldKey,
						},
					);
				},
				[createAnalyticsEvent, fieldKey, isFieldSelectedForSummaryCard, setSummaryCardField],
			);

			return (
				<FieldTooltip
					fieldKey={fieldKey}
					strategy="hover"
					position="left"
					isSummaryCardField={shouldShowSummaryCardFieldToggle && isSummaryCardFieldToggleHovered}
				>
					<Box>
						<MenuOption
							hideActionMenu
							isDragEnabled={isDragEnabled}
							setOpenOption={noop}
							setIsMenuOpen={noop}
							outerSpacing={FIELD_ITEM_OUTER_SPACING}
						>
							<div
								css={[
									linkItemWrapperStyles,
									!isFieldSelectedForSummaryCard && fieldNotSelectedForSummaryCardStyles,
								]}
							>
								<ButtonItem
									testId="polaris-common.ui.field-config.item.link-item"
									onClick={onLinkItemClick}
									data-component-selector="link-item-50Fe"
									iconAfter={
										<Flex alignItems="center">
											<Flex alignItems="center" gap="space.050">
												<div
													css={hiddenStyles}
													data-component-selector="settings-button-wrapper-2xZ4"
												>
													<IconButton
														id="pendo.field-config.settings-button"
														testId="polaris-common.ui.field-config.item.settings-button"
														appearance="subtle"
														spacing="compact"
														label={fieldSettingsLabel}
														icon={SettingsIcon}
														onClick={onSettingsButtonClick}
														isTooltipDisabled={false}
													/>
												</div>
												{onHide && isSelected && (
													<div
														css={[isHidden && visibleStyles, !isHidden && hiddenStyles]}
														data-component-selector="hide-button-wrapper-35fC"
													>
														<IconButton
															testId="polaris-common.ui.field-config.item.hide-button"
															data-component-selector="hide-button-sk5G"
															isDisabled={!isFieldHideable || !canManageCurrentView}
															onClick={onHideButtonClick}
															label={formatMessage(messages.toggleField)}
															appearance="subtle"
															spacing="compact"
															icon={(iconProps) =>
																isHidden ? (
																	<EyeOpenStrikethrough color="currentColor" {...iconProps} />
																) : (
																	<EyeOpen color="currentColor" {...iconProps} />
																)
															}
															isTooltipDisabled={false}
															tooltip={{
																content: toggleFieldVisibilityTooltipContent,
															}}
														/>
													</div>
												)}
												{shouldShowSummaryCardFieldToggle && (
													<IconButton
														id={`pendo.field-config.summary-card-toggle.${isFieldSelectedForSummaryCard ? 'disable' : 'enable'}`}
														testId="polaris-common.ui.field-config.item.summary-card-toggle"
														data-component-selector="summary-card-field-button-wrapper-56fC"
														onClick={onSummaryCardToggleClick}
														onMouseOver={() => {
															setIsSummaryCardFieldToggleHovered(true);
														}}
														onMouseLeave={() => {
															setIsSummaryCardFieldToggleHovered(false);
														}}
														appearance="subtle"
														spacing="compact"
														label={
															isFieldSelectedForSummaryCard
																? formatMessage(messages.showField)
																: formatMessage(messages.hideField)
														}
														icon={(iconProps) =>
															isFieldSelectedForSummaryCard ? (
																<StarFilledIcon {...iconProps} />
															) : (
																<StarIcon {...iconProps} />
															)
														}
													/>
												)}
											</Flex>
											<FieldToggle
												testId="polaris-common.ui.field-config.item.toggle-field-button"
												fieldKey={fieldKey}
												hasReachedViewFieldsLimit={hasReachedViewFieldsLimit}
												isSelected={isSelected}
												togglePermissionType={togglePermissionType}
												onToggle={onToggle}
											/>
										</Flex>
									}
								>
									<Flex alignItems="center" xcss={fieldListItemContainerStyles}>
										{emoji ? (
											<EmojiWrapper>
												<Emoji emoji={emoji} fitToHeight={16} showTooltip />
											</EmojiWrapper>
										) : (
											<Flex xcss={iconWrapperStyles}>{icon}</Flex>
										)}
										<Box xcss={labelStyles}>{label}</Box>
										{isGlobalCustomField && <GlobalFieldMarker />}
										<DeliveryDataRestrictedMarker fieldKey={fieldKey} marginLeft={8} />
									</Flex>
								</ButtonItem>
							</div>
						</MenuOption>
					</Box>
				</FieldTooltip>
			);
		},
	),
	FieldListItemLegacy,
);

const iconWrapperStyles = xcss({
	alignItems: 'center',
	marginRight: 'space.100',
});

const visibleStyles = css({
	visibility: 'visible',
});

const hiddenStyles = css({
	visibility: 'hidden',
});

const linkItemWrapperStyles = css({
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="hide-button-sk5G"]:disabled': {
		background: 'unset',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="link-item-50Fe"]': {
		display: 'flex',
		padding: `${token('space.050')} 0 ${token('space.050')} 0`,
		minHeight: 'initial',
		'&:hover': {
			backgroundColor: 'transparent',
			textDecoration: 'none',
		},
		'&:active': {
			backgroundColor: 'transparent',
		},
		'&:focus': {
			outline: 'none',
			boxShadow: 'none',
		},
		'&:visited, &:active, &:focus': {
			textDecoration: 'none',
		},
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-component-selector="hide-button-wrapper-35fC"], [data-component-selector="settings-button-wrapper-2xZ4"]':
			{
				visibility: 'visible',
			},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-component-selector="summary-card-field-button-wrapper-56fC"] svg': {
			color: token('color.icon'),
		},
	},
});

const fieldNotSelectedForSummaryCardStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="summary-card-field-button-wrapper-56fC"] svg': {
		color: token('color.icon.disabled'),
	},
});

const fieldListItemContainerStyles = xcss({
	maxWidth: '262px',
});

const labelStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
