import { useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useViewIdsByViewUUID } from '@atlassian/jira-polaris-component-view-id-mapping/src/index.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import {
	updateViewLastViewed,
	updateViewLastViewedForCollections,
	updateViewLastViewedForSharing,
} from '../../services/jpd-views-service/update-view-last-viewed/index.tsx';
import { updateViewLastViewedPolarisApi } from '../../services/polaris-api/update-view-last-viewed/index.tsx';
import { memoizedLeadingDebounce } from './utils.tsx';

// ensure that we don't actually update anything more than once every X ms
// no matter what (re)render reasons or frequency we have in our react app
const UPDATE_THROTTLE_INTERVAL = 5000;
const throttledUpdateOperations = {
	updateViewLastViewed: memoizedLeadingDebounce(updateViewLastViewed, UPDATE_THROTTLE_INTERVAL),
	updateViewLastViewedForSharing: memoizedLeadingDebounce(
		updateViewLastViewedForSharing,
		UPDATE_THROTTLE_INTERVAL,
	),
	updateViewLastViewedForCollections: memoizedLeadingDebounce(
		updateViewLastViewedForCollections,
		UPDATE_THROTTLE_INTERVAL,
	),
	updateViewLastViewedPolarisApi: memoizedLeadingDebounce(
		updateViewLastViewedPolarisApi,
		UPDATE_THROTTLE_INTERVAL,
	),
};

export const LastSeenTimestampUpdater = ({ viewUUID }: { viewUUID: ViewUUID | undefined }) => {
	const apolloClient = useApolloClient();
	const viewIds = useViewIdsByViewUUID({ viewUUID });

	const container = useEnvironmentContainer();

	const updateOld = useCallback(() => {
		if (viewUUID === undefined) {
			return;
		}

		if (container?.type === 'VIEW') {
			throttledUpdateOperations.updateViewLastViewedForSharing(viewUUID);
		} else if (container?.type === 'COLLECTION') {
			throttledUpdateOperations.updateViewLastViewedForCollections(
				container.collectionId,
				viewUUID,
				new Date().toISOString(),
			);
		} else if (viewIds !== undefined) {
			throttledUpdateOperations.updateViewLastViewedPolarisApi(
				apolloClient,
				viewIds.viewAri,
				new Date().toISOString(),
			);
		}
	}, [apolloClient, viewIds, viewUUID, container]);

	const updateNew = useCallback(() => {
		if (viewUUID === undefined) {
			return;
		}
		if (container?.type === 'VIEW') {
			throttledUpdateOperations.updateViewLastViewedForSharing(viewUUID);
		} else {
			throttledUpdateOperations.updateViewLastViewed(viewUUID);
		}
	}, [viewUUID, container]);

	useEffect(() => {
		if (viewUUID === undefined) {
			return;
		}

		switch (expVal('jpd_visitor_handling_architecture_north_star', 'write', 'OLD')) {
			case 'NEW':
				updateNew();
				break;
			case 'BOTH':
				// dual write is orchestrated on jpd-views-service side, so we
				// only write NEW in the dual write stage
				updateNew();
				break;
			case 'OLD':
			default:
				updateOld();
		}
	}, [viewUUID, updateNew, updateOld]);

	return null;
};
