import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import OverviewIcon from '@atlaskit/icon/core/migration/align-left--overview';
import { Pressable, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useOpenRightSidebarOnViewInfo } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useAutoOpenSidebarCondition,
	useCurrentViewAri,
	useUserHasVisitedCurrentView,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

export const ShowDescriptionButton = () => {
	const { formatMessage } = useIntl();
	const userHasSeenCurrentView = useUserHasVisitedCurrentView();
	const currentViewAri = useCurrentViewAri();
	const { updateLastViewedState } = useViewActions();
	const openSidebarOnViewInfo = useOpenRightSidebarOnViewInfo();
	const shouldAutoOpenSidebar = useAutoOpenSidebarCondition();

	useEffect(
		() => () => {
			if (fg('jpd_visitor_handling_refactor')) {
				return;
			}

			if (currentViewAri && !userHasSeenCurrentView) {
				updateLastViewedState(currentViewAri);
			}
		},
		[currentViewAri, updateLastViewedState, userHasSeenCurrentView],
	);

	useEffect(() => {
		if (!fg('killswitch_jpd_onboarding_video') && shouldAutoOpenSidebar) {
			openSidebarOnViewInfo();
		}
	}, [openSidebarOnViewInfo, shouldAutoOpenSidebar]);

	return isVisualRefreshEnabled() ? (
		<Pressable
			testId="polaris-ideas.ui.view-header.view-subtitle.show-description-button.description-button"
			onClick={openSidebarOnViewInfo}
			backgroundColor="color.background.neutral.subtle"
			padding="space.0"
			xcss={pressableStyles}
		>
			<OverviewIcon
				label={formatMessage(messages.descriptionButton)}
				LEGACY_size="small"
				color={token('color.icon.subtlest')}
			/>
			{formatMessage(messages.descriptionButton)}
		</Pressable>
	) : (
		<CustomButton
			testId="polaris-ideas.ui.view-header.view-subtitle.show-description-button.description-button"
			onClick={openSidebarOnViewInfo}
			iconBefore={
				<OverviewIcon
					label={formatMessage(messages.descriptionButton)}
					LEGACY_size="small"
					color={token('color.icon.subtlest')}
				/>
			}
		>
			{formatMessage(messages.descriptionButton)}
		</CustomButton>
	);
};

const pressableStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body.UNSAFE_small',
	display: 'flex',
	gap: 'space.050',

	':hover': {
		textDecoration: 'underline',
	},
	':active': {
		color: 'color.text',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		display: 'flex',
		alignItems: 'center',
		gap: token('space.050', '4px'),
		padding: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		font: token('font.body.small', fontFallback.body.small),
		height: 'auto',
		verticalAlign: 'baseline',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: `${token('color.text.subtlest', colors.N200)} !important`,
		background: 'transparent',
		'&:hover': {
			background: 'transparent',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: `${token('color.text.subtlest', colors.N200)} !important`,
			textDecoration: 'underline',
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > *': {
			margin: 0,
		},
	},
});
