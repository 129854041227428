import React from 'react';
import { styled } from '@compiled/react';
import { useIntl } from '@atlassian/jira-intl';
import { ConfigYAxis } from '../../../view-controls/config-matrix-axis/y-axis/index.tsx';
import { Header } from '../../header/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	onClose: () => void;
};

export const YAxisPanel = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<FieldsContainer>
			<Header title={formatMessage(messages.yAxisOptions)} onClose={onClose} />
			<ConfigYAxis />
		</FieldsContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div({
	minHeight: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
});
