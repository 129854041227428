import React, { type SyntheticEvent, memo, useState, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import LinkIcon from '@atlaskit/icon/glyph/link';
import { JiraLogo } from '@atlaskit/logo';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import HelpPanelButton from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import {
	useIsSelectedIssueArchived,
	useSelectedIssueSummary,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useUserPropertiesActions,
	useUserProperty,
} from '@atlassian/jira-polaris-common/src/controllers/user-properties/index.tsx';
import { useCanManageDeliveryTickets } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import HintMessage from '@atlassian/jira-polaris-lib-hint-message/src/async.tsx';
import { SpotlightTypes } from '@atlassian/jira-polaris-lib-onboarding/src/common/types.tsx';
import { useSpotlights } from '@atlassian/jira-polaris-lib-onboarding/src/controllers/index.tsx';
import { useAnalyticsEvents, FireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { LinkIssue } from '../../../../../common/ui/link-issue/index.tsx';
import { SendToBacklog } from '../../../../../common/ui/send-to-backlog/index.tsx';
import { Restore } from '../../../restore/index.tsx';
import JiraBoardIcon from './assets/board.svg';
import messages from './messages.tsx';

type EmptyDeliveryProps = {
	isCompact?: boolean;
};

const DELIVERY_TICKETS_HINT_CLOSED_KEY = 'polaris.delivery-tickets-hint-closed';
const HELP_ARTICLE_ID = '79Gsxo13vpSGA8lVFlA88n';
const HELP_FALLBACK_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/create-a-jira-issue-from-jira-product-discovery/';

const LearnMoreLink = () => {
	const { formatMessage } = useIntl();

	return (
		<HelpPanelButton
			appearance="link"
			spacing="none"
			articleId={HELP_ARTICLE_ID}
			onClick={(e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireCompoundAnalyticsEvent.IdeaView.DeliveryTab.learnMoreButtonClicked(
					analyticsEvent,
					false,
				);
			}}
			fallbackComponent={
				<Button
					appearance="link"
					spacing="none"
					rel="noopener noreferrer"
					target="_blank"
					href={HELP_FALLBACK_URL}
				>
					{formatMessage(messages.learnMoreLink)}
				</Button>
			}
		>
			{formatMessage(messages.learnMoreLink)}
		</HelpPanelButton>
	);
};

export const EmptyDelivery = memo<EmptyDeliveryProps>(({ isCompact }: EmptyDeliveryProps) => {
	const { formatMessage } = useIntl();
	const canManageDelivery = useCanManageDeliveryTickets();
	const ideaSummary = useSelectedIssueSummary();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const { refreshIssueLinks } = useIdeaActions();
	const [inCreate, setInCreate] = useState(false);
	const [inLink, setInLink] = useState(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { closeSpotlight } = useSpotlights();
	const [, { loadProperty, saveProperty }] = useUserPropertiesActions();

	useEffect(() => {
		loadProperty(DELIVERY_TICKETS_HINT_CLOSED_KEY);
	}, [loadProperty]);

	const [{ value: isHintClosed, isLoading: isLoadingHint, error: hintClosedError }] =
		useUserProperty(DELIVERY_TICKETS_HINT_CLOSED_KEY);

	const handleCloseHint = useCallback(() => {
		fireCompoundAnalyticsEvent.IdeaView.DeliveryTab.deliveryTicketsHintClosed(
			createAnalyticsEvent({}),
		);
		saveProperty(DELIVERY_TICKETS_HINT_CLOSED_KEY, true);
	}, [saveProperty, createAnalyticsEvent]);

	const onCreate = useCallback(
		(isFromHint?: boolean) => {
			fireCompoundAnalyticsEvent.IdeaView.DeliveryTab.createDeliveryTicketButtonClicked(
				createAnalyticsEvent({}),
				isFromHint,
			);
			setInLink(false);
			setInCreate(true);
			saveProperty(DELIVERY_TICKETS_HINT_CLOSED_KEY, true);
		},
		[createAnalyticsEvent, saveProperty],
	);

	const onLink = useCallback(
		(isFromHint?: boolean) => {
			fireCompoundAnalyticsEvent.IdeaView.DeliveryTab.linkDeliveryTicketButtonClicked(
				createAnalyticsEvent({}),
				isFromHint,
			);
			setInCreate(false);
			setInLink(true);
			saveProperty(DELIVERY_TICKETS_HINT_CLOSED_KEY, true);
		},
		[createAnalyticsEvent, saveProperty],
	);

	const onCreateCanceled = useCallback(() => {
		setInCreate(false);
	}, []);

	const onCreated = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		refreshIssueLinks(() => {});
		closeSpotlight(SpotlightTypes.CREATE_DELIVERY_TICKET);
	}, [refreshIssueLinks, closeSpotlight]);

	const onLinkCanceled = useCallback(() => {
		setInLink(false);
	}, []);

	const onIssueLinked = useCallback(() => {
		closeSpotlight(SpotlightTypes.CREATE_DELIVERY_TICKET);
	}, [closeSpotlight]);

	if (isLoadingHint) {
		return null;
	}

	if (inCreate) {
		return (
			<CreateContainer>
				<SendToBacklog
					defaultSummary={ideaSummary}
					onCancel={onCreateCanceled}
					onCreated={onCreated}
					isCompact={isCompact}
				/>
			</CreateContainer>
		);
	}

	if (inLink) {
		return (
			<CreateContainer>
				<LinkIssue
					isPolarisIssueLink
					excludedProjectTypes={[PRODUCT_DISCOVERY_PROJECT]}
					onCancel={onLinkCanceled}
					onIssueLinked={onIssueLinked}
					isCompact={isCompact}
				/>
			</CreateContainer>
		);
	}

	if (isHintClosed !== true && hintClosedError === null && canManageDelivery && !isIdeaArchived) {
		return (
			<Box xcss={hintWrapperStyles}>
				<FireTrackAnalytics eventName="hint viewed" actionSubjectId="deliveryTickets" />
				<HintMessage
					title={formatMessage(messages.titleSpork)}
					description={formatMessage(messages.description)}
					onClose={handleCloseHint}
					learnMore={{
						label: formatMessage(messages.learnMoreLink),
						articleId: HELP_ARTICLE_ID,
						fallbackHref: HELP_FALLBACK_URL,
						onClick: (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
							fireCompoundAnalyticsEvent.IdeaView.DeliveryTab.learnMoreButtonClicked(
								analyticsEvent,
								true,
							);
						},
					}}
					primaryButton={{
						label: formatMessage(messages.createDeliveryTicket),
						onClick: () => onCreate(true),
					}}
					secondaryButton={{
						label: formatMessage(messages.linkToIssue),
						onClick: () => onLink(true),
					}}
				>
					<Box xcss={jiraSoftwareLogoWrapperStyles}>
						<JiraLogo appearance="brand" />
					</Box>
				</HintMessage>
			</Box>
		);
	}

	return (
		<Box xcss={wrapperStyles}>
			<Container>
				<ImageContainer src={JiraBoardIcon} alt="Delivery tickets" />
				<Content>
					{canManageDelivery && (
						<>
							<Box xcss={deliveryTitleStyles}>{formatMessage(messages.title)}</Box>
							<Description>
								{formatMessage(
									isIdeaArchived ? messages.descriptionArchived : messages.description,
								)}
							</Description>
							{isIdeaArchived ? (
								<Restore />
							) : (
								<ButtonGroup>
									<Button
										id="polaris-idea.ui.sections.deliver.create-epic-button"
										testId="polaris-ideas.ui.idea-view.sections.deliver.empty-state.create-epic-button"
										iconBefore={
											<AddIcon label={formatMessage(messages.createDeliveryTicket)} size="small" />
										}
										appearance="primary"
										onClick={() => onCreate(false)}
										isDisabled={isIdeaArchived}
									>
										{formatMessage(messages.createDeliveryTicket)}
									</Button>
									<Box xcss={marginLeftStyles} />
									<Button
										id="polaris-idea.ui.sections.deliver.link-issue-button"
										iconBefore={
											<LinkIcon label={formatMessage(messages.linkToIssue)} size="small" />
										}
										onClick={() => onLink(false)}
										isDisabled={isIdeaArchived}
									>
										{formatMessage(messages.linkToIssue)}
									</Button>
								</ButtonGroup>
							)}
						</>
					)}
					{!canManageDelivery && (
						<Description>
							{formatMessage(messages.descriptionReadOnly)} <LearnMoreLink />
						</Description>
					)}
				</Content>
			</Container>
		</Box>
	);
});

const wrapperStyles = xcss({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateContainer = styled.div({
	marginTop: token('space.150', '12px'),
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	marginTop: token('space.300', '24px'),
	flexWrap: 'wrap',
	gap: token('space.300', '24px'),
	padding: `${token('space.500', '40px')} ${token('space.250', '20px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	flex: 1,
	maxWidth: '460px',
	width: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, button': {
		fontWeight: 400,
	},
	textAlign: 'center',
	marginTop: token('space.negative.050', '-4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroup = styled.div({
	display: 'flex',
	justifyContent: 'center',
	marginTop: token('space.200', '16px'),
});

const marginLeftStyles = xcss({
	marginLeft: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageContainer = styled.img({
	height: '226px',
});

const hintWrapperStyles = xcss({
	marginTop: 'space.150',
});

const jiraSoftwareLogoWrapperStyles = xcss({
	marginTop: 'space.200',
});

const deliveryTitleStyles = xcss({
	fontWeight: 600,
	textAlign: 'center',
	fontSize: token('space.200', '16px'),
	marginBottom: 'space.100',
});
