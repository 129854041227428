import React, { useEffect, useState } from 'react';
import differenceBy from 'lodash/differenceBy';
import isEqual from 'lodash/isEqual';
import type { MultiValue } from '@atlaskit/react-select/src';
import Select, { createFilter, type StylesConfig } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { useOpenIdeaViewFieldSidebar } from '../../../../controllers/idea/utils/idea-view.tsx';
import { useIssueActions } from '../../../../controllers/issue/index.tsx';
import { getSearchParams } from '../../../common/issue-select/utils.tsx';
import type { ConnectionFieldOption, ConnectionFieldProps } from '../types.tsx';
import { useConnectionFieldOptions } from '../utils.tsx';
import { MAX_OPTIONS } from './constants.tsx';
import { ConnectionFieldSelectContext } from './context/index.tsx';
import { ConnectionFieldControl } from './control/index.tsx';
import { ConnectionFieldMenuList } from './menu-list/index.tsx';
import messages from './messages.tsx';
import { ConnectionFieldIssueOption } from './option/index.tsx';

type Props = ConnectionFieldProps & {
	onClose: () => void;
};

const customComponents = {
	MultiValueContainer: () => null,
	Input: () => null,
	ClearIndicator: () => null,
	Control: ConnectionFieldControl,
	MenuList: ConnectionFieldMenuList,
	Option: ConnectionFieldIssueOption,
};

export const ConnectionFieldEditView = ({
	onClose,
	localIssueId,
	fieldKey,
	menuPortalTarget,
}: Props) => {
	const { formatMessage } = useIntl();

	const openIdeaViewFieldSidebar = useOpenIdeaViewFieldSidebar(localIssueId);
	const { updateIssueConnections } = useIssueActions();
	const [allIssues, linkedIssues] = useConnectionFieldOptions(localIssueId, fieldKey);

	const [value, setValue] = useState<MultiValue<ConnectionFieldOption>>(linkedIssues);
	const [inputValue, setInputValue] = useState('');
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	// opens menu faster rather then passing menuIsOpen={true} to Select
	// investigate if it can be improved https://pi-dev-sandbox.atlassian.net/browse/POL-12366
	useEffect(() => {
		setMenuIsOpen(true);
	}, []);

	const filterOption = (option: ConnectionFieldOption) => {
		const { searchString } = getSearchParams(inputValue);
		const filter = createFilter<ConnectionFieldOption>({
			stringify: ({ data: { issueKey }, label }) => `${issueKey} ${label}`,
		});
		return filter({ value: option.value, label: option.label, data: option }, searchString);
	};

	const selectedOptions = value.filter(filterOption);
	const allOptions = allIssues.filter(filterOption).slice(0, MAX_OPTIONS);

	const localOptions = [
		{ options: selectedOptions },
		{
			options: differenceBy(allOptions, selectedOptions, 'value'),
		},
	];

	const onCloseRequested = () => {
		if (!isEqual(linkedIssues, value)) {
			const transform = ({ value: id, label: summary, ...option }: ConnectionFieldOption) => ({
				id,
				summary,
				...option,
			});

			updateIssueConnections({
				localIssueId,
				issuesToConnect: differenceBy(value, linkedIssues, 'value').map(transform),
				issuesToDisconnect: differenceBy(linkedIssues, value, 'value').map(transform),
			});
		}

		onClose();
	};

	const onKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Esc' || event.key === 'Escape') {
			onCloseRequested();
		}
	};

	const onEditFieldRequested = () => {
		openIdeaViewFieldSidebar(fieldKey);
		onCloseRequested();
	};

	return (
		<OutsideClickAlerter onClickOutside={onCloseRequested}>
			{(outsideClickAlerterProps) => (
				<div {...outsideClickAlerterProps}>
					<ConnectionFieldSelectContext.Provider value={{ fieldKey, onEditFieldRequested }}>
						<Select<ConnectionFieldOption, true>
							isMulti
							autoFocus
							isSearchable
							isClearable
							menuIsOpen={menuIsOpen}
							hideSelectedOptions={false}
							components={customComponents}
							onChange={setValue}
							value={value}
							onKeyDown={onKeyDown}
							onInputChange={setInputValue}
							inputValue={inputValue}
							options={localOptions}
							filterOption={() => true}
							noOptionsMessage={() => formatMessage(messages.noOptionsMessageNonFinal)}
							enableAnimation={false}
							menuPlacement="auto"
							maxMenuHeight={390}
							styles={stylesConfig}
							menuPortalTarget={menuPortalTarget}
						/>
					</ConnectionFieldSelectContext.Provider>
				</div>
			)}
		</OutsideClickAlerter>
	);
};

const stylesConfig: StylesConfig<ConnectionFieldOption, true> = {
	menu: (styles) => ({
		...styles,
		zIndex: 100,
		width: '400px',
	}),
	menuList: (styles) => ({
		...styles,
		padding: 0,
	}),
	groupHeading: (styles) => ({
		...styles,
		':empty': {
			display: 'none',
		},
	}),
	group: (styles) => ({
		...styles,
		padding: `${token('space.075')} 0 ${token('space.075')} 0`,
		':not(:first-of-type)': {
			borderTop: `1px solid ${token('color.border')}`,
		},
	}),
	valueContainer: (styles) => ({
		...styles,
		display: 'flex',
	}),
	noOptionsMessage: (styles) => ({
		...styles,
		padding: `${token('space.150')}`,
	}),
	control: (styles) => ({
		...styles,
		flexWrap: 'nowrap',
		'&&': {
			paddingLeft: token('space.050'),
			paddingRight: token('space.050'),
		},
	}),
};
