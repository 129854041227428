import React from 'react';
import {
	useIsEmbedded,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useLinkedDeliveryIssuesExist } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useIsExporting } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { DeliveryFieldInteractive, DeliveryFieldPreview } from './delivery/index.tsx';
import { EmptyDeliveryInteractive, EmptyDeliveryPreview } from './empty/index.tsx';

type InteractiveProps = {
	issueId: LocalIssueId;
	appearance: 'list' | 'board';
	type: typeof FIELD_TYPES.DELIVERY_PROGRESS | typeof FIELD_TYPES.DELIVERY_STATUS;
};

type Props = {
	interactive: boolean;
} & InteractiveProps;

export const LinkedIssuesField = ({ issueId, appearance, type, interactive }: Props) => {
	const embedded = useIsEmbedded();
	const isSharedView = useIsSharedView();
	const linkedIssuesExist = useLinkedDeliveryIssuesExist(issueId);
	const isExporting = useIsExporting();

	if (linkedIssuesExist) {
		// has value
		if (!interactive || embedded || isSharedView) {
			return <DeliveryFieldPreview type={type} issueId={issueId} hideTooltip={!isSharedView} />;
		}
		return <DeliveryFieldInteractive type={type} issueId={issueId} />;
	}

	if (embedded || isSharedView) {
		// no value and embedded - render nothing
		return null;
	}

	// render empty cell
	if (interactive) {
		return <EmptyDeliveryInteractive appearance={appearance} issueId={issueId} />;
	}

	if (isExporting) {
		return null;
	}

	return <EmptyDeliveryPreview />;
};
