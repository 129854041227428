import React, { type ReactNode, useCallback } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { ExperienceMetadata, JPDExperience } from '../common/utils/experience/types.tsx';

declare type ExperienceFailErrorBoundaryProps = {
	children: ReactNode;
	experience: JPDExperience | Array<JPDExperience>;
	metadata?: ExperienceMetadata['metadata'];
};

const ExperienceFailErrorBoundary = ({
	children,
	experience,
	metadata,
}: ExperienceFailErrorBoundaryProps) => {
	const onError = useCallback(
		(location: string, error: Error) => {
			if (isClientFetchError(error) && fg('jpd_skip_client_fetch_errors_slo_wrapper')) {
				return;
			}
			const failureReason: ExperienceMetadata = {
				metadata: {
					...metadata,
					error: error.message,
				},
			};

			if (Array.isArray(experience)) {
				experience.forEach((e) => e.failure(failureReason));
			} else {
				experience.failure(failureReason);
			}
		},
		[experience, metadata],
	);
	return (
		<JSErrorBoundary
			fallback="flag"
			id="async.jira.polaris.experience"
			packageName="atlassian/jira-polaris-lib-analytics"
			onError={onError}
		>
			{children}
		</JSErrorBoundary>
	);
};

export default ExperienceFailErrorBoundary;
