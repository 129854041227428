import React, {
	type ReactNode,
	useCallback,
	useMemo,
	useState,
	useRef,
	useEffect,
	Fragment,
	memo,
} from 'react';
import { styled } from '@compiled/react';
import { useApolloClient } from '@apollo/react-hooks';
import Spinner from '@atlaskit/spinner';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { Content, Main } from '@atlassian/jira-navigation-system/src/index.tsx';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import {
	ProjectNotFoundError,
	ProjectNotOnboardedError,
	GenericError,
} from '@atlassian/jira-polaris-common/src/common/utils/errors/main.tsx';
import {
	IdeaViewEndMark,
	IdeaViewStartMark,
	MARKS,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/idea-view.tsx';
import {
	useCloudId,
	useCurrentUser,
} from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import { CollabContainer } from '@atlassian/jira-polaris-common/src/controllers/collab/index.tsx';
import {
	useIsEmbedded,
	useIsSharedView,
	useIsCollectionView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useFieldCount,
	useGlobalFieldCount,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useFilteredIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/filters-hooks.tsx';
import { useSortedGroupOptions } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import {
	useRankedIssueCount,
	useCurrentUserManuallyCreatedIssueIds,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useSelectedIssueSummary,
	useSelectedIssueKey,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useProjectActions } from '@atlassian/jira-polaris-common/src/controllers/project/main.tsx';
import {
	useError,
	useIsProjectAvailable,
	useIsProjectOnboarded,
	useProjectName,
	useProjectTemplateVersion,
} from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import { RightSidebarContainer } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/main.tsx';
import {
	useCurrentPolarisRouteSection,
	useFullscreenQueryParameter,
	usePolarisRouter,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import {
	useCurrentViewFieldCount,
	useCurrentViewTitle,
	useCurrentViewAri,
	useCurrentViewVerticalGroupBy,
	useViewTypeCount,
	useViewAnalyticsData,
	useCurrentViewGroupBy,
	useCurrentViewSlug,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	useProjectAri,
	useProjectAriUnsafe,
	useProjectIdUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIsBeta } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import {
	useCanCreateAndEditIssues,
	useCanCreateInsights,
	useCanManageDeliveryTickets,
	useIsProjectAdmin,
	useIsLoadedPermissions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { ERROR_REASON_POSSIBLE_ACCESS_LOSS } from '@atlassian/jira-polaris-component-view-visitors/src/services/polaris-api/view-collab/index.tsx';
import { ProjectTemplateEnum } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import {
	VIEW_KIND_TABLE,
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useAppRefresher } from '@atlassian/jira-polaris-lib-app-refresher/src/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import OnboardingContent from '@atlassian/jira-polaris-lib-onboarding-content/src/async.tsx';
import { OnboardingFlowsContainer } from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/index.tsx';
import { SpotlightTypes } from '@atlassian/jira-polaris-lib-onboarding/src/common/types.tsx';
import { OnboardingContainer } from '@atlassian/jira-polaris-lib-onboarding/src/ui/main.tsx';
import { useShouldShowOnboardingExperience } from '@atlassian/jira-polaris-lib-onboarding/src/utils.tsx';
import { RealtimeQueueContextProvider } from '@atlassian/jira-polaris-lib-realtime-queue/src/utils/context/index.tsx';
import { SmartCardContainer } from '@atlassian/jira-polaris-lib-smart-card/src/controllers/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	useAnalyticsEvents,
	FireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { Redirect } from '@atlassian/react-resource-router';
import { PolarisDataContainer, PolarisRootDataContainer } from '../controllers/index.tsx';
import { IdeasRouteContainer } from '../controllers/router/index.tsx';
import { ToSConsentChecker } from './consent/index.tsx';
import { CreateIssueInterceptor } from './create-issue-interceptor/index.tsx';
import { Feedback } from './feedback/index.tsx';
import { LandingViewRedirect, useLandOnViewByRank } from './landing-view-redirect/index.tsx';
import MainContent from './main-content/main.tsx';
import { Sidebar } from './sidebar/index.tsx';
import { TemplateApplier } from './template-applier/index.tsx';

const ROADMAP_VIEW_RANK = 4;
const PRIORITIZATION_VIEW_RANK = 2;

export type PolarisIdeasProps = {
	projectAri: Ari;
};

type ContextualAnalyticsAttributes = {
	projectViewListCount: number;
	projectViewBoardCount: number;
	projectViewMatrixCount: number;
	projectFieldCount: number;
	projectIssueCount: number;
};

const useContextualAnalyticsAttributes = (): ContextualAnalyticsAttributes => {
	const projectViewListCount = useViewTypeCount(VIEW_KIND_TABLE);
	const projectViewBoardCount = useViewTypeCount(VIEW_KIND_BOARD);
	const projectViewMatrixCount = useViewTypeCount(VIEW_KIND_MATRIX);
	const fieldCount = useFieldCount();
	const globalFieldCount = useGlobalFieldCount();
	const issueCount = useRankedIssueCount();
	const selectedViewType = useCurrentViewKind();
	const selectedViewId = useCurrentViewSlug();
	const selectedViewFieldsCount = useCurrentViewFieldCount();
	const currentViewGroupBy = useCurrentViewGroupBy();
	const currentViewVerticalGroupBy = useCurrentViewVerticalGroupBy();
	const { options } = useSortedGroupOptions(currentViewGroupBy?.key);
	const { options: verticalOptions } = useSortedGroupOptions(currentViewVerticalGroupBy?.key);
	const projectTemplateVersion = useProjectTemplateVersion();
	const selectedViewGroupOptionsCount = options?.length || 0;
	const selectedViewVerticalGroupOptionsCount = verticalOptions?.length || 0;
	const selectedViewIssuesCount = useFilteredIssueIds()?.length || 0;

	return useMemo(
		() => ({
			projectViewListCount,
			projectViewBoardCount,
			projectViewMatrixCount,
			projectFieldCount: fieldCount,
			projectGlobalFieldCount: globalFieldCount,
			projectIssueCount: issueCount,
			projectTemplateVersion,
			selectedViewId,
			selectedViewType,
			selectedViewFieldsCount,
			selectedViewGroupOptionsCount,
			selectedViewVerticalGroupOptionsCount,
			selectedViewIssuesCount,
		}),
		[
			projectViewMatrixCount,
			projectViewListCount,
			selectedViewType,
			selectedViewFieldsCount,
			projectViewBoardCount,
			fieldCount,
			issueCount,
			projectTemplateVersion,
			selectedViewGroupOptionsCount,
			selectedViewVerticalGroupOptionsCount,
			selectedViewIssuesCount,
			globalFieldCount,
			selectedViewId,
		],
	);
};

const Title = () => {
	const [projectName] = useProjectName();
	const viewTitle = useCurrentViewTitle();
	const selectedIssueKey = useSelectedIssueKey();
	const selectedIssueSummary = useSelectedIssueSummary();
	const [localTitle, setLocalTitle] = useState<string | null>(null);

	useEffect(() => {
		if (projectName === undefined) {
			return;
		}

		const title: Array<string> = [];

		if (selectedIssueKey !== undefined) {
			title.push(`[${selectedIssueKey}] ${selectedIssueSummary ?? ''}`);
		} else if (viewTitle !== undefined) {
			title.push(`${viewTitle}`);
		}
		setLocalTitle([...title, projectName].join(' - '));
	}, [projectName, viewTitle, selectedIssueSummary, selectedIssueKey]);

	if (localTitle === null) {
		return null;
	}

	return <DocumentTitle title={localTitle} />;
};

export const SidebarV4Placeholder = () => null;

export const PolarisIdeas = memo(() => {
	const cloudId = useCloudId();
	const currentUser = useCurrentUser();
	const viewControlsRef = useRef<HTMLDivElement>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { closeSummaryEditor } = useIdeaActions();
	const viewAri = useCurrentViewAri();
	const embedded = useIsEmbedded();
	const isSharedView = useIsSharedView();
	const isCollectionView = useIsCollectionView();
	useAppRefresher();

	const { setQuery } = usePolarisRouter();
	const fullscreen = useFullscreenQueryParameter();
	const apolloClient = useApolloClient();

	const { isAdmin, isSiteAdmin } = useTenantContext();

	const toggleFullscreen = useCallback(() => {
		if (fullscreen) {
			setQuery({ fullscreen: undefined, viewCommentId: undefined });
		} else {
			setQuery({ fullscreen: 'true', viewCommentId: undefined });
		}
		closeSummaryEditor();
	}, [closeSummaryEditor, fullscreen, setQuery]);

	const { possibleAccessLossError } = useErrorHandlers();
	const onCollabContainerError = useCallback(
		(error: Error, cause: String) => {
			if (cause === ERROR_REASON_POSSIBLE_ACCESS_LOSS) {
				possibleAccessLossError(error);
			}
		},
		[possibleAccessLossError],
	);

	const section = useCurrentPolarisRouteSection();

	const [isAvailable] = useIsProjectAvailable();
	const [error] = useError();
	const projectIsAvailable = isAvailable && !error;
	const shouldShowSidebar =
		!getWillShowNav4() &&
		(projectIsAvailable || isSiteAdmin || isAdmin) &&
		!embedded &&
		!fullscreen &&
		!isSharedView &&
		!isCollectionView;

	const MainComponent = getWillShowNav4() ? Fragment : Main;

	return (
		<>
			<Title />
			<FireScreenAnalytics />
			<IdeaViewStartMark mark={MARKS.IDEAS_EMOJI} />
			<RightSidebarContainer>
				<IdeaViewEndMark mark={MARKS.IDEAS_EMOJI} />
				<Feedback />
				<Content testId="polaris-ideas.ui.content">
					{shouldShowSidebar ? <Sidebar /> : <SidebarV4Placeholder />}
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766 */}
					<style>{`
                        [data-ds--page-layout--slot="top-navigation"] {
                            ${fullscreen && 'display: none;'}
                        }

                        [data-ds--page-layout--slot="main"] {
                            height: calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP})
                        }
                    `}</style>
					<MainComponent testId="polaris-ideas.ui.main">
						{!isSharedView && !isCollectionView && !fg('jpd_visitor_handling_refactor') ? (
							<CollabContainer
								createAnalyticsEvent={createAnalyticsEvent}
								apolloClient={apolloClient}
								viewAri={viewAri}
								embedded={embedded}
								cloudId={cloudId}
								accountId={currentUser}
								onError={onCollabContainerError}
							>
								<MainContent
									fullscreen={fullscreen}
									embedded={embedded}
									section={section}
									viewControlsRef={viewControlsRef}
									onToggleFullscreen={toggleFullscreen}
								/>
							</CollabContainer>
						) : (
							<MainContent
								fullscreen={fullscreen}
								embedded={embedded}
								section={section}
								viewControlsRef={viewControlsRef}
								onToggleFullscreen={toggleFullscreen}
							/>
						)}
					</MainComponent>
				</Content>
				<CreateIssueInterceptor />
			</RightSidebarContainer>
		</>
	);
});

PolarisIdeas.displayName = 'PolarisIdeas';

const OnboardingContentWithAnalytics = () => {
	const viewAnalyticsData = useViewAnalyticsData();

	return (
		<ContextualAnalyticsData {...viewAnalyticsData}>
			<OnboardingContent />
		</ContextualAnalyticsData>
	);
};

const PolarisOnboardingHandler = memo(() => {
	const { atlassianAccountId } = useTenantContext();
	const hasUserCreatedIssues = useCurrentUserManuallyCreatedIssueIds().length > 0;
	const canCreateAndEditIssues = useCanCreateAndEditIssues();
	const canCreateInsights = useCanCreateInsights();
	const canManageDelivery = useCanManageDeliveryTickets();
	const projectAri = useProjectAriUnsafe();
	const viewId = useCurrentViewAri();
	const projectId = useProjectIdUnsafe();

	const shouldShowOnboardingExperience = useShouldShowOnboardingExperience(projectAri);
	const apolloClient = useApolloClient();
	const spotlights = useMemo(
		() => ({
			[SpotlightTypes.CREATE_IDEA]:
				canCreateAndEditIssues && shouldShowOnboardingExperience === true,
			[SpotlightTypes.CREATE_INSIGHT]: canCreateInsights && shouldShowOnboardingExperience === true,
			[SpotlightTypes.CREATE_DELIVERY_TICKET]:
				canManageDelivery && shouldShowOnboardingExperience === true,
			[SpotlightTypes.JOIN_COMMUNITY]: shouldShowOnboardingExperience !== null,
		}),
		[canCreateAndEditIssues, canCreateInsights, canManageDelivery, shouldShowOnboardingExperience],
	);

	return (
		<LandingViewRedirect>
			<OnboardingContainer
				accountId={atlassianAccountId}
				hasUserCreatedIssues={hasUserCreatedIssues}
				spotlights={spotlights}
				apolloClient={apolloClient}
				projectAri={projectAri}
			>
				<PolarisIdeas />
				{shouldShowOnboardingExperience === true && viewId !== undefined && (
					<FireTrackAnalytics
						eventName="feature exposed"
						actionSubjectId="jpdOnboardingExperiment"
						attributes={{
							viewId,
							projectId,
						}}
					/>
				)}
			</OnboardingContainer>
			<OnboardingContentWithAnalytics />
		</LandingViewRedirect>
	);
});

PolarisOnboardingHandler.displayName = 'PolarisOnboardingHandler';

type PolarisIdeasContextualAnalyticsInternalProps = {
	projectId: string;
	children: ReactNode;
	attributes: ContextualAnalyticsAttributes;
};

const PolarisIdeasContextualAnalyticsInternal = memo<PolarisIdeasContextualAnalyticsInternalProps>(
	({ projectId, attributes, children }: PolarisIdeasContextualAnalyticsInternalProps) => (
		<ContextualAnalyticsData
			containerType="project"
			containerId={projectId}
			sourceName="polarisIdeas"
			sourceType={SCREEN}
			attributes={attributes}
		>
			{children}
		</ContextualAnalyticsData>
	),
);

type PolarisIdeasContextualAnalyticsProps = {
	children: ReactNode;
};

const PolarisIdeasContextualAnalytics = ({ children }: PolarisIdeasContextualAnalyticsProps) => {
	const attributes = useContextualAnalyticsAttributes();
	const projectId = useProjectIdUnsafe();

	return (
		<PolarisIdeasContextualAnalyticsInternal attributes={attributes} projectId={projectId}>
			{children}
		</PolarisIdeasContextualAnalyticsInternal>
	);
};

const PolarisProjectTemplateHandler = ({ projectAri }: PolarisIdeasProps) => {
	const embedded = useIsEmbedded();
	const [isAvailable] = useIsProjectAvailable();
	const [isTemplateApplied] = useIsProjectOnboarded();
	const { isAdmin, isSiteAdmin, appPermissions } = useTenantContext();

	const [canAdministerProject] = useIsProjectAdmin();
	const [isPermissionsLoaded] = useIsLoadedPermissions();

	const [isInProgress, setIsInProgress] = useState(
		isTemplateApplied === undefined ? undefined : isTemplateApplied === false && !embedded,
	);

	useEffect(() => {
		if (isInProgress === undefined && isTemplateApplied !== undefined) {
			setIsInProgress(isTemplateApplied === false && !embedded);
		}
	}, [isInProgress, isTemplateApplied, embedded]);

	const { getProjectTemplate } = useProjectActions();
	const landOnViewByRank = useLandOnViewByRank();

	const handleComplete = useCallback(() => {
		const projectTemplate = getProjectTemplate?.();
		if (projectTemplate?.initialProjectTemplate === ProjectTemplateEnum.ROADMAP) {
			landOnViewByRank(ROADMAP_VIEW_RANK);
		}
		// GALILEO-1300 START
		else if (projectTemplate?.initialProjectTemplate === ProjectTemplateEnum.PRIORITIZATION) {
			landOnViewByRank(PRIORITIZATION_VIEW_RANK);
		}
		// GALILEO-1300 END
		setIsInProgress(false);
	}, [getProjectTemplate, landOnViewByRank]);

	/*  when
        * the template is not applied
        * and the current user IS NOT
            * a jira admin or a site admin
            * or can administer the project and has a valid license
        then we show that the project is not ready to access

        This would happen if the project was created and couldn't be onboarded or onboarding is still in progress and a different user is meanwhile accessing the project.
        A Jira- or Site-admin needs to be able to access the project settings, although the project is not readily onboarded
     */
	if (
		isTemplateApplied === false &&
		!(
			isAdmin ||
			isSiteAdmin ||
			(isPermissionsLoaded && canAdministerProject && appPermissions.hasProductDiscoveryAccess)
		)
	) {
		return <ProjectNotOnboardedError />;
	}

	// let's apply the template if it is not applied yet, the current user can administer the project and has proper product access
	if (
		isAvailable &&
		isInProgress === true &&
		isPermissionsLoaded &&
		canAdministerProject &&
		appPermissions.hasProductDiscoveryAccess
	) {
		return <TemplateApplier onComplete={handleComplete} />;
	}

	return (
		<>
			<IdeasRouteContainer>
				<PolarisDataContainer containerAri={projectAri}>
					{!embedded && <ToSConsentChecker />}
					<PolarisIdeasContextualAnalytics>
						{embedded ? <PolarisIdeas /> : <PolarisOnboardingHandler />}
					</PolarisIdeasContextualAnalytics>
				</PolarisDataContainer>
			</IdeasRouteContainer>
		</>
	);
};

export const LoadingScreen = ({ testId }: { testId?: string }) => (
	<LoadingContainer>
		<Spinner testId={testId} />
	</LoadingContainer>
);

const PolarisProjectLoadingGatekeeper = ({ projectAri }: PolarisIdeasProps) => {
	const embedded = useIsEmbedded();
	const [isOnboarded] = useIsProjectOnboarded();
	const beta = useIsBeta();

	if (beta) {
		return <Redirect to="/jira/polaris/unlicensed?beta=true" />;
	}

	// show a project not found error if the project is not yet onboarded AND we are in embedded view
	if (isOnboarded === false && embedded) {
		experience.project.pageLoad.abort('ProjectNotFoundError');
		return <ProjectNotFoundError />;
	}

	return <PolarisProjectTemplateHandler projectAri={projectAri} />;
};

export const PolarisIdeasApp = () => {
	const projectAri = useProjectAri();
	const { atlassianAccountId } = useTenantContext();

	if (!projectAri) {
		experience.project.pageLoad.failure('GenericError');
		return <GenericError error={new Error('projectAri is null on PolarisIdeasApp')} />;
	}

	return (
		<SmartCardContainer>
			<RealtimeQueueContextProvider>
				<OnboardingFlowsContainer accountId={atlassianAccountId}>
					<PolarisRootDataContainer containerAri={projectAri}>
						<PolarisProjectLoadingGatekeeper projectAri={projectAri} />
					</PolarisRootDataContainer>
				</OnboardingFlowsContainer>
			</RealtimeQueueContextProvider>
		</SmartCardContainer>
	);
};

export default PolarisIdeasApp;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingContainer = styled.div({
	display: 'flex',
	width: '100vw',
	height: '100vh',
	alignItems: 'center',
	justifyContent: 'center',
});
