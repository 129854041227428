import React, { type ReactNode, type PropsWithChildren, useEffect } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Editor as EditorType } from './ui/editor/index.tsx';

type EditorBoundaryProps = PropsWithChildren<{
	fallback?: ReactNode;
}>;

export const EditorBoundary = ({ children, fallback }: EditorBoundaryProps) => (
	<ErrorBoundary id="polaris-editor" packageName="polaris-editor" render={() => <>{fallback}</>}>
		<Placeholder name="polaris-editor-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const Editor = lazyForPaint<typeof EditorType>(
	() =>
		import(/* webpackChunkName: "async-polaris-lib-editor" */ './ui/editor').then(
			({ Editor: EditorComponent }) => EditorComponent,
		),
	{ ssr: false },
);

export const usePreloadEditor = () => {
	useEffect(() => Editor.preload(), []);
};
