import React, { useState, useEffect, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import Popup from '@atlaskit/popup';
import { N20A, N400, N500, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { MenuOptionProps } from './types.tsx';

export const MenuOption = ({
	children,
	menuComponent = null,
	isActive = false,
	isDragEnabled,
	hideActionMenu,
	outerSpacing,
	setOpenOption,
	setIsMenuOpen,
}: MenuOptionProps) => {
	const { formatMessage } = useIntl();
	const [open, setOpen] = useState(false);

	const onToggleOpen = () => {
		hideActionMenu !== true && setOpen(!open);
	};

	useEffect(() => {
		setIsMenuOpen && setIsMenuOpen(open);
	}, [open, setIsMenuOpen]);

	return (
		<Popup
			zIndex={1000}
			autoFocus={false}
			placement="bottom-end"
			isOpen={open}
			onClose={() => setOpen(false)}
			content={() => (
				<div
					onClick={() => setOpen(false)}
					onKeyPress={() => setOpen(false)}
					role="menu"
					tabIndex={0}
				>
					{menuComponent}
				</div>
			)}
			trigger={(triggerProps) => (
				<div {...triggerProps}>
					<OptionItemWrapper
						data-component-selector="option-item-wrapper-kC88"
						data-testid={`polaris-lib-decoration.ui.menu-option.option-item-wrapper-${isActive ? 'active' : 'inactive'}`}
						$isActive={isActive}
						$isDragEnabled={isDragEnabled}
					>
						<OptionItem
							onClick={({ target, currentTarget }: SyntheticEvent<HTMLElement>) => {
								let isInteractiveEl = false;
								let node: unknown = target;

								while (!isInteractiveEl && node instanceof HTMLElement && node !== currentTarget) {
									isInteractiveEl = !!node.getAttribute('data-interactive');
									node = node.parentNode;
								}

								// Do not open menu if element is interactive, e.g. input
								if (isInteractiveEl) {
									return setOpen(false);
								}
								return isActive ? setOpenOption(null) : onToggleOpen();
							}}
							onKeyDown={() => undefined}
							$isSelected={open && hideActionMenu !== true}
							role="menuitem"
							tabIndex={0}
							$isDragEnabled={isDragEnabled}
							outerSpacing={outerSpacing}
						>
							{isDragEnabled && (
								<DragHandle $isDragEnabled={isDragEnabled} outerSpacing={outerSpacing}>
									⠿
								</DragHandle>
							)}
							<ItemWrapper>{children}</ItemWrapper>
							<DoneButton
								testId="polaris-lib-decoration.ui.menu-option.done-button"
								isActive={isActive}
								spacing="compact"
								appearance="primary"
							>
								{formatMessage(messages.saveOption)}
							</DoneButton>
							{hideActionMenu !== true && (
								<MoreButton
									testId="polaris-lib-decoration.ui.menu-option.more-button"
									data-component-selector="more-button-9Kk3"
									isActive={isActive}
									isSelected={open}
									spacing="compact"
									onClick={onToggleOpen}
									appearance="subtle"
									iconAfter={<MoreVerticalIcon size="small" label="btn.more" />}
								/>
							)}
						</OptionItem>
					</OptionItemWrapper>
				</div>
			)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DoneButton = styled(Button)<{ isActive: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isActive }) => (isActive ? 'initial' : 'none!important'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreButton = styled(Button)<{ isActive: boolean; isSelected: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isActive }) => (isActive ? 'none!important' : 'inherit'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isSelected }) => (isSelected ? 'visible' : 'hidden'),
	background: N20A,
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionItemWrapper = styled.div<{ $isActive: boolean; $isDragEnabled: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingTop: ({ $isActive }) => $isActive && token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ $isActive }) =>
		$isActive ? token('color.background.disabled', N20A) : 'initial',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ $isDragEnabled }) =>
			$isDragEnabled && token('color.background.disabled', N20A),
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ $isDragEnabled }) =>
			$isDragEnabled && token('color.background.disabled', N20A),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover [data-component-selector="more-button-9Kk3"]': {
		visibility: 'visible',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:active [data-component-selector="more-button-9Kk3"]': {
		visibility: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionItem = styled.div<{
	$isSelected?: boolean;
	$isDragEnabled: boolean;
	outerSpacing: string;
}>({
	position: 'relative',
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ $isSelected }) =>
		$isSelected ? token('color.background.disabled', N20A) : 'transparent',
	color: token('color.text.subtle', N500),
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: (props) => (props.$isDragEnabled ? '0' : props.outerSpacing),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: (props) => props.outerSpacing,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		color: token('color.text', N800),
		cursor: 'pointer',
		textDecoration: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus': {
		outline: 'none',
		boxShadow: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active': {
		borderRadius: '3px',
		color: token('color.text.subtle', N500),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: (props) =>
			props.$isDragEnabled ? token('color.background.disabled', N20A) : 'transparent',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"& span[type='drag-handle']": {
			visibility: 'visible',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragHandle = styled.span<{ $isDragEnabled: boolean; outerSpacing: string }>({
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ $isDragEnabled }) => (!$isDragEnabled ? 'hidden!important' : 'hidden'),
	justifyContent: 'center',
	cursor: 'grab',
	display: 'flex',
	height: 'unset',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => props.outerSpacing,
	alignItems: 'baseline',
	marginRight: 0,
	overflow: 'hidden',
	color: N400,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '13px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="option-item-wrapper-kC88"]:hover &': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		visibility: ({ $isDragEnabled }) => ($isDragEnabled ? 'visible' : 'hidden'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemWrapper = styled.div({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	minWidth: 0,
	overflow: 'hidden',
});
