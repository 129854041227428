import { useMemo } from 'react';
import type { GroupBase } from '@atlaskit/react-select';
import {
	useAllIssueIdsMatchingConnectionFieldFilters,
	useConnectionFieldIssueIds,
} from '../../../controllers/issue/selectors/connection-hooks.tsx';
import { useLocalIssueIdToJiraIssueId } from '../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useIssueTypes,
	useKeys,
	useSummaries,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { useExternalIssueDataByJiraId } from '../../../controllers/issue/selectors/properties/linked-issues/hooks.tsx';
import type { ConnectionFieldOption } from './types.tsx';

export const useConnectionFieldOptions = (localIssueId: string, fieldKey: string) => {
	const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
	const externalIssueData = useExternalIssueDataByJiraId();
	const linkedIssueIds = useConnectionFieldIssueIds(localIssueId, fieldKey);
	const allIssues = useAllIssueIdsMatchingConnectionFieldFilters(localIssueId, fieldKey);
	const issueTypes = useIssueTypes();
	const summaries = useSummaries();
	const keys = useKeys();

	const allOptions: ConnectionFieldOption[] = useMemo(
		() =>
			allIssues.map((id) => ({
				value: localIssueIdToJiraId[id],
				label: summaries[id],
				issueKey: keys[id],
				issueType: issueTypes[id],
			})),
		[allIssues, issueTypes, keys, localIssueIdToJiraId, summaries],
	);

	const selectedOptions: ConnectionFieldOption[] = linkedIssueIds.map(({ id }) => {
		const jiraId = parseInt(id, 10);
		return {
			value: id,
			label: externalIssueData[jiraId]?.summary,
			issueKey: externalIssueData[jiraId]?.issueKey,
			issueType: externalIssueData[jiraId]?.issueType,
		};
	});

	return [allOptions, selectedOptions] as const;
};

export const isGroup = (
	optionOrGroup: ConnectionFieldOption | GroupBase<ConnectionFieldOption>,
): optionOrGroup is GroupBase<ConnectionFieldOption> => 'options' in optionOrGroup;
