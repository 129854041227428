import React, { type ReactNode, useEffect, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import type { SingleValueProps, OptionType } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueOption } from '../types.tsx';
import { messages } from './messages.tsx';

export type IssueOptionPropsNext = {
	data: IssueOption;
	innerProps: OptionType['innerProps'] | null;
	renderIconAfter?: (option: IssueOption) => ReactNode;
};

export const Option = (props: IssueOptionPropsNext) => {
	const {
		data,
		renderIconAfter,
		innerProps: { ref, ...innerProps },
	} = props;
	const { item } = data;
	const summaryRef = useRef<HTMLDivElement>(null);

	const { formatMessage } = useIntl();
	const [isSummaryOverflown, setIsSummaryOverflown] = useState(false);
	const summary = data?.item?.summaryText ?? data.label;
	const iconAfter = renderIconAfter?.(data);

	useEffect(() => {
		if (summaryRef.current) {
			const isOverflown = summaryRef.current?.offsetWidth < summaryRef.current?.scrollWidth;
			setIsSummaryOverflown(isOverflown);
		}
	}, [summary]);

	if (data.isNew === true) {
		return (
			<Container
				{...innerProps}
				hasHoverEffects
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				ref={ref as React.Ref<any>}
				data-component-selector="container-7fF9"
			>
				<AddIconContainer>
					<AddIcon label="" />
				</AddIconContainer>
				<Summary ref={summaryRef} data-component-selector="summary-kE63">
					{formatMessage(messages.issuePickerCreatePlaceholder, {
						label: data.label,
					})}
				</Summary>
			</Container>
		);
	}

	if (!item) {
		return (
			<Container
				data-testid="polaris-common.ui.common.issue-select.option.container"
				{...innerProps}
				hasHoverEffects
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				ref={ref as React.Ref<any>}
				data-component-selector="container-7fF9"
			>
				<SummaryWrapper>
					<Summary>{props.data.label}</Summary>
				</SummaryWrapper>
			</Container>
		);
	}

	return (
		<Container
			data-testid="polaris-common.ui.common.issue-select.option.container"
			{...innerProps}
			hasHoverEffects
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			ref={ref as React.Ref<any>}
			data-component-selector="container-7fF9"
		>
			<Icon src={item.img} alt="type" />
			<Key limitWidth title={item.key}>
				{item.key}
			</Key>
			<Summary truncate={!iconAfter} ref={summaryRef} data-component-selector="summary-kE63">
				{item.summaryText}
			</Summary>
			{!!iconAfter && (
				<IconAfterWrapper isSummaryOverflown={isSummaryOverflown}>{iconAfter}</IconAfterWrapper>
			)}
		</Container>
	);
};

export const SelectedOption = (props: SingleValueProps<IssueOption>) => {
	const issue = props.data.item;

	if (!issue) {
		return (
			<Container hasHoverEffects data-component-selector="container-7fF9">
				<SummaryWrapper>
					<Summary data-component-selector="summary-kE63">{props.data.label}</Summary>
				</SummaryWrapper>
			</Container>
		);
	}

	return (
		<Container hasHoverEffects={false} data-component-selector="container-7fF9">
			<Icon src={issue.img} alt="type" />
			<Key limitWidth>{issue.key}</Key>
			<Summary data-component-selector="summary-kE63">{issue.summaryText}</Summary>
		</Container>
	);
};

type BaseOptionPropsNext = {
	issueKey: string;
	hasHoverEffects: boolean;
	isOptionSelected?: boolean | undefined;
	img: string;
	summary: string;
	innerProps: OptionType['innerProps'] | null;
};

type PropsOfContainer = {
	hasHoverEffects: boolean;
	isOptionSelected?: boolean | undefined;
	innerProps?: OptionType['innerProps'] | null;
};

export const BaseOption = (props: BaseOptionPropsNext) => {
	const { innerProps, hasHoverEffects, isOptionSelected } = props;
	const propsOfContainer: PropsOfContainer & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		ref?: React.Ref<any>;
	} = {
		hasHoverEffects,
		isOptionSelected,
	};
	if (innerProps) {
		const { ref, ...restOfInnerProps } = innerProps;
		propsOfContainer.innerProps = { ...restOfInnerProps };
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		propsOfContainer.ref = ref as React.Ref<any>;
	}

	return (
		<Container {...propsOfContainer}>
			<Icon src={props.img} alt="type" />
			<Key limitWidth title={props.issueKey}>
				{props.issueKey}
			</Key>
			<Summary title={props.summary}>{props.summary}</Summary>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.img({
	height: '16px',
	width: '16px',
	marginRight: token('space.100', '8px'),
	marginLeft: token('space.100', '8px'),
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Key = styled.div<{ limitWidth?: boolean }>({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
	marginRight: token('space.050', '4px'),
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	flex: '0 0 auto',
	fontWeight: 600,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ limitWidth }) => (limitWidth ? '80px' : 'auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryWrapper = styled.div({
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.div<{ truncate?: boolean }>({
	flex: 1,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	maxWidth: '100%',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textAlign: 'left',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textOverflow: ({ truncate }) => truncate !== false && 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ hasHoverEffects: boolean; isOptionSelected?: boolean }>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isOptionSelected }) => (isOptionSelected ? '0' : token('space.050', '4px')),
	boxSizing: 'border-box',
	flex: 1,
	minWidth: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ hasHoverEffects }) => hasHoverEffects && 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transition: ({ hasHoverEffects }) => hasHoverEffects && '0.3s',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ hasHoverEffects }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			hasHoverEffects && token('color.background.neutral.subtle.hovered', colors.N20),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconAfterWrapper = styled.div<{ isSummaryOverflown: boolean }>({
	borderRadius: 8,
	marginRight: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	boxShadow: ({ isSummaryOverflown }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isSummaryOverflown && `-6px 1px 9px 2px ${token('elevation.surface.overlay', colors.N0)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddIconContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '20px',
	height: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: colors.N20A,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N500,
	marginRight: token('space.150', '12px'),
	marginLeft: token('space.100', '8px'),
});
