import React, { type PropsWithChildren, memo, useEffect, useMemo } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	useCanPublishPublicDiscoveryViews,
	useCanPublishDiscoveryViews,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { publishFeatureDisabled } from '../../common/utils/publish/index.tsx';
import { Container, useSharingActions } from './main.tsx';
import { useIsSharingOnSiteEnabled } from './selectors/hooks.tsx';

type SharingContainerInnerProps = PropsWithChildren<{
	viewUUID: string | undefined;
	edition?: ApplicationEdition;
	isSharedView: boolean;
}>;

const SharingContainerInner = memo<SharingContainerInnerProps>(
	({ children, edition, viewUUID }) => {
		const { loadSharingSettings } = useSharingActions();
		const isSharingSiteConfigEnabled = useIsSharingOnSiteEnabled();

		useEffect(() => {
			if (publishFeatureDisabled(edition)) {
				return;
			}

			if (!ff('polaris.sharing-enabled') || !isSharingSiteConfigEnabled) {
				return;
			}

			if (viewUUID) {
				loadSharingSettings(viewUUID);
			}
		}, [viewUUID, loadSharingSettings, isSharingSiteConfigEnabled, edition]);

		return <>{children}</>;
	},
);

export const SharingContainer: typeof Container = ({ children, ...props }) => {
	const canPublishDiscoveryViews = useCanPublishDiscoveryViews();
	const canPublishPublicDiscoveryViews = useCanPublishPublicDiscoveryViews();
	const permissions = useMemo(
		() => ({
			canPublishDiscoveryViews,
			canPublishPublicDiscoveryViews,
		}),
		[canPublishPublicDiscoveryViews, canPublishDiscoveryViews],
	);

	return (
		<Container {...props} permissions={permissions}>
			<SharingContainerInner
				edition={props.edition}
				viewUUID={props.viewUUID}
				isSharedView={!!props.isSharedView}
			>
				{children}
			</SharingContainerInner>
		</Container>
	);
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useSharingActions };
