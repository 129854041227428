import { createSelector } from 'reselect';
import type { State } from '../types.tsx';

export const getCrossProjectViewFlow = (state: State) => state.crossProjectView;

export const getCrossProjectViewTourDismissed = createSelector(
	getCrossProjectViewFlow,
	(flow) => flow.tourDismissed,
);

export const getTourSpotlightStep = createSelector(
	getCrossProjectViewFlow,
	(flow) => flow.tourSpotlightStep,
);

export const getCrossProjectListViewFieldsDismissed = createSelector(
	getCrossProjectViewFlow,
	(flow) => flow.listViewfieldsDismissed,
);
