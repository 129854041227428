import React, { useCallback, useState, useEffect, useMemo, type SyntheticEvent } from 'react';
import Button from '@atlaskit/button';
import CheckCircleIcon from '@atlaskit/icon/core/migration/check-circle';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type CopyLinkProps = {
	size: 'small' | 'medium' | 'large' | 'xlarge';
	appearance?: 'default' | 'danger' | 'link' | 'primary' | 'subtle' | 'subtle-link' | 'warning';
	url?: string | null;
	label?: string;
	position?: 'bottom' | 'left' | 'right' | 'top' | 'mouse';
	onCopy?: () => void;
};

export const CopyLink = ({
	size,
	appearance = 'default',
	url,
	onCopy,
	label,
	position = 'top',
}: CopyLinkProps) => {
	const { formatMessage } = useIntl();
	const [isCopied, setCopied] = useState(false);

	const copyLinkMessage = useMemo(
		() => label || formatMessage(messages.copyLink),
		[formatMessage, label],
	);

	const handleCopy = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();
			e.stopPropagation();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const shareUrl = url === null || url === undefined ? new URL(window.location.href) : url;
			// Copy URL into clipboard

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			navigator.clipboard
				.writeText(shareUrl.toString())
				.then(() => {
					setCopied(true);
					onCopy && onCopy();
				})
				.catch(() => setCopied(false));
		},
		[onCopy, url],
	);

	useEffect(() => {
		const copyTimer = setTimeout(() => setCopied(false), 2500);
		return () => {
			clearTimeout(copyTimer);
		};
	}, [isCopied]);

	return (
		<Tooltip
			position={position}
			content={isCopied ? formatMessage(messages.copiedMessage) : copyLinkMessage}
		>
			<Button
				onClick={handleCopy}
				appearance={isCopied ? 'subtle-link' : appearance}
				spacing="none"
				iconBefore={
					isCopied ? (
						<CheckCircleIcon
							color={token('color.icon.accent.green')}
							LEGACY_size={size}
							label={copyLinkMessage}
						/>
					) : (
						<LinkIcon LEGACY_size={size} label={copyLinkMessage} color={token('color.icon')} />
					)
				}
			/>
		</Tooltip>
	);
};
