import React from 'react';
import { Stack, Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { CollectionOwner } from '../common/ui/owner/index.tsx';
import { ProjectsList } from '../common/ui/projects-list/index.tsx';
import messages from './messages.tsx';

type Props = {
	collectionUUID?: string;
	origin: 'view' | 'collection';
};

export const CollectionDetails = ({ collectionUUID, origin }: Props) => {
	const { formatMessage } = useIntl();

	if (!collectionUUID) {
		return null;
	}

	return (
		<Stack space="space.300">
			<Stack space="space.150">
				<Text color="color.text.subtle" weight="semibold">
					{formatMessage(messages.ownerHeading)}
				</Text>
				<CollectionOwner collectionUUID={collectionUUID} />
			</Stack>
			<Stack space="space.150">
				<Text color="color.text.subtle" weight="semibold">
					{origin === 'view' && formatMessage(messages.projectsHeadingView)}
					{origin === 'collection' && formatMessage(messages.projectsHeadingCollection)}
				</Text>
				<ProjectsList collectionUUID={collectionUUID} />
			</Stack>
		</Stack>
	);
};
