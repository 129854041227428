import React from 'react';
import { HelperMessage } from '@atlaskit/form';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import { Flex, Text, Box, xcss } from '@atlaskit/primitives';
import Select, { CreatableSelect, components, type ControlProps } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { N300A } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import type {
	OptionComponentProps,
	SelectProps,
	OptionType,
} from '../../../../common/types/select/index.tsx';
import { ESCAPE } from '../../../../common/utils/key-code/index.tsx';
import { FieldSelectOptionComponent } from './field-select-option/index.tsx';
import messages from './messages.tsx';

const OptionComponent = ({ data: { key }, ...rest }: OptionComponentProps) => (
	// @ts-expect-error - TS2740 - Type '{ children: Element; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 8 more.
	<components.Option {...rest}>
		{key ? <FieldSelectOptionComponent fieldKey={key} /> : rest.children}
	</components.Option>
);

const CreateOptionLabel = ({ inputValue }: { inputValue: string }) => {
	const { formatMessage } = useIntl();
	return (
		<Flex
			testId="polaris-common.ui.common.decoration.searchable-dropdown.create-option"
			gap="space.050"
			alignItems="center"
			xcss={createOptionContainerStyles}
		>
			<AddIcon label="create-field" />
			{formatMessage(messages.createOption, { value: <Text weight="bold">{inputValue}</Text> })}
		</Flex>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NoOptionsMessage = (props: any) => {
	const { formatMessage } = useIntl();
	return (
		<components.NoOptionsMessage {...props}>
			{formatMessage(messages.noMatchesIndicator)}
		</components.NoOptionsMessage>
	);
};

const CreatableControlComponent = (props: ControlProps<OptionType>) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<components.Control {...props} />
			<Box xcss={helperMessageContainerStyles}>
				<HelperMessage>{formatMessage(messages.creatableSelectHelperText)}</HelperMessage>
			</Box>
		</>
	);
};

const DropdownIndicator = () => (
	<EditorSearchIcon primaryColor={token('color.icon', colors.N100)} label="" />
);

export const SearchableDropdown = ({
	inputId,
	autoFocus = true,
	options,
	onSelect,
	onClose,
	onSearch,
	isSearchable = true,
	isCreatable,
}: SelectProps) => {
	const { formatMessage } = useIntl();
	const SelectComponent = isCreatable ? CreatableSelect : Select;

	return (
		<SelectComponent
			inputId={inputId}
			autoFocus={autoFocus && isSearchable}
			backspaceRemovesValue={false}
			formatCreateLabel={(inputValue) => <CreateOptionLabel inputValue={inputValue} />}
			isValidNewOption={(inputValue, _, allOptions) =>
				!!inputValue &&
				!allOptions.some((o) => o.label.trim().toLowerCase() === inputValue.trim().toLowerCase())
			}
			components={{
				DropdownIndicator,
				NoOptionsMessage,
				IndicatorSeparator: null,
				Option: OptionComponent,
				Control: isCreatable ? CreatableControlComponent : components.Control,
			}}
			controlShouldRenderValue={false}
			hideSelectedOptions={false}
			isClearable={false}
			escapeClearsValue
			menuIsOpen
			onKeyDown={(e) => {
				if (e.keyCode === ESCAPE) {
					onClose && onClose();
				}
			}}
			onChange={({ key }, action) => onSelect(key, action)}
			options={options}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			isOptionDisabled={(option: any) => !!option.disabled}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			isOptionSelected={(option: any) => option.selected}
			placeholder={formatMessage(messages.searchHint)}
			styles={{
				control: (styles) => ({
					...styles,
					width: 224,
					margin: token('space.100', '8px'),
					lineHeight: '20px',
					display: isSearchable ? styles.display : 'none',
					':hover': {
						cursor: 'text',
					},
				}),
				menu: () => ({
					width: 240,

					borderTop: isSearchable ? `1px solid ${token('color.border', N300A)}` : undefined,
				}),
				menuList: (styles) => ({
					...styles,
					height: isCreatable ? undefined : 'calc(50vh - var(--topNavigationHeight) - 80px)',
				}),
			}}
			tabSelectsValue={false}
			onInputChange={(inputValue) => {
				onSearch?.(inputValue);
			}}
			isSearchable={isSearchable}
		/>
	);
};
const createOptionContainerStyles = xcss({
	marginTop: 'space.negative.050',
	marginLeft: 'space.negative.050',
});

const helperMessageContainerStyles = xcss({
	marginBottom: 'space.100',
	marginLeft: 'space.100',
});
