import React, { useCallback, useState } from 'react';
import { useIsEmbedded } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useIsEditingSummary } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useIsEditable } from '../../utils/index.tsx';
import { EditShortTextField } from './edit/main.tsx';
import { ReadOnlySummaryField } from './read-only/index.tsx';
import { SummaryViewField } from './view/index.tsx';

type EditableLinkFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	interactive: boolean;
};

export const EditableLinkField = ({
	localIssueId,
	fieldKey,
	interactive,
}: EditableLinkFieldProps) => {
	const isEditing = useIsEditingSummary(localIssueId);
	// Storing the editing state locally additionally to global state to avoid having edit view open
	// the same idea in multiple groups in list view
	const [isLocalEditing, setIsLocalEditing] = useState(false);
	const { setSummaryEditing } = useIdeaActions();
	const isEditable = useIsEditable(fieldKey);
	const embedded = useIsEmbedded();

	const onCancelEditing = useCallback(() => {
		setIsLocalEditing(false);
		setSummaryEditing(localIssueId, false);
	}, [localIssueId, setSummaryEditing]);

	const onStartEditing = useCallback(() => {
		setIsLocalEditing(true);
		setSummaryEditing(localIssueId, true);
	}, [localIssueId, setSummaryEditing]);

	if (isEditable && isEditing && isLocalEditing) {
		return (
			<EditShortTextField
				fieldKey={fieldKey}
				localIssueId={localIssueId}
				onCancel={onCancelEditing}
			/>
		);
	}

	if (!interactive || embedded) {
		return <ReadOnlySummaryField fieldKey={fieldKey} localIssueId={localIssueId} />;
	}

	return (
		<SummaryViewField fieldKey={fieldKey} localIssueId={localIssueId} onEdit={onStartEditing} />
	);
};
