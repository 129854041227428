import { styled } from '@compiled/react';
import { B400, N0, N20, N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MatrixViewPlaceholder = styled.div<{ isActive: boolean }>({
	display: 'flex',
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: '0',
	boxSizing: 'border-box',
	top: '0',
	left: '0',
	paddingBottom: token('space.300', '24px'),
	borderRadius: '6px',
	backgroundColor: token('color.background.input.hovered', N20),

	'&::before': {
		content: '',
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: token('elevation.surface', N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: ({ isActive }) => (isActive ? '0' : '0.6'),
		transition: 'opacity 0.1s ease-in-out',
		zIndex: '2',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AxisXPlaceholder = styled.div<{ isActive: boolean }>({
	position: 'absolute',
	left: token('space.1000', '80px'),
	bottom: token('space.600', '48px'),
	width: '90%',
	height: '4px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isActive }) =>
		isActive
			? token('color.background.accent.blue.subtler.pressed', B400)
			: token('color.border', N40),
	borderRadius: '6px',

	'&::before': {
		content: 'X-AXIS',
		position: 'absolute',
		left: 0,
		width: '100%',
		height: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ isActive }) =>
			isActive
				? token('color.background.accent.blue.subtler.pressed', B400)
				: token('color.text.disabled', N0),
		font: token('font.body.UNSAFE_small'),
		fontWeight: token('font.weight.semibold'),
		bottom: token('space.250'),
		paddingBottom: token('space.025'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AxisYPlaceholder = styled.div<{ isActive: boolean }>({
	position: 'absolute',
	left: token('space.600', '48px'),
	bottom: token('space.1000', '80px'),
	width: '4px',
	height: '85%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isActive }) =>
		isActive
			? token('color.background.accent.blue.subtler.pressed', B400)
			: token('color.border', N40),
	borderRadius: '6px',

	'&::before': {
		content: 'Y-AXIS',
		position: 'absolute',
		bottom: 0,
		rotate: '-90deg',
		width: '100px',
		height: '100px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ isActive }) =>
			isActive
				? token('color.background.accent.blue.subtler.pressed', B400)
				: token('color.text.disabled', N0),
		font: token('font.body.UNSAFE_small'),
		fontWeight: token('font.weight.semibold'),
		left: token('space.100'),
	},
});
