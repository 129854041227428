import React, { useCallback, useMemo, useRef, useState, type ChangeEvent } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	intervalAbsoluteDatesFilter,
	intervalEmptyFilter,
	intervalNotEmptyFilter,
	intervalRollingDatesFilters,
} from '@atlassian/jira-polaris-domain-view/src/filter/index.tsx';
import {
	INTERVAL_FILTER_TIME_CURRENT,
	type DistributeArray,
	type IntervalFieldFilterLegacyValue,
	type IntervalFieldFilterValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	isArrayIntervalFieldFilterLegacyValue,
	isIntervalFieldFilterAbsoluteDatesValue,
	isIntervalFieldFilterLegacyValue,
	isIntervalFieldFilterRollingDatesCurrentValue,
	isIntervalFieldFilterRollingDatesPastNextValue,
	isIntervalFieldFilterEmptyValue,
	isIntervalFieldFilterNotEmptyValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/utils.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	IntervalFilterContentAbsoluteDates,
	type IntervalFilterContentAbsoluteDatesProps,
	type IntervalFilterContentAbsoluteDatesValue,
} from '../content-absolute-dates/index.tsx';
import {
	IntervalFilterContentRollingDates,
	ROLLING_DATES_INTERVAL_DATE_START,
	ROLLING_DATES_PERIOD_WEEK,
	ROLLING_DATES_TIME_CURRENT,
	type IntervalFilterContentRollingDatesProps,
	type IntervalFilterContentRollingDatesValue,
} from '../content-rolling-dates/index.tsx';
import { filters } from '../utils/index.tsx';
import { intervalFilterValueMigration } from '../utils/interval-filter-value-migration.tsx';
import { assertUnreachable, type SelectOption, type UiFilter } from '../utils/types.tsx';
import { messages } from './messages.tsx';

const NumberPicker = ({
	value,
	onChange,
}: {
	value: number | undefined;
	onChange: (arg1: number | undefined) => void;
}) => (
	<TextField
		type="number"
		value={value}
		onChange={(event: ChangeEvent<HTMLInputElement>) => {
			onChange(Number(event.target.value));
		}}
	/>
);

type IntervalFilterContentContainerLegacyProps = {
	values: IntervalFieldFilterLegacyValue[];
	onChange: (values: DistributeArray<IntervalFieldFilterValue[]>) => void;
};

const IntervalFilterContentContainerLegacy = ({
	values,
	onChange,
}: IntervalFilterContentContainerLegacyProps) => {
	const { formatMessage } = useIntl();
	const value = Math.max((values.length > 0 ? values[0].numericValue : 0) || 0, 0);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Box xcss={containerLegacyStyles}>
			{filters.map((filter: UiFilter) => (
				<React.Fragment key={filter.id}>
					<Box xcss={containerItemStyles}>
						<Radio
							id={filter.id}
							name={filter.id}
							value={filter.id}
							label={formatMessage(filter.label)}
							isChecked={filter.isChecked(values)}
							onChange={() => {
								fireCompoundAnalyticsEvent.NavSidebarArchiveViewClicked(
									createAnalyticsEvent({ filter: filter.id }),
								);
								onChange(filter.getValues(value) || []);
							}}
						/>
					</Box>
					{filter.isChecked(values) && filter.hasParameter && (
						<Box xcss={containerItemStyles}>
							<NumberPicker
								value={value}
								onChange={(nv) => onChange(filter.getValues(Math.max(nv || 0, 0)) || [])}
							/>
						</Box>
					)}
				</React.Fragment>
			))}
		</Box>
	);
};

const absoluteDatesInitialValues: IntervalFilterContentAbsoluteDatesValue = Object.freeze({});
const rollingDatesInitialValues: IntervalFilterContentRollingDatesValue = Object.freeze({
	count: 1,
	intervalDateType: ROLLING_DATES_INTERVAL_DATE_START,
	period: ROLLING_DATES_PERIOD_WEEK,
	time: ROLLING_DATES_TIME_CURRENT,
});

const SELECT_OPTION_SPECIFIC_DATES = 'SPECIFIC_DATES';
const SELECT_OPTION_ROLLING_DATES = 'ROLLING_DATES';
const SELECT_OPTION_IS_EMPTY = 'IS_EMPTY';
const SELECT_OPTION_IS_NOT_EMPTY = 'IS_NOT_EMPTY';

type SelectOptionValue =
	| typeof SELECT_OPTION_SPECIFIC_DATES
	| typeof SELECT_OPTION_ROLLING_DATES
	| typeof SELECT_OPTION_IS_EMPTY
	| typeof SELECT_OPTION_IS_NOT_EMPTY;

type IntervalFilterContentContainerNextProps = {
	values: DistributeArray<IntervalFieldFilterValue[]>;
	onChange: (values: DistributeArray<IntervalFieldFilterValue[]>) => void;
};

const IntervalFilterContentNext = ({
	values,
	onChange,
}: IntervalFilterContentContainerNextProps) => {
	const { formatMessage } = useIntl();

	const [optionValue, setOptionValue] = useState<SelectOptionValue | undefined>(() => {
		if (values.length !== 1) {
			return undefined;
		}

		const value = values[0];

		if (isIntervalFieldFilterAbsoluteDatesValue(value)) {
			return SELECT_OPTION_SPECIFIC_DATES;
		}

		if (
			isIntervalFieldFilterLegacyValue(value) ||
			isIntervalFieldFilterRollingDatesCurrentValue(value) ||
			isIntervalFieldFilterRollingDatesPastNextValue(value)
		) {
			return SELECT_OPTION_ROLLING_DATES;
		}

		if (isIntervalFieldFilterEmptyValue(value)) {
			return SELECT_OPTION_IS_EMPTY;
		}

		if (isIntervalFieldFilterNotEmptyValue(value)) {
			return SELECT_OPTION_IS_NOT_EMPTY;
		}

		assertUnreachable(value);
	});

	const options: SelectOption<SelectOptionValue>[] = useMemo(() => {
		return [
			{ label: formatMessage(messages.specificDates), value: SELECT_OPTION_SPECIFIC_DATES },
			{ label: formatMessage(messages.rollingDates), value: SELECT_OPTION_ROLLING_DATES },
			{ label: formatMessage(messages.isEmpty), value: SELECT_OPTION_IS_EMPTY },
			{ label: formatMessage(messages.isNotEmpty), value: SELECT_OPTION_IS_NOT_EMPTY },
		];
	}, [formatMessage]);

	const currentOption = useMemo(
		() => options.find((option) => option.value === optionValue),
		[options, optionValue],
	);

	const [absoluteDates, setAbsoluteDates] = useState<IntervalFilterContentAbsoluteDatesValue>(
		() => {
			if (optionValue !== SELECT_OPTION_SPECIFIC_DATES) {
				return absoluteDatesInitialValues;
			}

			const value = values[0];
			if (isIntervalFieldFilterAbsoluteDatesValue(value)) {
				return {
					startDate: value.start,
					endDate: value.end,
				};
			}

			return absoluteDatesInitialValues;
		},
	);

	const [rollingDates, setRollingDates] = useState<IntervalFilterContentRollingDatesValue>(() => {
		if (optionValue !== SELECT_OPTION_ROLLING_DATES) {
			return rollingDatesInitialValues;
		}

		const value = values[0];
		if (isIntervalFieldFilterLegacyValue(value)) {
			const migrated = intervalFilterValueMigration(value);
			if (migrated.time === INTERVAL_FILTER_TIME_CURRENT) {
				const { intervalDateType, time, period } = migrated;
				return { intervalDateType, time, period, count: 0 };
			}

			const { intervalDateType, time, period, numericValue } = migrated;
			return { intervalDateType, time, period, count: numericValue };
		}

		if (isIntervalFieldFilterRollingDatesCurrentValue(value)) {
			const { intervalDateType, time, period } = value;
			return { intervalDateType, time, period, count: 0 };
		}

		if (isIntervalFieldFilterRollingDatesPastNextValue(value)) {
			const { intervalDateType, time, period, numericValue } = value;
			return { intervalDateType, time, period, count: numericValue };
		}

		return rollingDatesInitialValues;
	});

	const stateRef = useRef({
		optionValue,
		absoluteDates,
		rollingDates,
	});

	stateRef.current.optionValue = optionValue;
	stateRef.current.absoluteDates = absoluteDates;
	stateRef.current.rollingDates = rollingDates;

	const handleChange = useCallback(() => {
		switch (stateRef.current.optionValue) {
			case SELECT_OPTION_SPECIFIC_DATES:
				onChange([
					intervalAbsoluteDatesFilter({
						start: stateRef.current.absoluteDates.startDate,
						end: stateRef.current.absoluteDates.endDate,
					}),
				]);
				break;

			case SELECT_OPTION_ROLLING_DATES: {
				const { count, period, time, intervalDateType } = stateRef.current.rollingDates;
				if (time === INTERVAL_FILTER_TIME_CURRENT) {
					onChange([
						intervalRollingDatesFilters({
							intervalDateType,
							time,
							period,
						}),
					]);
				} else {
					onChange([
						intervalRollingDatesFilters({
							intervalDateType,
							numericValue: count,
							period,
							time,
						}),
					]);
				}
				break;
			}

			case SELECT_OPTION_IS_EMPTY:
				onChange([intervalEmptyFilter()]);
				break;

			case SELECT_OPTION_IS_NOT_EMPTY:
				onChange([intervalNotEmptyFilter()]);
				break;

			default:
			// Do nothing
		}
	}, [onChange]);

	const handleOptionChange = useCallback(
		(nextOption: SelectOption<SelectOptionValue> | null) => {
			if (nextOption) {
				setOptionValue(nextOption.value);
				stateRef.current.optionValue = nextOption.value;
				handleChange();
			}
		},
		[handleChange],
	);

	const handleAbsoluteDatesChange: IntervalFilterContentAbsoluteDatesProps['onChange'] =
		useCallback(
			(value) => {
				setAbsoluteDates(value);
				stateRef.current.absoluteDates = value;
				handleChange();
			},
			[handleChange],
		);

	const handleRollingDatesChange: IntervalFilterContentRollingDatesProps['onChange'] = useCallback(
		(value) => {
			setRollingDates(value);
			stateRef.current.rollingDates = value;
			handleChange();
		},
		[handleChange],
	);

	return (
		<Box xcss={containerStyles}>
			<Select
				options={options}
				value={currentOption}
				placeholder={formatMessage(messages.chooseOption)}
				onChange={handleOptionChange}
			/>
			{optionValue === SELECT_OPTION_SPECIFIC_DATES ||
			optionValue === SELECT_OPTION_ROLLING_DATES ? (
				<Box xcss={containerOptionsStyles}>
					{optionValue === SELECT_OPTION_SPECIFIC_DATES && (
						<IntervalFilterContentAbsoluteDates
							value={absoluteDates}
							onChange={handleAbsoluteDatesChange}
						/>
					)}
					{optionValue === SELECT_OPTION_ROLLING_DATES && (
						<IntervalFilterContentRollingDates
							value={rollingDates}
							onChange={handleRollingDatesChange}
						/>
					)}
				</Box>
			) : null}
		</Box>
	);
};

type IntervalFilterContentContainerProps = {
	values: DistributeArray<IntervalFieldFilterValue[]>;
	onChange: (values: DistributeArray<IntervalFieldFilterValue[]>) => void;
};
export const IntervalFilterContentContainer = ({
	values,
	onChange,
}: IntervalFilterContentContainerProps) => {
	if (fg('polaris_better_date_filters')) {
		return <IntervalFilterContentNext values={values} onChange={onChange} />;
	}

	// FF is OFF and `values` contains new filters
	// that cannot be supported by using the `legacy` component.
	// New filters are not backwards compatible with the old one
	// therefore we pass an empty array here
	return (
		<IntervalFilterContentContainerLegacy
			values={isArrayIntervalFieldFilterLegacyValue(values) ? values : []}
			onChange={onChange}
		/>
	);
};

const containerLegacyStyles = xcss({
	width: '212px',
	maxWidth: '212px',
	padding: 'space.200',
});

const containerStyles = xcss({
	width: '368px',
	maxWidth: '368px',
	padding: 'space.200',
});

const containerItemStyles = xcss({
	marginBottom: 'space.025',
});

const containerOptionsStyles = xcss({
	borderTop: '1px solid',
	borderColor: 'color.border',
	marginTop: 'space.150',
	marginLeft: 'space.negative.200',
	marginRight: 'space.negative.200',
	paddingTop: 'space.150',
	paddingRight: 'space.200',
	paddingLeft: 'space.200',
	padding: 'space.200',
});
