import React from 'react';
import { useBetaActions } from '@atlassian/jira-polaris-component-environment-tenant/src/index.tsx';
import { PolarisIdeasCommonContext } from '../context/index.tsx';
import { useActions as useDynamicFieldActions } from '../dynamic-field/index.tsx';
import {
	useIssueActionFailedHandling,
	useIssueCreationFailedHandling,
	useIssueLoadingFailedHandling,
	useIssueUpdateFailedHandling,
} from './error/index.tsx';
import { IssueIdeaContextHandler, IssuesSweetStateContainer, IssuesLoader } from './main.tsx';
import { PolarisIssueRealtimeHandler } from './realtime/index.tsx';
import type { ExternalProps } from './types.tsx';
import { useIssueBulkUpdate } from './utils/issue-bulk-update/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useIssueActions, useIssueStore } from './main';

export const IssuesContainer = (props: ExternalProps) => {
	const { children, ...rest } = props;
	const { projectId, onContainerReady } = rest;
	const handleIssueBulkUpdate = useIssueBulkUpdate();
	const handleIssueLoadingFailed = useIssueLoadingFailedHandling();
	const handleIssueCreationFailed = useIssueCreationFailedHandling();
	const handleIssueUpdateFailed = useIssueUpdateFailedHandling();
	const handleIssueActionFailed = useIssueActionFailedHandling();
	const { tryAddCurrentUserAsSiteCreator } = useBetaActions();
	const { markDynamicFieldAsInitialized, initialized } = useDynamicFieldActions();

	return (
		<PolarisIdeasCommonContext.Consumer>
			{({ onUpdateComments, ...contextRest }) => (
				<IssuesSweetStateContainer
					{...rest}
					scope="issues-singleton"
					onIssueBulkUpdate={handleIssueBulkUpdate}
					onIssueLoadingFailed={handleIssueLoadingFailed}
					onIssueCreationFailed={handleIssueCreationFailed}
					onIssueUpdateFailed={handleIssueUpdateFailed}
					onActionFailed={handleIssueActionFailed}
					onTryAddCurrentUserAsSiteCreator={tryAddCurrentUserAsSiteCreator}
					onDynamicFieldInitialized={markDynamicFieldAsInitialized}
					onDynamicFieldStoreInitialized={initialized}
					onUpdateComments={onUpdateComments}
				>
					<IssueIdeaContextHandler context={contextRest} />
					<IssuesLoader />
					<PolarisIssueRealtimeHandler projectId={projectId} onContainerReady={onContainerReady} />
					{children}
				</IssuesSweetStateContainer>
			)}
		</PolarisIdeasCommonContext.Consumer>
	);
};
