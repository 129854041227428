import React from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { Box, xcss } from '@atlaskit/primitives';
import { P200 } from '@atlaskit/theme/colors';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useIsDeliveryFieldType } from '../../../controllers/field/selectors/field-hooks.tsx';
import { useIsDeliveryIssueCountRestricted } from '../../../controllers/issue/selectors/meta-hooks.tsx';
import { type InfoMarkerProps, InfoMarker } from '../info-marker/index.tsx';
import messages from './messages.tsx';

export type MarkerProps = {
	fieldKey: FieldKey;
} & Pick<InfoMarkerProps, 'isInline' | 'marginLeft' | 'marginRight'>;

export const DeliveryDataRestrictedMarker = ({
	fieldKey,
	marginLeft,
	marginRight,
	isInline = false,
}: MarkerProps) => {
	const { formatMessage } = useIntl();
	const deliveryField = useIsDeliveryFieldType(fieldKey);
	const deliveryIssueCountRestricted = useIsDeliveryIssueCountRestricted();

	const showDeliveryIssueWarning = deliveryField && deliveryIssueCountRestricted;

	return showDeliveryIssueWarning ? (
		<InfoMarker
			id="polaris.common.src.ui.delivery.warning"
			marginLeft={marginLeft || 0}
			marginRight={marginRight || 0}
			isInline={isInline}
			tooltipContent={formatMessage(messages.infoMessage)}
		/>
	) : null;
};

export type InfoProps = {
	fieldKey: FieldKey;
};
export const DeliveryDataRestrictedInfoText = ({ fieldKey }: InfoProps) => {
	const { formatMessage } = useIntl();
	const deliveryField = useIsDeliveryFieldType(fieldKey);
	const deliveryIssueCountRestricted = useIsDeliveryIssueCountRestricted();
	const showDeliveryIssueWarning = deliveryField && deliveryIssueCountRestricted;

	return showDeliveryIssueWarning ? (
		// @ts-expect-error - TS2739 - Type '{ children: (string | Element)[]; }' is missing the following properties from type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { marginLeft: string | number; marginRight: string | number; }, any>>': marginLeft, marginRight
		<Container>
			<Box xcss={iconContainerStyles}>
				{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
				<InfoIcon size="small" label="warn" primaryColor={P200} />
			</Box>
			{formatMessage(messages.infoMessage)}
		</Container>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	marginLeft: string | number;
	marginRight: string | number;
	isInline?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ marginLeft }) => `${marginLeft}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ marginRight }) => `${marginRight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isInline }) => isInline && 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	verticalAlign: ({ isInline }) => isInline && 'middle',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		display: 'flex',
	},
});

const iconContainerStyles = xcss({
	marginRight: 'space.075',
});
