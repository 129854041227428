import React, { useState, memo } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import {
	Header,
	NavigationHeader,
	NavigationContent,
	NavigationFooter,
	SideNavigation,
} from '@atlaskit/side-navigation';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { LeftSidebar } from '@atlassian/jira-navigation-system/src/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import {
	useIsProjectOnboarded,
	useProjectAvatarUrls,
	useProjectName,
} from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import { useCurrentPolarisRouteSection } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import {
	useAllIdeasViewId,
	useViewSlug,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { ViewSectionTypeViews } from '@atlassian/jira-polaris-common/src/controllers/views/types.tsx';
import { useIsPreviewContributorMode } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FeedbackButton } from './feedback-button/index.tsx';
import { PreviewModeBanner } from './preview-mode/index.tsx';
import { SidebarSections } from './sections/index.tsx';
import { SettingsSidebar } from './settings/main.tsx';
import { SettingsSection } from './settings/section/index.tsx';

export const POLARIS_SIDEBAR_TEST_ID = 'PolarisContextualNavigation';

const SidebarHeader = memo(({ testId }: { testId?: string }) => {
	const [projectName] = useProjectName();
	const [projectAvatarUrls] = useProjectAvatarUrls();
	return (
		<NavigationHeader>
			<Header
				description="Discovery project"
				iconBefore={
					<Avatar
						appearance="square"
						size="small"
						src={projectAvatarUrls?.['48x48']}
						testId={testId}
					/>
				}
			>
				{projectName}
			</Header>
		</NavigationHeader>
	);
});

const SidebarContent = memo(({ isLandingOnOtherView }: { isLandingOnOtherView?: boolean }) => {
	const allIdeasViewId = useAllIdeasViewId();
	const allIdeasViewSlug = useViewSlug(allIdeasViewId);
	const canManageViews = useCanManageViews();
	const section = useCurrentPolarisRouteSection();
	const [prevRoute, setPrevRoute] = useState({
		section: ViewSectionTypeViews,
		resource: allIdeasViewSlug,
	});
	const [isPreviewContributorMode] = useIsPreviewContributorMode();
	const [isProjectOnboarded] = useIsProjectOnboarded();
	return section !== 'settings' ? (
		<>
			<NavigationContent>
				{isProjectOnboarded && (
					<SidebarSections
						isReadOnly={!canManageViews}
						isLandingOnOtherView={isLandingOnOtherView}
					/>
				)}
				{/* @ts-expect-error - TS2322 - Type 'Dispatch<SetStateAction<{ section: string; resource: string | undefined; }>>' is not assignable to type '(arg1: PolarisRoute) => void'. */}
				<SettingsSection onNavToSettings={setPrevRoute} />
				<FeedbackButton />
			</NavigationContent>
			<NavigationFooter>{isPreviewContributorMode && <PreviewModeBanner />}</NavigationFooter>
		</>
	) : (
		<NavigationContent>
			<SettingsSidebar prevRoute={prevRoute} />
		</NavigationContent>
	);
});

const SidebarInternal = ({
	testId,
	isLandingOnOtherView,
}: {
	testId?: string;
	isLandingOnOtherView?: boolean;
}) => {
	const { closeSummaryEditor } = useIdeaActions();

	return (
		<SidebarStyleOverrides>
			<LeftSidebar
				width={240}
				id="polaris-sidebar"
				testId={POLARIS_SIDEBAR_TEST_ID}
				/* This is messy, I know, but there isn't a better way right now
                   There is a bug in LeftSidebar meaning the onExpand/Collapse doesn't fire
                   This is tracked on https://product-fabric.atlassian.net/browse/DSP-9088
                   If this is fixed we should be able to put this code back to being simpler
                 */
				overrides={{
					ResizeButton: {
						render: (Component, props) => {
							const ComponentWithOnClick = (
								<Component
									{...props}
									onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
										props?.onClick && props?.onClick(event);
										closeSummaryEditor();
									}}
								/>
							);

							return (
								<Placeholder name="polaris-sidebar" fallback={ComponentWithOnClick}>
									{ComponentWithOnClick}
								</Placeholder>
							);
						},
					},
				}}
			>
				{/* set sidebar width and top navigation height of the pagelayout again so that our polaris navigation is positioned correctly */}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766 */}
				<style>{':root{--leftSidebarWidth:240px; --topNavigationHeight:56px;}'}</style>
				<NavSideBar
					label="Jira Product Discovery navigation"
					testId="polaris-ideas.ui.sidebar.side-navigation"
				>
					<SidebarHeader testId={testId} />
					<SidebarContent isLandingOnOtherView={isLandingOnOtherView} />
				</NavSideBar>
			</LeftSidebar>
		</SidebarStyleOverrides>
	);
};

export const Sidebar = memo(SidebarInternal);
Sidebar.displayName = 'LeftSidebar';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarStyleOverrides = styled.div({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.leftSidebar,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button[role="separator"]': {
		width: '8px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button[role="separator"]:focus': {
		boxShadow: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& div[overflow='hidden']": {
		overflow: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NavSideBar = styled(SideNavigation)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&': {
		overflow: 'visible',
	},
});
