import React, { type SyntheticEvent, useCallback, useState, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import InlineDialog from '@atlaskit/inline-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type ChildrenProps = {
	onOpen: () => void;
};

type Props = {
	onSubmit: () => void;
	title: string;
	children: (props: ChildrenProps) => ReactNode;
	testId?: string;
};

export const InlineDelete = ({ children, title, onSubmit, testId }: Props) => {
	const { formatMessage } = useIntl();

	const [isOpen, setIsOpen] = useState(false);

	const onOpen = useCallback(() => {
		setIsOpen(true);
	}, []);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onCancel = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'deleteCancel');
			onClose();
		},
		[onClose],
	);

	const onDelete = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'deleteConfirm');
			onSubmit();
			onClose();
		},
		[onSubmit, onClose],
	);

	return (
		<InlineDialog
			onClose={onClose}
			isOpen={isOpen}
			testId={testId}
			content={
				<DeleteConfirmContentContainer>
					<DeleteConfirmHeaderContainer>
						<ErrorIcon label="error" primaryColor={token('color.icon.danger', colors.R300)} />
						{title}
					</DeleteConfirmHeaderContainer>
					<Box xcss={deleteConfirmMessageContainerStyles}>
						{formatMessage(messages.deleteWarning)}
					</Box>
					<DeleteConfirmActionsContainer>
						<Button testId="polaris-lib-inline-delete.ui.cancel-button" onClick={onCancel}>
							{formatMessage(messages.cancel)}
						</Button>
						<Button
							testId="polaris-lib-inline-delete.ui.submit-button"
							appearance="danger"
							onClick={onDelete}
						>
							{formatMessage(messages.delete)}
						</Button>
					</DeleteConfirmActionsContainer>
				</DeleteConfirmContentContainer>
			}
		>
			{children({ onOpen })}
		</InlineDialog>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeleteConfirmContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeleteConfirmHeaderContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	fontWeight: 'bold',
});

const deleteConfirmMessageContainerStyles = xcss({
	marginTop: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeleteConfirmActionsContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	marginTop: token('space.100', '8px'),
	alignItems: 'center',
	justifyContent: 'flex-end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button': {
		marginLeft: token('space.075', '6px'),
	},
});
