/** @jsx jsx */
import React, { useState, useRef, useCallback, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import findLast from 'lodash/findLast';
import Button, { IconButton } from '@atlaskit/button/new';
import { Emoji } from '@atlaskit/emoji';
import ArrowDownIcon from '@atlaskit/icon/core/migration/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/core/migration/arrow-up';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { DragHandleButtonSmall } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button-small';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FieldSearchableDropdown } from '@atlassian/jira-polaris-component-field-searchable-dropdown/src/ui/field-searchable-dropdown/index.tsx';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

const OptionComponent = ({ field }: { field: Field }) => {
	const icon = useMemo(
		() =>
			iconForPolarisFieldType(
				field.type,
				{ label: field.label },
				field.configuration?.optionWeightType !== undefined,
			),
		[field.configuration?.optionWeightType, field.label, field.type],
	);

	const emojiId = field.emoji;
	const emoji = useEmoji(emojiId);

	return (
		<Flex alignItems="center">
			{emoji ? (
				<Flex alignItems="center" xcss={emojiWrapperStyles}>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</Flex>
			) : (
				<Flex xcss={iconContainerStyles}>{icon}</Flex>
			)}
			<Box xcss={optionTextStyles}>{field.label}</Box>
		</Flex>
	);
};

type SortFieldComponentProps = {
	selectedField?: Field;
	asc: boolean;
	fields: Field[];
	label?: string;
	isDisabled?: boolean;
	isHoverable?: boolean;
	isUsingCustomColor?: boolean;
	onClearSort?: () => void;
	onClickDirection: () => void;
	onChangeField: (field: Field) => void;
};

export const SortFieldComponent = ({
	selectedField,
	asc,
	fields,
	label,
	isDisabled = false,
	isHoverable = true,
	isUsingCustomColor = false,
	onClearSort,
	onClickDirection,
	onChangeField,
}: SortFieldComponentProps) => {
	const { formatMessage } = useIntl();
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [dialogOpen, setDialogOpen] = useState(false);

	const toggleDialog = useCallback(() => setDialogOpen((prev) => !prev), []);

	return (
		<Flex
			xcss={[sortFieldContainerStyles, !isDisabled && isHoverable && sortFieldContainerHoverStyles]}
		>
			<Box paddingInline="space.050" xcss={[(isDisabled || !isHoverable) && hiddenStyles]}>
				<DragHandleButtonSmall appearance="subtle" type="button" label="view-dragHandle" />
			</Box>
			<Box paddingInlineEnd="space.100">{label}</Box>
			<Flex xcss={sortFieldGroupStyles}>
				<Box xcss={inputContainerStyles}>
					<PolarisInlineDialog
						noPadding
						onClose={(target) => {
							if (buttonRef.current && !buttonRef.current.contains(target)) {
								setDialogOpen(false);
							}
						}}
						isOpen={dialogOpen}
						placement="bottom"
						content={
							<>
								<FireUiAnalytics
									actionSubject="sortFieldMenu"
									action="viewed"
									actionSubjectId="view-controls"
								/>
								<FieldSearchableDropdown
									options={fields.map(
										({ key, label: fieldLabel, type, emoji, configuration, global, custom }) => ({
											key,
											label: fieldLabel,
											type,
											emoji,
											global,
											custom,
											hasWeightType: configuration?.optionWeightType !== undefined,
										}),
									)}
									onSelect={(key) => {
										const field = findLast(fields, { key });
										field !== undefined && onChangeField(field);
										setDialogOpen(false);
									}}
									onClose={() => setDialogOpen(false)}
								/>
							</>
						}
					>
						<div
							css={[
								isUsingCustomColor && buttonWrapperCustomColorStyles,
								!selectedField && selectFieldButtonWrapperStyles,
							]}
						>
							<Button
								testId="polaris-component-view-sort-configuration.ui.config-sort.field.button"
								data-component-selector="polaris-component-view-sort-configuration.ui.config-sort.field.button-9nf7"
								ref={buttonRef}
								isSelected={dialogOpen}
								onClick={toggleDialog}
								isDisabled={isDisabled}
								iconAfter={(iconProps) => <ChevronDownIcon size="medium" {...iconProps} />}
								shouldFitContainer
							>
								{selectedField ? (
									<OptionComponent field={selectedField} />
								) : (
									<Flex justifyContent="start" alignItems="center">
										{formatMessage(messages.fieldSelectPlaceholder)}
									</Flex>
								)}
							</Button>
						</div>
					</PolarisInlineDialog>
				</Box>
				{selectedField && (
					<div css={[isUsingCustomColor && buttonWrapperCustomColorStyles]}>
						<IconButton
							data-component-selector="polaris-component-view-sort-configuration.ui.config-sort.field.button-9nf7"
							onClick={onClickDirection}
							label={
								asc ? formatMessage(messages.ascSortButton) : formatMessage(messages.descSortButton)
							}
							isDisabled={isDisabled}
							icon={asc ? ArrowUpIcon : ArrowDownIcon}
						/>
					</div>
				)}
				{!isDisabled && onClearSort && (
					<IconButton
						appearance="subtle"
						onClick={onClearSort}
						label={formatMessage(messages.clearButton)}
						icon={(iconProps) => <TrashIcon size="small" {...iconProps} />}
					/>
				)}
			</Flex>
		</Flex>
	);
};

const inputContainerStyles = xcss({
	flexGrow: 1,
	minWidth: '0',
});

const sortFieldContainerStyles = xcss({
	alignItems: 'center',
	paddingBlock: 'space.050',
});

const sortFieldContainerHoverStyles = xcss({
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const sortFieldGroupStyles = xcss({
	flex: 1,
	gap: 'space.050',
	paddingInlineEnd: 'space.200',
	minWidth: '0',
});

const iconContainerStyles = xcss({
	alignItems: 'center',
	paddingInlineEnd: 'space.100',
});

const optionTextStyles = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflowX: 'hidden',
});

const hiddenStyles = xcss({
	visibility: 'hidden',
});

// Align "Select field" button with sort rules, requires reducing maxWidth by 2 button widths and spacing around it
const selectFieldButtonWrapperStyles = css({
	maxWidth: `calc(100% - ${token('space.800')} - ${token('space.100')})`,
});

const buttonWrapperCustomColorStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="polaris-component-view-sort-configuration.ui.config-sort.field.button-9nf7"]':
		{
			background: token('color.background.warning'),
			color: token('color.text.accent.yellow.bolder'),
		},
});

const emojiWrapperStyles = xcss({ marginInlineEnd: 'space.100' });
