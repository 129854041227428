import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { RemoteIssueAttachmentsResponse } from '@atlassian/jira-polaris-remote-issue/src/controllers/attachments/types.tsx';
import type {
	RemoteIssue,
	RemoteJiraIssue,
} from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import type { Action, GetState, SetState } from '@atlassian/react-sweet-state';
import { getFieldKeys } from '../../selectors/index.tsx';
import type { PolarisJiraIssueAttachment, Props, State } from '../../types.tsx';
import { transformIssueLinkData } from '../delivery-tickets/utils.tsx';
import { loadDeliveryTicketsEstimate } from '../load-delivery-tickets-estimate/index.tsx';
import { loadIssueLinkArchived } from '../load-issue-link-archived/index.tsx';

const setLoadingState = (
	isLoading: boolean,
	loadingProps: {
		issueKey: IssueKey;
	},
	issueStateCallbackHandled: boolean,
	setState: SetState<State>,
	getState: GetState<State>,
) => {
	setState({
		meta: {
			...getState().meta,
			isLoading,
			loadingProps,
			issueStateCallbackHandled,
		},
	});
};

const setErrorState = (
	setState: SetState<State>,
	getState: GetState<State>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	e: any,
	loadingProps: {
		issueKey: IssueKey;
	},
) => {
	setState({
		meta: {
			...getState().meta,
			loadingProps,
			isLoading: false,
			error: e,
			issueStateCallbackHandled: false,
		},
	});
};

const addPreloadedFields = (setState: SetState<State>, response: RemoteIssue) => {
	setState({
		preloadedFields: {
			summary: response.fields.summary,
			description: response.fields.description,
			issuetype: response.fields.issuetype
				? {
						iconUrl: response.fields.issuetype.iconUrl,
					}
				: undefined,
		},
	});
};

const addIssueLinks = (
	getState: GetState<State>,
	setState: SetState<State>,
	response: RemoteIssue,
) => {
	setState({
		issueLinks: response.fields.issuelinks ?? [],
	});
};

const addComments = (
	getState: GetState<State>,
	setState: SetState<State>,
	response: RemoteJiraIssue,
) => {
	setState({
		comments: response.fields.comment?.comments ?? [],
	});
};

export const addDeliveryTickets = (
	getState: GetState<State>,
	setState: SetState<State>,
	response: RemoteJiraIssue,
	shouldRefreshAllDeliveryTickets = false,
) => {
	const state = getState();

	if (!state.polarisDeliveryIssueLinkType) {
		return;
	}

	const issueLinkData = transformIssueLinkData(
		response.fields.issuelinks,
		state.polarisDeliveryIssueLinkType,
		state.deliveryTickets,
		shouldRefreshAllDeliveryTickets,
	);

	if (shouldRefreshAllDeliveryTickets) {
		setState({
			...state,
			deliveryTickets: issueLinkData,
		});
	} else if (!isEqual(state.deliveryTickets, issueLinkData)) {
		const deliveryTicketsByIssueId = keyBy(issueLinkData, ({ issueId }) => issueId);
		const newDeliveryTickets = [
			...state.deliveryTickets.filter(
				(data) =>
					!(
						deliveryTicketsByIssueId[data.issueId] &&
						deliveryTicketsByIssueId[data.issueId].parentKey === data.parentKey
					),
			),
			...issueLinkData,
		];

		setState({
			...state,
			deliveryTickets: newDeliveryTickets,
		});
	}
};

export const addWatchInfo = (
	getState: GetState<State>,
	setState: SetState<State>,
	response?: {
		isWatching: boolean;
		watchCount: number;
		watchers: User[];
	},
) => {
	if (!response) {
		setState({
			watchInfo: undefined,
		});
	} else {
		const watchData = {
			isWatching: response.isWatching,
			watchCount: response.watchCount,
			watchersList: response.watchers.map(({ accountId, avatarUrls, displayName }: User) => ({
				accountId,
				avatarUrls,
				displayName,
			})),
		};

		setState({
			watchInfo: watchData,
		});
	}
};

const addAttachments = (
	getState: GetState<State>,
	setState: SetState<State>,
	data: Omit<
		RemoteIssueAttachmentsResponse,
		'clientId' | 'endpointUrl' | 'tokenLifespanInSeconds'
	> &
		Partial<Pick<RemoteIssueAttachmentsResponse, 'clientId' | 'endpointUrl'>>,
) => {
	if (data.clientId === undefined || data.endpointUrl === undefined) {
		return;
	}

	const baseAuth = {
		clientId: data.clientId,
		baseUrl: data.endpointUrl,
	};

	const attachmentsWithMediaConfig: PolarisJiraIssueAttachment[] = [];

	data.tokensWithFiles.forEach(({ token, files }) => {
		files.forEach((file) => {
			attachmentsWithMediaConfig.push({
				file,
				mediaClientConfig: {
					authProvider: () =>
						Promise.resolve({
							...baseAuth,
							token,
						}),
				},
			});
		});
	});

	setState({
		attachments: {
			attachments: attachmentsWithMediaConfig,
			inUpload: [],
			isLoading: false,
		},
	});
};

export const loadIssue =
	(): Action<State, Props> =>
	async ({ setState, getState, dispatch }, props) => {
		const { issueKey, onIssueLoaded, onIssuesContainerInitialized, issuesRemote } = props;
		// For when the user opens multiple issues in a row and receives issue data after navigating away (POL-7815)
		const isCurrentlyOpenedIssue = (): boolean => {
			const currentlyOpenedIssue = getState().issueKey;
			return currentlyOpenedIssue === issueKey;
		};

		if (!issueKey || issueKey === getState().meta.loadingProps?.issueKey) {
			return;
		}

		setLoadingState(true, { issueKey }, false, setState, getState);

		const fields = getFieldKeys(getState(), props);

		setState({
			attachments: {
				...getState().attachments,
				isLoading: true,
			},
		});

		await Promise.all([
			issuesRemote
				.fetchIssue({
					issueIdOrKey: issueKey,
					fields: fields?.length ? [...fields, 'description'] : undefined,
				})
				.then((response) => {
					if (!isCurrentlyOpenedIssue()) return;
					if (
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.location.hostname !== 'localhost' && // Storybook fix
						response?.key &&
						response.key !== issueKey
					) {
						// This would cause a full redirect if keys do not match
						// Fix for POL-6669 bug report

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.location.href = `${window.location.origin}/browse/${response.key}`;
					}
					addComments(getState, setState, response);
					addIssueLinks(getState, setState, response);
					addDeliveryTickets(getState, setState, response, true);
					addPreloadedFields(setState, response);

					onIssueLoaded(response, getState().meta.isSingleIssueLoaded === false).then(() => {
						setState({
							meta: {
								...getState().meta,
								isSingleIssueLoaded: true,
							},
						});
					});
					onIssuesContainerInitialized().then(() => {
						setState({
							meta: {
								...getState().meta,
								issueStateCallbackHandled: true,
							},
						});
					});
					dispatch(loadIssueLinkArchived());
					dispatch(loadDeliveryTicketsEstimate());
				})
				.catch((error) => {
					if (!isCurrentlyOpenedIssue()) return;
					if (isClientFetchError(error)) {
						experience.ideaView.directPageSegmentLoad.abort(error);
						experience.ideaView.pageSegmentLoad.abort(error);
					}
					setErrorState(setState, getState, error, { issueKey });
				}),

			issuesRemote
				.fetchIssueWatchers({ issueIdOrKey: issueKey })
				.then((watchData) => {
					if (!isCurrentlyOpenedIssue()) return;
					addWatchInfo(getState, setState, watchData);
				})
				.catch(() => {
					if (!isCurrentlyOpenedIssue()) return;
					addWatchInfo(getState, setState, undefined);
				}),

			issuesRemote
				.fetchIssueAttachments({ issueIdOrKey: issueKey })
				.then((attachments) => {
					if (!isCurrentlyOpenedIssue()) return;
					addAttachments(getState, setState, attachments);
				})
				.catch(() => {
					if (!isCurrentlyOpenedIssue()) return;
					addAttachments(getState, setState, { tokensWithFiles: [] });
				}),
		]).then(() => {
			if (!isCurrentlyOpenedIssue()) return;
			setLoadingState(
				false,
				{ issueKey },
				getState().meta.issueStateCallbackHandled,
				setState,
				getState,
			);
		});
	};
