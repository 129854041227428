/// @ts-expect-error - TS7016 - Could not find a declaration file for module 'jwt-decode'. '/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/node_modules/jwt-decode/lib/index.js' implicitly has an 'any' type.
import jwtDecode from 'jwt-decode';
import { getPolarisCollabToken } from '@atlassian/jira-polaris-component-view-visitors/src/services/polaris-api/view-collab/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';

const permissionTokenCache = new Map();
const permissionTokenInFlightCache = new Map();

export const permissionTokenRefresh = async (
	apolloClient: PolarisApolloClient,
	viewAri: string,
	onError: (error: Error, reason: String, status: Number) => void,
) => {
	const tokenFromCache = permissionTokenCache.get(viewAri);
	if (tokenFromCache) {
		try {
			const jwt = jwtDecode(tokenFromCache);
			const nowInSeconds = Date.now() / 1000;
			if (jwt.exp > nowInSeconds + 30) {
				return Promise.resolve(tokenFromCache);
			}
		} catch {
			// do nothing
		}
	}
	if (permissionTokenInFlightCache.has(viewAri)) {
		return permissionTokenInFlightCache.get(viewAri);
	}
	const promise = getPolarisCollabToken(apolloClient, viewAri, onError)
		.then(({ token }) => {
			permissionTokenCache.set(viewAri, token);
			return token;
		})
		.catch((err) => {
			permissionTokenCache.delete(viewAri);
			throw err;
		})
		.finally(() => {
			permissionTokenInFlightCache.delete(viewAri);
		});
	permissionTokenInFlightCache.set(viewAri, promise);
	return promise;
};
