import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import { useCurrentUser } from '../../../common/utils/tenant-context/index.tsx';
import { createHook } from '../main.tsx';
import type { CollabParticipant } from '../types.tsx';

const useCollabPresence = createHook(({ presence }) => presence);

export const usePresence = (): CollabParticipant[] => {
	const presence = useCollabPresence();
	const currentUser = useCurrentUser();
	return useMemo(
		() =>
			uniqBy(
				presence.filter(({ clientId }) => clientId !== currentUser && clientId !== undefined),
				({ clientId }) => clientId,
			).concat(...presence.filter(({ clientId }) => clientId === undefined)),
		[currentUser, presence],
	);
};
