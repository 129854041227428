import React, { useMemo, useRef, useState, useCallback, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import { Emoji } from '@atlaskit/emoji';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import type {
	GroupOptionsType,
	OptionType,
	SelectProps,
} from '../../../common/types/select/index.tsx';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '../../../controllers/field/selectors/field-hooks.tsx';
import { useOpenRightSidebarOnCreate } from '../../../controllers/right-sidebar/actions/hooks.tsx';
import type { RightSidebarOpeningSource } from '../../../controllers/right-sidebar/types.tsx';
import { EmojiWrapper } from '../../field-config/item/emoji-wrapper/index.tsx';
import { CreateFieldButton } from '../create-field/index.tsx';
import { messages } from './messages.tsx';

type FieldTriggerProps = {
	field: Field;
};

export const FieldItem = ({ field }: FieldTriggerProps) => {
	const icon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	return (
		<FieldWithIcon>
			{emoji && emojiId !== undefined ? (
				<EmojiWrapper>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</EmojiWrapper>
			) : (
				<FieldIcon>{icon}</FieldIcon>
			)}
			<FieldName>{field.label}</FieldName>
		</FieldWithIcon>
	);
};

type FieldSelectProps = {
	inputId?: string;
	isClearable?: boolean;
	selectedField?: Field;
	fieldOptions: (OptionType | GroupOptionsType)[];
	SearchableComponent: ComponentType<SelectProps>;
	onChange: (fieldKey?: FieldKey) => void;
	canCreateField?: boolean;
	onFieldCreate?: (fieldKey: FieldKey) => void;
	isSearchable?: boolean;
	sidebarOpeningSource?: RightSidebarOpeningSource;
};

export const FieldSelect = ({
	inputId,
	isClearable = false,
	selectedField,
	fieldOptions,
	onChange,
	SearchableComponent,
	canCreateField,
	onFieldCreate = noop,
	isSearchable,
	sidebarOpeningSource,
}: FieldSelectProps) => {
	const { formatMessage } = useIntl();
	const selectFieldMsg = useMemo(() => formatMessage(messages.notSelectedField), [formatMessage]);

	const buttonRef = useRef<HTMLElement | null>(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const toggleDialog = useCallback(() => setDialogOpen(!dialogOpen), [dialogOpen]);
	const onClear = useCallback(() => onChange(undefined), [onChange]);
	const openRightSidebarOnCreate = useOpenRightSidebarOnCreate(sidebarOpeningSource);
	const [fieldSearchString, setFieldSearchString] = useState('');

	const handleOpenRightSidebarOnCreate = useCallback(() => {
		openRightSidebarOnCreate(fieldSearchString, onFieldCreate);
	}, [openRightSidebarOnCreate, fieldSearchString, onFieldCreate]);

	return (
		<PolarisInlineDialog
			noPadding
			onClose={(target) => {
				if (buttonRef.current && !buttonRef.current.contains(target)) {
					setDialogOpen(false);
				}
			}}
			isOpen={dialogOpen}
			placement="bottom-start"
			content={
				<>
					<SearchableComponent
						inputId={inputId}
						options={fieldOptions}
						onSelect={(fieldKey) => {
							onChange(fieldKey);
							setDialogOpen(false);
						}}
						onClose={() => setDialogOpen(false)}
						onSearch={setFieldSearchString}
						isSearchable={isSearchable}
					/>
					{canCreateField && (
						<CreateFieldButtonContainer>
							<CreateFieldButton onCreate={handleOpenRightSidebarOnCreate} />
						</CreateFieldButtonContainer>
					)}
				</>
			}
		>
			<ButtonsWrapper>
				<Button
					id="pendo.field-select.button"
					testId="polaris-common.ui.common.field-select.button"
					ref={buttonRef}
					isSelected={dialogOpen}
					onClick={toggleDialog}
					iconAfter={<ChevronDownIcon label={selectFieldMsg} />}
				>
					{selectedField ? <FieldItem field={selectedField} /> : selectFieldMsg}
				</Button>
				{isClearable && selectedField !== undefined && (
					<ClearButton
						appearance="subtle-link"
						onClick={onClear}
						iconBefore={
							<TrashIcon size="small" label={formatMessage(messages.clearSelectionButton)} />
						}
					/>
				)}
			</ButtonsWrapper>
		</PolarisInlineDialog>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldWithIcon = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldIcon = styled.div({
	marginRight: token('space.100', '8px'),
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldName = styled.div({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	maxWidth: '5vw',
	textOverflow: 'ellipsis',
	'@media screen and (min-width: 1300px)': {
		maxWidth: '10vw',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsWrapper = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ClearButton = styled(Button)({
	flex: 0,
	paddingLeft: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateFieldButtonContainer = styled.div({
	padding: token('space.100', '8px'),
	boxShadow: '0 -1px 0 rgb(0 0 0 / 10%)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&& button': {
		textAlign: 'center',
		'&:hover': {
			textAlign: 'center',
		},
	},
});
