import React, { useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_RENDER_MATRIX_VIEW_START,
	PAGE_LOAD_MARK_RENDER_MATRIX_VIEW_END,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/project.tsx';
import {
	useIsEmbedded,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useSelectedIssue,
	useUsersByAccountId,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useOpenRightSidebarMatrixXAxisOptions,
	useOpenRightSidebarMatrixYAxisOptions,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import {
	useIsIdeasBucketOpen,
	useRightSidebarShowing,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	RightSidebarShowingXAxisOptions,
	RightSidebarShowingYAxisOptions,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewAccessLevel,
	useCurrentViewConfigured,
	useCurrentViewId,
	useCurrentViewXAxis,
	useCurrentViewYAxis,
	useCurrentViewZAxis,
	useHasSharedViewConfigError,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { IdeaCardContextProvider } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/utils.tsx';
import {
	useCanEditIssues,
	useHasNoProjectPermissions,
	useCanModifyReporter,
	useIsLoadedPermissions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import { REPORTER_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import {
	fireCompoundAnalyticsEvent,
	useViewOpenedAnalytics,
} from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import ExperienceFailErrorBoundary from '@atlassian/jira-polaris-lib-analytics/src/ui/index.tsx';
import { useIsViewPermissionsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { Matrix } from '@atlassian/jira-polaris-lib-matrix/src/main.tsx';
import type { AxisValue, ItemId } from '@atlassian/jira-polaris-lib-matrix/src/types.tsx';
import { useRunOnce } from '@atlassian/jira-polaris-lib-run-once/src/index.tsx';
import { MatrixViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/matrix-view/index.tsx';
import { PublishedViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/publish-view/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { IdeasBucketButton } from '../common/ideas-bucket-button/index.tsx';
import { useCurrentViewSuccessExperience } from '../common/utils/experience.tsx';
import { MatrixBubble, MatrixClusterBubble, MatrixBubbleWrapper } from './bubble/index.tsx';
import { MatrixSidebarItem } from './matrix-sidebar-item/index.tsx';
import { BubbleTooltip } from './tooltip/index.tsx';
import { NO_WEIGHT_Z_AXIS, useAxisConfiguration } from './utils/axis.tsx';
import { useItems } from './utils/items.tsx';

const matrixComponents = {
	SidebarItem: MatrixSidebarItem,
	Item: MatrixBubble,
	ItemWrapper: MatrixBubbleWrapper,
	ClusteredItem: MatrixClusterBubble,
	DnDTooltip: BubbleTooltip,
} as const;

export const IdeaMatrix = () => {
	useRunOnce(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_MATRIX_VIEW_START);
	});
	const [hasModifyReporterPermission] = useCanModifyReporter();

	const selectedXAxisField = useCurrentViewXAxis();
	const selectedYAxisField = useCurrentViewYAxis();
	const selectedZAxisField = useCurrentViewZAxis();
	const { setFieldValues } = useViewActions();
	const { items, emptyState } = useItems(
		selectedXAxisField,
		selectedYAxisField,
		selectedZAxisField,
	);
	const xValues = useMemo(() => items.map((item) => item.x), [items]);
	const yValues = useMemo(() => items.map((item) => item.y), [items]);
	const zValues = useMemo(() => items.map((item) => item.z), [items]);

	const xAxis = useAxisConfiguration(selectedXAxisField, xValues, false, 'x');
	const yAxis = useAxisConfiguration(selectedYAxisField, yValues, false, 'y');
	const zAxis = useAxisConfiguration(selectedZAxisField, zValues, true, 'z');

	const canManageCurrentView = useCanManageCurrentView();
	const viewAccessLevel = useCurrentViewAccessLevel();
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();
	const embedded = useIsEmbedded();
	const isSharedView = useIsSharedView();
	const [canEditIssues] = useCanEditIssues();

	const safeZAxis = zAxis !== undefined ? zAxis : NO_WEIGHT_Z_AXIS;

	const viewId = useCurrentViewId();
	const { updateFieldValue, updateField } = useIssueActions();
	const fieldX = useField(selectedXAxisField);
	const fieldY = useField(selectedYAxisField);
	const usersX = useUsersByAccountId(fieldX?.key || '');
	const usersY = useUsersByAccountId(fieldY?.key || '');
	const getNewValue = useCallback(
		(value: string): UserFieldValue | undefined => {
			if (usersX && usersX[value]) {
				return usersX[value];
			}
			if (usersY && usersY[value]) {
				return usersY[value];
			}
			return undefined;
		},
		[usersX, usersY],
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isAxisLocked = useCallback(
		(fieldKey?: string, fieldEditable?: boolean) =>
			!fieldEditable || (fieldKey === REPORTER_FIELDKEY && !hasModifyReporterPermission),
		[hasModifyReporterPermission],
	);

	const updateFieldValueOnMoveItems = useCallback(
		(field: Field, newValue: AxisValue, itemIds: ItemId[]) => {
			if (field.type === FIELD_TYPES.STATUS) {
				itemIds.forEach((itemId) => {
					updateField(field.key, false, itemId, { id: newValue });
				});
			} else if (
				(field.type === FIELD_TYPES.ASSIGNEE || field.type === FIELD_TYPES.REPORTER) &&
				typeof newValue === 'string'
			) {
				updateFieldValue({
					fieldKey: field.key,
					localIssueIds: itemIds,
					newValue: getNewValue(newValue),
				});
			} else if (
				field.type === FIELD_TYPES.NUMBER ||
				field.type === FIELD_TYPES.RATING ||
				field.type === FIELD_TYPES.SLIDER ||
				field.type === FIELD_TYPES.CHECKBOX
			) {
				updateFieldValue({
					fieldKey: field.key,
					localIssueIds: itemIds,
					newValue,
				});
			} else {
				updateFieldValue({
					fieldKey: field.key,
					localIssueIds: itemIds,
					newValue: { id: newValue },
				});
			}
			fireCompoundAnalyticsEvent.MatrixView.fieldUpdatedByDragWithinXAxis(field.type);
		},
		[getNewValue, updateField, updateFieldValue],
	);

	const onMoveItems = useCallback(
		(itemIds: ItemId[], newX: AxisValue, newY: AxisValue) => {
			if (
				fieldX?.editable &&
				selectedXAxisField !== undefined &&
				!isAxisLocked(fieldX?.key, fieldX?.editable)
			) {
				updateFieldValueOnMoveItems(fieldX, newX, itemIds);
			}
			if (
				fieldY?.editable &&
				selectedYAxisField !== undefined &&
				!isAxisLocked(fieldY?.key, fieldY?.editable)
			) {
				updateFieldValueOnMoveItems(fieldY, newY, itemIds);
			}
		},
		[
			fieldX,
			selectedXAxisField,
			isAxisLocked,
			fieldY,
			selectedYAxisField,
			updateFieldValueOnMoveItems,
		],
	);

	useCurrentViewSuccessExperience();

	const analyticsCallback = useCallback(
		(accessLevel: string | undefined) => {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_MATRIX_VIEW_END);
			fireCompoundAnalyticsEvent.MatrixView.opened(createAnalyticsEvent({}), accessLevel);
		},
		[createAnalyticsEvent],
	);

	useViewOpenedAnalytics({
		viewId,
		viewAccessLevel,
		isViewPermissionsEnabled,
		analyticsCallback,
		isSharedView,
	});

	const selectedIssue = useSelectedIssue();
	const [isIdeasBucketOpen] = useIsIdeasBucketOpen();
	const isCurrentViewConfigured = useCurrentViewConfigured();
	const [permissionsLoaded] = useIsLoadedPermissions();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const hasSharedViewConfigError = useHasSharedViewConfigError();
	const openXAxisOptions = useOpenRightSidebarMatrixXAxisOptions();
	const openYAxisOptions = useOpenRightSidebarMatrixYAxisOptions();
	const [sidebarShowing] = useRightSidebarShowing();
	const handleOpenXAxisOptions = useCallback(() => {
		fireCompoundAnalyticsEvent.ViewControls.openMatrixXAxisSidebarButtonClicked(
			createAnalyticsEvent({}),
			{ isEmptyView: true },
		);
		openXAxisOptions();
	}, [createAnalyticsEvent, openXAxisOptions]);
	const handleOpenYAxisOptions = useCallback(() => {
		fireCompoundAnalyticsEvent.ViewControls.openMatrixYAxisSidebarButtonClicked(
			createAnalyticsEvent({}),
			{ isEmptyView: true },
		);
		openYAxisOptions();
	}, [createAnalyticsEvent, openYAxisOptions]);
	const handleOpenAxisOptions = fieldX ? handleOpenYAxisOptions : handleOpenXAxisOptions;
	const sidebarShowingXAxisOptions = sidebarShowing.mode === RightSidebarShowingXAxisOptions;
	const sidebarShowingYAxisOptions = sidebarShowing.mode === RightSidebarShowingYAxisOptions;

	if (isSharedView && hasSharedViewConfigError) {
		return (
			<PublishedViewEmptyState
				permissionsLoaded={permissionsLoaded}
				hasProjectPermissions={!hasNoProjectPermissions}
			/>
		);
	}

	if (!isCurrentViewConfigured) {
		return (
			<MatrixViewEmptyState
				onButtonClick={canManageCurrentView ? handleOpenAxisOptions : undefined}
				isHintHidden={sidebarShowingXAxisOptions || sidebarShowingYAxisOptions}
				isXAxisActive={sidebarShowingXAxisOptions}
				isYAxisActive={sidebarShowingYAxisOptions}
			/>
		);
	}

	const isAxesDragDisabled = !canManageCurrentView || embedded;
	return (
		<ExperienceFailErrorBoundary
			experience={experience.matrixView.pageSegmentLoad}
			metadata={{ isSharedView }}
		>
			<IdeaCardContextProvider>
				<Wrapper isEmbedded={embedded}>
					<Matrix
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						xAxis={xAxis!}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						yAxis={yAxis!}
						zAxis={safeZAxis}
						items={items}
						viewId={viewId}
						highlightedItem={selectedIssue}
						components={matrixComponents}
						emptyState={emptyState}
						onSortAxisOptions={setFieldValues}
						onMoveItems={onMoveItems}
						xAxisLocked={isAxisLocked(fieldX?.key, fieldX?.editable)}
						yAxisLocked={isAxisLocked(fieldY?.key, fieldY?.editable)}
						isSidebarOpen={!isIdeasBucketOpen}
						isAxesDragDisabled={isAxesDragDisabled}
						isItemsDragDisabled={!canEditIssues}
					/>
					{canEditIssues && (
						<IdeasBucketButtonWrapper>
							<IdeasBucketButton isHighlighted={emptyState !== 'NONE'} />
						</IdeasBucketButtonWrapper>
					)}
				</Wrapper>
			</IdeaCardContextProvider>
		</ExperienceFailErrorBoundary>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isEmbedded: boolean }>({
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isEmbedded }) => (isEmbedded ? 'calc(100vh - 20px)' : 'calc(100% - 20px)'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeasBucketButtonWrapper = styled.div({
	position: 'absolute',
	right: '28px',
	bottom: '28px',
	zIndex: 10,
});

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useMatrixIdeaCount } from './utils/items';
