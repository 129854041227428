import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { N700 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useSummary } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { DISPLAYING_FIELDS_MIN_CARD_WIDTH, SUMMARY_FIELD_STYLES } from '../../constants.tsx';

type SummaryProps = {
	id: string;
	isMultiline: boolean;
	isResizing: boolean;
};

const Container = (props: ComponentPropsWithoutRef<typeof ContainerComponent>) => (
	<HeadingContainer data-testid="polaris-common.ui.idea-card-v2.header.summary.header">
		<ContainerComponent {...props} />
	</HeadingContainer>
);

export const Summary = ({ id, isMultiline, isResizing }: SummaryProps) => {
	const summary = useSummary(id);
	return (
		<Container>
			{/* eslint-disable-next-line no-nested-ternary */}
			{isEmpty(summary) ? (
				'-'
			) : isMultiline ? (
				<div>{summary}</div>
			) : (
				<Tooltip content={!isResizing && summary} position="auto-start" hideTooltipOnMouseDown>
					<TooltipContent>
						<span>{summary}</span>
					</TooltipContent>
				</Tooltip>
			)}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContent = styled.div({
	width: '100%',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`@container cardContainer (max-width: ${DISPLAYING_FIELDS_MIN_CARD_WIDTH}px)`]: {
		overflow: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingContainer = styled.div({
	display: 'inline-block',
	maxWidth: '100%',
	width: 'auto',
	verticalAlign: 'top',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerComponent = styled.div({
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		...SUMMARY_FIELD_STYLES,
		color: token('color.text', N700),
	},
});
