import React from 'react';
import sumBy from 'lodash/sumBy';
import Button from '@atlaskit/button/new';
import InfoIcon from '@atlaskit/icon/utility/information';
import { Box, Flex, xcss, Text } from '@atlaskit/primitives';
import { components } from '@atlaskit/react-select';
import type { MenuListComponentProps } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { SearchBoxComponent } from '@atlassian/jira-polaris-lib-search-box/src/ui/index.tsx';
import type { ConnectionFieldOption } from '../../types.tsx';
import { isGroup } from '../../utils.tsx';
import { MAX_OPTIONS } from '../constants.tsx';
import { useConnectionFieldSelectContext } from '../context/index.tsx';
import messages from './messages.tsx';

export const ConnectionFieldMenuList = (
	props: MenuListComponentProps<ConnectionFieldOption, true>,
) => {
	const { formatMessage } = useIntl();
	const { inputValue, onInputChange, options } = props.selectProps;
	const { onEditFieldRequested } = useConnectionFieldSelectContext();
	const canEditFields = useCanEditFields();
	const allOptionsLength = sumBy(options, (option) =>
		isGroup(option) ? option.options.length : 1,
	);

	return (
		<>
			<Box padding="space.150" xcss={searchBoxStyles}>
				<SearchBoxComponent
					value={inputValue}
					onChange={(val) =>
						onInputChange(val, {
							action: 'input-change',
							prevInputValue: inputValue,
						})
					}
					isFocused
					isCompact={false}
				/>
			</Box>

			<components.MenuList {...props}>
				{props.children}
				{allOptionsLength >= MAX_OPTIONS && (
					<Flex alignItems="center" gap="space.100" xcss={helperTextContainerStyles}>
						<Box xcss={infoIconStyles}>
							<InfoIcon color={token('color.icon')} label="" />
						</Box>
						<Text color="color.text.subtlest" size="small">
							{formatMessage(messages.limitedOptionsHelperTextNonFinal, {
								maxOptions: MAX_OPTIONS,
							})}
						</Text>
					</Flex>
				)}
			</components.MenuList>

			<Box paddingInline="space.150" paddingBlock="space.100" xcss={editButtonStyles}>
				<Button appearance="default" onClick={onEditFieldRequested}>
					{canEditFields
						? formatMessage(messages.editFieldButtonLabelNonFinal)
						: formatMessage(messages.showFieldSettingsButtonLabelNonFinal)}
				</Button>
			</Box>
		</>
	);
};

const border = `1px solid ${token('color.border')}`;

const searchBoxStyles = xcss({
	borderBottom: border,
});

const editButtonStyles = xcss({
	borderTop: border,
});

const helperTextContainerStyles = xcss({
	paddingBlockStart: 'space.025',
	paddingBlockEnd: 'space.200',
	paddingInline: 'space.150',
});

const infoIconStyles = xcss({
	paddingInlineEnd: 'space.025',
});
