import React, { useMemo, useCallback, useState, useEffect } from 'react';
import noop from 'lodash/noop';
import Heading from '@atlaskit/heading';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { Box, Pressable, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { Draggable, useDroppableEvents } from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { SortFieldComponent } from './field/index.tsx';
import { messages } from './messages.tsx';

// prevents infinite loop in useEffect, `sortFields = []` would result in the array reference changing on each render
const DEFAULT_SORT_FIELDS: SortField[] = [];

type SetSortFieldsCallback = (
	sortFields?: SortField[],
	onSuccess?: () => void,
	onError?: () => void,
) => void | Promise<void>;

type ConfigSortProps = {
	isSharedView: boolean;
	canManagePermanentSortRules: boolean;
	availableSortFields: Field[];
	permanentSortFields: SortField[] | undefined;
	temporarySortFields: SortField[] | undefined;
	setPermanentSortFields: SetSortFieldsCallback;
	setTemporarySortFields: SetSortFieldsCallback;
};

export const ConfigSort = ({
	isSharedView,
	canManagePermanentSortRules,
	availableSortFields,
	permanentSortFields = DEFAULT_SORT_FIELDS,
	temporarySortFields,
	setPermanentSortFields,
	setTemporarySortFields,
}: ConfigSortProps) => {
	const { formatMessage } = useIntl();

	const [permanentSortFieldsLocal, setPermanentSortFieldsLocal] = useState<SortField[]>([]);

	const isDisabled = !canManagePermanentSortRules;

	// Optimistically save permanentSortFieldsLocal.
	useEffect(() => {
		setPermanentSortFieldsLocal(permanentSortFields);
	}, [permanentSortFields]);

	const sortedAvailableSortFields = useMemo(
		() => availableSortFields.sort((a, b) => a.label.localeCompare(b.label)),
		[availableSortFields],
	);

	const availablePermanentSortFields = useMemo(() => {
		const usedFieldKeys = new Set(permanentSortFieldsLocal.map(({ fieldKey }) => fieldKey));
		return sortedAvailableSortFields.filter((field) => !usedFieldKeys.has(field.key));
	}, [sortedAvailableSortFields, permanentSortFieldsLocal]);

	const onSave = useCallback(
		(newSortFields: SortField[]) => {
			experience.headerView.viewSort.start();
			setPermanentSortFields(
				newSortFields,
				() => {
					experience.headerView.viewSort.success();
				},
				(error?: Error) => {
					experience.headerView.viewSort.failure(error);
				},
			);
		},
		[setPermanentSortFields],
	);

	const onReset = useCallback(() => {
		experience.headerView.viewSort.start();
		setPermanentSortFields(
			undefined,
			() => {
				experience.headerView.viewSort.success();
			},
			(error?: Error) => {
				experience.headerView.viewSort.failure(error);
			},
		);
	}, [setPermanentSortFields]);

	const onAddNewField = useCallback(
		(field: Field, asc = true) => {
			const newFields = [...permanentSortFieldsLocal, { fieldKey: field.key, asc }];
			onSave(newFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortFieldAdded();
		},
		[onSave, permanentSortFieldsLocal],
	);

	const onToggleDirection = useCallback(
		(fieldKey: FieldKey) => {
			const newFields = permanentSortFieldsLocal.map((sortField) => {
				if (fieldKey === sortField.fieldKey) {
					return {
						...sortField,
						asc: !sortField.asc,
					};
				}

				return sortField;
			});
			// Optimistic save
			setPermanentSortFieldsLocal(newFields);
			onSave(newFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortDirectionChanged();
		},
		[onSave, permanentSortFieldsLocal],
	);

	const onChangeField = useCallback(
		(field: Field, fieldKey: FieldKey) => {
			const newFields = permanentSortFieldsLocal.map((sortField) => {
				if (fieldKey === sortField.fieldKey) {
					return {
						fieldKey: field.key,
						asc: sortField.asc,
					};
				}
				return sortField;
			});
			// Optimistic save
			setPermanentSortFieldsLocal(newFields);
			onSave(newFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortFieldsChanged();
		},
		[onSave, permanentSortFieldsLocal],
	);

	const onClearSort = useCallback(
		(fieldKey: FieldKey) => {
			const newFields = permanentSortFieldsLocal.filter(({ fieldKey: key }) => key !== fieldKey);
			// Clear view sort if no sort fields left update otherwise
			if (newFields.length) {
				onSave(newFields);
				fireCompoundAnalyticsEvent.RightSidebarViewSortFieldRemoved();
			} else {
				onReset();
				fireCompoundAnalyticsEvent.RightSidebarViewSortReset();
			}
		},
		[onReset, onSave, permanentSortFieldsLocal],
	);

	const handleSort = useCallback(
		({ srcIdx, dstIdx }: { srcIdx: number; dstIdx: number }) => {
			// outside the sortFieldsLocal list
			if (dstIdx < 0 || dstIdx >= permanentSortFieldsLocal.length + 1) {
				return;
			}

			const newSortFields = [...permanentSortFieldsLocal];
			const [removed] = newSortFields.splice(srcIdx, 1);
			newSortFields.splice(dstIdx, 0, removed);

			// Optimistic save
			setPermanentSortFieldsLocal(newSortFields);
			// Save to the server
			onSave(newSortFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortFieldsReordered();
		},
		[onSave, permanentSortFieldsLocal],
	);

	const onSort = useCallback(
		({ srcId, dstId, edge }: { srcId: string; dstId: string; edge: Edge }) => {
			const srcIdx = permanentSortFieldsLocal.findIndex(({ fieldKey }) => fieldKey === srcId);
			const dstIdx = permanentSortFieldsLocal.findIndex(({ fieldKey }) => fieldKey === dstId);

			let dstAdj = srcIdx < dstIdx && edge === 'top' ? -1 : 0;
			dstAdj = srcIdx > dstIdx && edge === 'bottom' ? 1 : dstAdj;

			handleSort({ srcIdx, dstIdx: dstIdx + dstAdj });
		},
		[handleSort, permanentSortFieldsLocal],
	);

	useDroppableEvents({
		onSort,
	});

	return (
		<Stack alignBlock="start" space="space.200" xcss={containerStyles}>
			<Stack space="space.150">
				<Box paddingInline="space.200">
					{isDisabled && !isSharedView ? (
						<Box xcss={sectionStyles}>{formatMessage(messages.sortDisabledText)}</Box>
					) : (
						formatMessage(messages.helpText)
					)}
				</Box>
				{(!!permanentSortFieldsLocal.length ||
					(!isDisabled && !!availablePermanentSortFields.length)) && (
					<Box>
						{permanentSortFieldsLocal.map(({ asc, fieldKey }, idx) => {
							const selectedField = sortedAvailableSortFields.find(({ key }) => fieldKey === key);
							const labelMessage = idx ? messages.sortThenLabel : messages.sortFirstLabel;

							return (
								<Draggable key={fieldKey} id={fieldKey} isDragDisabled={isDisabled}>
									<SortFieldComponent
										key={fieldKey}
										selectedField={selectedField}
										asc={asc}
										fields={availablePermanentSortFields}
										label={formatMessage(labelMessage)}
										onClearSort={() => onClearSort(fieldKey)}
										onChangeField={(newField) => onChangeField(newField, fieldKey)}
										onClickDirection={() => onToggleDirection(fieldKey)}
										isDisabled={isDisabled}
										isHoverable={!isDisabled}
									/>
								</Draggable>
							);
						})}
						{!isDisabled && !!availablePermanentSortFields.length && (
							<SortFieldComponent
								fields={availablePermanentSortFields}
								asc
								label={formatMessage(
									permanentSortFieldsLocal.length
										? messages.sortThenLabel
										: messages.sortFirstLabel,
								)}
								onChangeField={(newField) => onAddNewField(newField)}
								onClickDirection={noop}
								isHoverable={false}
							/>
						)}
						{!isDisabled && !!permanentSortFieldsLocal.length && (
							<Box paddingBlock="space.050" paddingInline="space.200">
								<Pressable
									testId="polaris-component-view-sort-configuration.ui.config-sort.reset-sort-button"
									id="polaris-ideas.ui.view-controls.config-sort.reset-sort-button"
									onClick={onReset}
									xcss={resetButtonStyles}
								>
									{formatMessage(messages.resetButton)}
								</Pressable>
							</Box>
						)}
					</Box>
				)}
			</Stack>
			{fg('polaris_just-for-you') && (
				<>
					{!isSharedView && <Box xcss={dividerStyles} />}
					<TemporarySortRules
						availablePermanentSortFields={availablePermanentSortFields}
						allAvailableSortFields={availableSortFields}
						isSharedView={isSharedView}
						temporarySortFields={temporarySortFields}
						setTemporarySortFields={setTemporarySortFields}
					/>
				</>
			)}
		</Stack>
	);
};

type TemporarySortRulesProps = {
	availablePermanentSortFields: Field[];
	allAvailableSortFields: Field[];
	temporarySortFields: SortField[] | undefined;
	isSharedView: boolean;
	setTemporarySortFields: SetSortFieldsCallback;
};

const TemporarySortRules = ({
	availablePermanentSortFields,
	allAvailableSortFields,
	temporarySortFields = DEFAULT_SORT_FIELDS,
	isSharedView,
	setTemporarySortFields,
}: TemporarySortRulesProps) => {
	const { formatMessage } = useIntl();

	const [temporarySortFieldsLocal, setTemporarySortFieldsLocal] = useState<SortField[]>([]);

	const availableTemporarySortFields = useMemo(() => {
		const usedFieldKeys = new Set(temporarySortFieldsLocal.map(({ fieldKey }) => fieldKey));
		return availablePermanentSortFields.filter((field) => !usedFieldKeys.has(field.key));
	}, [availablePermanentSortFields, temporarySortFieldsLocal]);

	// Optimistically save temporarySortFieldsLocal.
	useEffect(() => {
		setTemporarySortFieldsLocal(temporarySortFields);
	}, [temporarySortFields]);

	const onSave = useCallback(
		(newSortFields: SortField[]) => {
			setTemporarySortFields(newSortFields);
		},
		[setTemporarySortFields],
	);

	const onAddNewField = useCallback(
		(field: Field, asc = true) => {
			const newFields = [
				...temporarySortFieldsLocal,
				{ fieldKey: field.key, asc, isTemporary: true },
			];
			onSave(newFields);
		},
		[onSave, temporarySortFieldsLocal],
	);

	const onReset = useCallback(() => {
		setTemporarySortFields(undefined);
	}, [setTemporarySortFields]);

	const onClearSort = useCallback(
		(fieldKey: FieldKey) => {
			const newFields = temporarySortFieldsLocal.filter(({ fieldKey: key }) => key !== fieldKey);

			if (newFields.length) {
				onSave(newFields);
			} else {
				onReset();
			}
		},
		[onSave, onReset, temporarySortFieldsLocal],
	);

	const onChangeField = useCallback(
		(field: Field, fieldKey: FieldKey) => {
			const newFields = temporarySortFieldsLocal.map((sortField) => {
				if (fieldKey === sortField.fieldKey) {
					return {
						fieldKey: field.key,
						asc: sortField.asc,
						isTemporary: true,
					};
				}
				return sortField;
			});

			// Optimistic update
			setTemporarySortFieldsLocal(newFields);
			onSave(newFields);
		},
		[onSave, temporarySortFieldsLocal],
	);

	const onToggleDirection = useCallback(
		(fieldKey: FieldKey) => {
			const newFields = temporarySortFieldsLocal.map((sortField) => {
				if (fieldKey === sortField.fieldKey) {
					return {
						...sortField,
						asc: !sortField.asc,
					};
				}

				return sortField;
			});

			// Optimistic update
			setTemporarySortFieldsLocal(newFields);
			onSave(newFields);
		},
		[onSave, temporarySortFieldsLocal],
	);

	const handleSort = useCallback(
		({ srcIdx, dstIdx }: { srcIdx: number; dstIdx: number }) => {
			// outside the sortFieldsLocal list
			if (dstIdx < 0 || dstIdx >= temporarySortFieldsLocal.length + 1) {
				return;
			}

			const newSortFields = [...temporarySortFieldsLocal];
			const [removed] = newSortFields.splice(srcIdx, 1);
			newSortFields.splice(dstIdx, 0, removed);

			// Optimistic update
			setTemporarySortFieldsLocal(newSortFields);

			onSave(newSortFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortFieldsReordered();
		},
		[onSave, temporarySortFieldsLocal],
	);

	const onSort = useCallback(
		({ srcId, dstId, edge }: { srcId: string; dstId: string; edge: Edge }) => {
			const srcIdx = temporarySortFieldsLocal.findIndex(({ fieldKey }) => fieldKey === srcId);
			const dstIdx = temporarySortFieldsLocal.findIndex(({ fieldKey }) => fieldKey === dstId);

			let dstAdj = srcIdx < dstIdx && edge === 'top' ? -1 : 0;
			dstAdj = srcIdx > dstIdx && edge === 'bottom' ? 1 : dstAdj;

			handleSort({ srcIdx, dstIdx: dstIdx + dstAdj });
		},
		[handleSort, temporarySortFieldsLocal],
	);

	useDroppableEvents({
		onSort,
	});

	return (
		<Box>
			<Stack space="space.100">
				{!isSharedView && (
					<>
						<Box paddingInline="space.200">
							<Heading size="xsmall">{formatMessage(messages.justForYouHeading)}</Heading>
						</Box>
						<Box paddingInline="space.200" paddingBlockEnd="space.050">
							<Text size="small" color="color.text.subtlest">
								{formatMessage(messages.justForYouDescription)}
							</Text>
						</Box>
					</>
				)}
				<Box>
					{temporarySortFieldsLocal.map(({ asc, fieldKey }, idx) => {
						const selectedField = allAvailableSortFields.find(({ key }) => fieldKey === key);
						const labelMessage = idx ? messages.sortThenLabel : messages.sortFirstLabel;

						return (
							<Draggable key={fieldKey} id={fieldKey} isDragDisabled={false}>
								<SortFieldComponent
									key={fieldKey}
									selectedField={selectedField}
									asc={asc}
									fields={availableTemporarySortFields}
									label={formatMessage(labelMessage)}
									onClearSort={() => onClearSort(fieldKey)}
									onChangeField={(newField) => onChangeField(newField, fieldKey)}
									onClickDirection={() => onToggleDirection(fieldKey)}
									isUsingCustomColor={!isSharedView}
								/>
							</Draggable>
						);
					})}
					{!!availableTemporarySortFields.length && (
						<SortFieldComponent
							fields={availableTemporarySortFields}
							asc
							label={formatMessage(
								temporarySortFieldsLocal.length ? messages.sortThenLabel : messages.sortFirstLabel,
							)}
							onChangeField={(newField) => onAddNewField(newField)}
							onClickDirection={noop}
							isHoverable={false}
						/>
					)}
				</Box>
			</Stack>
			{!!temporarySortFieldsLocal.length && (
				<Box paddingBlock="space.050" paddingInline="space.200">
					<Pressable
						id="polaris-ideas.ui.view-controls.config-sort.reset-sort-button"
						onClick={onReset}
						xcss={resetButtonStyles}
					>
						{formatMessage(messages.resetButton)}
					</Pressable>
				</Box>
			)}
		</Box>
	);
};

const containerStyles = xcss({
	height: '100%',
	paddingBlock: 'space.200',
});

const resetButtonStyles = xcss({
	padding: '0',
	background: 'none',
	lineHeight: '2em',
	':hover': {
		textDecoration: 'underline',
	},
});

const sectionStyles = xcss({
	padding: 'space.150',
	backgroundColor: 'color.background.information',
});

const dividerStyles = xcss({
	height: '1px',
	marginBlock: 'space.100',
	marginInline: 'space.200',
	background: token('color.border'),
	flexShrink: 0,
});
