import React, { useState, useCallback, useMemo } from 'react';
import throttle from 'lodash/throttle';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { WidthObserver } from '@atlaskit/width-detector';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { IssueCreateGroupTypeNoGroup } from '@atlassian/jira-polaris-common/src/controllers/issue/types.tsx';
import {
	useOpenRightSidebarOnField,
	useOpenRightSidebarOnFilter,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useAllowedFiltersFields,
	useActivePermanentFiltersFields,
	useActiveTemporaryFiltersFields,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewSortBy,
	useCurrentViewSortByField,
	useCurrentViewVerticalGroupBy,
	useIsCurrentViewInCaptureSet,
	useIsExporting,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import { VIEW_KIND_BOARD } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { callWhenIdle } from '@atlassian/jira-polaris-lib-idle-callback/src/index.tsx';
import { PRESENTATIONAL_FIELDKEYS } from '@atlassian/jira-polaris-lib-list/src/constants.tsx';
import {
	ContextualAnalyticsData,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useIsRankingAllowedDuringCreation } from '../../../../utils.tsx';
import { useCanCreateIdeasWhenNoGrouping } from '../../utils/can-create-ideas.tsx';
import { CellDropdownMenu } from './cell-dropdown-menu/index.tsx';
import { ColumnAdd } from './column-add/index.tsx';
import { MIN_VISIBLE_LABEL_WIDTH } from './constants.tsx';
import { SummaryColumnAddIdea } from './summary-column-add-idea/index.tsx';
import { HeaderTrigger } from './trigger/index.tsx';
import type { HeaderCellProps, HeaderCellSummaryProps } from './types.tsx';

export const HeaderCell = ({ children, fieldKey }: HeaderCellProps) => {
	const fieldType = useFieldType(fieldKey);
	const canEditFields = useCanEditFields();
	const canManageCurrentView = useCanManageCurrentView();
	const sortField = useCurrentViewSortByField(fieldKey);
	const sortFields = useCurrentViewSortBy();
	const openRightSidebarOnField = useOpenRightSidebarOnField();
	const openRightSidebarOnFilter = useOpenRightSidebarOnFilter();
	const allowedFiltersFields = useAllowedFiltersFields();
	const activePermanentFiltersFields = useActivePermanentFiltersFields();
	const activeTemporaryFiltersFields = useActiveTemporaryFiltersFields();
	const isCaptureSetView = useIsCurrentViewInCaptureSet();
	const isFieldRemovable = fieldKey !== SUMMARY_FIELDKEY;
	const isSharedView = useIsSharedView();

	const {
		clearFieldOrNumericFilter,
		setTemporarySortFieldsOfCurrentView,
		setPermanentSortFieldsOfCurrentView,
		cloneCurrentView,
		removeFieldFromView,
	} = useViewActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [width, setWidth] = useState<number>(MIN_VISIBLE_LABEL_WIDTH);
	const throttledSetWidth = throttle(setWidth, 50);
	const isLabelHidden = width < MIN_VISIBLE_LABEL_WIDTH;

	const canCloneAsBoard = !isCaptureSetView;

	const [isDropdownOpen, setDropdownOpen] = useState(false);

	const safelyCloseDropdown = useCallback(() => {
		// opening the side panel and closing the Header's dropdown menu at the same time sometimes causes weird layout shifts.
		// to avoid that we schedule the closing of the dropdown menu to the next iteration of the event loop
		callWhenIdle(() => setDropdownOpen(false), 50);
	}, []);

	const onToggleDropdown = useCallback(() => {
		setDropdownOpen(!isDropdownOpen);

		fireUIAnalytics(
			createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
			'viewFieldActions',
		);
	}, [setDropdownOpen, isDropdownOpen, createAnalyticsEvent]);

	const onManageField = useCallback(() => {
		if (canEditFields) {
			fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
				selectedActionItem: 'editField',
			});
		} else {
			fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
				selectedActionItem: 'viewFieldSettings',
			});
		}
		openRightSidebarOnField(fieldKey);
		safelyCloseDropdown();
	}, [canEditFields, openRightSidebarOnField, fieldKey, safelyCloseDropdown, createAnalyticsEvent]);

	const permanentSortFieldsWithoutThisField = useMemo(
		() => sortFields?.filter((sf: SortField) => sf.fieldKey !== fieldKey && !sf.isTemporary) || [],
		[fieldKey, sortFields],
	);

	const temporarySortFieldsWithoutThisField = useMemo(
		() => sortFields?.filter((sf: SortField) => sf.fieldKey !== fieldKey && sf.isTemporary) || [],
		[fieldKey, sortFields],
	);

	const onSetAsSort = useCallback(
		(asc: boolean) => {
			fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
				selectedActionItem: `setFieldSort ${asc ? 'ASC' : 'DESC'}`,
			});
			setDropdownOpen(false);

			if ((sortField?.isTemporary || !canManageCurrentView) && fg('polaris_just-for-you')) {
				setTemporarySortFieldsOfCurrentView([{ fieldKey, asc, isTemporary: true }]);
			} else {
				setPermanentSortFieldsOfCurrentView([{ fieldKey, asc }]);
			}
		},
		[
			createAnalyticsEvent,
			fieldKey,
			sortField?.isTemporary,
			canManageCurrentView,
			setTemporarySortFieldsOfCurrentView,
			setPermanentSortFieldsOfCurrentView,
		],
	);

	const onRemoveSort = useCallback(() => {
		fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
			selectedActionItem: 'removeFieldSort',
		});

		setDropdownOpen(false);
		if (sortField?.isTemporary) {
			setTemporarySortFieldsOfCurrentView(
				temporarySortFieldsWithoutThisField.length
					? temporarySortFieldsWithoutThisField
					: undefined,
			);
		} else {
			setPermanentSortFieldsOfCurrentView(
				permanentSortFieldsWithoutThisField.length
					? permanentSortFieldsWithoutThisField
					: undefined,
			);
		}
	}, [
		createAnalyticsEvent,
		sortField?.isTemporary,
		setTemporarySortFieldsOfCurrentView,
		temporarySortFieldsWithoutThisField,
		permanentSortFieldsWithoutThisField,
		setPermanentSortFieldsOfCurrentView,
	]);

	const onRemoveField = useCallback(() => {
		fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
			selectedActionItem: 'removeField',
		});

		setDropdownOpen(false);
		removeFieldFromView(fieldKey);
	}, [createAnalyticsEvent, removeFieldFromView, fieldKey]);

	const onViewAsBoard = useCallback(() => {
		if (canCloneAsBoard) {
			fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
				selectedActionItem: 'cloneAsBoard',
			});

			setDropdownOpen(false);
			cloneCurrentView(VIEW_KIND_BOARD, fieldKey);
		}
	}, [canCloneAsBoard, cloneCurrentView, createAnalyticsEvent, fieldKey]);

	const onFilter = useCallback(() => {
		fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
			selectedActionItem: 'filter',
		});

		openRightSidebarOnFilter(fieldKey);
		safelyCloseDropdown();
	}, [createAnalyticsEvent, fieldKey, openRightSidebarOnFilter, safelyCloseDropdown]);

	const onClearFilter = useCallback(() => {
		fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
			selectedActionItem: 'clearFilter',
		});

		clearFieldOrNumericFilter(fieldKey);
		clearFieldOrNumericFilter(fieldKey, true);
	}, [clearFieldOrNumericFilter, createAnalyticsEvent, fieldKey]);

	const shouldFilterBeVisible = useMemo(
		() => allowedFiltersFields.some((field) => field.key === fieldKey),
		[allowedFiltersFields, fieldKey],
	);

	const shouldClearFilterBeVisible = useMemo(() => {
		if (fg('polaris_just-for-you')) {
			if (canManageCurrentView) {
				return (
					activePermanentFiltersFields.some((field) => field.key === fieldKey) ||
					activeTemporaryFiltersFields.some((field) => field.key === fieldKey)
				);
			}

			return activeTemporaryFiltersFields.some((field) => field.key === fieldKey);
		}

		return activePermanentFiltersFields.some((field) => field.key === fieldKey);
	}, [activePermanentFiltersFields, activeTemporaryFiltersFields, canManageCurrentView, fieldKey]);

	if (fieldKey === PRESENTATIONAL_FIELDKEYS.ADD) {
		return <ColumnAdd isDisabled={!canManageCurrentView} />;
	}

	const mainContent = (
		<CellDropdownMenu
			trigger={({ triggerRef, ...triggerProps }) => (
				<HeaderTrigger
					{...triggerProps}
					ref={triggerRef}
					onClick={onToggleDropdown}
					isLabelHidden={isLabelHidden}
					fieldKey={fieldKey}
					disabled={false}
					disableTooltip={isDropdownOpen}
				/>
			)}
			canCloneAsBoard={canCloneAsBoard}
			canEditFields={canEditFields}
			canManageCurrentView={canManageCurrentView}
			fieldType={fieldType}
			isClearFilterVisible={shouldClearFilterBeVisible}
			isFieldRemovable={isFieldRemovable}
			isFilterVisible={shouldFilterBeVisible}
			isOpen={isDropdownOpen}
			isSharedView={isSharedView}
			sortField={sortField}
			onClearFilter={onClearFilter}
			onFilter={onFilter}
			onManageField={onManageField}
			onRemoveField={onRemoveField}
			onRemoveSort={onRemoveSort}
			onSetAsSort={onSetAsSort}
			onViewAsBoard={onViewAsBoard}
			setIsOpen={setDropdownOpen}
		/>
	);

	return (
		<ContextualAnalyticsData attributes={{ fieldType }}>
			<Inline grow="fill" space="space.050" alignBlock="center">
				<Box xcss={mainContentStyles}>
					<WidthObserver setWidth={throttledSetWidth} />
					{mainContent}
				</Box>
				{children ? <Inline xcss={extraContentStyles}>{children}</Inline> : null}
			</Inline>
		</ContextualAnalyticsData>
	);
};

// This is intentionally a separate component to avoid
// calling multiple times hooks used below for each column / HeaderCell
// as customers may define "infinite" number of columns for their List View
export const HeaderCellSummary = ({ fieldKey }: HeaderCellSummaryProps) => {
	const isExporting = useIsExporting();
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	const canCreateIdeasWhenNoGrouping = useCanCreateIdeasWhenNoGrouping(isExporting);
	const isRankingAllowedDuringCreation = useIsRankingAllowedDuringCreation();
	const { createIssue } = useIssueActions();

	const handleSummaryAddIdeaClick = useCallback(() => {
		if (fg('polaris-create-inline-ideas-experience-tracking')) {
			experience.listView.createIdeaInline.start();
			experience.listView.createIdeaInlineClickResponse.start();
		}

		createIssue(0, undefined, {
			groupType: IssueCreateGroupTypeNoGroup,
			rankingAllowed: isRankingAllowedDuringCreation,
		});
	}, [createIssue, isRankingAllowedDuringCreation]);

	return (
		<HeaderCell fieldKey={fieldKey}>
			{!verticalGroupByField && canCreateIdeasWhenNoGrouping && (
				<SummaryColumnAddIdea onClick={handleSummaryAddIdeaClick} />
			)}
		</HeaderCell>
	);
};

const mainContentStyles = xcss({
	flex: 1,
});

const extraContentStyles = xcss({
	flexShrink: 0,
});
