import React, {
	useState,
	useCallback,
	useEffect,
	memo,
	type MouseEvent,
	type KeyboardEvent,
} from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import Popup from '@atlaskit/popup';
import { xcss, Box } from '@atlaskit/primitives';
import { type CustomItemProps, LinkItem } from '@atlaskit/side-navigation';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from '@atlassian/jira-polaris-common/src/common/ui/field/text-field/messages.tsx';
import type { JpdTreeItem } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/sections-tree.tsx';
import { useViewSetName } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { ItemId } from '@atlassian/jira-polaris-lib-tree/src/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { DeleteModal } from '../common/delete-modal/index.tsx';
import { EditableTitle } from '../common/editable-title/index.tsx';
import { Actions } from './actions/index.tsx';
import { GetIcon } from './icon/index.tsx';

type Props = {
	isDraggingPreview: boolean;
	isSorting: boolean;
	isReadOnly: boolean;
	cssFn?: CustomItemProps['cssFn'];
	item: JpdTreeItem;
	section: string;
	onCollapseSection: (id: ItemId) => void;
	onExpandSection: (id: ItemId) => void;
	onToggleSection?: (e: MouseEvent<Element> | KeyboardEvent<Element>) => void;
	onDelete?: () => void;
	onRename?: (arg1: string) => void;
};

const ViewSectionInternal = (props: Props) => {
	const {
		cssFn,
		section,
		onDelete,
		onRename,
		isDraggingPreview,
		isReadOnly,
		isSorting,
		item,
		onCollapseSection,
		onExpandSection,
		onToggleSection,
	} = props;

	const [isRenaming, setIsRenaming] = useState(false);
	const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const viewSetName = useViewSetName(section);
	const isNew = viewSetName === '';

	useEffect(() => {
		if (isSorting) {
			setOpen(false);
			setIsRenaming(false);
		}
	}, [isSorting]);

	const onTriggerRename = useCallback(() => {
		setOpen(false);
		setIsRenaming(true);
	}, []);

	const onCancelRename = useCallback(
		(value: string | undefined) => {
			// Remove section if user didn't enter any value
			if (isNew && value === '') {
				onDelete && onDelete();
			}
			setIsRenaming(false);
		},
		[isNew, onDelete],
	);

	const onConfirmRename = useCallback(
		(value: string) => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'pressed', actionSubject: 'keyboardShortcut' }),
				'confirmViewSectionName',
			);
			setIsRenaming(false);
			onRename && onRename(value || formatMessage(messages.defaultSectionTitle));
		},
		[formatMessage, onRename, createAnalyticsEvent],
	);

	const onTriggerDelete = useCallback(() => {
		setOpen(false);
		setIsConfirmDeleteOpen(true);
	}, []);

	const onConfirmDelete = useCallback(() => {
		setIsConfirmDeleteOpen(false);
		onDelete && onDelete();
	}, [onDelete]);

	const onCancelDelete = useCallback(() => {
		setIsConfirmDeleteOpen(false);
	}, []);

	return (
		<>
			<Box
				data-component-selector="link-item-wrapper-s25K"
				xcss={[isDraggingPreview && draggingContanerStyles]}
			>
				<LinkItem
					testId="polaris-ideas.ui.sidebar.sections.view-section.link-item"
					// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
					cssFn={cssFn}
					onClick={onToggleSection}
					iconBefore={
						<>
							<DragHandle
								isVisibleOnHover={!isReadOnly}
								// @ts-expect-error - TS2322 - Type '{ children: Element; isVisibleOnHover: boolean; label: string; type: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLSpanElement> & HTMLAttributes<HTMLSpanElement> & { ...; }, any>, any, any>> & Readonly<...> & Readonly<...>'.
								label="view-dragHandle"
								type="drag-handle"
							>
								<DragHandlerIcon label="" />
							</DragHandle>
							<GetIcon
								item={item}
								onExpandSection={onExpandSection}
								onCollapseSection={onCollapseSection}
							/>
						</>
					}
					iconAfter={
						<Popup
							placement="auto-start"
							isOpen={isOpen}
							onClose={() => setOpen(false)}
							content={() => (
								<Actions onRename={onTriggerRename} onDelete={onDelete && onTriggerDelete} />
							)}
							trigger={(triggerProps) =>
								!isReadOnly &&
								!isRenaming &&
								!isSorting && (
									<MoreButton isVisible={isOpen}>
										<Button
											{...triggerProps}
											isSelected={isOpen}
											onClick={() => setOpen(!isOpen)}
											appearance="subtle"
											iconAfter={<MoreVerticalIcon size="small" label="sidebar.ideas.more" />}
										/>
									</MoreButton>
								)
							}
						/>
					}
					// @ts-expect-error - label prop does not exist on button
					label="sidebar.ideas.switch-view"
				>
					<ItemWrapper>
						{viewSetName !== undefined && (
							<EditableTitle
								isRenaming={isNew || isRenaming}
								title={viewSetName}
								onChanged={onConfirmRename}
								onCancel={onCancelRename}
							/>
						)}
					</ItemWrapper>
				</LinkItem>
			</Box>
			{viewSetName !== undefined && (
				<DeleteModal
					type="viewSection"
					isOpen={isConfirmDeleteOpen}
					title={viewSetName}
					onCancel={onCancelDelete}
					onConfirm={onConfirmDelete}
				/>
			)}
		</>
	);
};

export const ViewSectionItem = memo<Props>(ViewSectionInternal);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DragHandle = styled.span<{ isVisibleOnHover?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isVisibleOnHover }) => (isVisibleOnHover ? 'hidden' : 'hidden!important'),
	cursor: 'grab',
	width: '18px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="link-item-wrapper-s25K"]:hover &': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		visibility: ({ isVisibleOnHover }) => (isVisibleOnHover ? 'visible' : 'hidden'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		lineHeight: 'unset',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MoreButton = styled.div<{ isVisible: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isVisible }) => (!isVisible ? 'hidden' : ''),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		height: '23px',
		lineHeight: '23px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('color.background.neutral.subtle.hovered', colors.N20),
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="link-item-wrapper-s25K"]:hover &': {
		visibility: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemWrapper = styled.div({
	whiteSpace: 'normal',
	display: 'flex',
	alignItems: 'center',
});

const draggingContanerStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle.hovered',
});
