import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	RightSidebarShowingField,
	showingCreateField,
	showingField,
	showingFieldList,
	showingFilter,
	showingGlobalFields,
	showingGroupByOptions,
	showingIdeaTemplates,
	showingIdeasBucket,
	showingNothing,
	showingPlay,
	showingSort,
	showingTimelineConfig,
	showingVerticalGroupByOptions,
	showingViewInfo,
	showingXAxisOptions,
	showingYAxisOptions,
	showingZAxisOptions,
	type PreventClosing,
	type RightSidebarOpeningSource,
	type Showing,
	type SidebarOrigin,
	type State,
} from '../types.tsx';

export const actions = {
	preventClosing:
		(preventClosing: PreventClosing) =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				preventClosing,
			});
		},
	close:
		() =>
		({ getState, setState }: StoreActionApi<State>) => {
			if (getState().preventClosing === 'all') {
				return;
			}
			setState({
				showing: showingNothing,
				preventClosing: false,
			});
		},
	openOnFieldList:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingFieldList,
				preventClosing: false,
			});
		},
	openOnField:
		(field: FieldKey, backTo?: Showing, origin?: SidebarOrigin) =>
		({ setState }: StoreActionApi<State>) => {
			const showing = showingField(field, backTo, origin);
			const preventClosing =
				showing.mode === RightSidebarShowingField && showing.origin === 'fieldsList'
					? 'onEsc'
					: false;
			setState({
				showing,
				option: null,
				preventClosing,
			});
		},
	openOnGlobalFields:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingGlobalFields,
			});
		},
	openOnCreate:
		(
			backTo?: Showing | RightSidebarOpeningSource,
			defaultFieldName?: string,
			onCreate?: (fieldKey: FieldKey) => void,
		) =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingCreateField('fieldsList', backTo, defaultFieldName, onCreate),
			});
		},
	openOnCreateForSettings:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingCreateField('projectFieldsPage'),
			});
		},
	openOnOption:
		(optionId: string | null, field?: FieldKey) =>
		({ setState }: StoreActionApi<State>) => {
			if (field === undefined) {
				// we're just changing the option within the existing showing
				setState({ option: optionId });
			} else {
				// we're changing both to showing a field *and* setting the option at the same time
				setState({
					showing: showingField(field),
					option: optionId,
				});
			}
		},
	openOnFilter:
		(tmpFieldKey?: FieldKey) =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingFilter(tmpFieldKey),
			});
		},
	onFilterRemoveTmpFieldKey:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingFilter(),
			});
		},
	openOnPlay:
		(localIssueId: LocalIssueId, playId: Ari) =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingPlay(localIssueId, playId),
			});
		},
	openOnSort:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingSort,
			});
		},
	openGroupByOptions:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingGroupByOptions,
			});
		},
	openVerticalGroupByOptions:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingVerticalGroupByOptions,
			});
		},
	openXAxisOptions:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingXAxisOptions,
			});
		},
	openYAxisOptions:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingYAxisOptions,
			});
		},
	openZAxisOptions:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingZAxisOptions,
			});
		},

	openOnIdeaTemplates:
		(onApplyTemplate: (template: ADF | undefined) => void) =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingIdeaTemplates(onApplyTemplate),
			});
		},
	openOnIdeasBucket:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingIdeasBucket,
			});
		},

	openOnTimelineConfig:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingTimelineConfig,
			});
		},

	openOnViewInfo:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				showing: showingViewInfo,
			});
		},
} as const;

export type Actions = typeof actions;
