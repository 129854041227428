import React, { useCallback, useEffect, useRef, useState, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button';
import ButtonNew from '@atlaskit/button/new';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	getPermalinkStatus,
	PermalinkType,
} from '@atlassian/jira-polaris-common/src/common/utils/permalink/index.tsx';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIsSharedViewUnavailable } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import {
	useCurrentViewSharingSettings,
	useIsCurrentViewSharingSettingsInitialLoading,
} from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewCommentCount,
	useCurrentViewCommentsLoaded,
	useCurrentViewUnseenCommentAvailable,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/comments-hooks.tsx';
import { useIsViewsLoading } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/meta-hooks.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewAri,
	useHasUnsavedChanges,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { PolarisNavigationBlocker } from '@atlassian/jira-polaris-common/src/ui/navigation-blocker/index.tsx';
import {
	useProjectIdUnsafe,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { IssuelessAdfController } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	DRAWER,
	FireScreenAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { shouldPreventClosing } from '../../click-focus-checker/index.tsx';
import { ViewCommentStream } from '../../comments/index.tsx';
import messages from './messages.tsx';

const withViewCommentsPanelAnalyticsContext =
	<P extends object>(Component: React.ComponentType<P>) =>
	(props: P) => (
		<ContextualAnalyticsData sourceName="ideaViewComments" sourceType={DRAWER}>
			<Component {...props} />
		</ContextualAnalyticsData>
	);

const useIsViewCommentHighlightingApplicable = () => {
	const isSharedView = useIsSharedView();
	const canManageCurrentView = useCanManageCurrentView();

	return canManageCurrentView && !isSharedView;
};

export const CommentsButton = withViewCommentsPanelAnalyticsContext(() => {
	const hasUnsavedChanges = useHasUnsavedChanges();
	const { setUnsavedChanges } = useViewActions();
	const { hasPermalink, permalinkId } = getPermalinkStatus(
		PermalinkType.VIEW_COMMENTS,

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.search,
	);
	const [commentsPopupOpen, setCommentsPopupOpen] = useState<boolean>(false);
	const [showPrompt, setShowPrompt] = useState<boolean>(false);

	const { formatMessage } = useIntl();
	const hasUnseenComment = useCurrentViewUnseenCommentAvailable();
	const commentCount = useCurrentViewCommentCount();
	const { setQuery } = usePolarisRouter();
	const isSharedView = useIsSharedView();
	const { seenViewComments } = useViewActions();
	const viewAri = useCurrentViewAri();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const isCurrentViewCommentsLoaded = useCurrentViewCommentsLoaded();

	const sharingSettings = useCurrentViewSharingSettings();
	const isSharedViewUnavailable = useIsSharedViewUnavailable();
	const isViewsLoading = useIsViewsLoading();
	const isCurrentViewSharingInitialSettingsLoading =
		useIsCurrentViewSharingSettingsInitialLoading();
	const isViewCommentHighlightingApplicable = useIsViewCommentHighlightingApplicable();

	useEffect(() => {
		if (hasPermalink && permalinkId && isCurrentViewCommentsLoaded) {
			setCommentsPopupOpen(true);
		}
	}, [hasPermalink, isCurrentViewCommentsLoaded, permalinkId]);

	const resetPopupState = useCallback(() => {
		setCommentsPopupOpen(false);

		setUnsavedChanges(false);
		setShowPrompt(false);
	}, [setCommentsPopupOpen, setUnsavedChanges]);

	const closePopup = useCallback(
		(event?: Event | SyntheticEvent) => {
			if (shouldPreventClosing(event?.target)) {
				return;
			}

			// click outside of popup
			if (event instanceof PointerEvent) {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'clicked',
						actionSubject: 'screen',
					}),
					'close',
				);
			}

			if (!showPrompt && hasUnsavedChanges && ff('polaris.unsaved-changes-prompt-active')) {
				event?.stopPropagation?.();
				event?.preventDefault?.();
			}

			if (!hasUnsavedChanges) {
				resetPopupState();
				return;
			}

			setShowPrompt(true);

			if (hasPermalink) {
				setQuery({ [PermalinkType.VIEW_COMMENTS]: undefined }, 'replace');
			}
		},
		[hasPermalink, resetPopupState, setQuery, hasUnsavedChanges, showPrompt, createAnalyticsEvent],
	);

	const onHandleTogglePopup = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'comments', {
				isActive: !commentsPopupOpen,
			});

			if (commentsPopupOpen) {
				closePopup();
				return;
			}

			setCommentsPopupOpen(true);
		},
		[commentsPopupOpen, closePopup],
	);

	const prevCountRef = useRef(false);
	useEffect(() => {
		if (
			!prevCountRef.current &&
			commentsPopupOpen &&
			viewAri &&
			isViewCommentHighlightingApplicable
		) {
			seenViewComments(viewAri);
		}
		prevCountRef.current = commentsPopupOpen;
	}, [
		commentsPopupOpen,
		viewAri,
		seenViewComments,
		isSharedView,
		isViewCommentHighlightingApplicable,
	]);

	const isCommentCountHighlighted = hasUnseenComment && isViewCommentHighlightingApplicable;
	const trigger = useCallback(
		(triggerProps: TriggerProps) =>
			isVisualRefreshEnabled() ? (
				<ButtonNew
					id="polaris.ideas.ui.view-header.comments-button.comment"
					{...triggerProps}
					onClick={onHandleTogglePopup}
					isSelected={commentsPopupOpen}
					iconAfter={() =>
						commentCount > 0 && (
							<Box xcss={badgeContainerStylesNew}>
								<Badge appearance={isCommentCountHighlighted ? 'primary' : 'default'}>
									{commentCount}
								</Badge>
							</Box>
						)
					}
				>
					{formatMessage(messages.commentButtonCaption)}
				</ButtonNew>
			) : (
				<Button
					id="polaris.ideas.ui.view-header.comments-button.comment"
					{...triggerProps}
					onClick={onHandleTogglePopup}
					isSelected={commentsPopupOpen}
					iconAfter={
						(commentCount || 0) > 0 ? (
							<Box xcss={badgeContainerStyles}>
								<Badge appearance={isCommentCountHighlighted ? 'primary' : 'default'}>
									{commentCount}
								</Badge>
							</Box>
						) : undefined
					}
				>
					<Container>{formatMessage(messages.commentButtonCaption)}</Container>
				</Button>
			),
		[
			onHandleTogglePopup,
			commentsPopupOpen,
			commentCount,
			isCommentCountHighlighted,
			formatMessage,
		],
	);

	if (
		isSharedView &&
		(isViewsLoading ||
			isSharedViewUnavailable ||
			isCurrentViewSharingInitialSettingsLoading ||
			!sharingSettings?.showViewComments)
	) {
		return null;
	}

	return (
		<IssuelessAdfController projectId={projectId} projectKey={projectKey}>
			{commentsPopupOpen && (
				<PolarisNavigationBlocker
					isDirty={hasUnsavedChanges}
					onDiscard={resetPopupState}
					isPromptOpen={showPrompt}
					setIsPromptOpen={setShowPrompt}
				/>
			)}
			<Popup
				placement="bottom-start"
				isOpen={commentsPopupOpen}
				onClose={closePopup}
				content={() => (
					<ViewCommentsContainer>
						<FireScreenAnalytics />
						<ViewCommentStream onDirty={setUnsavedChanges} />
					</ViewCommentsContainer>
				)}
				trigger={trigger}
				shouldUseCaptureOnOutsideClick
			/>
		</IssuelessAdfController>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});

const badgeContainerStyles = xcss({
	marginLeft: 'space.050',
});

const badgeContainerStylesNew = xcss({
	marginInline: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewCommentsContainer = styled.div({
	maxHeight: 'calc(100vh - 135px)',
	overflow: 'auto',
	width: '460px',
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
	padding: `0 ${token('space.100', '8px')} ${token('space.100', '8px')}`,
});
