import React, { useCallback } from 'react';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useSetSortModeOfCurrentViewToProjectRankAndCommitCurrentSort,
	useSetSortModeOfCurrentViewToProjectRankAndCommitViewRank,
	useSetSortModeOfCurrentViewToViewRankAndAddCurrentSort,
} from '@atlassian/jira-polaris-common/src/controllers/idea-ranking/index.tsx';
import {
	useActiveIssueCount,
	useLocalIssueIdToJiraIssueIdMapper,
	useRankedIssueIds,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useSortedSelectedFields } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import {
	useCanManagePermanentSort,
	useCanManageAdvancedSortConfig,
	useCurrentViewContainsArchived,
	useCurrentViewKind,
	useCurrentViewPermanentSortBy,
	useCurrentViewSortMode,
	useCurrentViewTemporarySortBy,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { AdvancedSortingPanel } from '@atlassian/jira-polaris-component-view-sort-configuration/src/ui/advanced/index.tsx';
import { ConfigSort } from '@atlassian/jira-polaris-component-view-sort-configuration/src/ui/config-sort/index.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { Header } from '../header/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	onClose: () => void;
};

export const SortPanel = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();
	const viewKind = useCurrentViewKind();
	const containsArchived = useCurrentViewContainsArchived();
	const isSharedView = useIsSharedView();
	const currentSortMode = useCurrentViewSortMode();

	const rankedIssueIds = useRankedIssueIds();
	const idMapper = useLocalIssueIdToJiraIssueIdMapper();

	const canManageAdvancedSortConfig = useCanManageAdvancedSortConfig();
	const canManagePermanentSortRules = useCanManagePermanentSort();

	const activeIssueCount = useActiveIssueCount();

	const setSortModeOfCurrentViewToViewRankAndAddCurrentSort =
		useSetSortModeOfCurrentViewToViewRankAndAddCurrentSort();
	const setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort =
		useSetSortModeOfCurrentViewToProjectRankAndCommitCurrentSort();
	const setSortModeOfCurrentViewToProjectRankAndCommitViewRank =
		useSetSortModeOfCurrentViewToProjectRankAndCommitViewRank();
	const { setSortModeOfCurrentViewToViewRank, setSortModeOfCurrentViewToProjectRank } =
		useViewActions();
	const setSortModeToViewRank = useCallback(() => {
		setSortModeOfCurrentViewToViewRank(rankedIssueIds.map(idMapper).filter(Boolean));
	}, [idMapper, rankedIssueIds, setSortModeOfCurrentViewToViewRank]);

	const availableSortFields = useSortedSelectedFields();
	const permanentSortFields = useCurrentViewPermanentSortBy();
	const temporarySortFields = useCurrentViewTemporarySortBy();

	const { setPermanentSortFieldsOfCurrentView, setTemporarySortFieldsOfCurrentView } =
		useViewActions();

	if (currentSortMode === undefined) {
		return null;
	}

	const displayAdvancedSortingPanel =
		viewKind !== VIEW_KIND_TIMELINE && !isSharedView && !containsArchived;

	return (
		<Stack spread="space-between" xcss={containerStyles}>
			<Box>
				<Header title={formatMessage(messages.sort)} onClose={onClose} />
				<ConfigSort
					isSharedView={isSharedView}
					canManagePermanentSortRules={canManagePermanentSortRules}
					availableSortFields={availableSortFields}
					permanentSortFields={permanentSortFields}
					temporarySortFields={temporarySortFields}
					setPermanentSortFields={setPermanentSortFieldsOfCurrentView}
					setTemporarySortFields={setTemporarySortFieldsOfCurrentView}
				/>
			</Box>
			{!!displayAdvancedSortingPanel && (
				<AdvancedSortingPanel
					sortMode={currentSortMode}
					activeIssueCount={activeIssueCount}
					canManageAdvancedSortConfig={canManageAdvancedSortConfig}
					setSortModeOfCurrentViewToViewRankAndAddCurrentSort={
						setSortModeOfCurrentViewToViewRankAndAddCurrentSort
					}
					setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort={
						setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort
					}
					setSortModeOfCurrentViewToProjectRankAndCommitViewRank={
						setSortModeOfCurrentViewToProjectRankAndCommitViewRank
					}
					setSortModeOfCurrentViewToProjectRank={setSortModeOfCurrentViewToProjectRank}
					setSortModeToViewRank={setSortModeToViewRank}
				/>
			)}
		</Stack>
	);
};

const containerStyles = xcss({
	minHeight: '100%',
});
