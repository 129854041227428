import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useLinkedIssueDataByLinkedIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/linked-issues/hooks.tsx';
import { LinkedIssueCard } from '@atlassian/jira-polaris-common/src/ui/linked-issue-card/index.tsx';
import type { FieldValueComponentProps, FieldValueConfiguration } from '../types.tsx';
import messages from './messages.tsx';

const Component = ({ groupIdentity, fieldKey }: FieldValueComponentProps) => {
	const linkedIssue = useLinkedIssueDataByLinkedIssueId(parseInt(groupIdentity, 10));

	if (linkedIssue === undefined) {
		return null;
	}

	return (
		<Box padding="space.025">
			<LinkedIssueCard
				issueKey={linkedIssue.issueKey}
				summary={linkedIssue.summary}
				issueType={linkedIssue.issueType}
				fieldKey={fieldKey}
				isLink={false}
			/>
		</Box>
	);
};

const EmptyComponent = () => {
	const { formatMessage } = useIntl();

	return <Box paddingInlineStart="space.050">{formatMessage(messages.emptyColumnHeader)}</Box>;
};

export const connectionFieldValueConfig: FieldValueConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
	EmptyComponent,
};
