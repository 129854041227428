import React from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { IssueSelect } from '../common/issue-select/main.tsx';
import type { IssueOption } from '../common/issue-select/types.tsx';
import { useDefaultOptions, useExcludedProjectTypes } from '../common/issue-select/utils.tsx';
import { messages } from './messages.tsx';
import { useExcludedIssueIds } from './utils.tsx';

type Props = {
	isDisabled: boolean;
	onIssueSelected: (arg1: IssueOption | undefined) => void;
	onCreateStart: () => void;
	onCreateEnd: () => void;
};

export const CreatableIdeaSelect = ({
	isDisabled,
	onIssueSelected,
	onCreateStart,
	onCreateEnd,
}: Props) => {
	const { formatMessage } = useIntl();
	const defaultOptions = useDefaultOptions();
	const excludedProjectTypes = useExcludedProjectTypes();
	const excludedIssueIds = useExcludedIssueIds();

	return (
		<IssueSelect
			isAttachedToBody
			placeholder={formatMessage(messages.issueSelectPlaceholder)}
			isDisabled={isDisabled}
			autoFocus
			projectId={undefined}
			excludedProjectTypes={excludedProjectTypes}
			defaultOptions={defaultOptions}
			hideArchived={ff('polaris-hide-archived-ideas-in-issue-picker-result')}
			excludedIssueIds={excludedIssueIds}
			onIssueSelected={onIssueSelected}
			createOption={{
				onCreateStart,
				onCreateEnd,
			}}
		/>
	);
};
