import React, { useMemo, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import without from 'lodash/without';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { FieldSearchableDropdown } from '@atlassian/jira-polaris-component-field-searchable-dropdown/src/ui/field-searchable-dropdown/index.tsx';
import {
	useCanCreateFields,
	useCanEditFields,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import { CrossProjectListViewsFieldsOnboarding } from '@atlassian/jira-polaris-lib-onboarding-flows/src/ui/cross-project-view/fields/list-view/index.tsx';
import { ExploreProjectFieldsSectionMessage } from '@atlassian/jira-polaris-lib-onboarding-flows/src/ui/project-fields/explore-section-message/index.tsx';
import { useProductUsageStartTimestamp } from '@atlassian/jira-polaris-lib-onboarding/src/controllers/selectors/product-usage-start-timestamp-hooks.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsCollectionView } from '../../../controllers/environment/index.tsx';
import { useOpenRightSidebarOnCreate } from '../../../controllers/right-sidebar/actions/hooks.tsx';
import { useFieldsForViewControls } from '../../../controllers/views/selectors/fields-hooks.tsx';
import { useCurrentViewFieldKeys } from '../../../controllers/views/selectors/view-hooks.tsx';
import messages from './messages.tsx';

type Props = {
	isDisabled: boolean;
	onAddField: (arg1: FieldKey) => void;
};

export const AddFieldComponent = ({ isDisabled, onAddField }: Props) => {
	const { formatMessage } = useIntl();

	const allFields = useFieldsForViewControls();
	const currentViewFieldKeys = useCurrentViewFieldKeys();
	const openRightSidebarOnCreate = useOpenRightSidebarOnCreate();
	const [fieldSearchString, setFieldSearchString] = useState('');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const canEditFields = useCanEditFields();
	const canCreateFields = useCanCreateFields();
	const projectKey = useProjectKeyUnsafe();
	const isCollectionView = useIsCollectionView();

	const productUsageStartTimestamp = useProductUsageStartTimestamp();

	const sortedOptions = useMemo(() => {
		const otherFields = without(Object.keys(allFields), ...currentViewFieldKeys);
		return otherFields
			.map((fieldKey) => allFields[fieldKey])
			.sort((a, b) => a.label.localeCompare(b.label));
	}, [allFields, currentViewFieldKeys]);

	const [dialogOpen, setDialogOpen] = useState(false);
	const toggleDialog = () => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
			'addField',
		);

		setDialogOpen(!dialogOpen);
	};

	const handleOpenRightSidebarOnCreate = useCallback(() => {
		openRightSidebarOnCreate(fieldSearchString);
		setDialogOpen(false);
	}, [fieldSearchString, openRightSidebarOnCreate]);

	return (
		<Popup
			onClose={() => setDialogOpen(false)}
			isOpen={dialogOpen}
			placement="bottom-start"
			autoFocus={false}
			content={() => (
				<>
					{canEditFields && (
						<Box xcss={sectionMessageStyles}>
							{isCollectionView && fg('jpd-aurora-roadmap-fields-onboarding') ? (
								<CrossProjectListViewsFieldsOnboarding innerPaddingInline="space.100" />
							) : (
								<ExploreProjectFieldsSectionMessage
									innerPaddingInline="space.100"
									projectKey={projectKey}
									productUsageStartTimestamp={productUsageStartTimestamp}
								/>
							)}
						</Box>
					)}
					<FieldSearchableDropdown
						isCreatable={canCreateFields}
						options={sortedOptions.map(
							({ key, label: fieldLabel, type, emoji, configuration, global, custom }) => ({
								key,
								label: fieldLabel,
								type,
								emoji,
								global,
								custom,
								hasWeightType: configuration?.optionWeightType !== undefined,
							}),
						)}
						onSelect={(value, actionMeta) => {
							if (actionMeta.action === 'create-option') {
								handleOpenRightSidebarOnCreate();
								return;
							}

							fireUIAnalytics(
								createAnalyticsEvent({
									action: 'clicked',
									actionSubject: 'dropdownItem',
								}),
								'field',
								{
									issueFieldKey: value,
								},
							);
							onAddField(value);
							setDialogOpen(false);
						}}
						onClose={() => setDialogOpen(false)}
						onSearch={setFieldSearchString}
					/>
				</>
			)}
			trigger={(triggerProps) => (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<ButtonContainer className="hide-from-export">
					<ButtonWithTooltip
						{...triggerProps}
						testId="polaris-common.ui.field-config.add.add-more"
						isSelected={dialogOpen}
						isDisabled={isDisabled}
						spacing="none"
						iconBefore={
							<EditorAddIcon
								size="medium"
								label="polaris-common.ui.field-config.add.add-more.icon"
							/>
						}
						onClick={toggleDialog}
						tooltipProps={{
							content: isDisabled
								? formatMessage(messages.noManageViewFieldsPermissions)
								: formatMessage(messages.addMoreButton),
						}}
					/>
				</ButtonContainer>
			)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonContainer = styled.div({
	margin: token('space.100', '8px'),
	width: '24px',
	height: '24px',
});

const sectionMessageStyles = xcss({
	maxWidth: '240px',
});
