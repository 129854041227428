/** @jsx jsx */
import React, { useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useSortedGroupOptions } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import {
	VariableOptionRenderer,
	useOptionsWithLabels,
} from '../../../../../filters/filter-component/options/index.tsx';
import type { SelectOption } from '../../../../../filters/filter-component/select/content/index.tsx';
import { SelectFilterComponent } from '../../../../../filters/filter-component/select/index.tsx';

const useSelectOptions = (field: Field): SelectOption[] => {
	const groupOptions = useSortedGroupOptions(field.key);
	const optionsWithLabels = useOptionsWithLabels(field, groupOptions.options);

	return useMemo(
		() =>
			optionsWithLabels.map((option) => ({
				label: option.label,
				id: option.groupIdentity,
				OptionRenderComponent: () => (
					<VariableOptionRenderer
						field={field}
						groupIdentity={option.groupIdentity}
						value={option.value}
					/>
				),
			})),
		[field, optionsWithLabels],
	);
};

type FilterComponentProps = {
	field: Field;
	defaultOpen?: boolean;
	isTemporary?: boolean;
	isDisabled?: boolean;
	values: string[];
	onChange: (fieldKey: FieldKey, values: string[]) => void;
};

export const FilterComponent = ({
	field,
	defaultOpen,
	isDisabled = false,
	values,
	onChange,
}: FilterComponentProps) => {
	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);
	const options = useSelectOptions(field);
	const selectedOptions = values
		.map((value) => options?.find((option) => option.label === value)?.id)
		.filter(Boolean);

	const handleChange = (newOptionsIds: (string | undefined)[]) => {
		const newValues = newOptionsIds
			.map((id) => options.find((option) => option.id === id)?.label)
			.filter(Boolean);

		onChange(field.key, newValues);
	};

	switch (field.type) {
		case FIELD_TYPES.SINGLE_SELECT:
			return (
				<div css={filterComponentWrapperStyles}>
					<SelectFilterComponent
						label={field.label}
						fieldType={field.type}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						selected={selectedOptions}
						onChange={handleChange}
						options={options}
						defaultOpen={defaultOpen}
						isDisabled={isDisabled}
						isLastFilter={false}
					/>
				</div>
			);

		default:
			return null;
	}
};

const filterComponentWrapperStyles = css({
	flexGrow: 1,
	margin: `${token('space.050')} 0`,
	minWidth: '0',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		width: '100%',
		textAlign: 'left',
	},
});
