import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type Props = {
	isRecommended: boolean;
	isActionEnabled: boolean;
	title: string;
	description: string;
	icon: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Action?: ComponentType<Record<any, any>> | undefined;
};

export const PolarisAppPanel = ({
	title,
	description,
	icon,
	isRecommended,
	Action,
	isActionEnabled,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<PanelWrapper isRecommended={isRecommended}>
			<PanelHeader>
				<PanelHeaderCaption>{title}</PanelHeaderCaption>
				{isRecommended && (
					<PanelHeaderRecommendedIndicator>
						{formatMessage(messages.recommended)}
					</PanelHeaderRecommendedIndicator>
				)}
			</PanelHeader>
			<MainContentPanel>
				<AppIconPanel>
					<AppIconImage src={icon} />
				</AppIconPanel>
				<InnerContentPanel>
					<DescriptionPanel>{description}</DescriptionPanel>
					{isActionEnabled ? (
						<Box xcss={actionPanelStyles}>
							{Action === undefined && (
								<ComingSoonPanel>{formatMessage(messages.comingSoon)}</ComingSoonPanel>
							)}
							{Action !== undefined && <Action />}
						</Box>
					) : null}
				</InnerContentPanel>
			</MainContentPanel>
		</PanelWrapper>
	);
};

PolarisAppPanel.defaultProps = {
	isActionEnabled: true,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelWrapper = styled.div<{ isRecommended: boolean }>({
	display: 'flex',
	flexDirection: 'column',
	borderRadius: '3px',
	paddingTop: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.200', '16px'),
	paddingRight: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isRecommended }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isRecommended ? token('color.background.information', colors.B50) : undefined,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelHeader = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelHeaderCaption = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelHeaderRecommendedIndicator = styled.div({
	marginLeft: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.inverse', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('color.background.information.bold', colors.B400),
	borderRadius: '3px',
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	textTransform: 'uppercase',
	fontWeight: 'bold',
	lineHeight: '16px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainContentPanel = styled.div({
	marginTop: token('space.100', '8px'),
	display: 'flex',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppIconPanel = styled.div({
	width: '36px',
	height: '36px',
	minWidth: '36px',
	minHeight: '36px',
	display: 'flex',
	boxShadow: token(
		'elevation.shadow.raised',
		'0 1px 1px rgba(9,30,66,0.25),0 0 1px 1px rgba(9,30,66,0.13)',
	),
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface', colors.N0),
	borderRadius: '3px',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppIconImage = styled.img({
	width: '28px',
	height: '28px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerContentPanel = styled.div({
	marginLeft: token('space.100', '8px'),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionPanel = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
});

const actionPanelStyles = xcss({
	marginTop: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ComingSoonPanel = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
});
