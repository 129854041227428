import gqlTagPolaris from 'graphql-tag';
import { ff } from '@atlassian/jira-feature-flagging';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { ViewFragment } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/get/index.tsx';
import type {
	jira_polaris_UpdateViewSet as UpdateViewSet,
	jira_polaris_UpdateViewSetVariables as UpdateViewSetVariables,
} from './__generated_apollo__/jira_polaris_UpdateViewSet';
import type { UpdateViewSetProps, UpdateViewSetReturn } from './types.tsx';

const UPDATE_VIEW_SET_MUTATION = gqlTagPolaris`
mutation jira_polaris_UpdateViewSet(
    $viewSetId: ID!,
    $name: String,
    $isShareViewDialogEnabled: Boolean!,
    $skipDescription: Boolean!,
  ) {
    updatePolarisViewSet(
      input: {
        viewSet: $viewSetId
        name: $name
      }
    ) {
      success
      errors {
        message
      }
      node {
        id
        name
        type
        views {
          ...EdgeView
        }
        viewsets {
          id
          name
          type
          views {
            ...EdgeView
          }
        }
      }
    }
  }
  ${ViewFragment}
`;

export const updateViewSet = async (
	apolloClient: PolarisApolloClient,
	props: UpdateViewSetProps,
): Promise<UpdateViewSetReturn> => {
	const result = await apolloClient.mutate<UpdateViewSet, UpdateViewSetVariables>({
		mutation: UPDATE_VIEW_SET_MUTATION,
		variables: {
			viewSetId: props.viewSetId,
			name: props.name,
			isShareViewDialogEnabled: ff('polaris.sharing-enabled'),
			skipDescription: false,
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`project-config.update-view-set-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (!result.data?.updatePolarisViewSet?.node) {
		throw new Error('project-config.update-view-set-error: no data or no data node');
	}

	return {
		node: { ...result.data.updatePolarisViewSet.node },
	};
};
