import React, { type PropsWithChildren } from 'react';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import { useIssueViewLayout } from '../../controllers/route/index.tsx';

type Props = {
	issueKey: string;
};

export const IssueLink = ({ issueKey, children }: PropsWithChildren<Props>) => {
	const issueViewLayout = useIssueViewLayout();
	const container = useEnvironmentContainer();
	const currentProjectKey =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectKey : undefined;

	const projectKey = issueKey.split('-')[0];
	const isSameProject = currentProjectKey && projectKey === currentProjectKey;
	const link = isSameProject
		? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			`${window.location.pathname}?selectedIssue=${issueKey}${issueViewLayout ? `&issueViewLayout=${issueViewLayout}` : ''}`
		: `/browse/${issueKey}`;

	return (
		<Link href={link} target={isSameProject ? undefined : '_blank'}>
			{children}
		</Link>
	);
};
