import React, { type ComponentPropsWithRef } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { ErrorScreen } from './error-screen/index.tsx';
import type { SharingForm as SharingFormType } from './form/index.tsx';
import { LoadingScreen } from './loading-screen/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazySharingForm = lazyForPaint<typeof SharingFormType>(
	() =>
		import(/* webpackChunkName: "async-polaris-lib-control-sharing" */ './form').then(
			({ SharingForm }) => SharingForm,
		),
	{ ssr: false },
);

export const AsyncSharingForm = (containerProps: ComponentPropsWithRef<typeof SharingFormType>) => (
	<ErrorBoundary
		id="polaris-control-sharing"
		packageName="polaris-control-sharing"
		render={ErrorScreen}
	>
		<Placeholder name="polaris-control-sharing-boundary" fallback={<LoadingScreen />}>
			<LazySharingForm {...containerProps} />
		</Placeholder>
	</ErrorBoundary>
);
