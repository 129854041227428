import React from 'react';
import { styled } from '@compiled/react';
import LikeIcon from '@atlaskit/icon/glyph/like';
import { N500, N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useGetPlayById } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/plays-hooks.tsx';
import type { PolarisPlay } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import { FormattedDate } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { useInsight } from '../../../../../controllers/insights/selectors/insights-hooks.tsx';

type HeaderProps = {
	playId: Ari;
	insightId: string;
};

export const Header = ({ playId, insightId }: HeaderProps) => {
	const play: PolarisPlay | undefined = useGetPlayById(playId);
	const insight = useInsight(insightId);

	if (insight === undefined) {
		return null;
	}

	return (
		<Wrapper data-testid="polaris-ideas.ui.insights.insights.play-insight.header.wrapper">
			<TitleWrapper>
				<IconWrapper>
					<LikeIcon size="small" label="play" primaryColor={token('color.text.subtle', N500)} />
				</IconWrapper>
				<Title>{play?.label}</Title>
			</TitleWrapper>
			<DateWrapper>
				<FormattedDate date={insight.created} />
			</DateWrapper>
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	backgroundColor: 'transparent',
	border: 0,
	borderRadius: '3px',
	width: '100%',
	lineHeight: '16px',
	overflowX: 'hidden',
	marginBottom: token('space.050', '4px'),
	color: 'inherit',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: 'inherit',
	fontStyle: 'normal',
	fontWeight: 'normal',
	outline: 'none',
	minHeight: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	display: 'flex',
	fontWeight: 'bold',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateWrapper = styled.div({
	color: token('color.text.subtlest', N200),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	margin: `0 ${token('space.075', '6px')}`,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
	color: token('color.text.subtle', N500),
	whiteSpace: 'normal',
	flexGrow: 1,
});
