import React, { memo } from 'react';
import LinkIcon from '@atlaskit/icon/glyph/link';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { Button } from '../button/styled.tsx';
import messages from './messages.tsx';

type Props = {
	showLabel?: boolean;
	onClicked: () => void;
};

export const LinkButton = memo<Props>(({ onClicked, showLabel = false }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Tooltip content={formatMessage(messages.linkButtonCaption)}>
			<Button
				testId="polaris-ideas.ui.idea-view.controls.link.button"
				iconBefore={<LinkIcon label={formatMessage(messages.linkButtonCaption)} />}
				onClick={onClicked}
			>
				{showLabel && formatMessage(messages.linkButtonCaption)}
			</Button>
		</Tooltip>
	);
});
