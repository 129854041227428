import React, { useMemo, useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useOpenPreviewMode } from '@atlassian/jira-polaris-common/src/controllers/preview-mode/index.tsx';
import {
	useIssueCreationEnabled,
	useSaveIssueCreationEnabled,
	useSaveSubmitIdeaDescriptionTemplateId,
	useSaveSubmitIdeaFormMandatoryFields,
	useSubmitIdeaFormMandatoryFields,
	useSaveSubmitIdeaFormViewId,
	useSubmitIdeaFormDescriptionTemplate,
	useSubmitIdeaFormView,
} from '@atlassian/jira-polaris-common/src/controllers/project-properties/index.tsx';
import {
	useAllIdeasViewId,
	useAllViewsNameAndIds,
	useViewSlug,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { IssueCreationForm } from '@atlassian/jira-polaris-common/src/ui/create-idea-dialog/creation-form/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { Components } from '@atlassian/jira-polaris-lib-forms-settings/src/controllers/types.tsx';
import PolarisProjectFormSettingsScreen from '@atlassian/jira-polaris-lib-forms-settings/src/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { IdeaTemplatesPanel } from '../../right-sidebar/idea-templates/main.tsx';
import { SectionMessageComponent } from '../../right-sidebar/idea-templates/section-message/index.tsx';
import { BreadcrumbsProjectSettings } from '../breadcrumbs/index.tsx';
import { messages } from './messages.tsx';

export const PolarisFormsSettings = () => {
	const { generalActionFailedError } = useErrorHandlers();
	const { formatMessage } = useIntl();
	const issueCreationEnabled = useIssueCreationEnabled();
	const onIssueCreationChanged = useSaveIssueCreationEnabled();
	const viewId = useSubmitIdeaFormView();
	const onViewIdChanged = useSaveSubmitIdeaFormViewId();
	const allIdeasViewId = useAllIdeasViewId();
	const allIdeasViewSlug = useViewSlug(allIdeasViewId);
	const descriptionTemplateId = useSubmitIdeaFormDescriptionTemplate();
	const onTemplateIdChanged = useSaveSubmitIdeaDescriptionTemplateId();
	const areFieldsMandatory = useSubmitIdeaFormMandatoryFields();
	const onFieldsMandatoryChanged = useSaveSubmitIdeaFormMandatoryFields();
	const viewOptions = useAllViewsNameAndIds();
	const openPreviewMode = useOpenPreviewMode();
	const { error, success } = useNotifications();

	const components: Components = useMemo(
		() => ({
			IssueForm: (props) => <IssueCreationForm {...props} />,
			IssueFormSectionMessage: SectionMessageComponent,
			DescriptionTemplates: (props) => <IdeaTemplatesPanel {...props} />,
		}),
		[],
	);

	const viewIdValue = useMemo(() => viewId.value, [viewId.value]);

	const onCopyFormLink = useCallback(() => {
		const viewSlug = viewIdValue ? viewIdValue.split('/')[1] : allIdeasViewSlug;
		const baseUrl =
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.origin +
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.pathname.replace('/settings/create-ideas', `/view/${viewSlug}`);
		const urlToCopy = `${baseUrl}?openIdeaCreationModal=true`;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		navigator.clipboard
			.writeText(urlToCopy)
			.then(() =>
				success({
					title: formatMessage(messages.copiedTitle),
					description: formatMessage(messages.copiedMessage),
				}),
			)
			.catch(() =>
				error({
					title: formatMessage(messages.copyFailTitle),
					description: formatMessage(messages.copyFailMessage),
				}),
			);
	}, [error, formatMessage, success, viewIdValue, allIdeasViewSlug]);

	const onPreviewOpen = useCallback(
		(previewRoleName = 'contributor') => {
			openPreviewMode(previewRoleName);
		},
		[openPreviewMode],
	);

	return (
		<PolarisProjectFormSettingsScreen
			viewId={viewId}
			issueCreationEnabled={issueCreationEnabled}
			areFieldsMandatory={areFieldsMandatory}
			components={components}
			viewOptions={viewOptions}
			descriptionTemplateId={{
				...descriptionTemplateId,
				isLoading: descriptionTemplateId.isLoading,
			}}
			onError={generalActionFailedError}
			onIssueCreationChanged={onIssueCreationChanged}
			onViewIdChanged={onViewIdChanged}
			onCopyIdeaFormLink={onCopyFormLink}
			onDescriptionTemplateIdChanged={onTemplateIdChanged}
			onFieldsMandatoryChanged={onFieldsMandatoryChanged}
			onPreviewModeOpen={onPreviewOpen}
			breadcrumbs={<BreadcrumbsProjectSettings />}
		/>
	);
};
