import React, { useMemo } from 'react';
import { useFieldIdMapAsync } from '@atlassian/jira-polaris-component-field-id-mapping/src/controllers/index.tsx';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import type { ChainableContextProvider } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	createSharingViewsRemote,
	createViewsRemote,
	createCollectionViewsRemote,
} from './controllers/index.tsx';
import type { ViewRemote } from './types.tsx';

const { RemoteContextProvider, useRemoteFromContext } = createRemoteContext<ViewRemote>('view');

export const createViewRemoteProvider =
	(projectId: ProjectId): ChainableContextProvider =>
	({ children, apolloClient }) => {
		const { cloudId } = useTenantContext();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const fieldMappingPromise = useFieldIdMapAsync(projectId);

		const viewRemote = useMemo(
			() =>
				createViewsRemote(
					cloudId,
					projectId,
					apolloClient,
					createAnalyticsEvent,
					fieldMappingPromise,
				),
			[cloudId, apolloClient, createAnalyticsEvent, fieldMappingPromise],
		);

		return <RemoteContextProvider remote={viewRemote}>{children}</RemoteContextProvider>;
	};

export const createSharingViewRemoteProvider =
	(viewId: string): ChainableContextProvider =>
	({ children }) => {
		const { cloudId, atlassianAccountId } = useTenantContext();
		const viewRemote = useMemo(
			() => createSharingViewsRemote(cloudId, viewId, atlassianAccountId),
			[cloudId, atlassianAccountId],
		);

		return <RemoteContextProvider remote={viewRemote}>{children}</RemoteContextProvider>;
	};

export const createCollectionViewRemoteProvider =
	(collectionUUID: string): ChainableContextProvider =>
	({ children }) => {
		const { cloudId } = useTenantContext();
		const viewRemote = useMemo(
			() => createCollectionViewsRemote(cloudId, collectionUUID),
			[cloudId],
		);

		return <RemoteContextProvider remote={viewRemote}>{children}</RemoteContextProvider>;
	};

export const useViewRemote = useRemoteFromContext;
