import type { GlobalField } from '@atlassian/jira-polaris-domain-field/src/global-field/types.tsx';
import { notImplementedError } from '../../common/utils.tsx';
import { createGlobalField } from '../../services/jira/create-global-field/index.tsx';
import { deleteGlobalField } from '../../services/jira/delete-global-field/index.tsx';
import { getGlobalField } from '../../services/jira/fetch-global-field/index.tsx';
import { getGlobalFields } from '../../services/jira/fetch-global-fields/index.tsx';
import { searchGlobalFields } from '../../services/jira/search-global-fields/index.tsx';
import { updateGlobalFieldConfiguration } from '../../services/jira/update-global-field-configuration/index.tsx';
import { updateGlobalField } from '../../services/jira/update-global-field/index.tsx';
import type { GlobalFieldCrudRemote } from './types.tsx';
import { transformGlobalField } from './utils.tsx';

export const createNotImplementedJiraGlobalFieldCrudRemote = (): GlobalFieldCrudRemote => ({
	fetchGlobalFields: notImplementedError('fetchGlobalFields'),
	fetchGlobalField: notImplementedError('fetchGlobalField'),
	searchGlobalFields: notImplementedError('searchGlobalFields'),
	createGlobalField: notImplementedError('createGlobalField'),
	deleteGlobalField: notImplementedError('deleteGlobalField'),
	updateGlobalField: notImplementedError('updateGlobalField'),
	updateGlobalFieldConfiguration: notImplementedError('updateGlobalFieldConfiguration'),
});

export const createJiraGlobalFieldCrudRemote = (): GlobalFieldCrudRemote => ({
	fetchGlobalFields: async () => {
		const globalFields = await getGlobalFields();

		const fieldsMap: Record<string, GlobalField> = {};

		for (const globalField of globalFields) {
			fieldsMap[globalField.key] = transformGlobalField(globalField);
		}

		return fieldsMap;
	},
	fetchGlobalField: async ({ fieldKey }) => {
		const globalField = await getGlobalField(fieldKey);

		return transformGlobalField(globalField);
	},
	searchGlobalFields: async (request) => {
		const fields = await searchGlobalFields(request);

		return fields.map(transformGlobalField);
	},
	createGlobalField: async ({ name, fieldType, configuration }) => {
		const field = await createGlobalField(name, fieldType, configuration);

		return transformGlobalField(field);
	},
	deleteGlobalField: async ({ fieldKey }) => {
		await deleteGlobalField(fieldKey);
	},
	updateGlobalField: async ({ fieldKey, description, name }) => {
		await updateGlobalField(fieldKey, description, name);
	},
	updateGlobalFieldConfiguration: async ({ fieldKey, patch }) => {
		await updateGlobalFieldConfiguration(fieldKey, patch);
	},
});
