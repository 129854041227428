import React, {
	memo,
	useCallback,
	useEffect,
	useRef,
	useMemo,
	useState,
	type ElementRef,
} from 'react';
import defer from 'lodash/defer';
import isArray from 'lodash/isArray';
import deepEquals from 'lodash/isEqual';
import Select, { type StylesConfig, createFilter as createFilterNext } from '@atlaskit/select';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	OptionProperty,
	LocalIssueId,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useStateWithRef } from '../../../../common/utils/react/hooks.tsx';
import { useFieldOptionsWithAriResolved } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useDecoratableSelectCustomComponentsForOptions } from '../../../common/decoration/decoration/select/index.tsx';
import { stylesConfig } from './styled.tsx';
import { createOptionsGroups } from './utils.tsx';

type PropsNext = {
	isMulti: boolean;
	value: OptionProperty[];
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	menuPortalTarget?: HTMLElement;
	onUpdate: (options: OptionProperty[], allowClose: boolean) => void;
	onCloseRequested: () => void;
	onConfigRequested?: (optionId?: string) => void;
	testId?: string;
};

type Option = {
	label?: string;
	value: string;
};

/* There are times for custom fields when the label might be 'Option 1'
   but the value is something like '121315'. This means that when you
   search for something like '2' then 'Option 1' would appear because
   the default search uses the label and the value. Using a custom
   function here updates the search to only use the label and fall back
   to the value when the label doesn't exist. */
const stringify = (option: Option) => `${option.label || option.value}`;

const EMPTY_STRING = '';

export const SelectEdit = memo<PropsNext>(
	({
		testId,
		localIssueId,
		fieldKey,
		isMulti,
		value: selectedOptions = [],
		onUpdate,
		onCloseRequested,
		onConfigRequested,
		menuPortalTarget,
	}: PropsNext) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const ref = useRef<ElementRef<typeof Select<OptionProperty, boolean>> | null>(null);
		const [inputValue, setInputValue] = useState(EMPTY_STRING);
		const [isFocused, setIsFocused] = useState(false);
		const [menuIsOpen, setMenuIsOpen] = useState(true);
		const onCloseSelect = useCallback(() => {
			setMenuIsOpen(false);
		}, [setMenuIsOpen]);

		const allFieldOptions: OptionProperty[] = useFieldOptionsWithAriResolved(fieldKey);

		// if user delete some selected options, value (selectedOptions) is not updated
		// so value could contains non existing options. just checking that
		const selectedOptionsVerified = useMemo(
			() => selectedOptions.filter(({ id }) => allFieldOptions.some((opt) => opt.id === id)),
			[selectedOptions, allFieldOptions],
		);

		const [value, setValue, valueRef] = useStateWithRef(selectedOptionsVerified);

		const [localOptions, setLocalOptions] = useState(
			createOptionsGroups(value, allFieldOptions, isMulti),
		);

		useEffect(() => {
			if (!isShallowEqual(value, selectedOptionsVerified)) {
				setValue(selectedOptionsVerified);
			}
		}, [selectedOptionsVerified, setValue, value]);

		useEffect(() => {
			setLocalOptions(createOptionsGroups(value, allFieldOptions, isMulti));
		}, [value, allFieldOptions, isMulti]);

		const onChange = useCallback(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(newValue: any, allowClose = true) => {
				if (!newValue) {
					if (valueRef.current !== undefined) {
						onUpdate([], true);
					}
					return;
				}
				const newValueArray = isArray(newValue) ? newValue : [newValue];
				if (valueRef.current !== undefined) {
					const currentValueIdArray = (
						isArray(valueRef.current) ? valueRef.current : [valueRef.current]
					).map(({ id }) => id);
					const valueForUpdateIdArray = newValueArray.map(({ id }) => id);
					if (deepEquals(currentValueIdArray, valueForUpdateIdArray)) {
						return;
					}
				}
				onUpdate(newValueArray, allowClose);
				setInputValue(EMPTY_STRING);
				defer(() => ref.current?.focus());
			},
			[onUpdate, valueRef],
		);

		const onConfig = useCallback(
			(optionId?: string) => {
				if (onConfigRequested !== undefined) {
					onConfigRequested(optionId ?? undefined);
					onCloseRequested();
					onCloseSelect();
				}
			},
			[onCloseRequested, onCloseSelect, onConfigRequested],
		);

		const customComponents = useDecoratableSelectCustomComponentsForOptions({
			menuId: `select-menu-${localIssueId}-${fieldKey}`,
			configOnly: true,
			isMulti,
			fieldKey,
			onCloseRequested,
			onConfigRequested: onConfig,
		});
		return (
			<Select<OptionProperty, boolean>
				testId={testId}
				menuPortalTarget={menuPortalTarget}
				ref={ref}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onKeyDown={(event: any) => {
					switch (event.key) {
						case 'Esc': // IE/Edge specific value
						case 'Escape':
							onCloseRequested();
							break;
						default:
					}
				}}
				onMenuInputFocus={() => setIsFocused(true)}
				menuIsOpen={menuIsOpen}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onChange={(newValue: any) => {
					onChange(newValue);
					if (!isMulti) {
						onCloseRequested();
					}
				}}
				isMulti={isMulti}
				hideSelectedOptions={false}
				isSearchable
				isClearable
				backspaceRemovesValue={false}
				inputValue={inputValue}
				onInputChange={setInputValue}
				onFocus={() => {
					setIsFocused(true);

					fireUIAnalytics(
						createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
						'issueField',
					);
				}}
				isFocused={isFocused}
				value={value}
				autoFocus
				options={localOptions}
				components={customComponents}
				getOptionLabel={({ value: label }) => label}
				getOptionValue={({ id }) => id}
				enableAnimation={false}
				menuPlacement="auto"
				maxMenuHeight={320}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Ignored via go/DSP-18766
				styles={stylesConfig as StylesConfig<OptionProperty, boolean>}
				filterOption={createFilterNext({
					ignoreCase: true,
					ignoreAccents: true,
					trim: true,
					stringify,
				})}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={testId && 'select-editview'}
				classNamePrefix={testId && 'select'}
			/>
		);
	},
);
