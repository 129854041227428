import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import InlineDialog from '@atlaskit/inline-dialog';
import type { InlineDialogProps } from '@atlaskit/inline-dialog/types';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';

export type PolarisInlineDialogProps = Omit<InlineDialogProps, 'onClose'> & {
	noPadding?: boolean;
	// onClose is called if a click occurred outside of the dialog - the function argument can be a ref of the target
	onClose: (arg1: HTMLElement) => void;
};

export const PolarisInlineDialog = (props: PolarisInlineDialogProps) => {
	const { children, onClose, noPadding, content, ...rest } = props;

	const handleClickOutside = useCallback(
		(event: Event) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			onClose(event.target as HTMLElement);
		},
		[onClose],
	);

	const wrappedContent = useMemo(
		() => (
			<OutsideClickAlerter onClickOutside={handleClickOutside}>
				{(outsideClickAlerterProps) => <div {...outsideClickAlerterProps}>{content}</div>}
			</OutsideClickAlerter>
		),
		[content, handleClickOutside],
	);

	return (
		<PolarisInlineDialogContainer noPadding={noPadding ?? false}>
			<InlineDialog {...rest} content={wrappedContent}>
				{children}
			</InlineDialog>
		</PolarisInlineDialogContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PolarisInlineDialogContainer = styled.div<{ noPadding: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		padding: ({ noPadding }) => (noPadding ? '0px !important' : undefined),
	},
});
