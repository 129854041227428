import { createHook } from '../index.tsx';
import {
	getCrossProjectViewTourDismissed,
	getTourSpotlightStep,
	getCrossProjectListViewFieldsDismissed,
} from './cross-project-view.tsx';

export const useCrossProjectViewTourDismissed = createHook(getCrossProjectViewTourDismissed);
export const useTourSpotlightStep = createHook(getTourSpotlightStep);
export const useCrossProjectListViewFieldsDismissed = createHook(
	getCrossProjectListViewFieldsDismissed,
);
