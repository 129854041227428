import React, { useMemo, type ReactNode } from 'react';
import Badge from '@atlaskit/badge';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueViewSection } from '@atlassian/jira-polaris-common/src/common/types/issue/index.tsx';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useConnectionFieldsKeysCount } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import {
	useIssueCommentCount,
	useUnseenCommentsAvailableForIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/comments/hooks.tsx';
import { useLinkedDeliveryIssuesCount } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useUnseenInsightsAvailableForIssue,
	useInsightsCountForIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/insights/hooks.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { SharingSettings } from '@atlassian/jira-polaris-domain-view/src/sharing/settings/index.tsx';
import { useInsightsCount } from '../../../controllers/insights/selectors/insights-hooks.tsx';
import { messages } from './messages.tsx';
import { BadgeContainer, MessagesTabWrapper, MessageContainer } from './styled.tsx';

export type IssueViewTab = {
	name: IssueViewSection;
	label: ReactNode;
	content: null;
	testId: string;
};

export const useTabs = ({
	localIssueId,
	isSharedView,
	sharingOptions,
}: {
	localIssueId: LocalIssueId;
	isSharedView: boolean;
	sharingOptions?: SharingSettings;
}): IssueViewTab[] => {
	const { formatMessage } = useIntl();
	const isCollectionView = useIsCollectionView();
	const commentCount = useIssueCommentCount(localIssueId);
	// disabling for collection view for now: https://pi-dev-sandbox.atlassian.net/browse/POL-10557?focusedCommentId=729350
	const hasUnseenComment = useUnseenCommentsAvailableForIssue(localIssueId) && !isCollectionView;
	// used on project view
	const insightsCountForIssue = useInsightsCountForIssue(localIssueId);
	// disabling for collection view for now: https://pi-dev-sandbox.atlassian.net/browse/POL-10863?focusedCommentId=729349
	const hasUnreadInsights = useUnseenInsightsAvailableForIssue(localIssueId) && !isCollectionView;
	const linkedLinkedIssuesCount = useLinkedDeliveryIssuesCount(localIssueId);
	// used on collection view
	const insightsCount = useInsightsCount() || insightsCountForIssue;
	const connectionFieldsKeysCount = useConnectionFieldsKeysCount();

	return useMemo(() => {
		const connectionsTab: IssueViewTab = {
			name: 'connections',
			label: formatMessage(messages.connectionsNonFinal),
			content: null,
			testId: 'polaris.idea.src.ui.sections.tabs.connections',
		};

		const tabs: IssueViewTab[] = [
			{
				name: 'comments',
				label: (
					<MessagesTabWrapper>
						<MessageContainer>{formatMessage(messages.comments)}</MessageContainer>
						{(commentCount || 0) > 0 && (
							<BadgeContainer>
								<Badge appearance={hasUnseenComment ? 'primary' : 'default'}>{commentCount}</Badge>
							</BadgeContainer>
						)}
					</MessagesTabWrapper>
				),
				content: null,
				testId: 'polaris.idea.src.ui.sections.tabs.comments',
			},
			...(connectionFieldsKeysCount > 0 && fg('jpd_issues_relationships') ? [connectionsTab] : []),
			{
				name: 'capture',
				label: (
					<MessagesTabWrapper>
						<MessageContainer>{formatMessage(messages.insights)}</MessageContainer>
						{(insightsCount || 0) > 0 && (
							<BadgeContainer>
								<Badge appearance={hasUnreadInsights ? 'primary' : 'default'}>
									{insightsCount}
								</Badge>
							</BadgeContainer>
						)}
					</MessagesTabWrapper>
				),
				content: null,
				testId: 'polaris.idea.src.ui.sections.tabs.capture',
			},
			{
				name: 'deliver',
				label: (
					<MessagesTabWrapper>
						<MessageContainer>{formatMessage(messages.deliver)}</MessageContainer>
						{linkedLinkedIssuesCount > 0 && (
							<BadgeContainer>
								<Badge appearance="default">{linkedLinkedIssuesCount}</Badge>
							</BadgeContainer>
						)}
					</MessagesTabWrapper>
				),
				content: null,
				testId: 'polaris.idea.src.ui.sections.tabs.deliver',
			},
			{
				name: 'history',
				label: <>{formatMessage(messages.history)}</>,
				content: null,
				testId: 'polaris.idea.src.ui.tab-view.history',
			},
		];
		return tabs.filter((tab) => {
			if (isSharedView) {
				if (tab.name === 'comments') {
					return sharingOptions?.showIdeaComments;
				}
				if (tab.name === 'capture') {
					return sharingOptions?.showIdeaInsights;
				}
				return false;
			}
			return true;
		});
	}, [
		formatMessage,
		commentCount,
		hasUnseenComment,
		insightsCount,
		hasUnreadInsights,
		linkedLinkedIssuesCount,
		isSharedView,
		sharingOptions?.showIdeaComments,
		sharingOptions?.showIdeaInsights,
		connectionFieldsKeysCount,
	]);
};
