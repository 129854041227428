import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	anyoneElseInThisProjectLabel: {
		id: 'polaris-component-view-access.access-screen.access-user-list.anyone-else-access-list-item.anyone-else-in-this-project-label',
		defaultMessage: 'Anyone else in this project',
		description:
			'Item in the view permission list showing default permission for every other user in this project',
	},
	viewOnlyLabel: {
		id: 'polaris-component-view-access.access-screen.access-user-list.anyone-else-access-list-item.view-only-label',
		defaultMessage: 'View only',
		description:
			'View only label describing other users permissions displayed when view access level is set to limited',
	},
	noAccessLabel: {
		id: 'polaris-component-view-access.access-screen.access-user-list.anyone-else-access-list-item.no-access-label',
		defaultMessage: 'No access',
		description:
			'No access label describing other users permissions displayed when view access level is set to limited',
	},
});
