import { useMemo } from 'react';
import indexOf from 'lodash/indexOf';
import take from 'lodash/take';
import {
	useAllDeliveryFields,
	useAllEditableFields,
	useFieldType,
	useFieldTypes,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import {
	useCurrentViewTableColumnSize,
	useCurrentViewTableColumnSizesByFieldKey,
	useIsExporting,
	useCurrentViewVisibleFieldKeys,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	useCanEditIssues,
	useCanRankIdeas,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { PRESENTATIONAL_FIELDKEYS } from '@atlassian/jira-polaris-lib-list/src/constants.tsx';
import {
	FIELD_TYPE_WIDTHS,
	FIELD_KEY_WIDTHS,
	COLUMN_DEFAULT_WIDTH,
	FIELD_TYPE_MIN_WIDTHS,
} from './constants.tsx';

export const useColumnIdsWithAdditionalColumns = () => {
	const columnIds = useCurrentViewVisibleFieldKeys();
	const isExporting = useIsExporting();
	const canRankIdea = useCanRankIdeas();
	const [canEditIssues] = useCanEditIssues();

	return useMemo(
		() => [
			...(canRankIdea ? [PRESENTATIONAL_FIELDKEYS.DND] : []),
			...(canEditIssues ? [PRESENTATIONAL_FIELDKEYS.SELECT] : []),
			...columnIds,
			...(isExporting ? [] : [PRESENTATIONAL_FIELDKEYS.ADD]),
		],
		[canRankIdea, canEditIssues, columnIds, isExporting],
	);
};

export const useFixedColumns = (): string[] => {
	const allColumns = useColumnIdsWithAdditionalColumns();

	return useMemo(() => {
		const summaryColIndex = indexOf(allColumns, SUMMARY_FIELDKEY);

		if (summaryColIndex === -1) {
			return [];
		}

		return take(allColumns, summaryColIndex + 1);
	}, [allColumns]);
};

export const useEditableColumnsIds = (): string[] => {
	const [canEditIssues] = useCanEditIssues();
	const columnIds = useCurrentViewVisibleFieldKeys();
	const editableFields = useAllEditableFields();
	const [deliveryFields] = useAllDeliveryFields();
	return useMemo(() => {
		if (!canEditIssues) {
			return [];
		}
		return columnIds.filter(
			(columnId) => editableFields.includes(columnId) || deliveryFields.includes(columnId),
		);
	}, [columnIds, deliveryFields, editableFields, canEditIssues]);
};

const getSizeWithDefaults = (
	viewColumnSize?: number,
	fieldKey?: FieldKey,
	fieldType?: FieldType,
): number => {
	const fieldKeyOverride = fieldKey !== undefined ? FIELD_KEY_WIDTHS[fieldKey] : undefined;
	const fieldTypeOverride = fieldType !== undefined ? FIELD_TYPE_WIDTHS[fieldType] : undefined;

	return viewColumnSize !== undefined
		? viewColumnSize
		: fieldKeyOverride || fieldTypeOverride || COLUMN_DEFAULT_WIDTH;
};

export const useColumnWidth = (fieldKey?: FieldKey): number => {
	const viewColumnSize = useCurrentViewTableColumnSize(fieldKey);
	const fieldType = useFieldType(fieldKey);

	return useMemo(
		() => getSizeWithDefaults(viewColumnSize, fieldKey, fieldType),
		[viewColumnSize, fieldKey, fieldType],
	);
};

export const useColumnWidthsWithDefaults = (): Record<FieldKey, number> => {
	const columnIds = useColumnIdsWithAdditionalColumns();
	const columnSizes = useCurrentViewTableColumnSizesByFieldKey();
	const [fieldTypesByKey] = useFieldTypes();

	return useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const sizeMap: Record<string, any> = {};
		columnIds.forEach((fieldKey) => {
			sizeMap[fieldKey] = getSizeWithDefaults(
				columnSizes[fieldKey],
				fieldKey,
				fieldTypesByKey[fieldKey],
			);
		});
		return sizeMap;
	}, [columnSizes, columnIds, fieldTypesByKey]);
};

export const useColumnMinWidths = (): Record<FieldKey, number> => {
	const columnIds = useColumnIdsWithAdditionalColumns();
	const [fieldTypesByKey] = useFieldTypes();

	return useMemo(() => {
		const minWidths: Record<string, number> = {};

		columnIds.forEach((columnId) => {
			const type = fieldTypesByKey[columnId];

			if (type !== undefined) {
				const minWidthForType = FIELD_TYPE_MIN_WIDTHS[type];

				if (minWidthForType !== undefined) {
					minWidths[columnId] = minWidthForType;
				}
			}
		});

		return minWidths;
	}, [columnIds, fieldTypesByKey]);
};
