import React, { memo, useCallback, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import { Section, type CustomItemProps } from '@atlaskit/menu';
import { HeadingItem } from '@atlaskit/side-navigation';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ScreenReaderMenuHeader } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/screen-reader-header/index.tsx';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useIsViewsLoading } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/meta-hooks.tsx';
import { useSectionsTree } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/sections-hooks.tsx';
import type {
	SimplifiedView,
	JpdTreeItem,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/sections-tree.tsx';
import { useViewIdsInViewSet } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useHasReachedViewsLimit } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view/view-sets/hooks.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { VIEW_CREATE_POPUP_TYPES } from '@atlassian/jira-polaris-lib-create-view-popup/src/types.tsx';
import { ViewCreatePopup } from '@atlassian/jira-polaris-lib-create-view-popup/src/ui/index.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-lib-limits/src/controllers/index.tsx';
import { Tree } from '@atlassian/jira-polaris-lib-tree/src/main.tsx';
import type { ItemId, OnDragEnd, RenderItem } from '@atlassian/jira-polaris-lib-tree/src/types.tsx';
import {
	moveItemOnTree,
	mutateTree,
} from '@atlassian/jira-polaris-lib-tree/src/utils/mutations.tsx';
import { whyDidYouRenderPlaceholder } from '@atlassian/jira-polaris-why-did-you-render-placeholder/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import { ViewItem, ViewItemSkeleton } from './view-item/main.tsx';
import { ViewSectionItem } from './view-section/main.tsx';

type IdeasSectionProps = {
	isReadOnly: boolean;
};

// Styles for section items
const getViewsItemStyles: CustomItemProps['cssFn'] = (
	state = { isSelected: false, isDisabled: false },
) => {
	const { isSelected } = state;
	return {
		display: 'flex',
		padding: `${token('space.050', '4px')} ${token('space.050', '4px')} ${token(
			'space.050',
			'4px',
		)} 0`,
		backgroundColor: isSelected
			? token('color.background.neutral.subtle.hovered', colors.N20A)
			: 'transparent',
		color: isSelected
			? token('color.text.brand', colors.B400)
			: token('color.text.subtle', colors.N500),
		'&:hover': {
			color: token('color.text', colors.N800),
			backgroundColor: token('color.background.neutral.subtle.hovered', colors.N20A),
			cursor: 'pointer',
			textDecoration: 'none',
		},
		'&:focus, & *:focus': {
			outline: 'none',
			boxShadow: 'none',
		},
		'&:active': {
			borderRadius: '3px',
			backgroundColor: token('color.background.neutral.subtle.hovered', colors.N20A),
			color: isSelected ? token('color.text.brand', colors.B400) : token('color.text', colors.N500),
			'span[type="drag-handle"]': {
				visibility: 'visible',
			},
		},
		'&:visited, &:active, &:focus': {
			textDecoration: 'none',
		},
		'& [data-item-elem-before]': {
			display: 'flex',
			height: 'unset',
			width: 'unset',
			alignItems: 'center',
		},
	};
};

export const IdeasSection = memo<IdeasSectionProps>(({ isReadOnly }: IdeasSectionProps) => {
	const {
		setCurrentView,
		cloneView,
		createNewView,
		deleteView,
		renameView,
		addViewEmoji,
		rankViews,
		refreshViewsRanks,
		createSection,
		deleteSection,
		saveSection,
	} = useViewActions();

	const sectionsTree = useSectionsTree('PRIORITIZE');

	const viewIdsInCaptureSection = useViewIdsInViewSet('CAPTURE');

	const { clearCreatedProperty } = useIssueActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { closeIssueView } = usePolarisRouter();

	const [viewLimitType, viewLimitValue] = useHasReachedViewsLimit(false);
	const [sectionLimitType, sectionLimitValue] = useHasReachedViewsLimit(true);
	const getEntityLimitMessage = useEntityLimitMessage();

	const isCollectionView = useIsCollectionView();

	const renameSection = useCallback(
		(id: string) => (name: string) => {
			saveSection({ id, name, isReadOnly });
		},
		[isReadOnly, saveSection],
	);

	const deleteSectionLocal = useCallback(
		(id: string) => {
			deleteSection(id);
		},
		[deleteSection],
	);

	const onSelectView = useCallback(
		(viewId: string) => {
			closeIssueView();
			setCurrentView(viewId);
		},
		[closeIssueView, setCurrentView],
	);

	const onCreateViewItem = useCallback(
		(kind: ViewKind | 'SECTION', parentId?: string) => {
			fireCompoundAnalyticsEvent.NavSidebarPlusDropdownitemClicked(createAnalyticsEvent({}), kind);

			if (kind === 'SECTION') {
				createSection();
			} else {
				experience.navBar.createView.start();
				createNewView(
					kind,
					parentId,
					() => {
						experience.navBar.createView.success({
							metadata: {
								kind,
							},
						});
					},
					(error?: Error) => {
						fireCompoundAnalyticsEvent.View.createdFailure(createAnalyticsEvent({}), kind);
						experience.navBar.createView.failure({
							metadata: {
								error: error?.message,
								kind,
							},
						});
					},
				);
			}

			clearCreatedProperty();
		},
		[clearCreatedProperty, createAnalyticsEvent, createNewView, createSection],
	);

	const onCloneAsListView = useCallback(
		(viewId: string) => {
			cloneView(viewId, VIEW_KIND_TABLE);
			clearCreatedProperty();
		},
		[clearCreatedProperty, cloneView],
	);

	const onCloneAsBoardView = useCallback(
		(viewId: string) => {
			cloneView(viewId, VIEW_KIND_BOARD);
			clearCreatedProperty();
		},
		[clearCreatedProperty, cloneView],
	);

	const onCloneAsMatrixView = useCallback(
		(viewId: string) => {
			cloneView(viewId, VIEW_KIND_MATRIX);
			clearCreatedProperty();
		},
		[clearCreatedProperty, cloneView],
	);

	const onCloneAsTimelineView = useCallback(
		(viewId: string) => {
			cloneView(viewId, VIEW_KIND_TIMELINE);
			clearCreatedProperty();
		},
		[clearCreatedProperty, cloneView],
	);

	const onDeleteView = useCallback(
		async (viewId: string) => {
			setLoading(true);

			if (isCollectionView) {
				await deleteView(viewId);
				await refreshViewsRanks();
			} else {
				await deleteView(viewId);
			}

			setLoading(false);
		},
		[deleteView, isCollectionView, refreshViewsRanks],
	);

	const onRenameView = useCallback(
		(viewId: string, title: string) => {
			renameView(viewId, title, (view): void => {
				if (isClientFetchError(view?.saveError)) {
					experience.navBar.changeViewName.abort(view?.saveError);
				} else if (view && view.saveError) {
					experience.navBar.changeViewName.failure(view.saveError);
				} else if (view && !view.saveError) {
					experience.navBar.changeViewName.success();
				}
			});
		},
		[renameView],
	);

	const onAddEmoji = useCallback(
		(viewId: string, emoji: string | undefined) => {
			addViewEmoji(viewId, emoji);
		},
		[addViewEmoji],
	);

	const onRemoveEmoji = useCallback(
		(viewId: string, emoji: string | undefined) => {
			addViewEmoji(viewId, emoji);
		},
		[addViewEmoji],
	);

	// Local tree state
	const [tree, setTree] = useState(sectionsTree);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTree(sectionsTree);
	}, [sectionsTree]);

	const onExpandSection = useCallback(
		(id: ItemId) => {
			if (!tree) return;
			setTree(mutateTree(tree, id, { isExpanded: true }));
			saveSection({ isReadOnly: true, id: `${id}`, collapsed: true });
		},
		[saveSection, tree],
	);
	const onCollapseSection = useCallback(
		(id: ItemId) => {
			if (!tree) return;
			setTree(mutateTree(tree, id, { isExpanded: false }));
			saveSection({ isReadOnly: true, id: `${id}`, collapsed: false });
		},
		[saveSection, tree],
	);
	const onToggleSection = useCallback(
		(
			e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
			{ id, isExpanded }: Partial<JpdTreeItem>,
		) => {
			if (!tree || !id || !(e.target instanceof Element)) return;

			const ariaLabel = e.target.getAttribute('aria-label');
			// Prevent from toggling section if a menu button was clicked
			if (ariaLabel !== 'sidebar.ideas.more') {
				setTree(mutateTree(tree, id, { isExpanded: !isExpanded }));
				saveSection({ isReadOnly: true, id: `${id}`, collapsed: !isExpanded });
			}
		},
		[saveSection, tree],
	);

	const onDragEnd = useCallback<OnDragEnd>(
		async (source, destination, isExpandedBeforeDrag) => {
			if (!tree || !destination) {
				return;
			}

			let destinationIndex = destination.index;

			if (source.parentId === destination?.parentId && source.index === destination?.index) {
				return;
			}

			if (destinationIndex !== undefined && destinationIndex !== 0) {
				if (source.parentId === destination.parentId && destinationIndex - source.index > 0) {
					const itemId = tree.items[destination.parentId]?.children[destinationIndex];
					const rank = itemId
						? tree.items[itemId]?.data?.rank ?? tree.items[itemId]?.data?.view?.rank
						: undefined;
					destinationIndex = rank !== undefined ? rank - 1 : undefined;
				} else {
					const itemId = tree.items[destination.parentId]?.children[destinationIndex - 1];
					const rank = itemId
						? tree.items[itemId]?.data?.rank ?? tree.items[itemId]?.data?.view?.rank
						: undefined;

					destinationIndex = rank; // TODO: POL-10602 if view ranks don't start from '1', this might be incorrect
				}
			}

			experience.navBar.changeViewRank.start();

			// Moved item id
			const id = tree.items[source.parentId].children[source.index];

			// Do not allow dropping sections inside sections
			const itemType = tree.items[id]?.data?.type;
			const dstItemType = tree.items[destination.parentId]?.data?.type;
			if (itemType === 'section' && dstItemType === 'section') {
				return;
			}

			// Do not allow dropping placeholders
			if (itemType === 'placeholder' || dstItemType === 'placeholder') {
				return;
			}

			// Do not allow merging views
			if (dstItemType === 'view') {
				return;
			}

			let newTree = moveItemOnTree(tree, source, destination);
			newTree = mutateTree(newTree, id, { isExpanded: isExpandedBeforeDrag });

			const { parentId: destinationParentId } = destination;

			// Update ui
			setTree(newTree);
			setLoading(true);

			// Save view ranks.
			await rankViews(
				{
					id,
					parentId: tree.items[destinationParentId].id,
					srcParentId: tree.items[source.parentId].id,
					index: destinationIndex ?? 0,
				},
				() => {
					experience.navBar.changeViewRank.success();
				},
				(error?: Error) => {
					if (isClientFetchError(error)) {
						experience.navBar.changeViewRank.abort(error);
						return;
					}

					experience.navBar.changeViewRank.failure(error);
				},
			);
			// unlike regular views, the call to rank a collection view does not return the latest rankings
			// hence the need to refresh manually afterwards
			if (isCollectionView) {
				await refreshViewsRanks();
			}

			setLoading(false);

			fireUIAnalytics(
				createAnalyticsEvent({ action: 'dragged', actionSubject: 'navSidebarItem' }),
				String(itemType),
			);
		},
		[createAnalyticsEvent, isCollectionView, rankViews, refreshViewsRanks, tree],
	);

	// Allow dragging for a specific elements and only for a project admin
	const isDragEnabled = useCallback(
		(item: JpdTreeItem) => !isReadOnly && item.data?.type !== 'placeholder',
		[isReadOnly],
	);

	// Disable dropping for placeholders
	const isDropEnabled = useCallback(
		(item: JpdTreeItem) => !isReadOnly && item.data?.type !== 'placeholder',
		[isReadOnly],
	);

	const renderItem = useCallback<RenderItem<JpdTreeItem>>(
		({ item, draggableState }) => {
			const view: SimplifiedView | undefined = item.data?.view;
			const section: string | undefined = item.data?.section;
			const hasChildren = item.children.length > 1;
			const itemType = item.data?.type;
			return (
				<>
					{itemType === 'section' && section && (
						<ViewSectionItem
							cssFn={getViewsItemStyles}
							isDraggingPreview={draggableState === 'preview'}
							isReadOnly={isReadOnly}
							isSorting={false}
							item={item}
							section={section}
							onDelete={!hasChildren ? () => deleteSectionLocal(section) : undefined}
							onRename={renameSection(section)}
							onCollapseSection={onCollapseSection}
							onExpandSection={onExpandSection}
							onToggleSection={(e) => onToggleSection(e, item)}
						/>
					)}
					{itemType === 'view' && view && (
						<ViewItem
							cssFn={getViewsItemStyles}
							key={view.localId}
							viewId={view.localId}
							isDraggingPreview={draggableState === 'preview'}
							isReadOnly={isReadOnly}
							isSorting={false}
							onSelectView={() => onSelectView(view.localId)}
							onDelete={() => onDeleteView(view.localId)}
							onSaveAsList={() => onCloneAsListView(view.localId)}
							onSaveAsBoard={() => onCloneAsBoardView(view.localId)}
							onSaveAsMatrix={() => onCloneAsMatrixView(view.localId)}
							onSaveAsTimeline={() => onCloneAsTimelineView(view.localId)}
							onRename={(newTitle) => onRenameView(view.localId, newTitle)}
							onAddEmoji={(emoji) => onAddEmoji(view.localId, emoji)}
							onRemoveEmoji={() => onRemoveEmoji(view.localId, '')}
						/>
					)}
					{itemType === 'placeholder' && !isReadOnly && (
						<ViewCreatePopup
							onCreateView={(kind) => onCreateViewItem(kind, item.data?.parentId)}
							type={VIEW_CREATE_POPUP_TYPES.SIDEBAR_SUB_SECTION_ITEM}
							viewEntityLimitMessage={
								viewLimitType && getEntityLimitMessage(viewLimitType, viewLimitValue)
							}
						/>
					)}
				</>
			);
		},
		[
			renameSection,
			onCollapseSection,
			onExpandSection,
			isReadOnly,
			deleteSectionLocal,
			onToggleSection,
			onSelectView,
			onDeleteView,
			onCloneAsListView,
			onCloneAsBoardView,
			onCloneAsMatrixView,
			onRenameView,
			onAddEmoji,
			onRemoveEmoji,
			onCreateViewItem,
			onCloneAsTimelineView,
			viewLimitType,
			viewLimitValue,
			getEntityLimitMessage,
		],
	);

	return (
		<IdeasSectionWrapper>
			{whyDidYouRenderPlaceholder('LeftSidebarIdeasSection')}
			{loading && (
				<SpinnerWrapper>
					<Spinner size="medium" />
				</SpinnerWrapper>
			)}
			<IdeasViewItemWrapper $loading={loading}>
				{viewIdsInCaptureSection.map((viewId) => (
					<ViewItem
						cssFn={getViewsItemStyles}
						key={viewId}
						viewId={viewId}
						onRename={(newTitle) => onRenameView(viewId, newTitle)}
						isReadOnly={isReadOnly}
						isSorting={false}
						onAddEmoji={(emoji) => onAddEmoji(viewId, emoji)}
						onRemoveEmoji={() => onRemoveEmoji(viewId, '')}
						onSelectView={() => onSelectView(viewId)}
					/>
				))}
				{tree ? (
					<Tree
						tree={tree}
						renderItem={renderItem}
						onExpand={onExpandSection}
						onCollapse={onCollapseSection}
						onDragEnd={onDragEnd}
						isDragEnabled={isDragEnabled}
						isDropEnabled={isDropEnabled}
						indentPerLevel={27}
					/>
				) : null}
				<Section isScrollable>
					{!isReadOnly && (
						<ViewCreatePopup
							onCreateView={(kind) => onCreateViewItem(kind)}
							onCreateSection={isCollectionView ? undefined : () => onCreateViewItem('SECTION')}
							type={VIEW_CREATE_POPUP_TYPES.SIDEBAR_SECTION_ITEM}
							viewEntityLimitMessage={
								viewLimitType && getEntityLimitMessage(viewLimitType, viewLimitValue)
							}
							sectionEntityLimitMessage={
								sectionLimitType && getEntityLimitMessage(sectionLimitType, sectionLimitValue)
							}
						/>
					)}
				</Section>
			</IdeasViewItemWrapper>
		</IdeasSectionWrapper>
	);
});

type SidebarSectionsProps = {
	isReadOnly: boolean;
	isLandingOnOtherView?: boolean;
};

export const SidebarSections = memo<SidebarSectionsProps>(
	({ isReadOnly, isLandingOnOtherView }: SidebarSectionsProps) => {
		const { createNewView, createSection } = useViewActions();
		const isViewsLoading = useIsViewsLoading();
		const isCollectionView = useIsCollectionView();

		const { clearCreatedProperty } = useIssueActions();

		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { formatMessage } = useIntl();

		const [viewLimitType, viewLimitValue] = useHasReachedViewsLimit(false);
		const [sectionLimitType, sectionLimitValue] = useHasReachedViewsLimit(true);
		const getEntityLimitMessage = useEntityLimitMessage();

		const onCreateNewView = useCallback(
			(kind: ViewKind | 'SECTION') => {
				fireCompoundAnalyticsEvent.NavSidebarCreateViewDropdownitemClicked(
					createAnalyticsEvent({}),
					kind,
				);

				if (kind === 'SECTION') {
					createSection();
				} else {
					experience.navBar.createView.start();
					createNewView(
						kind,
						undefined,
						() => {
							experience.navBar.createView.success({
								metadata: {
									kind,
								},
							});
						},
						(error?: Error) => {
							fireCompoundAnalyticsEvent.View.createdFailure(createAnalyticsEvent({}), kind);
							experience.navBar.createView.failure({
								metadata: {
									error: error?.message,
									kind,
								},
							});
						},
					);
				}

				clearCreatedProperty();
			},
			[clearCreatedProperty, createAnalyticsEvent, createNewView, createSection],
		);

		return (
			<>
				<ScreenReaderMenuHeader />
				<Section>
					<HeadingItem headingLevel={3}>
						<HeadingContainer>
							{formatMessage(messages.viewsHeading)}
							{!isReadOnly && (
								<ViewCreatePopup
									onCreateView={(kind) => onCreateNewView(kind)}
									onCreateSection={isCollectionView ? undefined : () => onCreateNewView('SECTION')}
									type={VIEW_CREATE_POPUP_TYPES.SIDEBAR_HEADER}
									viewEntityLimitMessage={
										viewLimitType && getEntityLimitMessage(viewLimitType, viewLimitValue)
									}
									sectionEntityLimitMessage={
										sectionLimitType && getEntityLimitMessage(sectionLimitType, sectionLimitValue)
									}
								/>
							)}
						</HeadingContainer>
					</HeadingItem>
					{(isViewsLoading || isLandingOnOtherView) && (
						<>
							<ViewItemSkeleton />
							<ViewItemSkeleton />
							<ViewItemSkeleton />
							<ViewItemSkeleton />
							<ViewItemSkeleton />
						</>
					)}
					{!isLandingOnOtherView && <IdeasSection isReadOnly={isReadOnly} />}
				</Section>
			</>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	position: 'absolute',
	width: '100%',
	height: '100%',
	justifyContent: 'center',
	zIndex: 100,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeasSectionWrapper = styled.div({
	position: 'relative',
	margin: `0 ${token('space.negative.100', '-8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeasViewItemWrapper = styled.div<{ $loading: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ $loading }) => ($loading ? 0.5 : 1),
});
