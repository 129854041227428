import React from 'react';
import { Emoji } from '@atlaskit/emoji';
import { Box, Grid, Text, xcss } from '@atlaskit/primitives';
import { useFieldEmoji } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { IssueLink } from '../issue-link/index.tsx';

type LinkedIssueCardProps = {
	issueKey: string;
	summary: string;
	issueType: {
		iconUrl: string;
		name: string;
	};
	fieldKey: string; // field key is temporary measure to use field emoji before issue types are fully implemented
	isLink?: boolean;
};

export const LinkedIssueCard = ({
	issueKey,
	summary,
	issueType,
	isLink = true,
	fieldKey,
}: LinkedIssueCardProps) => {
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);

	const card = (
		<Grid alignItems="center" gap="space.050" templateColumns="auto auto" xcss={cardStyles}>
			{emoji ? (
				<Emoji emoji={emoji} fitToHeight={16} />
			) : (
				<img width={16} height={16} src={issueType.iconUrl} alt={issueType.name} />
			)}
			<Box xcss={summaryStyles}>
				<Text color={isLink ? 'color.text.selected' : undefined}>{summary}</Text>
			</Box>
		</Grid>
	);

	if (!isLink) {
		return card;
	}

	return <IssueLink issueKey={issueKey}>{card}</IssueLink>;
};

const cardStyles = xcss({
	border: '1px solid',
	borderColor: 'color.border',
	borderRadius: '4px',
	paddingBlock: 'space.025',
	paddingInlineStart: 'space.050',
	paddingInlineEnd: 'space.100',
	backgroundColor: 'elevation.surface',
	height: '24px',
	maxWidth: 'fit-content',
});

const summaryStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
