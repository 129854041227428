import React, { useCallback, useState, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import { format } from 'date-fns';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { LoadingButton } from '@atlaskit/button';
import FileIcon from '@atlaskit/icon/glyph/file';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useCurrentViewTitle,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
	VIEW_KIND_MATRIX,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { DATETIME_FORMAT } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ExportViewAsProps } from '../types.tsx';
import messages from './messages.tsx';
import { createCSVDownload, joinMatrixAsCsv } from './utils.tsx';
import { ExportBoardView } from './views/export-board-view/index.tsx';
import { ExportListView } from './views/export-list-view/index.tsx';
import { ExportMatrixView } from './views/export-matrix-view/index.tsx';
import { ExportTimelineView } from './views/export-timeline-view/index.tsx';

const getExportCsvComponentByKind = (kind: ViewKind | undefined) => {
	switch (kind) {
		case VIEW_KIND_BOARD:
			return ExportBoardView;
		case VIEW_KIND_TIMELINE:
			return ExportTimelineView;
		case VIEW_KIND_MATRIX:
			return ExportMatrixView;
		case VIEW_KIND_TABLE:
		default:
			return ExportListView;
	}
};

export const ExportViewAsCsv = ({ isLoading, onExporting, containerName }: ExportViewAsProps) => {
	const { formatMessage } = useIntl();

	const title = useCurrentViewTitle();
	const [isExportingCsv, setIsExportingCsv] = useState(false);

	const viewKind = useCurrentViewKind();
	const ExportCSVComponent = getExportCsvComponentByKind(viewKind);

	const exportAsCsv = useCallback(
		(valueMatrix: string[][]) => {
			if (title === null || title === undefined) return;
			if (containerName === undefined) return;

			const csvExport = joinMatrixAsCsv(valueMatrix);
			const filename = `${title} - ${containerName} ${format(new Date(), DATETIME_FORMAT)}.csv`;
			createCSVDownload(csvExport, filename);

			setIsExportingCsv(false);
			onExporting(false);
		},
		[title, containerName, setIsExportingCsv, onExporting],
	);

	return (
		<>
			<ExportCSVComponent>
				{(valueMatrix) => (
					<LoadingButtonContainer
						id="pendo.export-modal.export-as-csv-button"
						testId="polaris-ideas.ui.view-header.export-dialog.export-dialog-content.export-as-csv.button"
						iconBefore={<FileIcon label={formatMessage(messages.label)} size="medium" />}
						appearance="subtle"
						onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'downloadCSVButton');
							setIsExportingCsv(true);
							onExporting(true);
							exportAsCsv(valueMatrix);
						}}
						isDisabled={isLoading && !isExportingCsv}
						isLoading={isLoading && isExportingCsv}
					>
						<MessageContainer>{formatMessage(messages.button)}</MessageContainer>
					</LoadingButtonContainer>
				)}
			</ExportCSVComponent>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingButtonContainer = styled(LoadingButton)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	height: '40px !important',
	padding: `0 ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageContainer = styled.div({
	textAlign: 'start',
	lineHeight: '40px',
	fontWeight: 400,
	margin: `0 0 0 ${token('space.100', '8px')}`,
});
