/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { CollapsibleGroup } from '@atlassian/jira-issue-view-layout-group/src/ui/index.tsx';
import { useConnectionFieldsKeys } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useSelectedIssuesConnectionFieldIssuesCount } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { MAX_VISIBLE_ROWS_COUNT, ROW_HEIGHT } from './constants.tsx';
import { Content } from './content/index.tsx';
import { Header } from './header/index.tsx';

type Props = {
	fieldKey: string;
};

export const ConnectionSection = ({ fieldKey }: Props) => {
	const container = useEnvironmentContainer();
	const issuesCount = useSelectedIssuesConnectionFieldIssuesCount(fieldKey);
	const bodyHeight =
		issuesCount > 0 ? Math.min(issuesCount, MAX_VISIBLE_ROWS_COUNT) * ROW_HEIGHT : undefined;

	return (
		<CollapsibleGroup
			id={fieldKey}
			projectKey={container?.id || ''}
			title={<Header fieldKey={fieldKey} issuesCount={issuesCount} />}
			components={{
				Body: ({ children }) => (
					<div
						css={containerStyles}
						// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
						style={{ height: bodyHeight }}
					>
						{children}
					</div>
				),
			}}
			initialOpened
		>
			<Content fieldKey={fieldKey} />
		</CollapsibleGroup>
	);
};

export const ConnectionsList = () => {
	const connectionFieldsKeys = useConnectionFieldsKeys();

	return (
		<>
			{connectionFieldsKeys.map((connectionFieldKey) => (
				<ConnectionSection key={connectionFieldKey} fieldKey={connectionFieldKey} />
			))}
		</>
	);
};

const containerStyles = css({
	overflow: 'hidden',
});
