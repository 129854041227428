import React, { useRef } from 'react';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useConnectionFieldIssueIds } from '../../../../controllers/issue/selectors/connection-hooks.tsx';
import { ObservableValuesList } from '../../observable-values-list/index.tsx';
import type { ConnectionFieldProps } from '../types.tsx';
import { ConnectionFieldViewCard } from './card/index.tsx';

type Props = ConnectionFieldProps & {
	onClick: () => void;
};

export const ConnectionFieldView = ({ onClick, fieldKey, localIssueId }: Props) => {
	const listRef = useRef(null);
	const containerRef = useRef(null);
	const linkedIssueIds = useConnectionFieldIssueIds(localIssueId, fieldKey);

	const cards = linkedIssueIds.map(({ id }) => (
		<ConnectionFieldViewCard id={id} key={id} fieldKey={fieldKey} />
	));

	return (
		<Box ref={containerRef} onClick={onClick} xcss={containerStyles}>
			{linkedIssueIds.length > 1 ? (
				<Flex ref={listRef} alignItems="center" wrap="wrap" xcss={listStyles}>
					{/* TODO: add stack of ideas as tooltip */}
					<ObservableValuesList listRef={listRef} isActive containerRef={containerRef}>
						{cards}
					</ObservableValuesList>
				</Flex>
			) : (
				cards
			)}
		</Box>
	);
};

const containerStyles = xcss({
	width: '100%',
	height: '31px',
	position: 'relative',
	borderRadius: '4px',
	cursor: 'pointer',
	lineHeight: 1,
	overflow: 'hidden',
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
	},
	':empty': {
		marginTop: 'space.050',
		marginBottom: 'space.050',
	},
});

const listStyles = xcss({
	width: 'max-content',
});
