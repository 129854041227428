import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	DELIVERY_CALCULATION_STRATEGIES,
	DELIVERY_CALCULATION_MODE,
} from '@atlassian/jira-polaris-domain-field/src/presentation/constants.tsx';
import type {
	DeliveryFieldPresentationType,
	DeliveryFieldCalculationMode,
} from '@atlassian/jira-polaris-domain-field/src/presentation/types.tsx';
import type { LinkedIssuesFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/linked-issues/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useFieldActions } from '../../../../../controllers/field/main.tsx';
import {
	useFieldFormula,
	useFieldKeysOfType,
	useFieldPresentation,
	useFieldType,
} from '../../../../../controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '../../../../../controllers/issue/main.tsx';
import messages from './messages.tsx';
import { DeliveryConfigurationPreview } from './preview/index.tsx';

type Props = {
	fieldKey: FieldKey;
	readonly?: boolean;
};

const useDeliveryFieldPresentation = (fieldKey: FieldKey): DeliveryFieldPresentationType => {
	const presentation = useFieldPresentation(fieldKey);

	if (presentation?.type === DELIVERY_CALCULATION_STRATEGIES.SIMPLE) {
		return DELIVERY_CALCULATION_STRATEGIES.SIMPLE;
	}
	return DELIVERY_CALCULATION_STRATEGIES.DEFAULT;
};

function useOtherDeliveryFieldConfig(
	sourceFieldType?: typeof FIELD_TYPES.DELIVERY_PROGRESS | typeof FIELD_TYPES.DELIVERY_STATUS,
):
	| {
			fieldKey: FieldKey;
			formula: LinkedIssuesFormula;
	  }
	| undefined {
	const fieldType =
		sourceFieldType === FIELD_TYPES.DELIVERY_PROGRESS
			? FIELD_TYPES.DELIVERY_STATUS
			: FIELD_TYPES.DELIVERY_PROGRESS;

	const fieldKey = useFieldKeysOfType(fieldType)[0];
	const formula = useFieldFormula(fieldKey);

	if (sourceFieldType === undefined || formula === undefined) {
		return undefined;
	}

	return {
		fieldKey,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		formula: formula as LinkedIssuesFormula,
	};
}

const calculationTypes: DeliveryFieldPresentationType[] = [
	DELIVERY_CALCULATION_STRATEGIES.DEFAULT,
	DELIVERY_CALCULATION_STRATEGIES.SIMPLE,
];

const calculationModes: DeliveryFieldCalculationMode[] = [
	DELIVERY_CALCULATION_MODE.ISSUE_COUNT,
	DELIVERY_CALCULATION_MODE.STORY_POINTS,
];

export const DeliveryConfiguration = ({ fieldKey, readonly = false }: Props) => {
	const { formatMessage } = useIntl();
	const deliveryType = useDeliveryFieldPresentation(fieldKey);
	const { updateFieldFormula, updateDeliveryFieldPresentation } = useFieldActions();
	const { loadDeliveryProgress } = useIssueActions();
	const [calculationType, setCalculationType] = useState(deliveryType);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const formula = useFieldFormula(fieldKey) as LinkedIssuesFormula;
	const [calculationMode, setCalculationMode] = useState(
		formula?.parameters?.calculationMode || 'issue_count',
	);
	const fieldType = useFieldType(fieldKey);
	// @ts-expect-error - TS2345 - Argument of type '"PolarisIdeaStringField" | "PolarisIdeaIssueCommentsField" | "PolarisIdeaNumberField" | "PolarisIdeaOptionsField" | "PolarisIdeaOptionField" | ... 17 more ... |
	const otherDeliveryFieldConfig = useOtherDeliveryFieldConfig(fieldType);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleSelectCalculationType = useCallback(
		(newCalculationType: DeliveryFieldPresentationType) => {
			if (newCalculationType === calculationType || fieldKey === undefined) {
				return;
			}

			setCalculationType(newCalculationType);
			updateDeliveryFieldPresentation(fieldKey, newCalculationType);

			if (otherDeliveryFieldConfig?.fieldKey !== undefined) {
				updateDeliveryFieldPresentation(otherDeliveryFieldConfig.fieldKey, newCalculationType);
			}

			loadDeliveryProgress(newCalculationType, calculationMode);
		},
		[
			calculationType,
			fieldKey,
			updateDeliveryFieldPresentation,
			otherDeliveryFieldConfig,
			loadDeliveryProgress,
			calculationMode,
		],
	);

	const handleSelectCalculationMode = useCallback(
		(newMode: DeliveryFieldCalculationMode) => {
			if (newMode === calculationMode) {
				return;
			}

			if (formula !== undefined) {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'clicked',
						actionSubject: 'dropdownItem',
					}),
					newMode === DELIVERY_CALCULATION_MODE.STORY_POINTS
						? 'storyPointsDeliveryCalculation'
						: 'issueCountDeliveryCalculation',
				);

				setCalculationMode(newMode);
				const newFormula = {
					...formula,
					parameters: { ...formula.parameters, calculationMode: newMode },
				};
				updateFieldFormula(fieldKey, newFormula);

				if (otherDeliveryFieldConfig?.fieldKey !== undefined) {
					const otherDeliveryFieldFormula: LinkedIssuesFormula = {
						...otherDeliveryFieldConfig.formula,
						parameters: {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							...otherDeliveryFieldConfig.formula.parameters!,
							calculationMode: newMode,
						},
					};
					updateFieldFormula(otherDeliveryFieldConfig.fieldKey, otherDeliveryFieldFormula);
				}

				loadDeliveryProgress(calculationType, newMode);
			}
		},
		[
			calculationMode,
			formula,
			createAnalyticsEvent,
			updateFieldFormula,
			fieldKey,
			otherDeliveryFieldConfig,
			loadDeliveryProgress,
			calculationType,
		],
	);

	const messageKeyForCalculationMode = useCallback(
		(mode: string) =>
			mode === DELIVERY_CALCULATION_MODE.STORY_POINTS ? 'storyPoints' : 'issueCount',
		[],
	);

	return (
		<DeliveryConfigProgressPanel>
			<HeaderPanel>{formatMessage(messages.header)}</HeaderPanel>
			<Box xcss={descriptionPanelStyles}>
				{formatMessage(
					fieldType === FIELD_TYPES.DELIVERY_PROGRESS
						? messages.progressDescription
						: messages.statusDescription,
				)}
			</Box>
			<SelectionPanel>
				<DropdownMenu
					trigger={formatMessage(messages[calculationType])}
					testId="polaris-common.ui.config.fields.field-item.delivery.calculation-type"
				>
					<DropdownItemGroup>
						{calculationTypes.map((type) => (
							<DropdownItem
								key={type}
								isSelected={calculationType === type}
								onClick={() => handleSelectCalculationType(type)}
								isDisabled={readonly}
							>
								{formatMessage(messages[type])}
							</DropdownItem>
						))}
					</DropdownItemGroup>
				</DropdownMenu>
				<DropdownMenu
					trigger={formatMessage(messages[messageKeyForCalculationMode(calculationMode)])}
					testId="polaris-common.ui.config.fields.field-item.delivery.calculation-mode"
				>
					<DropdownItemGroup>
						{calculationModes.map((mode) => (
							<DropdownItem
								key={mode}
								isSelected={mode === calculationMode}
								onClick={() => handleSelectCalculationMode(mode)}
								isDisabled={readonly}
							>
								{formatMessage(messages[messageKeyForCalculationMode(mode)])}
							</DropdownItem>
						))}
					</DropdownItemGroup>
				</DropdownMenu>
			</SelectionPanel>
			<DeliveryConfigurationPreview
				// @ts-expect-error - TS2322 - Type '"PolarisIdeaStringField" | "PolarisIdeaIssueCommentsField" | "PolarisIdeaNumberField" | "PolarisIdeaOptionsField" | "PolarisIdeaOptionField" | ... 17 more ... | undefined' is not assignable to type 'DeliveryFieldType | undefined'.
				fieldType={fieldType}
				calculationType={calculationType}
				calculationMode={calculationMode}
				altText={formatMessage(messages[calculationType])}
			/>
		</DeliveryConfigProgressPanel>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryConfigProgressPanel = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderPanel = styled.div({
	fontWeight: 'bold',
});

const descriptionPanelStyles = xcss({
	marginTop: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectionPanel = styled.div({
	marginTop: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > *:first-child': {
		marginRight: token('space.100', '8px'),
	},
});
