import { useCallback, useEffect, useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import { useApolloClient } from '@apollo/react-hooks';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useViewIdsByViewUUID } from '@atlassian/jira-polaris-component-view-id-mapping/src/index.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { createStore, createStateHook, createActionsHook } from '@atlassian/react-sweet-state';
import type { CollabParticipant } from '../../domain/types.tsx';
import { ERROR_REASON_POSSIBLE_ACCESS_LOSS } from '../../services/polaris-api/view-collab/index.tsx';
import { actions } from './actions/index.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	criticalGetCollabTokenErrorHappened: false,
	userCache: {},
	views: {},
};

const Store = createStore({
	initialState,
	actions,
	name: 'PolarisCollabStore',
});

const useActions = createActionsHook(Store);

const useCollabPresence = createStateHook(Store, {
	selector: (state, { viewUUID }: { viewUUID: ViewUUID }) => state.views[viewUUID]?.presence || [],
});

export const usePresence = (viewUUID: ViewUUID): CollabParticipant[] => {
	const presence = useCollabPresence({ viewUUID });
	const currentUser = useAccountId();

	return useMemo(
		() =>
			uniqBy(
				presence.filter(({ clientId }) => clientId !== currentUser && clientId !== undefined),
				({ clientId }) => clientId,
			).concat(...presence.filter(({ clientId }) => clientId === undefined)),
		[currentUser, presence],
	);
};

type ViewCollabProviderProps = {
	viewUUID: ViewUUID;
};

export const ViewCollabProvider = ({ viewUUID }: ViewCollabProviderProps) => {
	const { resetProvider, updateProvider } = useActions();
	const viewId = useViewIdsByViewUUID({ viewUUID });
	const apolloClient = useApolloClient();
	const currentUser = useAccountId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { possibleAccessLossError } = useErrorHandlers();

	const onError = useCallback(
		(error: Error, cause: String) => {
			if (cause === ERROR_REASON_POSSIBLE_ACCESS_LOSS) {
				possibleAccessLossError(error);
			}
		},
		[possibleAccessLossError],
	);

	useEffect(() => {
		// @atlaskit/collab-provider works only client-side and must never be used in a SSR environment
		if (__SERVER__) {
			return;
		}

		if (!viewId || !currentUser) {
			return;
		}

		updateProvider(viewId, {
			onError,
			apolloClient,
			currentUser,
			createAnalyticsEvent,
		});

		return () => {
			resetProvider(viewId.viewUUID);
		};
	}, [
		resetProvider,
		updateProvider,
		viewId,
		currentUser,
		apolloClient,
		onError,
		createAnalyticsEvent,
	]);

	return null;
};
