import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useLinkedIssueDataByLinkedIssueId } from '../../../../../../controllers/issue/selectors/properties/linked-issues/hooks.tsx';
import { LinkedIssueCard } from '../../../../../linked-issue-card/index.tsx';
import messages from './messages.tsx';

export const connectionNoValueOptionMessage = messages.noValueOption;

const NoValueRenderer = () => {
	const { formatMessage } = useIntl();
	return <Box paddingInlineStart="space.100">{formatMessage(connectionNoValueOptionMessage)}</Box>;
};

type LinkedIssueRendererProps = {
	issueId: number;
	fieldKey: string;
};

const LinkedIssueRenderer = ({ issueId, fieldKey }: LinkedIssueRendererProps) => {
	const linkedIssue = useLinkedIssueDataByLinkedIssueId(issueId);

	if (linkedIssue === undefined) {
		return null;
	}

	return (
		<Box padding="space.025" xcss={cardStyles}>
			<LinkedIssueCard
				fieldKey={fieldKey}
				issueKey={linkedIssue.issueKey}
				summary={linkedIssue.summary}
				issueType={linkedIssue.issueType}
				isLink={false}
			/>
		</Box>
	);
};

type ConnectionOptionRendererProps = {
	field: Field;
	groupIdentity: string | undefined;
};

export const ConnectionOptionRenderer = ({
	field,
	groupIdentity,
}: ConnectionOptionRendererProps) => {
	if (groupIdentity === undefined) {
		return <NoValueRenderer />;
	}

	return <LinkedIssueRenderer issueId={parseInt(groupIdentity, 10)} fieldKey={field.key} />;
};

const cardStyles = xcss({
	width: 'fit-content',
});
