import {
	createGetAllIssueIdsMatchingConnectionFieldFilters,
	createGetConnectionFieldIssueIds,
	createGetSelectedIssuesConnectionFieldIssueIds,
	createGetSelectedIssuesConnectionFieldIssuesCount,
} from './connection.tsx';
import { createHigherLevelIssueHook2, createHigherLevelIssueHook } from './utils.tsx';

export const useConnectionFieldIssueIds = createHigherLevelIssueHook2(
	createGetConnectionFieldIssueIds,
);

export const useAllIssueIdsMatchingConnectionFieldFilters = createHigherLevelIssueHook2(
	createGetAllIssueIdsMatchingConnectionFieldFilters,
);

export const useSelectedIssuesConnectionFieldIssueIds = createHigherLevelIssueHook(
	createGetSelectedIssuesConnectionFieldIssueIds,
);
export const useSelectedIssuesConnectionFieldIssuesCount = createHigherLevelIssueHook(
	createGetSelectedIssuesConnectionFieldIssuesCount,
);
