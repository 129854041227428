import React, { useCallback, useState, useEffect } from 'react';
import { useIdeaIssueTypes } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import { useWorkflowActions } from '@atlassian/jira-polaris-common/src/controllers/workflow/main.tsx';
import { getIssueTypeExternalUuid } from '@atlassian/jira-polaris-common/src/services/jira/issue-type/index.tsx';
import { useProjectIdUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { AsyncWorkflowEditor } from '@atlassian/jira-workflow-editor/src/async.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';

export const PolarisWorkflowEditor = () => {
	const [issueTypeUuid, setIssueTypeUuid] = useState<string | undefined>(undefined);
	const { loadStatuses } = useWorkflowActions();
	const { goBack } = useRouterActions();

	const projectId = useProjectIdUnsafe();
	const [ideaTypes] = useIdeaIssueTypes();

	const onClose = useCallback(() => {
		goBack();
	}, [goBack]);

	const onSuccess = useCallback(() => {
		loadStatuses();
	}, [loadStatuses]);

	useEffect(() => {
		if (ideaTypes?.length > 0) {
			getIssueTypeExternalUuid(ideaTypes[0].jiraIssueTypeId, projectId).then(setIssueTypeUuid);
		}
	}, [ideaTypes, projectId]);

	if (issueTypeUuid === undefined || ideaTypes === undefined) {
		return null;
	}

	return (
		<AsyncWorkflowEditor
			issueTypeId={ideaTypes[0].jiraIssueTypeId}
			onClose={onClose}
			onSuccess={onSuccess}
		/>
	);
};
