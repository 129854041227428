import React, { useMemo, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldActions } from '../../../../../controllers/field/main.tsx';
import {
	useConnectionFieldFiltersAllowedFields,
	useConnectionFieldFiltersFields,
} from '../../../../../controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '../../../../../controllers/issue/index.tsx';
import { AddFilterComponent } from '../../../../filters/add/index.tsx';
import { FilterComponent } from './filter-component/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	fieldKey: FieldKey;
	readonly: boolean;
};

export const ConnectionFieldFilters = ({ fieldKey, readonly }: Props) => {
	const { formatMessage } = useIntl();
	const { updateConnectionFieldConfiguration } = useFieldActions();

	const [filterInCreation, setFilterInCreation] = useState<Field | undefined>();
	const existingFiltersFields = useConnectionFieldFiltersFields(fieldKey);
	const activeFiltersFields = useMemo(
		() =>
			filterInCreation
				? [
						...existingFiltersFields,
						{
							field: filterInCreation,
							values: [],
						},
					]
				: existingFiltersFields,
		[existingFiltersFields, filterInCreation],
	);

	const allowedFiltersFields = useConnectionFieldFiltersAllowedFields();
	const sortedAvailableFiltersFields = useMemo(
		() =>
			allowedFiltersFields
				.filter((field) => !activeFiltersFields.find((f) => f.field.key === field.key))
				.sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB)),
		[activeFiltersFields, allowedFiltersFields],
	);

	const onAddFilter = (key?: FieldKey) => {
		const newFilterField = allowedFiltersFields.find((field) => field.key === key);
		if (newFilterField) {
			setFilterInCreation(newFilterField);
		}
	};

	const { refreshConnectionFieldValues } = useIssueActions();

	const onClearFilter = async (filterFieldKey: FieldKey) => {
		setFilterInCreation(undefined);
		await updateConnectionFieldConfiguration(fieldKey, {
			fieldKey: filterFieldKey,
			values: [],
		});
		if (fg('jpd_issues_relationships')) {
			refreshConnectionFieldValues(fieldKey);
		}
	};

	const handleUpdateFilters = async (filterFieldKey: FieldKey, values: string[]) => {
		setFilterInCreation(undefined);
		await updateConnectionFieldConfiguration(fieldKey, {
			fieldKey: filterFieldKey,
			values,
		});
		if (fg('jpd_issues_relationships')) {
			refreshConnectionFieldValues(fieldKey);
		}
	};

	return (
		<Stack xcss={containerStyles} space="space.100">
			<Text weight="semibold">{formatMessage(messages.headerNonFinal)}</Text>
			{activeFiltersFields.map(({ values, field }) => (
				<Inline spread="space-between" alignBlock="center" key={field.key} space="space.050">
					<FilterComponent
						field={field}
						values={values}
						isDisabled={readonly}
						defaultOpen={filterInCreation?.key === field.key}
						onChange={handleUpdateFilters}
					/>
					{!readonly && (
						<IconButton
							appearance="subtle"
							onClick={() => onClearFilter(field.key)}
							label={formatMessage(messages.removeFilterButtonLabelNonFinal)}
							icon={(iconProps) => <TrashIcon size="small" {...iconProps} />}
						/>
					)}
				</Inline>
			))}
			{!readonly && activeFiltersFields.length === 0 && (
				<AddFilterComponent
					label={formatMessage(messages.addFilterLabelNonFinal)}
					options={sortedAvailableFiltersFields}
					onAddFilter={onAddFilter}
				/>
			)}
		</Stack>
	);
};

const containerStyles = xcss({
	paddingBlock: 'space.200',
});
